import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { SignoEmpresa } from '@interfaces/interface-bakend/entidades/signo-empresa.interface';
import { User } from '@interfaces/interface-bakend/seguridad/users/user.interface';
import { UserList } from '@interfaces/interface-bakend/seguridad/users/personas.interface';
import { PersonasService } from '@services/shared/personas.service';
import { SignoEmpresaService } from '@services/entidades/signo-empresas.service';
import { MatDialog } from '@angular/material/dialog';
import { ModalDetalleComponent } from '@components/usuarios/pages/modal-detalle/modal-detalle.component';
import { ModalDetalleGecrosComponent } from '@components/usuarios/pages/modal-detalle-gecros/modal-detalle-gecros.component';
import { PreviousRouteService } from '@services/shared/previous-route.service';
import { GuiMsjService } from '@services/shared/gui-msj.service';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-afiliados-asociados-listado',
    templateUrl: './afiliados-asociados-listado.component.html',
    styleUrls: ['./afiliados-asociados-listado.component.scss']
})
export class AfiliadosAsociadosListadoComponent implements OnInit, AfterViewInit {

    /* Data Table*/
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    displayedColumns: string[] =
        [
            'nombre', 'apellido', 'nacionalidad', 'identificacion', 'fechaNacimiento', 'email', 'telefono', '_id'
        ];
    dataSource: MatTableDataSource<User>;
    personas: UserList[];

    /* Fin Data Table*/

    signoEmpresa: SignoEmpresa;
    usuarioLoad: boolean = false;
    flagLoaded: boolean = false;

    routes: { url: string, label: string }[] = [];

    constructor(
        public dialog: MatDialog,
        private personasService: PersonasService,
        private signoEmpresaService: SignoEmpresaService,
        private router: Router,
        private previousRouteService: PreviousRouteService,
        private guiMsjService: GuiMsjService

    ) {
        this.dataSource = new MatTableDataSource();
    }

    ngOnInit(): void {
        this.previousRouteService.getRoutes().subscribe(routes => {
            this.routes = routes;
        }).unsubscribe();
        this.getEmpresaSeleccionada();
    }

    async ngAfterViewInit() {
        await this.loadDataPage();
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
    }

    async getEmpresa() {
        this.signoEmpresa = this.signoEmpresaService.getEmpresaSeleccionada();
    }

    async getEmpresaSeleccionada() {
        if (this.getEmpresa() === undefined) {
            await this.router.navigateByUrl('/especialidad-modificacion');
        }
    }

    verDetalle(idSede: string, idUser: string): void {
        const dialogRef = this.dialog.open(ModalDetalleComponent, {
            width: '600px',
            height: '300px',
            disableClose: true,
            data: { idUser, idSede }
        });

        dialogRef.afterClosed().subscribe(resp => {
        });
    }

    async verDetalleGecros(identificacion: string, user: User) {
        const dialogRef = this.dialog.open(ModalDetalleGecrosComponent, {
            width: '900px',
            height: '600px',
            disableClose: true,
            data: { identificacion, user }
        });

        dialogRef.afterClosed().subscribe(resp => {
        });
    }

    async verPersona(id: string) {
        await this.guiMsjService.msjFormSubmit('Espere');
        Swal.showLoading();
        this.personasService.setPersonaSeleccionada(await this.personasService.getById(id));
        Swal.close();
        this.router.navigateByUrl('/afiliado-modificacion');
    }

    async loadDataTable() {
        ;
        await this.personasService.getAllPersonasByEmpresa(this.signoEmpresa._id).then((afiliados: User[]) => {
            this.dataSource = new MatTableDataSource(afiliados);
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
        });
    }

    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }

    async loadDataPage() {
        await this.loadDataTable();
        this.flagLoaded = true;
        return;
    }
}
