<div class="navbar-area sticky-box-shadow"
     ngStickyNav
     stickyClass="sticky-box-shadow"
     ngStickyNav>

    <div class="container-fluid">
        <nav class="navbar navbar-expand-lg navbar-light bg-light"
             [class.active]="classApplied">
            <a class="navbar-brand"
               routerLink="/"><img class="brand"
                     src="assets/img/logo-signomedico-1.png"
                     width="40%"
                     alt="signomedico"></a>
            <button class="navbar-toggler"
                    type="button"
                    (click)="toggleClass()">
                <span class="burger-menu">
                    <span class="top-bar"></span>
                    <span class="middle-bar"></span>
                    <span class="bottom-bar"></span>
                </span>
            </button>
            <div class="collapse navbar-collapse"
                 id="navbarSupportedContent">

                <h4 *ngIf="!flagMenuLoaded">loading menu...</h4>
                <mat-progress-bar mode="indeterminate"
                                  color="primary"
                                  *ngIf="!flagMenuLoaded"></mat-progress-bar>

                <!-- MENU NO RESPONSIVE -->
                <ul class="navbar-nav"
                    *ngIf='this.flagMenuLoaded'>

                    <div *ngFor="let item of this.buildMenu">
                        <li *ngIf="item.descendants.length === 0"
                            class="nav-item megamenu"><a [routerLink]="item.menu.redirectTo"
                               class="nav-link main-menu-link"
                               routerLinkActive="active"
                               [routerLinkActiveOptions]="{exact: true}"
                               (click)="toggleClass()"> {{ item.menu.name}}
                            </a></li>

                        <ng-container
                                      *ngIf="item.menu.name == 'navbar.UL-ADMINISTRACION'; else elseTemplate">

                            <li *ngIf="item.descendants.length > 0"
                                class="nav-item megamenu"><a href="javascript:void(0)"
                                   class="nav-link"> {{
                                    item.menu.name}} <i class='bx bx-chevron-down'></i></a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item">
                                        <div class="container">
                                            <div class="row">
                                                <div class="col-lg-2 col-sm-4 col-md-3 col-6"
                                                     *ngFor="let item of item.descendants">
                                                    <div class="single-category-widget">

                                                        <a [routerLink]="item.redirectTo"
                                                           class="nav-link"
                                                           routerLinkActive="active"
                                                           [routerLinkActiveOptions]="{exact: true}"
                                                           (click)="toggleClass()">
                                                            <div class="icon">
                                                                <i [class]='item.icon'></i>
                                                            </div>
                                                            <h3> {{item.name}}
                                                            </h3>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </li>

                        </ng-container>
                        <ng-template #elseTemplate>

                            <li *ngIf="item.descendants.length > 0"
                                class="nav-item"><a href="javascript:void(0)"
                                   class="nav-link main-menu-link"> {{
                                    item.menu.name}} <i class='bx bx-chevron-down'></i></a>

                                <ul class="dropdown-menu">
                                    <li *ngFor="let item of item.descendants"
                                        class="nav-item"><a [routerLink]="item.redirectTo"
                                           class="nav-link"
                                           routerLinkActive="active"
                                           [routerLinkActiveOptions]="{exact: true}"
                                           (click)="toggleClass()"> {{item.name}} </a></li>
                                </ul>
                            </li>
                        </ng-template>
                        <br>
                    </div>
                </ul>

                <!-- MENU RESPONSIVE -->
                <ul class="navbar-nav for-responsive"
                    *ngIf='this.flagMenuLoaded'>

                    <div *ngFor="let item of this.buildMenu">
                        <li *ngIf="item.descendants.length === 0"
                            class="nav-item megamenu"><a [routerLink]="item.menu.redirectTo"
                               class="nav-link"
                               routerLinkActive="active"
                               [routerLinkActiveOptions]="{exact: true}"
                               (click)="toggleClass()"> {{item.menu.name}}
                            </a></li>

                        <li *ngIf="item.descendants.length > 0"
                            class="nav-item"><a href="javascript:void(0)"
                               class="nav-link"> {{
                                item.menu.name}} <i class='bx bx-chevron-down'></i></a>
                            <ul class="dropdown-menu">
                                <li *ngFor="let item of item.descendants"
                                    class="nav-item"><a [routerLink]="item.redirectTo"
                                       class="nav-link"
                                       routerLinkActive="active"
                                       [routerLinkActiveOptions]="{exact: true}"
                                       (click)="toggleClass()"> {{item.name}}
                                    </a>
                                </li>

                            </ul>
                        </li>

                    </div>
                    <li class="nav-item megamenu"><a routerLink="/"
                           class="nav-link"
                           routerLinkActive="active"
                           [routerLinkActiveOptions]="{exact: true}"><i class="fas fa-bell fa-2x"></i></a>
                    </li>

                </ul>
            </div>

            <div class="others-option d-flex align-items-center">
                <div class="option-item">
                    <button class="default-btn"
                            *ngIf="this.flagMenuLoaded"
                            (click)="logout()"><i class="fas fa-power-off"></i> {{ idioma | translate:
                        "form.SALIR-BTN" | async
                        }}</button>
                </div>
            </div>

        </nav>
    </div>
</div>