import { Component, OnInit, Inject, NgZone } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AuthService } from '../../services/auth/auth.service';
import { GuiToolsService } from '../../services/shared/gui-tools.service';
import Swal from 'sweetalert2';
import { Translators } from 'src/app/services/shared/translators.service';
import { APP_CONFIG, IAppConfig } from '../../app.config/app.config';
import { GuiMsjService } from 'src/app/services/shared/gui-msj.service';
import { MatDialog } from '@angular/material/dialog';
import { TermsConditions } from './terms-conditions/terms-conditions.component';
import { Register } from 'src/app/interfaces/interface-frontend/register';
import { Catalogo } from 'src/app/interfaces/interface-bakend/shared/catalogo/catalogo.interface';
import { OpenPageService } from '../../services/shared/open-page.service';
import { GecrosAfiliadosService } from 'src/app/services/entidades/gecros-afiliados.service';
import { SignoGecrosAfiliado } from 'src/app/interfaces/interface-bakend/entidades/signo-greco-afiliado.interface';
import { UsuarioService } from '../../services/shared/usuario.service';

@Component({
    selector: 'app-profile-authentication',
    templateUrl: './profile-authentication.component.html',
    styleUrls: ['./profile-authentication.component.scss'],
})
export class ProfileAuthenticationComponent implements OnInit {
    loginForm: FormGroup;
    registerForm: FormGroup;
    loadingForm = false;
    loading: Subscription;
    fieldTextType: boolean;
    idioma: Subscription;
    siteKey: string;
    showPasswordLogin: boolean;
    showPasswordRegister: boolean;
    showPasswordConfirm: boolean;
    concepto: string;
    check: boolean = false;
    flagLoadedModal = true;
    flagLoaded = false;
    flagPreCarga = false;
    flagExisteIdentificacion = false;
    selectedTipoIdentificacion: Catalogo;
    flagFormaValido = false;
    flagDataGecros: boolean = false;
    flagControlPrestaciones: boolean = false;
    flagMenorEdad: boolean = false;
    flagTitular: boolean = false;
    idPasaporte: string;
    isDisabled: boolean = true;
    identificacion: string = "";
    dataGecros: SignoGecrosAfiliado = undefined;
    email: any;
    afiliadoGecrosNombre: string = "";
    signoSedeCodigo: string;
    signoPlan: string;
    seleccionado: boolean;

    showTimeoutMessage: boolean = false;
    viewSpinner: boolean = false;
    searchTimeout: any;

    constructor(
        private authservice: AuthService,
        private guiToolsService: GuiToolsService,
        private translatorService: Translators,
        @Inject(APP_CONFIG) private iAppConfig: IAppConfig,
        public guiMsjService: GuiMsjService,
        public dialog: MatDialog,
        private openPageService: OpenPageService,
        private gecrosAfiliadosService: GecrosAfiliadosService,
        private zone: NgZone,
        private usuarioService: UsuarioService
    ) {
        this.loading = this.guiToolsService.getLoading().subscribe(resp => {
            this.loadingForm = resp;
        });

        this.idioma = this.translatorService.getIdiomaSeleccionado().subscribe(resp => {
            this.idioma = resp;
        });

        this.createforms();

    }

    async ngOnInit() {
        this.setSiteKey(this.iAppConfig.recaptcha.siteKey);
        this.setIdPasaporte(this.iAppConfig.dniid);
        await this.authservice.getCheck$().subscribe((acepta: boolean) => {
            this.check = acepta;
        });
      

        this.createListenersStateForm();
    }

    setSiteKey(key: string) {
        this.siteKey = key;
    }

    setIdPasaporte(id: string) {
        this.idPasaporte = id;
    }

    useLanguage(idioma: string) {
        this.translatorService.setIdiomaSeleccionado(idioma);
    }

    toggleFieldTextType() {
        this.fieldTextType = !this.fieldTextType;
    }

    get emailAccess() {
        return this.loginForm.get('email').invalid && this.loginForm.get('email').touched;
    }

    get passwordAccess() {
        return this.loginForm.get('password').invalid && this.loginForm.get('password').touched;
    }

    get emailNoValido() {
        const emailControl = this.registerForm.get('email');
        const emailValue = emailControl.value;
        const emailPattern = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/;
        return !emailControl.valid && emailControl.touched && emailPattern.test(emailValue);
    }

    get passwordNoValido() {
        return this.registerForm.get('password').invalid && this.registerForm.get('password').touched;
    }

    get confirmPasswordNoValido() {
        const pass1 = this.registerForm.get('password').value;
        const pass2 = this.registerForm.get('confirmPassword').value;
        return (pass1 === pass2) ? false : true;
    }

    get confirmEmailNoValido() {
        const email1 = this.registerForm.get('email').value;
        const email2 = this.registerForm.get('confirmEmail').value;
        return (email1 === email2) ? false : true;
    }

    get getNombreNoValido() {
        return this.registerForm.get('nombrePersona').invalid && this.registerForm.get('nombrePersona').touched;
    }

    get getApellidoNoValido() {
        return this.registerForm.get('apellidoPersona').invalid && this.registerForm.get('apellidoPersona').touched;
    }

    get getIdentificacionNoValida() {
        return this.registerForm.get('identificacion').invalid && this.registerForm.get('identificacion').touched;
    }


    async login() {

        if (this.loginForm.invalid) {

            return Object.values(this.loginForm.controls).forEach(control => {
                control.markAsTouched();
            });
        }

        this.authservice.login(
            this.loginForm.value.email,
            this.loginForm.value.password
        );

        await this.guiMsjService.msjFormSubmit('Espere');
        Swal.showLoading();

    }

    createListenersStateForm() {

        this.registerForm.get('email')?.valueChanges.subscribe(() => {
            this.registerForm.get('confirmEmail')?.reset();
        });

        return this.registerForm.statusChanges.subscribe(async status => {
            if (status === 'VALID' && this.flagDataGecros && this.flagControlPrestaciones && this.flagTitular) {
                this.flagFormaValido = true;
            } else {
                this.flagFormaValido = false;
            }
        });
    }

    async registerUser() {

        if (this.registerForm.invalid) {

            return Object.values(this.registerForm.controls).forEach(control => {
                control.markAsTouched();
            });
        }
        await this.guiMsjService.msjFormSubmit('Espere');
        Swal.showLoading();
        await this.authservice.registerUser(await this.setDataForma());
    }

    inputSeleccionado() {
        this.seleccionado = true;
    }

    async setDataForma(): Promise<Register> {

        const data: Register = {
            nombrePersona: this.registerForm.value.nombrePersona,
            apellidoPersona: this.registerForm.value.apellidoPersona,
            tipoIdentificacion: this.idPasaporte,
            identificacion: this.registerForm.value.identificacion,
            email: this.registerForm.value.email,
            emailConfirm: this.registerForm.value.confirmEmail,
            password: this.registerForm.value.password,
            passwordConfirm: this.registerForm.value.confirmPassword,
            aceptaTerminos: this.registerForm.value.aceptaTerminos,
            proteccionDeDatos: this.registerForm.value.proteccionDeDatos,
            clausula: this.registerForm.value.clausula,
            fromWeb: true,
            fromApp: false,
            signoGecrosAfiliado: this.dataGecros,
            signoPlan:  this.signoPlan != undefined ? this.signoPlan : null,
            codigoGecros: this.signoSedeCodigo != undefined ? this.signoSedeCodigo : null,
            notifica: true
            //   consentimiento : this.registerForm.value.consentimiento,
        };
        return data;
    }
    
    // CREACION DE FORMULARIOS

    async createforms() {
        this.createFormLogin();
        this.createFormRegister();
        this.flagLoaded = true;
    }

    createFormLogin() {
        this.loginForm = new FormGroup({
            email: new FormControl('', [Validators.required,]),
            password: new FormControl('', [Validators.required, Validators.minLength(8)]),
        });

    }

    async buscarAfiliadoGecros() {
        if (this.identificacion && this.identificacion.trim() !== '') {
          this.viewSpinner = true;
          this.showTimeoutMessage = false;
    
          clearTimeout(this.searchTimeout);
    
          this.searchTimeout = new Promise<void>((_, reject) => {
            setTimeout(() => {
              this.zone.run(() => {
                this.showTimeoutMessage = true;
              });
              reject(new Error('Timeout'));
            }, 3000);
          });
    
    
          try {
            const resp = await Promise.race([this.gecrosAfiliadosService.getAfiliadoByIdentificacion(this.identificacion),
            this.searchTimeout
            ]);
    
            if (
                resp.data[0] !== undefined &&
                this.controlPrestaciones(resp.data[0]) &&
                this.usuarioService.esTitular(resp.data[0], true)
            ) {    
              const nombresAfiliado: string[] = resp.data[0]?.nombreAfiliado.trim().split(/\s+/);
              const apellido = nombresAfiliado.pop().trim();
              const nombresSinApellido = nombresAfiliado.join(' ');
    
              this.flagTitular = true;
              this.flagDataGecros = true;
              this.dataGecros = resp.data[0];
              this.registerForm.controls['email'].setValue(resp.data[0]?.email?.trim() || null);
              this.registerForm.controls['confirmEmail'].setValue(resp.data[0]?.email?.trim() || null);
              this.registerForm.controls['nombrePersona'].setValue(nombresSinApellido);
              this.registerForm.controls['apellidoPersona'].setValue(apellido);
              this.signoSedeCodigo = resp.data[0]?.numeroAfiliado.substring(0, 3);
              this.signoPlan = resp.data[0]?.plan;
            } else {
              this.flagTitular = false;
              this.flagDataGecros = false;
              this.dataGecros = undefined;
              this.registerForm.controls['email'].setValue(null);
              this.registerForm.controls['confirmEmail'].setValue(null);
              this.registerForm.controls['nombrePersona'].setValue(null);
              this.registerForm.controls['apellidoPersona'].setValue(null);
              this.signoSedeCodigo = null;
              this.signoPlan = null;
            }
          } catch (error) {
            console.error("El DNI del asociado es vacío ", error);
            this.showTimeoutMessage = true;
          } finally {
            clearTimeout(this.searchTimeout);
            this.viewSpinner = false;
          }
        } else {
          this.viewSpinner = false;
        }
      }

      controlPrestaciones(afiliado) {
        console.log('afiliado', afiliado);
        if (afiliado !== undefined) {
            const convenio = afiliado.convenio.trim();
            switch (convenio) {
              case 'OSOETSYLRA':
                this.flagControlPrestaciones = true;
                return true;
              case 'OSOETSYLRA_SOM':
                this.flagControlPrestaciones = true;
                return true;
              case 'OSOETSYLRA_SYM':
                this.flagControlPrestaciones = true;
                return true;
              default:
                this.flagControlPrestaciones = false;
                Swal.fire({
                    title: 'Para recibir atención por favor comunicarse con 11 5815-2731',
                    text: 'Sepa disculpar las molestias',
                    icon: 'warning',
                    showClass: {
                        popup: 'animate__animated animate__fadeInDown'
                    },
                    hideClass: {
                        popup: 'animate__animated animate__fadeOutUp'
                    }
                });
                return false;
            }
        } else {
            return false;
        }
      }

      tieneAlMenos18Anios(afiliado): boolean {
          // Obtenemos la fecha de nacimiento del afiliado
          const fechaNacimiento = new Date(afiliado.fechaNacimiento);
          // Obtenemos la fecha actual
          const fechaActual = new Date();
          // Calculamos la diferencia en milisegundos entre la fecha actual y la fecha de nacimiento
          const diferenciaMilisegundos = fechaActual.getTime() - fechaNacimiento.getTime();
          // Convertimos la diferencia de milisegundos a años
          const edadEnAnios = diferenciaMilisegundos / (1000 * 60 * 60 * 24 * 365.25);

          // Si la edad es menor a 18, mostramos un mensaje de SweetAlert y retornamos false
          if (edadEnAnios < 18) {
              this.flagMenorEdad = true;
              Swal.fire({
                  title: 'Es menor de Edad, para recibir atención por favor comunicarse con 11 5815-2731',
                  text: 'Sepa disculpar las molestias',
                  icon: 'warning',
                  showClass: {
                      popup: 'animate__animated animate__fadeInDown'
                  },
                  hideClass: {
                      popup: 'animate__animated animate__fadeOutUp'
                  }
              });
              return false;
          } else {
            this.flagMenorEdad = false;
          }
          // Si la edad es mayor o igual a 18, retornamos true
          return true;
      }

    createFormRegister() {
        this.registerForm = new FormGroup({
            email: new FormControl('', [Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$'),]),
            confirmEmail: new FormControl('', [Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$'),]),
            password: new FormControl('',  [Validators.required, Validators.pattern(/^.{8}$/)]),
            confirmPassword: new FormControl('', [Validators.required, Validators.pattern(/^.{8}$/)]),
            nombrePersona: new FormControl('', [Validators.required, Validators.minLength(3)]),
            apellidoPersona: new FormControl('', [Validators.required, Validators.minLength(3)]),
            identificacion: new FormControl('', [Validators.required, Validators.minLength(8), Validators.maxLength(15)]),
            aceptaTerminos: new FormControl('', Validators.requiredTrue),
            proteccionDeDatos: new FormControl('', Validators.requiredTrue),
            clausula: new FormControl(false),
            recaptcha: new FormControl('', Validators.required)
        });
    }

    verTerminos() {
        this.openDialog();
    }

    openDialog() {
        const dialogRef = this.dialog.open(TermsConditions, {

            width: '900px',
            height: '800px',
            disableClose: true,

        });
        dialogRef.afterClosed().subscribe(async result => { });
        return;
    }

    openBlank(type: string) {
        this.openPageService.openBlank(type);
    }

}
