import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { GuiMsjService } from 'src/app/services/shared/gui-msj.service';
import { Catalogo } from '../../../../../interfaces/interface-bakend/shared/catalogo/catalogo.interface';
import { CatalogoService } from '../../../../../services/shared/catalogo.service';

@Component({
    selector: 'app-rubro-modificacion',
    templateUrl: './rubro-modificacion.component.html',
    styleUrls: ['./rubro-modificacion.component.scss'],
})
export class RubroModificacionComponent implements OnInit {
    form: FormGroup;
    catalogo: Catalogo;
    flagLoaded = false;

    constructor(
        private router: Router,
        private catalogoService: CatalogoService,
        public guiMsjService: GuiMsjService
    ) {
        this.getConcepto();
    }

    ngOnInit(): void {
        this.getEstadoVariables();
        this.createForm();
    }

    get rubroNoValido() {
        return this.form.get('rubro').invalid && this.form.get('rubro').touched;
    }

    async getConcepto() {
        this.catalogo = await this.catalogoService.getConceptoSeleccionado();
    }

    async getEstadoVariables() {
        if (this.getConcepto() === undefined) {
            await this.router.navigateByUrl('/rubros-listado');
        } else {
            await this.getConcepto();
            this.flagLoaded = true;
        }
    }

    createForm() {
        this.form = new FormGroup({
            rubro: new FormControl(this.catalogo.concepto, [
                Validators.required,
                Validators.min(3),
            ]),
            activo: new FormControl(this.catalogo.logico_1, [
                Validators.required,
            ]),
        });
    }

    async setDataForma(): Promise<Catalogo> {
        const data: Catalogo = {
            key: null,
            concepto: this.form.value.rubro,
            logico_1: this.form.value.activo,
            parent: this.catalogo.parent,
            properties: {},
        };

        return data;
    }

    async put() {
        await this.guiMsjService.msjFormSubmit('Guardando');
        this.catalogoService.put(this.catalogo._id, await this.setDataForma());
        await this.guiMsjService.msjFormSubmit('DatosGuardados');
        this.router.navigateByUrl('/rubros-listado');
    }
}
