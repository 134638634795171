<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Inicio</a></li>
                <li><a routerLink="/usuarios-administracion">Configuración Usuarios</a></li>
                <li>Listado Usuarios</li>
            </ul>
            <h2>Usuarios</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<app-loading *ngIf="!flagLoaded" class="m-5"></app-loading><br>

<div class="profile-area pb-70">
    <div class="container">
        <div class="profile-courses-quizzes">
            <h3>Listado de Usuarios</h3>
            <a routerLink="/usuario-alta"><i class="fas fa-plus"></i> Agregar Nuevo</a>
            <hr>
            <mat-form-field>
                <mat-label>Filtrar</mat-label>
                <input matInput (keyup)="applyFilter($event)" [disabled]="!flagLoaded" placeholder="Administrador..." autocomplete="off" #input>
            </mat-form-field>

            <div class="mat-elevation-z8">

                <table mat-table [dataSource]="dataSource" matSort>

                    <!-- Column -->
                    <ng-container matColumnDef="nombre">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre </th>
                        <td mat-cell *matCellDef="let element"> {{element.nombrePersona || 'SIN DATOS PERSONALES'}} {{element.apellidoPersona}} </td>
                    </ng-container>

                    <!-- Column -->
                    <ng-container matColumnDef="perfil">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Perfil </th>
                        <td mat-cell *matCellDef="let element"> {{element.perfil?.descripcion}} </td>
                    </ng-container>

                    <!-- Column -->
                    <ng-container matColumnDef="email">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
                        <td mat-cell *matCellDef="let element"> {{element.email}} </td>
                    </ng-container>

                    <!-- Column -->
                    <ng-container matColumnDef="estado">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Estado </th>
                        <td mat-cell *matCellDef="let element">
                            <div *ngIf="element.suspendido === false"><span class="badge bg-success">Activo</span></div>
                            <div *ngIf="element.suspendido === true"><span class="badge bg-danger">Suspendido</span></div>
                        </td>
                    </ng-container>

                    <!-- Column -->
                    <ng-container matColumnDef="aceptaTerminos">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Acepta Términos </th>
                        <td mat-cell *matCellDef="let element">
                            <div *ngIf="element.aceptaTerminos === true">
                                <section class="example-section">
                                    <mat-checkbox class="example-margin" [checked]="true" disabled></mat-checkbox>
                                </section>
                            </div>
                            <div *ngIf="element.aceptaTerminos === false || element.aceptaTerminos == null">
                                <section class="example-section">
                                    <mat-checkbox class="example-margin" disabled></mat-checkbox>
                                </section>
                            </div>
                        </td>
                    </ng-container>

                    <!-- Column -->
                    <ng-container matColumnDef="email_verified">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Email Verificado </th>
                        <td mat-cell *matCellDef="let element">
                            <div *ngIf="element.email_verified === true">
                                <section class="example-section">
                                    <mat-checkbox class="example-margin" [checked]="true" disabled></mat-checkbox>
                                </section>
                            </div>
                            <div *ngIf="element.email_verified === false || element.email_verified == null">
                                <section class="example-section">
                                    <mat-checkbox class="example-margin" disabled></mat-checkbox>
                                </section>
                            </div>
                        </td>
                    </ng-container>

                    <!-- Column -->
                    <ng-container matColumnDef="pin">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> PIN </th>
                        <td mat-cell *matCellDef="let element"> <strong>{{element.pin}}</strong></td>
                    </ng-container>

                    <!-- Column -->
                    <ng-container matColumnDef="created">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Creado </th>
                        <td mat-cell *matCellDef="let element"> {{element.createdAt | date:"dd/MM/YYYY"}} </td>
                    </ng-container>

                    <!-- Column -->
                    <ng-container matColumnDef="_id">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
                        <td mat-cell *matCellDef="let element">
                            <button style="margin-right: 5px;" (click)="verUsuario(element._id)" type="button" title="Modificar datos usuario" class="btn btn-warning"><i class="fas fa-edit"></i></button>
                            <button type="button" style="margin-right: 2%;" title="Modificar contraseña" class="btn btn-danger" (click)="this.openDialogModificarClave(element._id)">
                                <i class="fa fa-key" aria-hidden="true"></i>
                            </button>
                        </td>
                    </ng-container>

                    <!-- Row shown when there is no matching data. -->
                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
                    </tr>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="example-element-row"></tr>
                </table>

                <mat-paginator [pageSizeOptions]="[50, 100, 150, 200, 500, 800]" aria-label="Select page of users"></mat-paginator>
            </div>
        </div>
    </div>
</div>