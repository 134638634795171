import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PerfilesService } from 'src/app/services/perfiles/perfiles.service';
import { GuiMsjService } from 'src/app/services/shared/gui-msj.service';
import { Catalogo } from '../../../../../interfaces/interface-bakend/shared/catalogo/catalogo.interface';
import { CatalogoService } from '../../../../../services/shared/catalogo.service';
import { CatalogoDinamico } from '../../../../../interfaces/interface-bakend/shared/catalogo/catalogo-dinamico.interface';
import { EntidadesKeys } from 'src/app/enum/entidades-keys.enum';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-especialidad-modificacion',
    templateUrl: './especialidad-modificacion.component.html',
    styleUrls: ['./especialidad-modificacion.component.scss'],
})
export class EspecialidadModificacionComponent implements OnInit{
    form: FormGroup;
    catalogo: CatalogoDinamico;
    rubros: Catalogo[];
    updatedRubro: string;

    flagLoaded = false;

    constructor(
        private router: Router,
        private catalogoService: CatalogoService,
        public guiMsjService: GuiMsjService
    ) {
        this.getRubros();
    }

    ngOnInit(): void {
        this.getEstadoVariables();
        this.createForm();
    }

    get especialidadNoValida() {
        return (
            this.form.get('especialidad').invalid &&
            this.form.get('especialidad').touched
        );
    }

    get rubroNoValido() {
        return this.form.get('rubro').invalid && this.form.get('rubro').touched;
    }

    async getConcepto() {
        this.catalogo = this.catalogoService.getConceptoSeleccionado();
    }

    async getRubros() {
        this.catalogoService
            .findDescendantsByKey(EntidadesKeys.RUBROS)
            .then((rubros: Catalogo[]) => {
                this.rubros = rubros;   
            });
    }

    async getRubroSelected() {
        if (this.catalogo?.properties)
           this.updatedRubro = this.catalogo.properties?.rubro;
    }

    async getEstadoVariables() {
        if (this.getConcepto() === undefined) {
            await this.router.navigateByUrl('/especialidades-listado');
        } else {
            await this.getConcepto();
            await this.getRubroSelected();
            this.flagLoaded = true;
        }
    }

    createForm() {
        this.form = new FormGroup({
            especialidad: new FormControl(this.catalogo?.concepto, [
                Validators.required,
                Validators.min(3),
            ]),
            rubro: new FormControl("", [Validators.required]),
            activa: new FormControl(this.catalogo?.logico_1, [
                Validators.required,
            ]),
        });
    }

    async setDataForma(): Promise<CatalogoDinamico> {
        const data: CatalogoDinamico = {
            key: null,
            concepto: this.form.value.especialidad,
            logico_1: this.form.value.activa,
            parent: this.catalogo.parent,
            properties: {
                rubro: this.form.value.rubro,
            },
        };

        return data;
    }

    async put() {
        await this.guiMsjService.msjFormSubmit('Guardando');
        this.catalogoService.put(this.catalogo._id, await this.setDataForma());
        await this.guiMsjService.msjFormSubmit('DatosGuardados');
        this.router.navigateByUrl('/especialidades-listado');
    }

    async verCentrosMedicosAsociados() {
        await this.guiMsjService.msjFormSubmit('Espere');
        Swal.showLoading();
        this.router.navigateByUrl('/centros-medicos-asociados-listado');
        Swal.close();
    }

}
