<div class="page-title-area familiar-profile"
     *ngFor="let Image of bgImage;"
     style="background: url({{Image.background}}) no-repeat center center;">
  <div class="container">
    <div class="page-title-content">
      <ul>
        <li *ngFor="let route of routes">
          <a routerLink="{{route.url}}">{{route.label}}</a>
        </li>
      </ul>
      <div class="row">
        <h2 *ngIf="this.afiliadoLogged">{{this.afiliadoLogged?.nombrePersona
          +" "+ this.afiliadoLogged?.apellidoPersona}}</h2>
        <br>
        <h3>DNI {{this.afiliadoLogged?.identificacion}}</h3>
      </div>

    </div>
  </div>
  <div class="shape9">
    <img src="assets/img/shape-signomedico.png"
         alt="image"
         width="10%" />
  </div>
</div>

<div *ngIf="flagLoading"  class="row text-center animate__animated animate__fadeIn">
    <div class="col">
        <i class="far fa-compass fa-spin fa-4x" style="color:#73253f"></i>
    </div>
</div>

<div class="user-actions col-sm-12 col-md-12 col-lg-12">
  <div class="ptb-100">
    <div class="container" >
      <div *ngIf="!flagLoading && grupoFamiliar.length > 0" class="row">
        <div class="col">
            <h2>Grupo Familiar</h2>
            <div *ngFor="let afiliado of grupoFamiliar">

              <div class="callout"
                   [ngClass]="{'callout-success': afiliado.identificacion === this.afiliadoLogged?.identificacion, 'callout-board': afiliado.identificacion !== this.afiliadoLogged?.identificacion}">
                <div class="row">
                  <div class="col">
                    <h4>{{afiliado.nombre +" "+ afiliado.apellido}}</h4>
                    <h5>{{afiliado.parentesco}}</h5>
                  </div>
                  <div class="col credencial-button">
                    <a (click)="verCredencial(afiliado.identificacion)"
                       type="button"
                       class="btn default-btn btn-lg">
                      <i class="fas fa-address-card fa-sm"></i>
                      Ver credencial
                      <span></span>
                    </a>
                  </div>
                </div>
              </div>

            </div>
          </div>
      </div>

      <div *ngIf="!flagLoading && !flagDataGecros">
        <h4>SIN FAMILIARES A CARGO</h4>
      </div>   
      <mat-divider></mat-divider>
      <app-register-new-familiar *ngIf="!flagLoading && this.flagTitular"></app-register-new-familiar>
    </div>
  </div>
</div>