import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActividadesService } from '../../../../services/ofertas-laborales/actividades.service';
import { PaisesService } from '../../../../services/shared/paises.service';
import { Actividad, DocumentosEstadosSolicitar } from '../../../../interfaces/interface-bakend/ofertas-laborales/actividad.interface';
import { PaisDisponible } from '../../../../interfaces/interface-bakend/shared/paises/pais-disponible/pais-disponible.interface';
import { SolicitudesService } from '../../../../services/ofertas-laborales/solicitudes.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MediaOfertaLaboralFileUserPipe } from '../../../../pipes/ofertas-laborales/mediaOfertaLaboralFileUser.Pipe';
import { Solicitud } from '../../../../interfaces/interface-bakend/ofertas-laborales/solicitudes.interface';
import { IAppOfertas, APP_CONFIG_OFERTAS } from '../../../../app.config/app.config-ofertas';
import { CatalogoService } from '../../../../services/shared/catalogo.service';
import { Translators } from '../../../../services/shared/translators.service';
import { GuiMsjService } from '../../../../services/shared/gui-msj.service';
import { UploadFileSolicitudComponent } from '../../common/upload-file-solicitud/upload-file-solicitud.component';
import { AgregarDocumentosExtraComponent } from './agregar-documentos-extra/agregar-documentos-extra.component';
import { User } from 'src/app/interfaces/interface-bakend/seguridad/users/user.interface';
import { SignoSede } from 'src/app/interfaces/interface-bakend/entidades/signo-sede.interface';
import { SignoSedeService } from 'src/app/services/entidades/signo-sedes.service';
import { UsuarioService } from 'src/app/services/shared/usuario.service';
import { UserEntreUserService } from '@services/shared/user-entre-user.service';
import { UserEntreUser } from '@interfaces/interface-bakend/seguridad/users/user-entre-user.interface';

declare var require: any;
const FileSaver = require('file-saver');

@Component({
    selector: 'app-solicitud',
    templateUrl: './solicitud.component.html',
    styleUrls: ['./solicitud.component.scss']
})
export class SolicitudComponent implements OnInit, OnDestroy {

    actividad: Actividad;
    pais: PaisDisponible;
    nombrePais: string;
    solicitud: Solicitud;
    user: User;
    flagLoaded = false;
    documentosEstadosSolicitar: DocumentosEstadosSolicitar[];
    activarCargaDocumentosOpcionales: boolean;
    activarCargaExtra: boolean;
    activarDerivacion: boolean

    dataModal: any;

    /* ESTADOS */
    estadoDocumentacion: string;

    /* FLAGS */
    flagDocumentacionPresentada = false;
    flagEnRevision = false;
    flagEnAuditoria = false;
    flagAprobado = false;
    flagRechazada = false;

    idAdjuntoExtra: any;

    idDniFrente: any;
    idDniDorso: any;
    idFichaMedica: any;

    /* LABELS */
    labelDocExtra = 'Cargar documentos extras';

    idioma: Subscription;
    idiomaSubscription: Subscription;
    newState: Subscription;
    newStateSubscription: Subscription;

    flagProcesandoPago = false;
    sede: SignoSede = undefined;

    beneficiario!: UserEntreUser; 

    usuario: User;

    constructor(
        @Inject(APP_CONFIG_OFERTAS) private iAppOfertas: IAppOfertas,
        private router: Router,
        private actividadesService: ActividadesService,
        private paisesService: PaisesService,
        private solicitudesService: SolicitudesService,
        public dialog: MatDialog,
        private catalogoService: CatalogoService,
        private guiMsjService: GuiMsjService,
        private translatorService: Translators,
        private signoSedeService: SignoSedeService,
        private usuarioService: UsuarioService,
        private userEntreUserService: UserEntreUserService
    ) {

        this.idiomaSubscription = this.translatorService.getIdiomaSeleccionado().subscribe(resp => {
            this.idioma = resp;
        });

        this.newStateSubscription = this.solicitudesService.getsetNewStater().subscribe(resp => {
            this.initPostChangeStatus();
            this.newState = resp;
            this.reloadCurrentRoute();
        });

    }

    ngOnInit(): void {
        this.getEstadoVariables();
        this.cargarAdjuntos();
    }

    ngOnDestroy(): void {
        if (this.idiomaSubscription) {
            this.idiomaSubscription.unsubscribe();
        }
        if (this.newStateSubscription) {
            this.newStateSubscription.unsubscribe();
        }
    }

    async initPostChangeStatus() {
        this.getEstadoVariables();
        this.cargarAdjuntos();
    }

    async cargarAdjuntos() {
        this.idAdjuntoExtra = await this.catalogoService.getFindByKey('ADJUNTO_EXTRA');
    }

    getActividad() {
        return this.actividad = this.actividadesService.getActividadSeleccionada();
    }

    getPais() {
        this.nombrePais = this.paisesService.getPaisSeleccionado().pais.name_es;
        return this.pais = this.paisesService.getPaisSeleccionado();
    }

    getSolicitud() {
        return this.solicitud = this.solicitudesService.getSolicitudSeleccionada();
    }

    async getUser(id: string) {
        return this.user = await this.usuarioService.getFindOne(id);
    }

    async getSede() {
        const sedeId = this.user.signoSedes[0];
        if (sedeId !== null && sedeId !== undefined) {
            this.sede = await this.signoSedeService.getSedeById(sedeId.toString())
        }
    }

    getEstadoSolicitud(solicitud: Solicitud) {
        console.log('solicitud.lastState.numberState', solicitud.lastState.numberState);
        switch (solicitud.lastState.numberState) {

            case 0:  // PRESENTADO
                this.activarCargaDocumentosOpcionales = true;
                this.activarCargaExtra = true;
                this.flagDocumentacionPresentada = false;
                this.flagEnRevision = false;

                this.estadoDocumentacion = this.iAppOfertas.documentacion.pendiente;

                break;

            case 1: // DOCUMENTOS PRESENTADOS
                this.activarCargaDocumentosOpcionales = true;
                this.activarCargaExtra = true;
                this.flagDocumentacionPresentada = false;
                this.flagEnRevision = false;
                this.activarDerivacion = false;

                this.estadoDocumentacion = this.iAppOfertas.documentacion.pendiente;

                break;

            case 2: // EN REVISION
                this.activarCargaDocumentosOpcionales = false;
                this.activarCargaExtra = true;
                this.activarDerivacion = true;
                this.flagDocumentacionPresentada = true; // completo la documentacion
                this.flagEnRevision = true;
                this.flagAprobado = false;

                this.estadoDocumentacion = this.iAppOfertas.documentacion.completo;

                break;

            case 3: // EN AUDITORIA MÉDICA
                this.activarCargaDocumentosOpcionales = false;
                this.activarCargaExtra = true;
                this.activarDerivacion = false;
                this.flagDocumentacionPresentada = true;
                this.flagAprobado = false;
                this.flagEnRevision = false;
                this.flagEnAuditoria = true;

                this.estadoDocumentacion = this.iAppOfertas.documentacion.completo;

                break;

            case 4: // APROBADO
                this.activarCargaDocumentosOpcionales = false;
                this.activarCargaExtra = false;
                this.flagEnAuditoria = false;
                this.flagAprobado = true;
                this.flagDocumentacionPresentada = false;

                this.estadoDocumentacion = this.iAppOfertas.documentacion.completo;

                break;

            case 5: //RECHAZADO
                this.activarCargaDocumentosOpcionales = false;
                this.activarCargaExtra = false;
                this.flagDocumentacionPresentada = false; // completo la documentacion
                this.flagAprobado = false;
                this.flagRechazada = true;

                this.estadoDocumentacion = this.iAppOfertas.documentacion.completo;

                break;

            default:
                this.activarDerivacion = true;
                this.activarCargaDocumentosOpcionales = false;
                this.activarCargaExtra = false;
                this.flagDocumentacionPresentada = false;

                break;
        }

    }

    // EL GET SOLICITUD RETORNA UN OBJECT DEL USUARIO O EL STRING DEL USUARIO
    // LO CONTROLO ANTES DE LLAMAR A this.getUser , YA QUE USAR DE PARAMETRO UN STRING
    async getSolicitantes() {

        const { usuario } = this.getSolicitud();

        if (typeof usuario === 'string') {
            // La variable usuario es un string
            // console.log('por string', this.getSolicitud().usuario.toString());
            this.usuario = await this.getUser(this.getSolicitud().usuario.toString());
        } else if (typeof usuario === 'object') {
            // La variable usuario es un objeto
            // console.log('por object', this.getSolicitud());
            this.usuario =  await this.getUser(this.getSolicitud().usuario._id);
        }
    }

    async getEstadoVariables() {
        if (this.getActividad() === undefined) {
            this.router.navigateByUrl('/');
            return;
        } else {
            this.getActividad();
            this.getPais();
            this.getEstadoSolicitud(this.getSolicitud());
            await this.documentosAsolicitar(this.getSolicitud());
            await this.getSolicitantes();
            await this.getSede();
            await this.getBeneficiario();
            this.flagLoaded = true;
        }
    }

    async getBeneficiario() {
        this.beneficiario = await this.userEntreUserService.getBeneficiario(this.solicitud);
    }

    async documentosAsolicitar(solicitud: any): Promise<DocumentosEstadosSolicitar[]> {
        this.documentosEstadosSolicitar = solicitud.documentosEstadosSolicitar;
        return this.documentosEstadosSolicitar;
    }

    /* Modal Documento Carga*/
    openDialog(label: string, estado: number, idAdjunto: string): void {
        const dialogRef = this.dialog.open(UploadFileSolicitudComponent, {
            width: '800px',
            height: '600px',
            disableClose: true,
            data: {
                solicitud: this.solicitud,
                adjunto: idAdjunto,
                label,
                numberState: estado,
                labelButton: 'ACEPTAR',
                accept: 'application/pdf, image/jpeg, image/jpg, image/png'
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.dataModal = result;
            } else {
                return;
            }
        });
    }

    /* Modal Agregar Documento Extra */
    openDialogDocExtra(label: string, idAdjunto: string): void {
        const dialogRef = this.dialog.open(AgregarDocumentosExtraComponent, {
            width: '800px',
            height: '800px',
            disableClose: true,
            data: {
                solicitud: this.solicitud,
                adjunto: idAdjunto,
                label,
                labelButton: 'ACEPTAR',
                accept: 'application/pdf, image/jpeg, image/jpg, image/png'
            }
        });

        dialogRef.afterClosed().subscribe(result => {
        });
    }

    reloadCurrentRoute() {
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.router.navigate(['solicitud']);
            return;
        });
    }

    descargarContrato(idUsuario: string, adjunto: string, nombre: string) {
        FileSaver.saveAs(this.mediaOfertaLaboralFileUser(idUsuario, adjunto), nombre);
    }

    async getNombreAdjunto(idAdjunto): Promise<string> {
        return await this.actividadesService.getNombreAdjunto(idAdjunto);
    }

    async buscarContratoAdjuntoYdescargar(solicitud: Solicitud) {
        /* buscon adjunto mediante catalogo en colleccion de aduntos */
        let descargar: string;
        const objectCatalogo: any = await this.catalogoService.getFindByKey('ADJUNTO_CONTRATO');
        const adjuntoCatalogo = objectCatalogo.properties._idAdjunto;
        for (let index = 0; index < solicitud.adjuntos.length; index++) {
            const posInicial = 0;
            const longitud = solicitud.adjuntos[index].indexOf('.');
            const idAdjunto = solicitud.adjuntos[index].substring(posInicial, longitud);
            if (idAdjunto === adjuntoCatalogo) {
                descargar = solicitud.adjuntos[index];
                index = solicitud.adjuntos.length;
            }
        }
        /* descargo adjunto */
        const nombreArchivo = this.solicitud.usuario.nombrePersona + ' ' + this.solicitud.usuario.apellidoPersona;
        this.descargarContrato(this.solicitud.usuario._id, descargar, nombreArchivo);
    }

    mediaOfertaLaboralFileUser(idUsuario: string, adjunto: string) {
        const mediaOfertaLaboralFileUser = new MediaOfertaLaboralFileUserPipe();
        return mediaOfertaLaboralFileUser.transform(idUsuario, adjunto);
    }

    async actualizarEstadoSolicitud(_id: string, numberState: number, msj: string) {
        this.solicitudesService.updateSolicitudState(_id, numberState, false).then(async (solicitudUpdated: Solicitud) => {
            this.solicitudesService.setSolicitudSeleccionada(solicitudUpdated);
            this.router.navigateByUrl('/cursos-perfil-alumno');
            this.guiMsjService.msjFormSubmit(msj);
        });
    }
}



