import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Translators } from 'src/app/services/shared/translators.service';

@Component({
  selector: 'app-ofertas-disponibles',
  templateUrl: './ofertas-disponibles.component.html',
  styleUrls: ['./ofertas-disponibles.component.scss']
})
export class OfertasDisponiblesComponent implements OnInit {

    idioma: Subscription;

    constructor(private translatorService: Translators) {
        this.idioma = this.translatorService.getIdiomaSeleccionado().subscribe(resp => {
            this.idioma = resp;
        });
    }
    ngOnInit(): void {
    }
}
