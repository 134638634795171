import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Translators } from 'src/app/services/shared/translators.service';

@Component({
  selector: 'app-vendor-about',
  templateUrl: './vendor-about.component.html',
  styleUrls: ['./vendor-about.component.scss']
})
export class VendorAboutComponent implements OnInit {

  idioma: Subscription;
  // videoHome = 'CLIP_004.mp4';
  bgImage = [
    {
        img: 'assets/img/signo-medico/banner-bg-sm-1.png'
    }
  ]

  constructor(
      private translatorService: Translators,
  ) {
    this.idioma = this.translatorService.getIdiomaSeleccionado().subscribe(resp => {
        this.idioma = resp;
    });
  }


  ngOnInit(): void {
  }

}
