
import { Sede } from '../interfaces/interface-frontend/sede.interface';

export const sedes: Sede[] = [
  {
    id: '010',
    nombre: 'SEDE OBRA SOCIAL - CABA',
    telefono1: '46122000',
    telefono2: '20588847',
    direccion: 'Culpina 25/31',
    email: 'autorizaciones@osoetsylra.org',
    horario: 'Lunes a Viernes de  9:00 a  17.30 hs',
    whatsapp: '+5491158152731'
  },
  {
    id: '020',
    nombre: 'SEDE SAN MARTÍN - ZONA NORTE Y NOROESTE',
    telefono1: '47543766',
    telefono2: '1167264034',
    email: 'admsanmartin@uoetsylra.org',
    horario: 'Lunes a Viernes de  9:00 a  17.30 hs',
    whatsapp: '+5401167264034'
  },
  {
    id: '030',
    nombre: 'SEDE SAN JUSTO - ZONA OESTE',
    telefono1: '21176073',
    telefono2: '1167083948',
    direccion: 'Juan Florio 3443',
    email: 'admsanjusto@uoetsylra.org',
    horario: 'Lunes a Viernes de  9:00 a  17.30 hs',
    whatsapp: '+5401167083948'
  },
  {
    id: '040',
    nombre: 'SEDE TEMPERLEY - ZONA SUR',
    telefono1: '52398833',
    telefono2: '1165323411',
    direccion: 'AV. MEEKS 528 entre Garibaldi y Av. Fernandez',
    email: 'admquilmes@uoetsylra.org',
    horario: 'Lunes a Viernes de  9:00 a  17.30 hs',
    whatsapp: '+5401165323411'
  },
  {
    id: '040',
    nombre: 'LA PLATA',
    telefono1: '52398833',
    telefono2: '1165323411',
    direccion: 'AV. MEEKS 528 entre Garibaldi y Av. Fernandez',
    email: 'admquilmes@uoetsylra.org',
    horario: 'Lunes a Viernes de  9:00 a  17.30 hs',
    whatsapp: '+5401165323411'
  },
  {
    id: '060',
    nombre: 'SEDE MAR DEL PLATA',
    telefono1: '02236162077',
    email: 'admmardelplata@uoetsylra.org',
    horario: 'Lunes a Viernes de  9:00 a  17.30 hs',
    whatsapp: '+5493513604235'
  },
  {
    id: '070',
    nombre: 'SEDE PERGAMINO',
    telefono1: '02477444-857',
    telefono2: '02477607121',
    email: 'admpergamino@uoetsylra.org',
    horario: 'Lunes a Viernes de  9:00 a  17.30 hs',
    whatsapp: '+5492477607121'
  },
  {
    id: '080',
    nombre: 'SEDE ROSARIO',
    telefono1: '0341627-9476',
    email: 'admrosario@uoetsylra.org',
    horario: 'Lunes a Viernes de  9:00 a  17.30 hs',
    whatsapp: '+5493412679476'
  },
  {
    id: '090',
    nombre: 'SEDE CÓRDOBA',
    telefono1: '03514323707',
    email: 'admcordoba@uoetsylra.org',
    horario: 'Lunes a Viernes de  9:00 a  17.30 hs',
    whatsapp: '+5493513604235'
  },
  {
    id: '100',
    nombre: 'SEDE MENDOZA',
    telefono1: '02618047872',
    email: 'admmendoza@uoetsylra.org',
    horario: 'Lunes a Viernes de  9:00 a  17.30 hs',
    whatsapp: '+5492616858853'
  },
  {
    id: '110',
    nombre: 'SEDE TUCUMÁN',
    telefono1: '038156868521',
    email: 'admtucuman@uoetsylra.org',
    horario: 'Lunes a Viernes de  9:00 a  17.30 hs',
    whatsapp: '+5493816868521'
  },
  // {
  //   id: '010',
  //   nombre: 'SEDE CENTRAL',
  //   telefono1: '43839674',
  //   telefono2: '43819040',
  //   direccion: 'Chile 1571',
  //   email: 'admcaba@uoetsylra.org',
  //   horario: 'Lunes a Viernes de  9:00 a  17.30 hs',
  //   whatsapp: '+5401167264034'
  // },
];

