import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PerfilesService } from 'src/app/services/perfiles/perfiles.service';
import { GuiMsjService } from 'src/app/services/shared/gui-msj.service';
import { Catalogo } from '../../../../../interfaces/interface-bakend/shared/catalogo/catalogo.interface';
import { CatalogoService } from '../../../../../services/shared/catalogo.service';

@Component({
    selector: 'app-centro-propio-especialidad-modificacion',
    templateUrl: './centro-propio-especialidad-modificacion.component.html',
    styleUrls: ['./centro-propio-especialidad-modificacion.component.scss'],
})
export class CentroPropioEspecialidadModificacionComponent
    implements OnInit
{
    form: FormGroup;
    catalogo: Catalogo;

    flagLoaded = false;

    constructor(
        private router: Router,
        private catalogoService: CatalogoService,
        public guiMsjService: GuiMsjService
    ) {}

    ngOnInit(): void {
        this.getEstadoVariables();
        this.createForm();
    }

    get especialidadNoValida() {
        return (
            this.form.get('especialidad').invalid &&
            this.form.get('especialidad').touched
        );
    }

    async getConcepto() {
        this.catalogo = this.catalogoService.getConceptoSeleccionado();
    }

    async getEstadoVariables() {
        if (this.getConcepto() === undefined) {
            await this.router.navigateByUrl('/centros-propios-especialidades-listado');
        } else {
            await this.getConcepto();
            this.flagLoaded = true;
        }
    }

    createForm() {
        this.form = new FormGroup({
            especialidad: new FormControl(this.catalogo.concepto, [
                Validators.required,
                Validators.min(3),
            ]),
            activa: new FormControl(this.catalogo.logico_1, [
                Validators.required,
            ]),
        });
    }

    async setDataForma(): Promise<Catalogo> {
        const data: Catalogo = {
            key: null,
            concepto: this.form.value.especialidad,
            logico_1: this.form.value.activa,
            parent: this.catalogo.parent,
            properties: {},
        };

        return data;
    }

    async put() {
        await this.guiMsjService.msjFormSubmit('Guardando');
        this.catalogoService.put(this.catalogo._id, await this.setDataForma());
        await this.guiMsjService.msjFormSubmit('DatosGuardados');
        this.router.navigateByUrl('/centros-propios-especialidades-listado');
    }
}
