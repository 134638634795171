<div class="page-title-area">
  <div class="container">
    <div class="page-title-content">
      <ul>
        <li><a routerLink="/">Inicio</a></li>
        <li><a routerLink="/configurar-administracion">Configuración
          Administración</a></li>
        <li><a routerLink="/sedes-listado">Listado de Sedes</a></li>
        <li>Nueva Sede</li>
      </ul>
      <h2>Nueva Sede</h2>
    </div>
  </div>
  <div class="shape9"><img src="assets/img/shape-signomedico.png" alt="image" width="10%"></div>
</div>

<div class="andorra-apply-content ptb-70  animate__animated animate__fadeIn">
  <div class="container">
    <div class="row mx-auto">
      <div class="col-lg-12 col-md-12">
        <div class="apply-form">
          <form [formGroup]="form" (ngSubmit)="createSede()">

            <div class="row">
              <div class="col-lg-6">

                <div class="form-group">
                  <mat-form-field class="example-full-width" appearance="fill" [style.width.%]=100>
                    <mat-label>Código</mat-label>
                    <input formControlName="codigo" matInput placeholder="01"
                      [class.is-invalid]="codigoNoValido" autocomplete="off" required>
                    <mat-error *ngIf="codigoNoValido">Ingrese el
                      código asociado a la sede</mat-error>
                    <mat-hint>El código de la sede son los primeros 3 digitos del numero de
                      afiliado</mat-hint>
                  </mat-form-field>
                </div>

                <div class="form-group">
                  <mat-form-field class="example-full-width" appearance="fill" [style.width.%]=100>
                    <mat-label>Email</mat-label>
                    <input formControlName="email" type="email" matInput placeholder="sede@uoetsylra.org"
                      [class.is-invalid]="emailNoValido" autocomplete="off">
                    <mat-error *ngIf="emailNoValido">Ingrese un
                      email</mat-error>
                    <mat-icon matSuffix>alternate_email</mat-icon>
                  </mat-form-field>
                </div>

                <div class="form-group">
                  <mat-form-field class="example-full-width" appearance="fill" [style.width.%]=100>
                    <mat-label>Whatsapp</mat-label>
                    <div class="whatsapp-input">
                      <input formControlName="whatsapp" type="text" matInput placeholder="11 4256 7865"
                        [class.is-invalid]="whatsappNoValido" autocomplete="off">
                      <i class="fab fa-whatsapp fa-lg" style="color: #00f53d;"></i>
                    </div>
                    <mat-error *ngIf="whatsappNoValido">
                      Ingrese un número de whatsapp válido
                    </mat-error>
                  </mat-form-field>
                </div>

                <div class="form-group">
                  <mat-form-field class="example-full-width" appearance="fill" [style.width.%]=100>
                    <mat-label>Detalle</mat-label>
                    <textarea formControlName="detalle" #detalle maxlength="256"
                      [class.is-invalid]="detalleNoValido" matInput
                      placeholder="Ingrese un detalle"></textarea>
                    <mat-error *ngIf="detalleNoValido">Ingrese
                      un detalle</mat-error>
                    <mat-hint align="end">{{detalle.value.length}} /
                      256</mat-hint>
                  </mat-form-field>
                </div>

              </div>

              <div class="col-lg-6">

                <div class="form-group">
                  <mat-form-field class="example-full-width" appearance="fill" [style.width.%]=100>
                    <mat-label>Nombre</mat-label>
                    <input formControlName="nombre" matInput placeholder="Nombre"
                      [class.is-invalid]="nombreNoValido" autocomplete="off" required>
                    <mat-error *ngIf="nombreNoValido">Ingrese un
                      nombre</mat-error>
                  </mat-form-field>
                </div>

                <div class="form-group">
                  <mat-form-field class="example-full-width" appearance="fill" [style.width.%]=100>
                    <mat-label>Teléfono</mat-label>
                    <input formControlName="telefono" type="text" matInput placeholder="11 4256 7865"
                      [class.is-invalid]="telefonoNoValido" autocomplete="off">
                      <mat-error *ngIf="telefonoNoValido">Ingrese
                        un telefono válido</mat-error>
                    <mat-icon matSuffix>phone</mat-icon>
                  </mat-form-field>
                </div>

                <div class="form-group">
                  <mat-slide-toggle formControlName="consultorioInterno">Consultorio
                    Interno</mat-slide-toggle>
                </div>

              </div>

              <div class="row">
                <div class="col-lg-4 col-md-4">
                  <button type="button" routerLink="/sedes-listado"
                    class="btn btn-warning">Cancelar</button>
                </div>

                <div class="col-lg-4 col-md-4">
                  <button [disabled]="!form.valid" type="submit">Guardar</button>
                </div>

              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>