<h2>
    {{ idioma | translate: "form.CONFIRMAR-DATOS" | async | uppercase }}</h2>
<form class="row g-3">

    <h4 class="title">Información Básica</h4>

    <div class="row form-informacion-basica gx-2 gy-4">

        <div class="col-md-4">
            <label class="form-label">{{ idioma | translate: "form.NOMBRE" | async}}</label>
            <input type="text"
                   disabled
                   class="form-control"
                   [value]="this.data.nombrePersona">
        </div>

        <div class="col-md-4">
            <label class="form-label">{{ idioma | translate: "form.APELLIDO" | async}}</label>
            <input type="text"
                   disabled
                   class="form-control"
                   [value]="this.data.apellidoPersona">
        </div>

        <div class="col-md-4">
            <label class="form-label">{{ idioma | translate: "form.TIPO-ID" | async}}</label>
            <input type="text"
                   disabled
                   class="form-control"
                   [value]="this.data.tipoIdentificacion.concepto">
        </div>

        <div class="col-md-4">
            <label class="form-label">{{ idioma | translate: "form.NRO-ID" | async}}
            </label>
            <input type="text"
                   disabled
                   class="form-control"
                   [value]="this.data.identificacion">
        </div>

        <div class="col-md-4">
            <label class="form-label">{{ idioma | translate: "form.FECHA-NACIMIENTO" | async}}
            </label>
            <input type="text"
                   disabled
                   class="form-control"
                   [(ngModel)]="birth"
                   name="birth">
        </div>

        <div class="col-md-4">
            <label class="form-label">{{ idioma | translate: "form.PAIS-NACIMIENTO" | async}}
            </label>
            <input type="text"
                   disabled
                   class="form-control"
                   [value]="this.data?.paisNacimiento?.name_es">
        </div>

        <div class="col-md-4">
            <label class="form-label">{{ idioma | translate: "form.CODIGO-AREA" | async}}</label>
            <input type="text"
                   disabled
                   class="form-control"
                   [value]="this.data?.paisCodeArea">
        </div>

        <div class="col-md-4">
            <label class="form-label">{{ idioma | translate: "form.NUMERO-TELEFONO" | async}}</label>
            <input type="text"
                   disabled
                   class="form-control"
                   [value]="this.data?.telefono">
        </div>

        <div class="col-md-4">
            <label class="form-label">Sede Seleccionada</label>
            <input type="text"
                   disabled
                   class="form-control"
                   [value]="this.sedeSelecionada">
        </div>

        <div class="col-md-4">
            <label class="form-label">Plan Seleccionado</label>
            <input type="text"
                   disabled
                   class="form-control"
                   [value]="this.planSelecionado">
        </div>

        <div class="col-md-4">
            <label class="form-label">Empresa Seleccionada</label>
            <input type="text"
                   disabled
                   class="form-control"
                   [value]="this.empresaSelecionada">
        </div>
    </div>

    <br>

    <!-- Gestion -->

    <h4 class="title">Gestión</h4>

    <div class="row form-gestion gx-2 gy-4">

        <div class="col-md-4">
            <label class="form-label">Estado</label>
            <input type="text"
                   disabled
                   class="form-control"
                   [value]="this.data?.signoGestion?.estado">
        </div>

        <div class="col-md-4">
            <label class="form-label">Fecha</label>
            <input type="text"
                   disabled
                   class="form-control"
                   [value]="dateTransform(this.data?.signoGestion?.fechaVigencia)">
        </div>

        <div class="col-md-4">
            <label class="form-label">Fecha de Baja</label>
            <input type="text"
                   disabled
                   class="form-control"
                   [value]="dateTransform(this.data?.signoGestion?.fechaBaja)">
        </div>

        <div class="col-md-4">
            <label class="form-label">Fecha de Cobertura</label>
            <input type="text"
                   disabled
                   class="form-control"
                   [value]="dateTransform(this.data?.signoGestion?.fechaCobertura)">
        </div>

        <div class="col-md-4">
            <label class="form-label">PMI</label>
            <input type="text"
                   disabled
                   class="form-control"
                   [value]="this.data?.signoGestion?.pmi ? 'Si' : 'No'">
        </div>

        <div class="col-md-4">
            <label class="form-label">Fecha Vencimiento PMI</label>
            <input type="text"
                   disabled
                   class="form-control"
                   [value]="dateTransform(this.data?.signoGestion?.fechaVtoPmi)">
        </div>

        <div class="col-md-4">
            <label class="form-label">Discapacidad</label>
            <input type="text"
                   disabled
                   class="form-control"
                   [value]="this.data?.signoGestion?.discapacidad ? 'Si' : 'No'">
        </div>

        <div class="col-md-4">
            <label class="form-label">Fecha Vencimiento Discapacidad</label>
            <input type="text"
                   disabled
                   class="form-control"
                   [value]="dateTransform(this.data?.signoGestion?.fechaVtoDiscapacidad)">
        </div>

        <div class="col-md-4">
            <label class="form-label">Otras Cronicidades</label>
            <input type="text"
                   disabled
                   class="form-control"
                   [value]="this.data?.signoGestion?.otrasCronicidades ? 'Si' : 'No'">
        </div>

        <div class="col-md-4">
            <label class="form-label">Fecha Vencimiento Otras Cronicidades</label>
            <input type="text"
                   disabled
                   class="form-control"
                   [value]="dateTransform(this.data?.signoGestion?.fechaVtoOtrasCronicidades)">
        </div>
    </div>

    <div class="d-grid gap-2 d-md-flex justify-content-md-center">
        <button (click)="aceptar()"
                class="btn btn-primary me-md-2"
                type="button">{{ idioma | translate: "form.BTN-ACEPTAR" | async}}</button>
        <button (click)="cancelar()"
                class="btn btn-secondary"
                type="button">{{ idioma | translate: "form.BTN-CANCELAR" | async}}</button>
    </div>

</form>