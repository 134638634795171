import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { SignoDoctor } from 'src/app/interfaces/interface-bakend/entidades/signo-doctor.interface';
import { SignoDoctorService } from 'src/app/services/entidades/signo-doctores.service';
import { CatalogoService } from 'src/app/services/shared/catalogo.service';
import { GuiMsjService } from 'src/app/services/shared/gui-msj.service';
import { PerfilesService } from 'src/app/services/perfiles/perfiles.service';
import { AuthService } from '@services/auth/auth.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-doctores-listado',
  templateUrl: './doctores-listado.component.html',
  styleUrls: ['./doctores-listado.component.scss']
})
export class DoctoresListadoComponent implements OnInit, AfterViewInit {
  @ViewChild('previousRoute') previousRouteLink: ElementRef;

  /* Data Table*/
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] = ['doctor', 'especialidad', 'activo', '_id'];

  dataSource: MatTableDataSource<SignoDoctor>;
  signoDoctores: SignoDoctor[];
  /* Fin Data Table*/

  flagLoaded: boolean = false;
  keyPerfil: string = '';

  constructor(
    private signoDoctorService: SignoDoctorService,
    private router: Router,
    private guiMsjService: GuiMsjService,
    private catalogoService: CatalogoService,
    private authService: AuthService,
    private perfilesService: PerfilesService,
  ) {
    this.dataSource = new MatTableDataSource();
  }

  ngOnInit(): void {
    this.getPerfil().then(() => {
      this.perfilesService.allowedByUserLogon(this.router.url, this.previousRoute, this.keyPerfil);
    });
    this.loadDataPage();
  }

  async ngAfterViewInit() {
    await this.loadDataPage();
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  get previousRoute() {
    const previousRoute = this.previousRouteLink.nativeElement.getAttribute('href');
    return previousRoute;
  }

  async getPerfil() {
    try {
      const user = await this.authService.getUserLogon();
      if (user) {
        const { key } = user?.perfil
        this.keyPerfil = key;
      }
    }
    catch (error) {
      console.log('ABM - Error al obtener el perfil del usuario', error);
      return '';
    }
  }

  async getEspecialidadConcepto(_id): Promise<string | undefined> {
    try {
      const especialidad = await this.catalogoService.getFindOne(_id);
      if (especialidad !== undefined) {
        return especialidad.concepto;
      } else {
        console.error("Especialidad no encontrada");
        return undefined;
      }
    } catch (error) {
      console.error(error, "Error al obtener el concepto de la especialidad");
      return undefined;
    }
  }

  async loadDataTable() {
    await this.signoDoctorService.getDoctores().then(async (signoDoctores: SignoDoctor[]) => {
      for (const doctor of signoDoctores) {
        const conceptoEspecialidad = await this.getEspecialidadConcepto(doctor?.especialidad);
        doctor.especialidad = { _id: conceptoEspecialidad };
      }

      this.dataSource = new MatTableDataSource(signoDoctores);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.flagLoaded = true;
    });
  }

  async loadDataPage() {
    await this.loadDataTable();
    this.flagLoaded = true;
  }

  async verDoctor(_id: string) {
    await this.guiMsjService.msjFormSubmit('Espere');
    Swal.showLoading();
    const doctor = await this.signoDoctorService.getDoctorById(_id);
    if (doctor !== undefined) {
      this.router.navigateByUrl('/doctor-modificacion');
      this.signoDoctorService.setDoctorSeleccionado(doctor);
      Swal.close();
    } else {
      Swal.close();
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event?.target as HTMLInputElement)?.value.trim().toLowerCase();
    const customFilter = (data: any, filter: string): boolean => {
      // Verifica si el valor del filtro está en las columnas
      return (
        data.especialidad._id.toLowerCase().includes(filter) ||
        data.nombre.toLowerCase().includes(filter) ||
        (data.activo ? 'Si' : 'No').toLowerCase().includes(filter)
      );
    };

    // Aplica el filtro personalizado
    this.dataSource.filter = filterValue;
    this.dataSource.filterPredicate = customFilter;
  }

}
