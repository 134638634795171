import { filter } from 'rxjs/operators';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { SignoSede } from 'src/app/interfaces/interface-bakend/entidades/signo-sede.interface';
import { SignoSedeService } from 'src/app/services/entidades/signo-sedes.service';
import { GuiMsjService } from 'src/app/services/shared/gui-msj.service';
import { MatDialog } from '@angular/material/dialog';
import { PerfilesService } from '@services/perfiles/perfiles.service';
import { AuthService } from '@services/auth/auth.service';

import Swal from 'sweetalert2';

@Component({
    selector: 'app-sedes-listado',
    templateUrl: './sedes-listado.component.html',
    styleUrls: ['./sedes-listado.component.scss'],
})
export class SedesListadoComponent implements OnInit {
    @ViewChild('previousRoute') previousRouteLink : ElementRef;

    /* Data Table*/
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    displayedColumns: string[] = [
        'codigoGecro',
        'nombre',
        'email',
        'telefono',
        'whatsapp',
        '_id',
    ];

    dataSource: MatTableDataSource<SignoSede>;
    signoSedes: SignoSede[];

    /* Fin Data Table*/

    flagLoaded = false;
    keyPerfil: string = '';

    constructor(
        private signoSedeService: SignoSedeService,
        private router: Router,
        private guiMsjService: GuiMsjService,
        private perfilesService: PerfilesService,
        private authService: AuthService,
        public dialog: MatDialog
    ) {
        this.dataSource = new MatTableDataSource();
    }

    ngOnInit(): void {
        this.getPerfil().then(() => {
            this.perfilesService.allowedByUserLogon(this.router.url,  this.previousRoute, this.keyPerfil);
        });
        this.loadDataPage();
    }

    async ngAfterViewInit() {
        await this.loadDataPage();
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
    }

    get previousRoute() {
        const previousRoute = this.previousRouteLink.nativeElement.getAttribute('href');
        return previousRoute;
    }

    async getPerfil() {
        try {
            const user = await this.authService.getUserLogon();
            if (user) {
                const { key } = user?.perfil
                this.keyPerfil = key;
            }
        }
        catch (error) {
            console.log('ABM - Error al obtener el perfil del usuario', error);
            return '';
        }
    }

    async loadDataTable() {
        await this.signoSedeService
            .getSedes()
            .then((signoSedes: SignoSede[]) => {
                this.dataSource = new MatTableDataSource(signoSedes);
                this.dataSource.sort = this.sort;
                this.dataSource.paginator = this.paginator;
                this.flagLoaded = true;
            });
    }

    async loadDataPage() {
        await this.loadDataTable();
        this.flagLoaded = true;
    }

    async verSede(_id: string) {
        await this.guiMsjService.msjFormSubmit('Espere');
        Swal.showLoading();
        const sede = await this.signoSedeService.getSedeById(_id);
        if (sede !== undefined) {
            Swal.close();
            this.router.navigateByUrl('/sede-modificacion');
            this.signoSedeService.setSedeSeleccionada(sede);
        }
        else {
            Swal.close();
        }
    }

    applyFilter(event: Event) {
        const filterValue = (event?.target as HTMLInputElement)?.value.trim().toLowerCase();
        const customFilter = (data: any, filter: string): boolean => {
            // Verifica si el valor del filtro está en las columnas
            return (
                data.codigoGecro.toLowerCase().includes(filter) ||
                data.nombre.toLowerCase().includes(filter) ||
                data.email.toLowerCase().includes(filter) ||
                data.telefono.toLowerCase().includes(filter) ||
                data.whatsapp.toLowerCase().includes(filter)
            );
        };

        // Aplica el filtro personalizado
        this.dataSource.filter = filterValue;
        this.dataSource.filterPredicate = customFilter;
    }

}
