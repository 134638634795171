<div *ngIf="activarCargaDocumentosOpcionales">
    <div
    class="alert alert-secondary d-flex align-items-center"
    role="alert">
    <svg class="bi flex-shrink-0 me-2" width="24" height="24"
        role="img" aria-label="Info:">
        <use xlink:href="#exclamation-triangle-fill" />
    </svg>
    <div>
        <strong>"Cargar la Documentación"</strong>.
    </div>
</div>

    <div class="form-group" *ngFor="let doc of documentosEstadosSolicitar; let i = index">
        <button (click)="openDialogDocUpload('cargue su archivo...',doc.documento._id, i)" *ngIf="!doc.entregado">{{doc.documento.nombre}}<span></span></button>
        <button *ngIf="doc.entregado" disabled>{{doc.documento.nombre}}<span></span></button>
    </div>
</div>

<!-- <div *ngIf="!activarCargaDocumentosOpcionales">
    <div *ngFor="let doc of documentosEstadosSolicitar; let i = index">
        <button disabled>{{doc.documento.nombre}}<span></span></button>
    </div>
</div> -->
<app-loading *ngIf="flaProcesando" class="m-5"></app-loading><br>