<div class="page-title-area credencial-profile"
     *ngFor="let Image of bgImage;"
     style="background-image: url({{Image.background}});">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li *ngFor="let route of routes">
                  <a routerLink="{{route.url}}">{{route.label}}</a>
                </li>
              </ul>
            <h2>Credencial Virtual</h2>
        </div>
    </div>
    <div class="shape9">
        <img src="assets/img/shape-signomedico.png"
             alt="image"
             width="10%" />
    </div>
</div>

<div class="user-actions col-sm-12 col-md-12 col-lg-12">

    <div class="ptb-100">
        <div class="container">
            <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-12">
                    <div *ngIf="flagDataGecros"
                         class="credencial-image">
                        <div class="container col-lg-6 col-md-6 col-sm-6">
                            <p>
                                {{ idioma | translate: "signomedico.CREDENCIAL-USO" | async}}
                            </p>
                        </div>

                        <div class="container-credencial m-4">
                            <div class="sleeve">
                                <div class="credencial selected"
                                     *ngFor="let Image of bgImage"
                                     style="background-image: url({{Image.credencial}});">
                                    <div *ngIf="this.afiliadoFromGecros">
                                        <div class="datos-afiliado">
                                            <div class="numero-afiliado">
                                                <span>{{this.numeroAfiliado}}</span>
                                            </div>
                                        </div>
                                        <div class="credencial-expire">
                                            <span class="card-text">DNI:</span>
                                            {{this.afiliadoFromGecros?.documento}}
                                            <!-- <span class="card-text">VTO:</span> 12/24 -->
                                        </div>
                                        <div class="card-holder">
                                            <span class="card-text afiliado"
                                                  [ngStyle]="{'color': '#b71540'}">
                                                {{this.afiliadoFromGecros?.nombreAfiliado}}
                                            </span>
                                        </div>
                                        <div class="card-holder">
                                            <span class="card-text">PLAN:</span>
                                            {{this.afiliadoFromGecros?.plan || "No se encontro plan"}}
                                            <span class="card-text"> {{ idioma | translate:
                                                "signomedico.OBRA-SOCIAL" |
                                                async}}</span>
                                        </div>
                                        <span class="card-validez">
                                            VÁLIDO POR 24HRS  {{ this.currentDate }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="grupo-familiar">
                            <h2>Información General</h2>
                            <div class="callout"
                                 [ngClass]="{'callout-success': this.afiliadoFromGecros?.estado === estados[0], 'callout-danger': this.afiliadoFromGecros?.estado !== estados[0]}">
                                <div class="row">
                                    <div class="col">
                                        <h5>{{this.afiliadoFromGecros?.estado}}</h5>
                                        <h6>{{this.afiliadoFromGecros?.coberturas}}</h6>
                                    </div>
                                    <div class="col cobertura">
                                        <i *ngIf="this.afiliadoFromGecros?.estado === estados[0]"
                                           class="fas fa-check-circle fa-lg"
                                           style="color: #25fb09;"></i>
                                        <i *ngIf="this.afiliadoFromGecros?.estado !== estados[0]"
                                           class="fas fa-times-circle fa-lg"
                                           style="color: #f5220a;"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <small class="mb-3">{{ idioma | translate: "signomedico.CREDENCIAL-TEXT" |
                            async}}</small>
                    </div>
                </div>
            </div>