export const URL_LABELS = {
  '/': 'Inicio',
  '/grupofamiliar-alta': 'Alta Familiar',
  '/configurar-administracion': 'Configuración Administración',
  '/afiliados-listado': 'Listado de Afiliados',
  '/afiliado-modificacion': 'Modificación Afiliado',
  '/familiares': 'Mis Familiares',
  '/mi-credencial': 'Crendencial Virtual',
  '/empresas-listado': 'Listado Empresas',
  '/empresa-modificacion': 'Modificacion Empresa',
  '/afiliados-asociados-listado': 'Listado Afiliados Asociados',
}