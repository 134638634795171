import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Catalogo } from 'src/app/interfaces/interface-bakend/shared/catalogo/catalogo.interface';
import { CatalogoService } from 'src/app/services/shared/catalogo.service';
import { GuiMsjService } from 'src/app/services/shared/gui-msj.service';
import { EntidadesKeys } from 'src/app/enum/entidades-keys.enum';
import { PerfilesService } from 'src/app/services/perfiles/perfiles.service';
import { AuthService } from '@services/auth/auth.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-centros-propios-listado',
  templateUrl: './centros-propios-listado.component.html',
  styleUrls: ['./centros-propios-listado.component.scss']
})
export class CentrosPropiosListadoComponent implements OnInit {
  @ViewChild('previousRoute') previousRouteLink: ElementRef;

  /* Data Table*/
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  displayedColumns: string[] = ['centro', 'direccion', 'telefono', 'latitud', 'longitud', 'activo', '_id'];
  dataSource: MatTableDataSource<Catalogo>;
  /* Fin Data Table*/

  flagLoaded: boolean = false;
  keyPerfil: string = '';

  constructor(
    private catalogoService: CatalogoService,
    private router: Router,
    private guiMsjService: GuiMsjService,
    private authService: AuthService,
    private perfilesService: PerfilesService
  ) {
    this.dataSource = new MatTableDataSource();
  }

  ngOnInit(): void {
    this.getPerfil().then(() => {
      this.perfilesService.allowedByUserLogon(this.router.url, this.previousRoute, this.keyPerfil);
    });
    this.loadDataPage();
  }

  async ngAfterViewInit() {
    await this.loadDataPage();
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  get previousRoute() {
    const previousRoute = this.previousRouteLink.nativeElement.getAttribute('href');
    return previousRoute;
  }

  async getPerfil() {
    try {
      const user = await this.authService.getUserLogon();
      if (user) {
        const { key } = user?.perfil
        this.keyPerfil = key;
      }
    }
    catch (error) {
      console.log('ABM - Error al obtener el perfil del usuario', error);
      return '';
    }
  }

  async verCentro(id: string) {
    await this.guiMsjService.msjFormSubmit('Espere');
    Swal.showLoading();
    const centroPropio: Catalogo = await this.catalogoService.getFindOne(id);
    if (centroPropio != undefined) {
      this.router.navigateByUrl('/centro-propio-modificacion');
      this.catalogoService.setConcepto(centroPropio);
    } else {
      Swal.close;
    }
  }

  async loadDataTable() {
    const centros: Catalogo[] = await this.catalogoService.findDescendantsByKey(EntidadesKeys.CENTROS_PROPIOS);
    this.dataSource.data = centros;
  }

  applyFilter(event: Event) {
    const filterValue = (event?.target as HTMLInputElement)?.value.trim().toLowerCase();
    const customFilter = (data: any, filter: string): boolean => {
      // Verifica si el valor del filtro está en las columnas
      return (
        data.concepto.toLowerCase().includes(filter) ||
        data.properties.direccion.toLowerCase().includes(filter) ||
        data.properties.telefono.toLowerCase().includes(filter) ||
        data.properties.latitud.toLowerCase().includes(filter) ||
        data.properties.longitud.toLowerCase().includes(filter) ||
        (data.logico_1 ? 'Si' : 'No').toLowerCase().includes(filter)
      );
    };

    // Aplica el filtro personalizado
    this.dataSource.filter = filterValue;
    this.dataSource.filterPredicate = customFilter;
  }

  async loadDataPage() {
    await this.loadDataTable();
    this.flagLoaded = true;
  }
}
