import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { GuiMsjService } from 'src/app/services/shared/gui-msj.service';
import { SignoPlan } from '@interfaces/interface-bakend/entidades/signo-plan.interface';
import { SignoPlanService } from '@services/entidades/signo-planes.service';

@Component({
  selector: 'app-plan-modificacion',
  templateUrl: './plan-modificacion.component.html',
  styleUrls: ['./plan-modificacion.component.scss']
})
export class PlanModificacionComponent implements OnInit {
  form: FormGroup;
  signoPlan: SignoPlan;
  flagLoaded: boolean = false;

  constructor(
    private router: Router,
    private signoPlanService: SignoPlanService,
    private guiMsjService: GuiMsjService
  ) { }

  ngOnInit(): void {
    this.getEstadoPlan();
    this.createForm();
  }

  get idRefNoValido() {
    return this.form.get('identificacion').invalid;
  }

  get planNoValido() {
    return this.form.get('denominacion').invalid;
  }

  createForm() {
    this.form = new FormGroup({
      identificacion: new FormControl(this.signoPlan?.idRef, [
        Validators.required,
        Validators.maxLength(50)
      ]),
      denominacion: new FormControl(this.signoPlan?.denominacion, [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(50),
      ]),
    });
  }

  async getPlan() {
    return (this.signoPlan = this.signoPlanService.getPlanSeleccionado());
  }

  async getEstadoPlan() {
    if (this.getPlan() === undefined) {
      await this.router.navigateByUrl('/planes-listado');
    } else {
      await this.getPlan();
      this.flagLoaded = true;
    }
  }

  async updatePlan() {
    await this.guiMsjService.msjFormSubmit('Guardando');
    this.signoPlanService.updatePlan(this.signoPlan._id, await this.setDataForma())
    await this.guiMsjService.msjFormSubmit('DatosGuardados');
    this.router.navigateByUrl('/planes-listado');
  }

  async setDataForma(): Promise<SignoPlan> {
    const plan: SignoPlan = {
      idRef: this.form.value.identificacion,
      denominacion: this.form.value.denominacion || "",
    };

    return plan;
  }



}
