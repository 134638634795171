import { Injectable } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';
import { Observable, Subject } from 'rxjs';
import { DatePipe, formatDate } from '@angular/common';
import { Router } from '@angular/router';


@Injectable({
    providedIn: 'root'
})
export class GuiToolsService {

    private loading = new Subject<any>();

    constructor(

        private datePipe: DatePipe,
        private router: Router,
    ) {
        this.setLoading(false);
    }

    setLoading(flag: boolean) {
        this.loading.next({ value: flag });
    }

    getLoading(): Observable<any> {
        return this.loading.asObservable();
    }

    datePipeTransform(date, format): string {
        const utcDate = new Date(date);
        const formattedDate = this.datePipe.transform(utcDate, format, 'UTC');
        return formattedDate;
    }

    dateTransform(date): string {
        date = new Date(date).toISOString();
        const day = date.substring(8, 10);
        const month = date.substring(5, 7);
        const year = date.substring(0, 4);
        return (day + '-' + month + '-' + year);
    }

    reloadCurrentRoute(component: string) {
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.router.navigate([component]);
            return;
        });
    }

    /* REFRESH FORM */
    refreshComponent(url) {
        this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(async () => {
            await this.router.navigate([url]);
        });
    }

}
