<mat-vertical-stepper  *ngIf="vertical" #stepper>
    <mat-step [editable]="false" [interacted]="false">
        <ng-template matStepLabel>{{ idioma | translate: "solicitudes.STEPPER-PRESENTADO" | async }}</ng-template>
        <p>{{ idioma | translate: "solicitudes.STEPPER-PRESENTADO-DETALLE" | async }}</p>
    </mat-step>
    <mat-step [editable]="false" [completed]='step1Completed'>
        <ng-template matStepLabel>{{ idioma | translate: "solicitudes.STEPPER-DOCUMENTOS-PRESENTADOS" | async }}</ng-template>
        <p>{{ idioma | translate: "solicitudes.STEPPER-DOCUMENTOS-PRESENTADOS-DETALLE" | async }} </p>
    </mat-step>
    <mat-step [editable]="false" [completed]='step2Completed'>
        <ng-template matStepLabel>{{ idioma | translate: "solicitudes.STEPPER-EN-REVISION" | async }}</ng-template>
        <p>{{ idioma | translate: "solicitudes.STEPPER-EN-REVISION-DETALLE" | async }}</p>
    </mat-step>
    <mat-step [editable]="false" [completed]='step3Completed'>
        <ng-template matStepLabel>{{ idioma | translate: "solicitudes.STEPPER-EN-AUDITORIA-MEDICA" | async }}</ng-template>
        <p>{{ idioma | translate: "solicitudes.STEPPER-EN-AUDITORIA-MEDICA-DETALLE" | async }}</p>
    </mat-step>
    <mat-step color="accent" [editable]="false" [completed]='step4Completed'>
        <ng-template matStepLabel>{{ idioma | translate: "solicitudes.STEPPER-APROBADO" | async }}</ng-template>
        <p>{{ idioma | translate: "solicitudes.STEPPER-APROBADO-DETALLE" | async }}</p>
    </mat-step>
    <mat-step *ngIf="stepperSet === 5" [errorMessage]="true" [completed]="true">
        <ng-template color="warn" matStepLabel>{{ idioma | translate: "solicitudes.STEPPER-RECHAZADA" | async }}</ng-template>
        <p>{{ idioma | translate: "solicitudes.STEPPER-RECHAZADA-DETALLE" | async }}</p>
    </mat-step>

    <!--
    <mat-step [editable]="false" [completed]='step4Completed'>
        <ng-template matStepLabel>{{ idioma | translate: "solicitudes.STEPPER-PAGO-2" | async }}</ng-template>
        <p>{{ idioma | translate: "solicitudes.STEPPER-PAGO-2-DETALLE" | async }}</p>
    </mat-step>
    <mat-step [editable]="false" [completed]='step1Completed'>
        <ng-template matStepLabel>{{ idioma | translate: "solicitudes.STEPPER-DATOS-PERSONALES" | async }}</ng-template>
        <p>{{ idioma | translate: "solicitudes.STEPPER-DATOS-PERSONALES-DETALLE" | async }}</p>
    </mat-step>
    <mat-step [editable]="false" [completed]='step2Completed'>
        <ng-template matStepLabel>{{ idioma | translate: "solicitudes.STEPPER-DOCUMENTOS_ENVIADOS_PARCIAL" | async }}</ng-template>
        <p>{{ idioma | translate: "solicitudes.STEPPER-DOCUMENTOS_ENVIADOS_PARCIAL_DETALLE" | async }} </p>
    </mat-step> -->
    <!-- <mat-step color="accent" [editable]="false" [completed]='step7Completed'>
        <ng-template matStepLabel>{{ idioma | translate: "solicitudes.STEPPER-ESPERA-COMPROBACION-PAGO" | async }}</ng-template>
        <p>{{ idioma | translate: "solicitudes.STEPPER-ESPERA-COMPROBACION-PAGO" | async }}</p>
    </mat-step> -->
    <!-- PONER EL step DE ANULADA 9
    <mat-step *ngIf="stepperSet === 9" [errorMessage]="true" [completed]="true">
        <ng-template color="warn" matStepLabel>Solicitud Anulada</ng-template>
        <p>Su Solicitud fue anulada</p>
    </mat-step>-->
</mat-vertical-stepper>

<mat-horizontal-stepper *ngIf="!vertical" #stepper>
    <mat-step [editable]="false">
        <p>{{ idioma | translate: "solicitudes.STEPPER-PRESENTADO-DETALLE" | async }}</p>
    </mat-step>
    <mat-step [editable]="false" [completed]='step1Completed'>
        <p>{{ idioma | translate: "solicitudes.STEPPER-DOCUMENTOS-PRESENTADOS-DETALLE" | async }} </p>
    </mat-step>
    <mat-step [editable]="false" [completed]='step2Completed'>
        <p>{{ idioma | translate: "solicitudes.STEPPER-EN-REVISION-DETALLE" | async }}</p>
    </mat-step>
    <mat-step [editable]="false" [completed]='step3Completed'>
        <p>{{ idioma | translate: "solicitudes.STEPPER-EN-AUDITORIA-MEDICA-DETALLE" | async }}</p>
    </mat-step>
    <mat-step [editable]="false" [completed]='step4Completed' >
        <p>{{ idioma | translate: "solicitudes.STEPPER-APROBADO-DETALLE" | async }}</p>
    </mat-step>
    <mat-step *ngIf="stepperSet === 5" [errorMessage]="true" [completed]="true">
        <p>{{ idioma | translate: "solicitudes.STEPPER-RECHAZADA-DETALLE" | async }}</p>
    </mat-step>

     <!-- PONER EL step DE ANULADA 9
    <mat-step *ngIf="stepperSet === 9" [errorMessage]="true" [completed]="true">
        <ng-template color="warn" matStepLabel>Solicitud Anulada</ng-template>
        <p>Su Solicitud fue anulada</p>
    </mat-step>
    <mat-step [editable]="false" [completed]='step1Completed'>
        <ng-template matStepLabel>{{ idioma | translate: "solicitudes.STEPPER-DATOS-PERSONALES" | async }}</ng-template> 
        <p>{{ idioma | translate: "solicitudes.STEPPER-DATOS-PERSONALES-DETALLE" | async }}</p>
    </mat-step>
    <mat-step [editable]="false" [completed]='step2Completed'>
        <ng-template matStepLabel>{{ idioma | translate: "solicitudes.STEPPER-DOCUMENTOS_ENVIADOS_PARCIAL" | async }}</ng-template>
        <p>{{ idioma | translate: "solicitudes.STEPPER-DOCUMENTOS_ENVIADOS_PARCIAL_DETALLE" | async }} </p>
    </mat-step>
    <mat-step [editable]="false" [completed]='step5Completed'>
        <ng-template matStepLabel>{{ idioma | translate: "solicitudes.STEPPER-PRE-APROBADO" | async }}</ng-template> 
        <p>{{ idioma | translate: "solicitudes.STEPPER-PRE-APROBADO-DETALLE" | async }}</p>
    </mat-step>
    <mat-step [editable]="false" [completed]='step6Completed'>
         <ng-template matStepLabel>{{ idioma | translate: "solicitudes.STEPPER-PAGO-2" | async }}</ng-template> 
        <p>{{ idioma | translate: "solicitudes.STEPPER-PAGO-2-DETALLE" | async }}</p>
    </mat-step>
    <mat-step [editable]="false" [completed]='step7Completed'>
         <ng-template matStepLabel>{{ idioma | translate: "solicitudes.STEPPER-ESPERA-COMPROBACION-PAGO" | async }}</ng-template> 
        <p>{{ idioma | translate: "solicitudes.STEPPER-ESPERA-COMPROBACION-PAGO" | async }}</p>
    </mat-step> -->
</mat-horizontal-stepper>

