<div class="page-title-area">
  <div class="container">
    <div class="page-title-content">
      <ul>
        <li><a routerLink="/">Inicio</a></li>
        <li><a routerLink="/configurar-administracion">Configuración
            Administración</a></li>
        <li><a routerLink="/especialidades-listado">Listado de Especialidades</a></li>
        <li>Modificar Especialidad</li>
      </ul>
      <h2>Modificar Especialidad</h2>
      <h4 *ngIf="this.catalogo">{{catalogo.concepto}}</h4>

    </div>
  </div>
  <div class="shape9"><img src="assets/img/shape-signomedico.png" alt="image" width="10%"></div>
</div>

<div class="andorra-apply-content ptb-70  animate__animated animate__backInUp">
  <div class="container">
    <div class="row mx-auto">
      <div class="col-lg-12 col-md-12">
        <div class="apply-form">
          <form [formGroup]="form" (ngSubmit)="put()">

            <div class="row align-content-center align-items-center">

              <div class="col-lg-6 col-md-6 col-sm-12">
                <div class="form-group">
                  <mat-form-field class="example-full-width" appearance="fill" [style.width.%]=100>
                    <mat-label>Especialidad</mat-label>
                    <input formControlName="especialidad" matInput placeholder="Especialidad"
                      [class.is-invalid]="especialidadNoValida" autocomplete="off">
                    <mat-error *ngIf="especialidadNoValida">Ingrese una
                      especialidad</mat-error>
                  </mat-form-field>
                </div>
              </div>

              <div class="col-lg-6 col-md-6 col-sm-12">
                <div class="form-group">
                  <mat-slide-toggle formControlName="activa">Activa</mat-slide-toggle>
                </div>
              </div>

            </div>

            <div class="row align-content-center align-items-center">

              <div class="col-lg-4 col-md-4 col-sm-6">
                <div class="form-group">
                  <mat-form-field appearance="fill" [style.width.%]=80>
                    <mat-label>Seleccionar Rubros</mat-label>
                    <mat-select formControlName="rubro" [(ngModel)]="updatedRubro">
                      <mat-option *ngFor="let rubro of rubros" [value]="rubro._id"
                        required>{{rubro.concepto}}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="rubroNoValido">Por favor seleccione una especialidad</mat-error>
                  </mat-form-field>
                </div>
              </div>

              <div class="col-lg-4 col-md-4 col-sm-4">
                <div class="form-group">
                  <button (click)="verCentrosMedicosAsociados()"
                  type="button" class=" btn btn-primary button-list-centros-medicos"
                    title="'Lista de centros médicos asociados a la especialidad">
                    <i class="fas fa-th-list" aria-hidden="false" aria-label="List icon" ></i>
                    Centros Médicos
                  </button>
                </div>
              </div>

            </div>

            <div class="row">
              <div class="col-lg-4 col-md-4">
                <button type="button" routerLink="/especialidades-listado"
                  class="btn btn-warning">Cancelar</button>
              </div>

              <div class="col-lg-4 col-md-4">
                <button [disabled]="!form.valid" type="submit">Guardar</button>
              </div>

            </div>

          </form>
        </div>
      </div>
    </div>
  </div>
</div>