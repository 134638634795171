
/* App. Setting */
import { InjectionToken } from '@angular/core';

export let APP_CONFIG = new InjectionToken('app.config');

export interface IAppConfig {
    recaptcha: { siteKey: string };
    // id Catalogo Pasaporte
    pasaporteid : string;
    dniid : string;
    // Llaves de seguridad
    clientid: string;
    clientsecret: string;
}
export const AppConfig: IAppConfig = {
    recaptcha: { siteKey: '6LeaVVYpAAAAAOz95I1CbePWs3qgoLbQohLObyn1'},
    pasaporteid : '60ddcb763cb65f332fa75b00',
    dniid : '6272eb83577d4b3218ec6ba6', // esta variable se setea cuando se registra el usuario 
    clientid: 'JUhQX1tgQ3UGKfbEdfkfDLIZ8',
    clientsecret: 'dlMzRhZTE0LmpwZyIsInBlcmZpbCI6I',
};
