<div class="page-title-area">
  <div class="container">
    <div class="page-title-content">
      <ul>
        <li><a routerLink="/">Inicio</a></li>
        <li><a routerLink="/configurar-administracion">Configuración
          Administración</a></li>
        <li><a routerLink="/sedes-listado">Listado de Planes</a></li>
        <li>Modificar Plan</li>
      </ul>
      <h2>Modificar Plan</h2>
      <h4 *ngIf="this.signoPlan">{{signoPlan?.denominacion}}</h4>
    </div>
  </div>
  <div class="shape9"><img src="assets/img/shape-signomedico.png" alt="image" width="10%"></div>
</div>

<div class="andorra-apply-content ptb-70  animate__animated animate__fadeIn">
  <div class="container">
    <div class="row mx-auto">
      <div class="col-lg-12 col-md-12">
        <div class="apply-form">
          <form [formGroup]="form" (ngSubmit)="updatePlan()">

            <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-12">

                <div class="form-group">
                  <mat-form-field class="example-full-width" appearance="fill" [style.width.%]=100>
                    <mat-label>Identificación</mat-label>
                    <input formControlName="identificacion" type="number" matInput placeholder="Identificacion"
                      [class.is-invalid]="idRefNoValido" autocomplete="off" required>
                    <mat-error *ngIf="idRefNoValido">Ingrese una identificacion </mat-error>
                  </mat-form-field>
                </div>

              </div>

              <div class="col-lg-6 col-md-6 col-sm-12">

                <div class="form-group">
                  <mat-form-field class="example-full-width" appearance="fill" [style.width.%]=100>
                    <mat-label>Plan</mat-label>
                    <input formControlName="denominacion" matInput placeholder="Plan"
                      [class.is-invalid]="planNoValido" autocomplete="off" required>
                    <mat-error *ngIf="planNoValido">Ingrese un nombre de plan</mat-error>
                  </mat-form-field>
                </div>

            </div>

              <div class="row">
                <div class="col-lg-4 col-md-4">
                  <button type="button" routerLink="/planes-listado"
                    class="btn btn-warning">Cancelar</button>
                </div>

                <div class="col-lg-4 col-md-4">
                  <button [disabled]="!form.valid" type="submit">Guardar</button>
                </div>

              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>