<div class="page-title-area">
  <div class="container">
      <div class="page-title-content">
        <ul>
            <li *ngFor="let route of routes">
              <a routerLink="{{route.url}}">{{route.label}}</a>
            </li>
          </ul>
          <h2>Listado Afiliados Asociados</h2>
      </div>
  </div>
  <div class="shape9"><img src="assets/img/shape-signomedico.png" alt="image" width="10%"></div>
</div>
<app-loading *ngIf="!flagLoaded" class="m-5">
</app-loading><br>

<div class="profile-area pb-70">
  <div class="container">
      <div class="profile-courses-quizzes">
          <h3 *ngIf="this.signoEmpresa">Listado de Afiliados a <strong>{{this.signoEmpresa.nombre}}</strong></h3>

          <mat-form-field>
              <mat-label>Filtrar</mat-label>
              <input matInput (keyup)="applyFilter($event)" placeholder="Admin" autocomplete="off">
          </mat-form-field>

          <div class="mat-elevation-z8">

              <table mat-table [dataSource]="dataSource" matSort>

                  <ng-container matColumnDef="nombre">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre </th>
                      <td mat-cell *matCellDef="let element"> {{element.nombrePersona}} </td>
                  </ng-container>

                  <ng-container matColumnDef="apellido">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Apellido </th>
                      <td mat-cell *matCellDef="let element"> {{element.apellidoPersona}} </td>
                  </ng-container>

                  <ng-container matColumnDef="nacionalidad">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Nacionalidad </th>
                      <td mat-cell *matCellDef="let element"> {{element.paisNacimientoNombreEs}} </td>
                  </ng-container>

                  <ng-container matColumnDef="identificacion">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Identificación </th>
                      <td mat-cell *matCellDef="let element"> {{element.identificacion}} </td>
                  </ng-container>

                  <ng-container matColumnDef="fechaNacimiento">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha nacimiento </th>
                      <td mat-cell *matCellDef="let element"> {{element.birth | date}} </td>
                  </ng-container>

                  <ng-container matColumnDef="email">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
                      <td mat-cell *matCellDef="let element"> {{element.email}} </td>
                  </ng-container>

                  <ng-container matColumnDef="telefono">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Teléfono </th>
                      <td mat-cell *matCellDef="let element"> {{element.telefono}} </td>
                  </ng-container>

                  <ng-container matColumnDef="_id">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
                      <td mat-cell *matCellDef="let element">
                          <button (click)="verPersona(element._id)" type="button" class="btn btn-warning"><i class="fas fa-edit"></i></button>
                      </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="example-element-row"></tr>
              </table>

          </div>
          <mat-paginator [pageSizeOptions]="[5, 10, 25, 50, 100, 150, 200]" showFirstLastButtons></mat-paginator>

      </div>
  </div>
</div>