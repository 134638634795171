import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';
import { Response } from '../../interfaces/interface-bakend/response';
import { Menu, Menues } from '@interfaces/interface-bakend/seguridad/acl/menu.interface';

const URL_BACKEND = environment.urlBackend;

@Injectable({
  providedIn: 'root'
})
export class MenuesService {

  constructor(
    private authService: AuthService,
    private httpClient: HttpClient,
  ) { }


  getItemMenue(idMenu: string) {
    return new Promise(async resolve => {
      const headers = new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });
      this.httpClient.get<Response>(`${URL_BACKEND}/menues/findOne/${idMenu}`, { headers }).subscribe(resp => {
        resolve(resp.response);
      });
    });
  }

  getFindInmediateDescendants(menuId: string, i18nFile: string, lang: string): Promise<Menu[]> {
    return new Promise(async resolve => {
      const headers = new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });
      this.httpClient.get<Response>(`${URL_BACKEND}/menues/findInmediateDescendants/${menuId}/${i18nFile}/${lang}`, { headers }).subscribe(resp => {
        resolve(resp.response);
      });
    });
  }

  findRootNodes(tipo: string, i18nFile: string, lang: string): Promise<Menu[]> {
    return new Promise(async resolve => {
      const headers = new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });
      this.httpClient.get<Response>(`${URL_BACKEND}/menues/findRootNodes/${tipo}/${i18nFile}/${lang}`, { headers }).subscribe(resp => {
        resolve(resp.response);
      });
    });
  }

  getArrayOfDescendientes(menuId: string, i18nFile: string, lang: string): Promise<Menues[]> {
    return new Promise(async resolve => {
      const headers = new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });
      this.httpClient.get<Response>(`${URL_BACKEND}/menues/findAllDescendants/${menuId}/${i18nFile}/${lang}`, { headers }).subscribe(resp => {
        resolve(resp.response);
      });
    });
  }

}
