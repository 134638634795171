<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Inicio</a></li>
                <li><a routerLink="/tramites-configuracion">Configuración de Trámites</a></li>
                <li>Listado de Trámites</li>
            </ul>
            <h2>Listado de Trámites
            </h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape-signomedico.png" width="10%"  alt="image"></div>
</div>

<app-loading *ngIf="!flagLoaded" class="m-5"></app-loading><br>

<div class="profile-area pb-70 contenido-tabla">
    <div class="container">

        <div class="profile-courses-quizzes">
            <h3>Listado de Trámites
            </h3> <a routerLink="/tramite-alta"><i class="fas fa-plus"></i> Agregar Nuevo</a>

            <mat-form-field>
                <mat-label>Filtrar</mat-label>
                <input matInput (keyup)="applyFilter($event)" placeholder="Odontología..." autocomplete="off">
            </mat-form-field>

            <div class="mat-elevation-z8">
                <table mat-table [dataSource]="dataSource" matSort>

                    <!-- Column -->
                    <ng-container matColumnDef="nombre">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre </th>
                        <td mat-cell *matCellDef="let element"> {{element.nombre}} </td>
                    </ng-container>


                    <!-- Column -->
                    <ng-container matColumnDef="pais">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> País </th>
                        <td mat-cell *matCellDef="let element"> {{element.pais}} </td>
                    </ng-container>

                    <!-- Column -->
                    <ng-container matColumnDef="vigente">

                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Estado </th>
                        <td mat-cell *matCellDef="let element">
                            <div *ngIf="element.vigente == true"><span class="badge bg-success">Vigente</span></div>
                            <div *ngIf="element.vigente == false"><span class="badge bg-danger">No vigente</span></div>
                        </td>

                    </ng-container>

                    <!-- Column -->
                    <ng-container matColumnDef="_id">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
                        <td mat-cell *matCellDef="let element">
                            <button (click)="verActividad(element._id)" type="button" class="btn btn-warning"><i class="fas fa-edit"></i></button>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="example-element-row"></tr>
                </table>
                <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]="10"></mat-paginator>

            </div>
        </div>
    </div>
</div>