import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Catalogo } from '../../../../../interfaces/interface-bakend/shared/catalogo/catalogo.interface';
import { CatalogoService } from '../../../../../services/shared/catalogo.service';
import { GuiMsjService } from 'src/app/services/shared/gui-msj.service';
import { SignoLocalidad } from 'src/app/interfaces/interface-bakend/entidades/signo-localidad.interface';
import { SignoLocalidadService } from 'src/app/services/entidades/signo-localidades.service';
import { PerfilesService } from 'src/app/services/perfiles/perfiles.service';
import { AuthService } from '@services/auth/auth.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-localidades-listado',
  templateUrl: './localidades-listado.component.html',
  styleUrls: ['./localidades-listado.component.scss']
})
export class LocalidadesListadoComponent implements OnInit, AfterViewInit {
  @ViewChild('previousRoute') previousRouteLink: ElementRef;

  /* Data Table*/
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] = ['localidad', 'zona', 'activo', 'id'];
  dataSource: MatTableDataSource<Catalogo>;
  zonas: Catalogo[] = [];
  localidades: SignoLocalidad[];
  /* Fin Data Table*/

  flagLoaded: boolean = false;
  keyPerfil: string = '';

  constructor(
    private signoLocalidadesService: SignoLocalidadService,
    private catalogoService: CatalogoService,
    private guiMsjService: GuiMsjService,
    private router: Router,
    private authService: AuthService,
    private perfilesService: PerfilesService,
  ) { }

  ngOnInit(): void {
    this.getPerfil().then(() => {
      this.perfilesService.allowedByUserLogon(this.router.url, this.previousRoute, this.keyPerfil);
    });
    this.loadDataPage();
  }

  async ngAfterViewInit() {
    await this.loadDataPage();
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  get previousRoute() {
    const previousRoute = this.previousRouteLink.nativeElement.getAttribute('href');
    return previousRoute;
  }

  async getPerfil() {
    try {
      const user = await this.authService.getUserLogon();
      if (user) {
        const { key } = user?.perfil
        this.keyPerfil = key;
      }
    }
    catch (error) {
      console.log('ABM - Error al obtener el perfil del usuario', error);
      return '';
    }
  }

  async verLocalidad(id: string) {
    await this.guiMsjService.msjFormSubmit('Espere');
    Swal.showLoading();
    const localidad: SignoLocalidad = await this.signoLocalidadesService.getLocalidadById(id);
    if (localidad !== undefined) {
      this.router.navigateByUrl('/localidad-modificacion');
      this.signoLocalidadesService.setLocalidadSeleccionada(localidad);
    }
    else {
      Swal.close();
    }
  }

  /* Cargo tabla original sin mostrar detalle de zona */
  async loadDataTable() {
    await this.signoLocalidadesService
      .getLocalidades()
      .then((localidades: SignoLocalidad[]) => {
        this.dataSource = new MatTableDataSource(localidades);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      });
  }

  /* Cargo tabla de localidades con detalle de zona, devuelve un objeto personalizado */
  async loadDataTableDetalle() {
    try {
      const localidades: SignoLocalidad[] = await this.signoLocalidadesService.getLocalidades();
      const localidadesConZona = await Promise.all(localidades.map(async (localidad) => {
        if (localidad && localidad.zona) {
          const zona = await this.catalogoService.getFindOne(localidad.zona);
          return {
            id: localidad._id,
            concepto: localidad.nombre,
            activo: localidad.activo,
            zona: zona.concepto
          };
        }
      }));
      this.dataSource = new MatTableDataSource(localidadesConZona);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    } catch (error) {
      console.error('Error cargando datos:', error);
    }
  }
  
  applyFilter(event: Event) {
    const filterValue = (event?.target as HTMLInputElement)?.value.trim().toLowerCase();
  
    this.dataSource.filter = filterValue;
    const customFilter = (data: any, filter: string): boolean => {
      return (
        data.concepto.toLowerCase().includes(filter) ||
        (data.activo ? 'Si' : 'No').toLowerCase().includes(filter) || 
        data.zona.toLowerCase().includes(filter)
      );
    };
  }  

  async loadDataPage() {
    //await this.loadDataTable();
    await this.loadDataTableDetalle();
    this.flagLoaded = true;
  }
}

