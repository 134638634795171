<footer class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>OSOETSYLRA - Signo Médico</h3>
                    <strong><a href="www.osoetsylra.com.ar" target="_blank" rel="noopener" style="color:white">www.osoetsylra.com.ar</a> / @uoetsylra_oficial</strong>
                    <br><br>
                    <p style="text-align: justify; font-size: 14px;">{{ idioma | translate:"footer.FOOTER-OBRASOCIAL" | async}}</p>
                    <br>© Todos los derechos reservados
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>{{ idioma | translate:"footer.FOOTER-DIRECCION-TITULO" | async}}</h3>
                    <ul class="footer-contact-info">
                        <li><i class='bx bx-map'></i>Culpina 31 - CABA - C1406ENA
                            <br>Buenos Aires - República
                            Argentina
                        </li>
                        <h3>{{ idioma | translate:"footer.FOOTER-TELEFONOS" | async}}</h3>
                        <p>
                            <i class='bx bx-phone'></i> EMERGENCIAS Y URGENCIAS: <strong>0800-333-1147</strong>
                            <br><i class='bx bx-phone'></i> ATENCIÓN DEL AFILIADO: <strong>0810-333-4485</strong>
                        </p>
                        <h3>{{ idioma | translate:"footer.FOOTER-TURNOS" | async}}</h3>
                        <p>
                            <i class='bx bx-mobile'></i> CULPINA: <strong>11 3921-5242</strong>
                            <br><i class='bx bx-mobile'></i> SAN MARTÍN: <strong>11 6726-4034</strong>
                        </p>
                    </ul>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <app-tecnodret></app-tecnodret>
                    <p>{{ idioma | translate:"footer.FOOTER-SALUD" | async}}</p>
                    <img
                        src="assets/img/salud.png"
                        alt="superintendenciasalud"
                        width="50%"
                    >
                    <br>
                    <h6 style="color:aliceblue"> R.N.E.M.P. No 1-1109-04</h6>
                    <br>
                    <ul class="footer-contact-info">
                        <li><i class='bx bxs-lock'></i><a
                                (click)="openBlank('TC')"
                                style="color: white; text-decoration:none;"
                            >{{ idioma | translate:"legales.LEGAL-CTF-TC" | async}} </a></li>
                        <li><i class='bx bxs-lock'></i><a
                                (click)="openBlank('PDP')"
                                style="color: white; text-decoration:none;"
                            >{{ idioma | translate:"legales.LEGAL-CTF-PDP" | async}}</a></li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="footer-bottom-area">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <p><i class='bx bx-copyright'></i>2023 SignoMédico by <a
                            target="_blank"
                            href="https://cipbyte.io/"
                        ><strong style="color:darkorange">CipByte</strong> </a></p>
                </div>
            </div>
        </div>

    </div>
    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</footer>