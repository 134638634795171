import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Curso } from '../../interfaces/interface-bakend/cursos/curso.interface';
import { Materia } from '../../interfaces/interface-bakend/cursos/materia.interface';
import { Alumno } from '../../interfaces/interface-bakend/cursos/alumno.interface';
import { StorageService } from '../shared/storage.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { GuiMsjService } from '../shared/gui-msj.service';
import { Examen } from '../../interfaces/interface-bakend/cursos/examen.interface';
import { Response } from '../../interfaces/interface-bakend/response';
import { Leccion } from '../../interfaces/interface-bakend/cursos/leccion.interface';
import { Profesor } from '../../interfaces/interface-bakend/cursos/profesor.interface';
import { CursoAlumno } from '../../interfaces/interface-bakend/cursos/cursoAlumno.interface';
import { AlumnoTitulo, Titulo } from 'src/app/interfaces/interface-bakend/cursos/titulo.interface';
import { BehaviorSubject, Subject } from 'rxjs';

const URL_BACKEND = environment.urlBackend;

@Injectable({
  providedIn: 'root'
})
export class CursosService {

  cursoSeleccionado: Curso;
  tituloSeleccionado: Titulo;
  alumno: Alumno;
  modulos: Curso[];
  examen: Examen;
  token: string = null;
  private alumnoTitulos$: BehaviorSubject<AlumnoTitulo[]> = new BehaviorSubject<AlumnoTitulo[]>(null);

  constructor(
    private httpClient: HttpClient,
    private storageService: StorageService,
    private router: Router,
    private authService: AuthService,
    private guiMsjService: GuiMsjService,
  ) {
  }

  get alumnoTitulos(){
    return this.alumnoTitulos$.asObservable();
  }

  set alumnoTituloData(data: AlumnoTitulo[]){
    this.alumnoTitulos$.next(data);
  }

  getCursosDisponibles() {
    return new Promise( async resolve => {

        const headers =  new HttpHeaders({
          'x-token': await this.authService.loadJwToken(),
        });

        this.httpClient.get<Response>(`${ URL_BACKEND }/cursos/getCursosDisponibles`, { headers }).subscribe( resp => {
          resolve(resp.response);
        });
      });
  }

  getCursosModulos(): Promise<Curso[]>{
    return new Promise( async resolve => {
  
        const headers =  new HttpHeaders({
          'x-token': await this.authService.loadJwToken(),
        });
  
        this.httpClient.get<Response>(`${ URL_BACKEND }/cursos/getCursosModulo`, { headers }).subscribe( resp => {
          resolve(resp.response);
        });
      });
  }

  setCursoSeleccionado(curso: Curso){
    this.cursoSeleccionado = curso;
    this.storageService.setCurso(curso);
  }

  getCursoSeleccionado(): Curso{
    return this.cursoSeleccionado;
  }

  setTituloSeleccionado(titulo: Titulo){
    this.tituloSeleccionado = titulo;
    this.storageService.setTitulo(titulo);
  }

  getTituloSeleccionado(): Titulo{
    return this.tituloSeleccionado;
  }

  getCursoFindOne(id: string): Promise<Curso> {
    // console.log("RUTA>>> ", `${ URL_BACKEND }/cursos/findOne/${id}`);
  return new Promise( async resolve => {

    const headers =  new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
    });

    this.httpClient.get<Response>(`${ URL_BACKEND }/cursos/findOne/${id}`, { headers }).subscribe( resp => {
      resolve(resp.response);
    });
  });
  }

  getCursoAlumnoFindOne(id: string): Promise<CursoAlumno> {
    return new Promise( async resolve => {

      const headers =  new HttpHeaders({
          'x-token': await this.authService.loadJwToken(),
      });

      this.httpClient.get<Response>(`${ URL_BACKEND }/cursos/alumnos/findOne/${id}`, { headers }).subscribe( resp => {
        resolve(resp.response);
      });
    });
  }

  updateCursoAlumnoOne(id: string, data: CursoAlumno): Promise<CursoAlumno>{
    return new Promise(async resolve =>{
      const headers = new HttpHeaders({
        'x-token': await this.authService.loadJwToken()
      });

      this.httpClient.put<Response>(`${ URL_BACKEND }/cursos/alumnos/updateOne/${id}`, data, { headers }).subscribe(resp => {
        resolve(resp.response);
      });

    })
  }

  post(data: Materia){

    return new Promise( async resolve => {

    const headers =  new HttpHeaders({
      'x-token': await this.authService.loadJwToken(),
    });

    this.httpClient.post(`${ URL_BACKEND }/materias`, data, { headers }).subscribe(
      resp => {
      Swal.close();

      Swal.fire({
        icon: 'success',
        title: 'Materia guardada satisfactoriamente',
        showConfirmButton: false,
        timer: 1100
    });

      this.router.navigate(['/materias-listado']);
      resolve(true);
    },
    (error) => {
      Swal.close();
      Swal.fire('Error al guardar', '', 'error');
      resolve(false);
    }
    );
    });
 }

  getCatedras(id: string){

    return new Promise( async resolve => {

      const headers =  new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });

      this.httpClient.get<Response>(`${ URL_BACKEND }/materias/cursos/findByCurso/${id}`, { headers }).subscribe( resp => {
        resolve(resp.response);
      });
    });

  }

  borrarMateria(id: string){

    return new Promise( async resolve => {

      const headers =  new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });

      this.httpClient.delete<Response>(`${ URL_BACKEND }/materias/cursos/${id}`, { headers }).subscribe( resp => {
        resolve(resp.response);
      });
    });

  }

  getProfesores(){
    return new Promise( async resolve => {

      const headers =  new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });

      this.httpClient.get<Response>(`${ URL_BACKEND }/profesores/findAll`, { headers }).subscribe( resp => {
        resolve(resp.response);
      });
    });
  }

  getProfesorFindOne(id: string){

    return new Promise( async resolve => {

      const headers =  new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });

      this.httpClient.get<Response>(`${ URL_BACKEND }/profesores/findOne/${id}`, { headers }).subscribe( resp => {
        resolve(resp.response);
      });
    });

  }

  getCountAlumnos(): Promise<Alumno[]> {
    return new Promise( async resolve => {

      const headers =  new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });

      this.httpClient.get<Response>(`${ URL_BACKEND }/alumnos/findAll`, { headers }).subscribe( resp => {
        resolve(resp.response.length);
      });

    });
  }

  getCursosAlumnos(): Promise<Alumno[]> {
    return new Promise( async resolve => {

      const headers =  new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });

      this.httpClient.get<Response>(`${ URL_BACKEND }/cursos/alumnos/findAll`, { headers }).subscribe( resp => {
        resolve(resp.response);
      });

    });
  }

  getMaterias(): Promise<Materia[]>{
    return new Promise( async resolve => {

      const headers =  new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });

      this.httpClient.get<Response>(`${ URL_BACKEND }/materias/findAll`, { headers }).subscribe( resp => {
        resolve(resp.response);
      });
    });
   }

  getLecciones(): Promise<Leccion[]>{
    return new Promise( async resolve => {

      const headers =  new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });

      this.httpClient.get<Response>(`${ URL_BACKEND }/materias/lecciones/findAll`, { headers }).subscribe( resp => {
        resolve(resp.response);
      });
    });
   }

  getCursosAlumno(id: string): Promise<Curso[]> {
    return new Promise( async resolve => {
      const headers =  new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });
      this.httpClient.get<Response>(`${ URL_BACKEND }/cursos/alumnos/findAllByAlumno/${id}`, { headers }).subscribe( resp => {
        resolve(resp.response);
      });
    });
  }

  getCursoAlumnoEstado(alumnoId: string, cursoId: string): Promise<any> {
    return new Promise( async resolve => {
      const headers =  new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });
      this.httpClient.get<Response>(`${ URL_BACKEND }/cursos/alumnos/findByAlumnoCurso/${alumnoId}/${cursoId}`, { headers }).subscribe( resp => {
        resolve(resp.response);
      });
    });
  }

  postCurso( data: Curso){

    return new Promise( async resolve => {

      const headers =  new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });

      this.httpClient.post<Response>(`${ URL_BACKEND }/cursos`, data, { headers })
      .subscribe(
        resp => {
          resolve(resp.response);
          Swal.close();
      } ,

      (error) => {
        Swal.close();
        Swal.fire('Error al guardar', '', 'error');
        resolve(false);
      });
    });
  }

  postMateriaCurso( materiaCurso: any ){

    return new Promise( async resolve => {

      const headers = new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });

      this.httpClient.post<Response>(`${ URL_BACKEND }/materias/cursos`, materiaCurso, { headers })
      .subscribe(
        resp => {
          resolve(resp.response);
          this.guiMsjService.msjFormSubmit('DatosGuardados');
      } ,

      (error) => {
        resolve(false);
        this.guiMsjService.msjFormSubmit('GuardarError');
      });

    });

  }

  async postTitulo(titulo: Titulo){

    return new Promise( async resolve => {

      const headers = new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });

      this.httpClient.post<Response>(`${ URL_BACKEND }/titulos/`, titulo, { headers })
      .subscribe(
        resp => {
          resolve(resp.response);
          this.guiMsjService.msjFormSubmit('DatosGuardados');
      } ,

      (error) => {
        resolve(false);
        this.guiMsjService.msjFormSubmit('GuardarError');
      });

    });

  }

  async updateTitulo(id: string, titulo: Titulo){

    return new Promise( async resolve => {

      const headers = new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });

      this.httpClient.put<Response>(`${ URL_BACKEND }/titulos/updateOne/${id}`, titulo, { headers })
      .subscribe(
        resp => {
          resolve(resp.response);
          this.guiMsjService.msjFormSubmit('DatosGuardados');
      } ,

      (error) => {
        resolve(false);
        this.guiMsjService.msjFormSubmit('GuardarError');
      });

    });

  }

  async borarTituloAlumno(id: string){

    return new Promise( async resolve => {

      const headers =  new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });

      this.httpClient.delete<Response>(`${ URL_BACKEND }/alumnos/titulos/${id}`, { headers }).subscribe( resp => {
        resolve(resp.response);
      });
    });

  }

  // Alumno del Usuario Logueado
  async getByUserLogon(): Promise<Alumno> {

    return new Promise( async resolve => {

        await this.loadJwToken();

        const headers =  new HttpHeaders({
            'x-token': this.token
          });

        this.httpClient.get<Response>(`${ URL_BACKEND }/alumnos/getByUserLogon`, {headers})
          .subscribe (
            resp => {
              if (resp.response !== null) {
                  resolve(resp.response);
              } else {
                  resolve(resp.response);
              }
          }, error => {
            this.authService.guiMsjService.msjFormSubmit('errorTokenLogon');
            this.router.navigateByUrl('/profile-authentication');
          });
    });
  }

  /**
   * Si el usuario no es alumno lo crea
   */
   async getUsuarioEsAlumno(): Promise<boolean> {

    return new Promise( async resolve => {

        await this.loadJwToken();
        const headers =  new HttpHeaders({
            'x-token': this.token
          });

        this.httpClient.get<Response>(`${ URL_BACKEND }/alumnos/getByUserLogon`, {headers})
          .subscribe (
            resp => {
              if (resp.response !== null) {
                  resolve(true);
              } else {
                  resolve(false);
              }
          }, error => {
            this.authService.guiMsjService.msjFormSubmit('errorTokenLogon');
            this.router.navigateByUrl('/profile-authentication');
          });
    });
  }

  async loadJwToken(): Promise<string> {
    this.token = await localStorage.getItem('token') || null;
    return this.token ;
  }

  getCursos(): Promise<Curso[]>{
    return new Promise( async resolve => {

      const headers =  new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });

      this.httpClient.get<Response>(`${ URL_BACKEND }/cursos/findAll`, { headers }).subscribe( resp => {
        resolve(resp.response);
      });
    });
   }

  putCurso(id: string, data: Curso){

    return new Promise( async resolve => {

      const headers =  new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });

      this.httpClient.put(`${ URL_BACKEND }/cursos/updateOne/${id}`, data, { headers }).subscribe( resp => {

        Swal.close();

        Swal.fire({
          icon: 'success',
          title: 'Curso modificado satisfactoriamente',
          showConfirmButton: false,
          timer: 1100
        });

        this.router.navigate(['/cursos-listado']);
        resolve(true)
        ,
        // tslint:disable-next-line:no-unused-expression
        (error) => {
          Swal.close();
          Swal.fire('Error al modificar', '', 'error');
          resolve(false);
        };
      });
    });
  }

  async postComprarCurso(cursoPagar): Promise<Curso> {

    return new Promise( async resolve => {

        const headers =  new HttpHeaders({
          'x-token': await this.authService.loadJwToken(),
        });

        this.httpClient.post<Response>(`${ URL_BACKEND }/cursos/alumnos/comprarCurso`, cursoPagar , { headers }).subscribe( resp => {
            resolve(resp.response);
        }, error => {
            this.guiMsjService.msjFormSubmit('pagoCursoError');
        });
    });
}

  /* EXAMENES */

  async postExamenNuevo(examen: Examen): Promise<Examen> {

      return new Promise( async resolve => {

          const headers =  new HttpHeaders({
              'x-token': await this.authService.loadJwToken(),
          });

          this.httpClient.post<Response>(`${ URL_BACKEND }/materias/lecciones/examenes`, examen , { headers }).subscribe( resp => {
              resolve(resp.response);
          }, error => {
            console.log('error:',error)
            this.guiMsjService.msjFormSubmit('configGuardadaServiceERROR');
          });
      });
  }  

  setConfigExamen(examen: Examen) {
      this.storageService.setExamen(examen);
      this.examen = examen;
  }

  getConfigExamen(): Examen {
      return this.examen;
  }

  async getExamenesByCurso(id: string): Promise<Examen[]> {
    return new Promise( async resolve => {

      const headers =  new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });
      // tslint:disable-next-line:max-line-length
      this.httpClient.get<Response>(`${ URL_BACKEND }/materias/lecciones/examenes/asignaciones/findAllByCurso/${id}`, { headers }).subscribe( resp => {
        resolve(resp.response);
      });
    });
  }

  async getExamenesByCursoRecuperables(id: string): Promise<Examen[]> {
    return new Promise( async resolve => {

      const headers =  new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });
      // tslint:disable-next-line:max-line-length
      this.httpClient.get<Response>(`${ URL_BACKEND }/materias/lecciones/examenes/asignaciones/findAllByCursoRecuperable/${id}`, { headers }).subscribe( resp => {
        resolve(resp.response);
      });
    });
  }

  async getExamenById(id: string): Promise<Examen>{
    return new Promise( async resolve => {

      const headers =  new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });

      this.httpClient.get<Response>(`${ URL_BACKEND }/materias/lecciones/examenes/findOne/${id}`, { headers }).subscribe( resp => {
        resolve(resp.response);
      });

    });
  }

  async updateExamen(examen: Examen): Promise<Examen>{
    return new Promise( async resolve => {

      const headers =  new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });

      // tslint:disable-next-line:max-line-length
      this.httpClient.put<Response>(`${ URL_BACKEND }/materias/lecciones/examenes/updateOne/${examen._id}`, examen,  { headers }).subscribe( resp => {
        resolve(resp.response);
      });

    });
   }


/* FIN EXAMENES */

/*PROFESORES*/

postProfesor(profesor: Profesor): Promise<Profesor>{
  return new Promise( async resolve => {

    const headers =  new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
    });

    this.httpClient.post<Response>(`${ URL_BACKEND }/profesores`, profesor , { headers }).subscribe( resp => {
        resolve(resp.response);
    }, error => {
        this.guiMsjService.msjFormSubmit('configGuardadaServiceERROR');
    });
  });
}

/* FIN PROFESORES */

  /* MODULOS SELECCIONADOs PARA CURSAR */
  setModulosSeleccionados(modulos: Curso[]) {
    this.modulos = modulos;
  }

  getModulosSeleccionados(): Curso[] {
    return this.modulos;
  }

  /* FIN MODULOS SELECCIONADOs PARA CURSAR */

  /* ASIGNAR CURSOS A USUARIO/ALUMNO */
  async setCursosAlumno(cursos: Curso[]) {

    // 1- busco o creo alumno
    const alumno: Alumno = await this.getByUserLogon(); // SI NO EXISTE LO CREA

    cursos.forEach(async c => {
 
       // 2-busco curso
       const curso = await this.getCursoFindOne(c._id);
        
       // 3-creo CursoAlumnoDto
        const cursoAlumno: CursoAlumno = {
          curso: curso._id,
          alumno: alumno._id,
          fechaInscripcion: new Date(),
          fechaInicia: curso.fechaInicio,
          fechaFinaliza: curso.fechaFin,
        };

        // 4-post curso alumno
        await this.postCursoAlumnos(cursoAlumno);

    });
  }

  // El administrativo asigna el curso
  async setCursosAlumnoAdministrativo(cursos: Curso[], idUsuario: string) {

    // 1- Creo alumno, SI EXISTE LO RETORNA
    const alumno: Alumno = await this.crearAlumno(idUsuario);
    cursos.forEach(async c => {
 
       // 2-busco curso
       const curso = await this.getCursoFindOne(c._id);
        
       // 3-creo CursoAlumnoDto
        const cursoAlumno: CursoAlumno = {
          curso: curso._id,
          alumno: alumno._id,
          fechaInscripcion: new Date(),
          fechaInicia: curso.fechaInicio,
          fechaFinaliza: curso.fechaFin,
        };

        // 4-post curso alumno
        await this.postCursoAlumnos(cursoAlumno);

    });
  }

  async postCursoAlumnos(cursoAlumno): Promise<Curso> {

    return new Promise( async resolve => {

        const headers =  new HttpHeaders({
          'x-token': await this.authService.loadJwToken(),
        });

        this.httpClient.post<Response>(`${ URL_BACKEND }/cursos/alumnos`, cursoAlumno , { headers }).subscribe( resp => {
            resolve(resp.response);
        }, error => {
            this.guiMsjService.msjFormSubmit('cursoAlumnoError');
        });
    });
 }

 deleteByAlumnoCurso(alumnoId: string, cursoId: string){
  return new Promise( async resolve => {

    const headers =  new HttpHeaders({
      'x-token': await this.authService.loadJwToken(),
    });
    this.httpClient.delete<Response>(`${ URL_BACKEND }/cursos/alumnos/deleteByAlumnoCurso/${alumnoId}/${cursoId}`, { headers }).subscribe( resp => {
      resolve(resp.response);
    });
  });
 }

 async crearAlumno(idUsuario: string): Promise<Alumno> {
  return new Promise( async resolve => {  
    const headers =  new HttpHeaders({
      'x-token': await this.authService.loadJwToken(),
    });

    const body = {
      usuario: idUsuario,
    }

    this.httpClient.post<Response>(`${ URL_BACKEND }/alumnos/`, body , { headers }).subscribe( resp => {
        resolve(resp.response);
    }, error => {
        this.guiMsjService.msjFormSubmit('cursoAlumnoError');
    });

  }); 
 }

 async findAlumnoByUsuario(idUsuario: string): Promise<Alumno>{
  return new Promise( async resolve => {

    const headers =  new HttpHeaders({
      'x-token': await this.authService.loadJwToken(),
    });

    this.httpClient.get<Response>(`${ URL_BACKEND }/alumnos/getByUser/${idUsuario}`, { headers }).subscribe( resp => {
      resolve(resp.response);
    });

  });
 }

  getAlumnos() {
  return new Promise( async resolve => {

      const headers =  new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });

      this.httpClient.get<Response>(`${ URL_BACKEND }/alumnos/findAll`, { headers }).subscribe( resp => {
        resolve(resp.response);
      });
    });
  }

  setAlumnoSeleccionado(alumno: Alumno) {
    this.alumno = alumno;
  }

  getetAlumnoSeleccionado(): Alumno {
  return this.alumno;
  }

  async getTitulos(){
    return new Promise(async resolve=>{
      const headers =  new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });
      this.httpClient.get<Response>(`${ URL_BACKEND }/titulos/findAll`, { headers }).subscribe( resp => {
        resolve(resp.response);
      });
    })
  }

  async getDisciplinas(){
    return new Promise(async resolve=>{
      const headers =  new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });
      this.httpClient.get<Response>(`${ URL_BACKEND }/disciplinas/findAll`, { headers }).subscribe( resp => {
        resolve(resp.response);
      });
    })
  }

  async getTitulosAlumnosFindAllByAlumno(id: string): Promise<AlumnoTitulo[]> {
    return new Promise( async resolve => {
      const headers =  new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });
      this.httpClient.get<Response>(`${ URL_BACKEND }/alumnos/titulos/findAllByAlumno/${id}`, { headers }).subscribe( resp => {
        resolve(resp.response);
      });
    });
  }

  async postTituloAlumno(tituloAlumno){
    return new Promise( async resolve => {

      const headers =  new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });

      this.httpClient.post<Response>(`${ URL_BACKEND }/alumnos/titulos`, tituloAlumno , { headers }).subscribe( resp => {
          resolve(resp.response);
      }, error => {
          this.guiMsjService.msjFormSubmit('cursoAlumnoError');
      });
    });
  }

  async updateTituloAlumno(id: string, data: AlumnoTitulo){

    return new Promise( async resolve => {

      const headers = new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });

      this.httpClient.put<Response>(`${ URL_BACKEND }/alumnos/titulos/updateOne/${id}`, data, { headers })
      .subscribe(
        resp => {
          resolve(resp.response);
      } ,

      (error) => {
        resolve(false);
        this.guiMsjService.msjFormSubmit('GuardarError');
      });

    });

  }

  async tituloAlumnoGetOneById(id: string){
    return new Promise( async resolve => {
      const headers =  new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });
      this.httpClient.get<Response>(`${ URL_BACKEND }/alumnos/titulos/findOne/${id}`, { headers }).subscribe( resp => {
        resolve(resp.response);
      });
    });
  }

  async tituloGetOneById(id: string){
    return new Promise( async resolve => {
      const headers =  new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });
      this.httpClient.get<Response>(`${ URL_BACKEND }/titulos/findOne/${id}`, { headers }).subscribe( resp => {
        resolve(resp.response);
      });
    });
  }

}
