<div class="page-title-area">
  <div class="container">
    <div class="page-title-content">
      <ul>
        <li><a routerLink="/">Inicio</a></li>
        <li><a routerLink="/afiliados-listado">Listado Afiliados</a></li>
        <li>Nuevo Afiliado</li>
      </ul>
      <h2>Nuevo Afiliado</h2>
    </div>
  </div>
</div>

<div class="profile-authentication-area ptb-70 mt-5 ">

  <div class="container">
    <div class="info">
      <strong>¡Información!</strong> Una vez completado el proceso de registro, el afiliado recibirá un
      correo electrónico que incluirá un PIN de validación. Para activar su cuenta y empezar a utilizarla,
      deberá hacer click en el enlace proporcionado en el correo. En caso de que el afiliado encuentre
      dificultades para acceder al correo que contiene el <strong>PIN</strong>. En caso de que el afiliado
      encuentre dificultades para acceder al correo que contiene el PIN, puede activar la opción de
      "<u>Verficación de email</u>" para que el afiliado pueda acceder utilizando
      la dirección de correo y la contraseña con el cual lo registro.
    </div>
  </div>
  <div class="container">
    <div class="row justify-center">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <div class="register-form animate__animated animate__bounceIn">
          <h2>{{ idioma | translate: "login.REGISTRO" | async}} Afiliado</h2>
          <form [formGroup]="registerForm"
                (ngSubmit)="registerUser()">

            <div class="box-input-shadow-dni">
              <div class="form-group inner-addon right-addon ">
                <label>{{ idioma | translate: 'form.NRO-ID' | async }} </label>
                <div class="input-group">
                  <input type="string"
                         (input)="searchAfiliado()"
                         type="text"
                         formControlName="identificacion"
                         [(ngModel)]="identificacion"
                         class="form-control"
                         [placeholder]=" idioma | translate: 'form.placeholders.ID-NO' | async"
                         [class.is-invalid]="getIdentificacionNoValida">
                  <div *ngIf="(!flagDataGecros && viewSpinner)"
                       class="icon-container">
                    <strong>Buscando Afiliado</strong>
                    <i class="loader"></i>
                  </div>
                </div>
                <mat-error *ngIf="getIdentificacionNoValida">{{ idioma | translate:
                  "form.NRO-ID" | async}}</mat-error>
                <br>
                <div *ngIf="showTimeoutMessage && (!flagDataGecros && !dataGecros)"
                     class="text-danger">
                  No se han encontrado sus datos de afiliado, para
                  registrarse primero debe afiliarse a <strong><a href="https://gecros.com/" target="_blank" rel="noreferrer">Gecros</a></strong>.
                </div>
              </div>
            </div>

            <div class="form-group">
              <div class="row mb-4">
                <div class="col-lg-6 col-xs-12">
                  <div class="form-outline">
                    <label>{{ idioma | translate: 'form.NOMBRE' | async }}</label>
                    <input type="text"
                           formControlName="nombrePersona"
                           [placeholder]=" idioma | translate: 'form.placeholders.NOMBRE' | async"
                           [class.is-invalid]="getNombreNoValido"
                           class="form-control"
                           style="margin-right: 3px;">
                    <small style="margin-right: 30px;"
                           *ngIf="getNombreNoValido"
                           class="text-danger">{{
                      idioma | translate:
                      "form.placeholders.NOMBRE" | async}}</small>
                  </div>
                </div>

                <div class="col-lg-6 col-xs-12">
                  <div class="form-outline">
                    <label>{{ idioma | translate: 'form.APELLIDO' | async }}</label>
                    <input type="text"
                           formControlName="apellidoPersona"
                           [placeholder]=" idioma | translate: 'form.placeholders.APELLIDO' | async"
                           [class.is-invalid]="getApellidoNoValido"
                           class="form-control">
                    <small *ngIf="getApellidoNoValido"
                           class="text-danger">{{ idioma
                      | translate: "form.placeholders.APELLIDO" | async}}</small>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group">
              <label>{{ idioma | translate: "form.EMAIL" | async}}</label>
              <input formControlName="email"
                     type="email"
                     class="form-control text-lowercase"
                     [placeholder]=" idioma | translate: 'form.placeholders.INGRESE-EMAIL' | async"
                     [class.is-invalid]="emailNoValido">
              <small *ngIf="emailNoValido"
                     class="text-danger">{{ idioma | translate:
                "form.EMAIL-INVALIDO" | async}}</small>
            </div>
            <div class="form-group">
              <label>{{ idioma | translate: "form.CONFIRMAR-EMAIL" | async}}</label>
              <input formControlName="confirmEmail"
                     type="email"
                     class="form-control text-lowercase"
                     [placeholder]=" idioma | translate: 'form.placeholders.CONFIRME-EMAIL' | async"
                     [class.is-invalid]="confirmEmailNoValido">
              <small *ngIf="confirmEmailNoValido"
                     class="text-danger">{{ idioma |
                translate: "form.CONFIRMAR-EMAIL-INVALIDO" | async}}</small>
            </div>

            <div class="form-group inner-addon right-addon">
              <label>{{ idioma | translate: "login.CONTRASENA" | async}}</label>
              <div class="input-group">
                <input formControlName="password"
                       [type]="showPasswordRegister ? 'text' : 'password'"
                       class="form-control"
                       [placeholder]=" idioma | translate: 'login.placeholders.INGRESE-CONTRASENA' | async"
                       [class.is-invalid]="passwordNoValido"
                       (cut)="$event.preventDefault()"
                       (copy)="$event.preventDefault()"
                       (paste)="$event.preventDefault()">
                <i alt="show"
                   class="far fa-eye eye-show"
                   (click)="showPasswordRegister = !showPasswordRegister"
                   [class.hide]="showPasswordRegister"></i>
                <i alt="hide"
                   class="far fa-eye-slash eye-hide"
                   (click)="showPasswordRegister = !showPasswordRegister"
                   [class.hide]="!showPasswordRegister"></i><br>
              </div>
              <small *ngIf="passwordNoValido"
                     class="text-danger">{{ idioma | translate:
                "login.MSJ-CONTRASENA" | async}}</small>
            </div>

            <div class="form-group inner-addon right-addon">
              <label>{{ idioma | translate: "login.CONFIRMAR-CONTRASENA" | async}}</label>
              <div class="input-group">
                <input formControlName="confirmPassword"
                       [type]="showPasswordConfirm ? 'text' : 'password'"
                       class="form-control"
                       [placeholder]=" idioma | translate: 'login.CONFIRMAR-CONTRASENA' | async"
                       [class.is-invalid]="confirmPasswordNoValido">
                <i alt="show"
                   class="far fa-eye eye-show"
                   (click)="showPasswordConfirm = !showPasswordConfirm"
                   [class.hide]="showPasswordConfirm"></i>
                <i alt="hide"
                   class="far fa-eye-slash eye-hide"
                   (click)="showPasswordConfirm = !showPasswordConfirm"
                   [class.hide]="!showPasswordConfirm"></i><br>
              </div>
              <small *ngIf="confirmPasswordNoValido"
                     class="text-danger">{{ idioma |
                translate: "login.CONTRASENA-INVALIDA" | async}}</small>
            </div>

            <p class="description">{{ idioma | translate: "login.MSJ-CONTRASENA-INVALIDA" |
              async}}</p>

            <br>
            <br>

            <div class="form-group">
              <div class="warning alert alert-warning d-inline-block"
                   role="alert">
                <strong>¡Importante!</strong> Si activa la verificación por email debe proporcionarle la
                contraseña al afiliado para que pueda acceder al sistema.
              </div>
              <mat-slide-toggle formControlName="email_verified"><small>Activar verificación de
                  email</small></mat-slide-toggle>
            </div>

            <div class="form-group">
              <input type="checkbox"
                     formControlName="proteccionDeDatos"
                     style="margin-right: 5px !important;"> <em>{{ idioma | translate:
                "legales.LEGAL-CTF-3" | async}}
                - <a (click)="openBlank('CFR')"
                   class="link">{{ idioma |
                  translate:"legales.LEGAL-CTF-1" | async}}</a></em>
            </div>

            <div class="form-group">
              <input type="checkbox"
                     formControlName="clausula"
                     style="margin-right: 5px !important;">
              <em>{{ idioma | translate:
                "legales.LEGAL-CTF-4" | async}}</em>
            </div>

            <div class="form-group">
              <input type="checkbox"
                     formControlName="aceptaTerminos"
                     style="margin-right: 5px !important;">
              <em>{{ idioma | translate:
                "legales.LEGAL-CTF-5" | async}}
                - <a (click)="openBlank('TC')"
                   class="link">{{ idioma |
                  translate:"legales.LEGAL-CTF-TC" | async}}</a></em>
            </div>

            <button [disabled]="!registerForm.valid && !dataGecros"
                    [ngClass]="{'custom-disabled': !registerForm.valid && !dataGecros}"> {{ idioma | translate:
              "login.BTN-REGISTRAR-AFILIADO" | async}}</button>
            <br>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>