import { Component, Input, OnInit } from '@angular/core';
import { ActividadesService } from '../../../../services/ofertas-laborales/actividades.service';
import { PageEvent } from '@angular/material/paginator/public-api';
import { Subscription } from 'rxjs';
import { Actividad } from '../../../../interfaces/interface-bakend/ofertas-laborales/actividad.interface';
import { Translators } from '../../../../services/shared/translators.service';

@Component({
  selector: 'app-ofertas-listar',
  templateUrl: './ofertas-listar.component.html',
  styleUrls: ['./ofertas-listar.component.scss']
})
export class OfertasListarComponent implements OnInit {

  @Input() flagViewTitle = true;
  @Input() highValue = 3;  // default view

    // MatPaginator Inputs
   length: number = 0;
   pageSize: number = 38;
   pageSizeOptions: number[] = [3, 6, 9, 12, 18, 26, 32, 38];

   lowValue = 0;

   idioma: Subscription;

   flagLoaded = false;

    actividadesDisponibles: Actividad[];
      constructor(
          private actividadesService: ActividadesService,
          private translatorService: Translators,
      ) {
        this.idioma = this.translatorService.getIdiomaSeleccionado().subscribe(resp => {
            this.idioma = resp;
          });
      }

      async ngOnInit() {
        await this.getActividadesAllVigentes();
        this.highValue = this.lowValue + 38
      }

      async getActividadesAllVigentes() {
        await this.actividadesService.getActividadesVigentes().then((actividades: Actividad[]) => {
          this.actividadesDisponibles = actividades;
          this.flagLoaded = true;
        });
      }

      public getPaginatorData(event: PageEvent): PageEvent {
        this.lowValue = event.pageIndex * event.pageSize;
        this.highValue = this.lowValue + event.pageSize;
        return event;
      }

}
