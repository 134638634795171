import { Response } from 'src/app/interfaces/interface-bakend/response';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import { StorageService } from '../shared/storage.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { GuiMsjService } from '../shared/gui-msj.service';
import { SignoPlan } from '@interfaces/interface-bakend/entidades/signo-plan.interface';
import Swal from 'sweetalert2';

const URL_BACKEND = environment.urlBackend;

@Injectable({ providedIn: 'root' })
export class SignoPlanService {

    signoPlanSeleccionado: SignoPlan;

    constructor(
        private httpClient: HttpClient,
        private router: Router,
        private guiMsjService: GuiMsjService,
        private authService: AuthService,
        private storageService: StorageService
    ) { }
    async create(plan: SignoPlan): Promise<SignoPlan> {
        const headers = new HttpHeaders({
            'x-token': await this.authService.loadJwToken(),
        });

        return new Promise(async (resolve, reject) => {
            this.httpClient
                .post<Response>(`${URL_BACKEND}/signo-planes`, plan, {
                    headers,
                })
                .subscribe(
                    (resp) => {
                        Swal.close();

                        Swal.fire({
                            icon: 'success',
                            title: 'Plan guardado satisfactoriamente',
                            showConfirmButton: false,
                            timer: 1100,
                        });

                        this.router.navigate(['/planes-listado']);
                        resolve(resp.response);
                    },
                    (error) => {
                        console.error(error);
                        this.guiMsjService.msjFormSubmit('crearPlanError');
                        this.router
                            .navigateByUrl('/', { skipLocationChange: true })
                            .then(async () => {
                                await this.router.navigateByUrl('/');
                            });
                        reject(error);
                    }
                );
        });
    }

    getPlanSeleccionado(): SignoPlan {
        return this.signoPlanSeleccionado;
    }

    async getPlanes(): Promise<SignoPlan[]> {
        return new Promise(async (resolve) => {
            const headers = new HttpHeaders({
                'x-token': await this.authService.loadJwToken(),
            });

            this.httpClient
                .get<Response>(`${URL_BACKEND}/signo-planes/findAll`, {
                    headers,
                })
                .subscribe((resp: Response) => {
                    resolve(resp.response);
                });
        });
    }

    async getManyPlanesByDenominacion(denominacion: string): Promise<SignoPlan[]> {
        return new Promise(async (resolve) => {
            const headers = new HttpHeaders({
                'x-token': await this.authService.loadJwToken(),
            });

            this.httpClient
                .get<Response>(`${URL_BACKEND}/signo-planes/findManyLikeDenominacion/${denominacion}`, {
                    headers,
                })
                .subscribe((resp: Response) => {
                    resolve(resp.response);
                });
        });
    }

    async getPlanById(id: string): Promise<SignoPlan> {
        return new Promise(async (resolve) => {
            const headers = new HttpHeaders({
                'x-token': await this.authService.loadJwToken(),
            });

            this.httpClient
                .get<Response>(`${URL_BACKEND}/signo-planes/findOne/${id}`, {
                    headers,
                })
                .subscribe(
                    (resp: Response) => {
                        resolve(resp.response);
                    },
                    (error) => {
                        console.error("Error al encontrar plan", error);
                    })

        });
    }

    async getPlanByDenominacion(denomincacion: string): Promise<SignoPlan> {
        return new Promise(async (resolve) => {
            const headers = new HttpHeaders({
                'x-token': await this.authService.loadJwToken(),
            });

            this.httpClient
                .get<Response>(`${URL_BACKEND}/signo-planes/findOneByDenominacion/${denomincacion}`, {
                    headers,
                })
                .subscribe(
                    (resp: Response) => {
                        resolve(resp.response);
                    },
                    (error) => {
                        console.error("Error al encontrar plan por denominacion", error);
                    })

        });
    }

    async getPlanByIdRef(idRef: number): Promise<SignoPlan> {
        return new Promise(async (resolve) => {
            const headers = new HttpHeaders({
                'x-token': await this.authService.loadJwToken(),
            });

            this.httpClient
                .get<Response>(`${URL_BACKEND}/signo-planes/findOneByIdRef/${idRef}`, {
                    headers,
                })
                .subscribe(
                    (resp: Response) => {
                        resolve(resp.response);
                    },
                    (error) => {
                        console.error("Error al encontrar plan por id de referencia", error);
                    })

        });
    }

    async updatePlan(_id: string, plan: SignoPlan): Promise<SignoPlan> {
        return new Promise(async (resolve) => {
            const headers = new HttpHeaders({
                'x-token': await this.authService.loadJwToken(),
            });
            this.httpClient
                .put<Response>(
                    `${URL_BACKEND}/signo-planes/updateOne/${_id}`,
                    plan,
                    { headers }
                )
                .subscribe(
                    (resp: Response) => {
                        resolve(resp.response);
                    },
                    (error) => {
                        console.error(error);
                        this.guiMsjService.msjFormSubmit('planDuplicado');
                    }
                );
        });
    }

    setPlanSeleccionado(plan: SignoPlan) {
        this.signoPlanSeleccionado = plan;
        this.storageService.setPlan(this.signoPlanSeleccionado);
    }
}
