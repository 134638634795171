<mat-card class="example-card">
    <mat-card-header>
        <mat-card-title>Datos Afiliados Gecros</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <mat-spinner *ngIf="!flagLoad"></mat-spinner>
        <p style="background-color: rgba(255, 0, 0, 0.762); border-radius: 5px;" *ngIf="flagLoad && !gecrosAfiliado">Sin
            datos de Gecros</p>
        <p>Nombre afiliado: <strong>{{gecrosAfiliado?.nombreAfiliado}}</strong></p>
        <p>CUIL: <strong>{{gecrosAfiliado?.cuil}}</strong></p>
        <p>Nro afiliado: <strong>{{gecrosAfiliado?.numeroAfiliado}}</strong></p>
        <p>Estado: <strong>{{gecrosAfiliado?.estado}}</strong></p>
        <p>Convenio: <strong>{{gecrosAfiliado?.convenio}}</strong></p>
        <p>Plan: <strong>{{gecrosAfiliado?.plan}}</strong></p>
        <p>Observaciones: <strong>{{gecrosAfiliado?.observaciones}}</strong></p>
        <p><strong>Domicilio:</strong></p>
        <table *ngIf="gecrosAfiliado?.domicilios && gecrosAfiliado?.domicilios.length > 0">
            <thead>
                <tr>
                    <th width="25%">Calle</th>
                    <th>Altura</th>
                    <th width="15%">Localidad</th>
                    <th>Código Postal</th>
                    <th>Provincia</th>
                    <th>Teléfono Cel.</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let domicilio of gecrosAfiliado.domicilios">
                    <td>{{ domicilio?.calle }}</td>
                    <td>{{ domicilio?.numero }}</td>
                    <td>{{ domicilio?.localidad }}</td>
                    <td>{{ domicilio?.codigoPostal }}</td>
                    <td>{{ domicilio?.provincia }}</td>
                    <td>{{ domicilio?.telCelular }}</td>
                </tr>
            </tbody>
        </table>

        <mat-divider></mat-divider>
    </mat-card-content>
    <mat-card-actions>
        <button mat-raised-button color="warn" [disabled]="!gecrosAfiliado || btnSincronizar" (click)="sincronizarDatosGecros();">Sincronizar estos datos</button>
        <button mat-button mat-dialog-close>Cerrar</button>
    </mat-card-actions>
    <mat-progress-bar mode="indeterminate" *ngIf="progressBar"></mat-progress-bar>
</mat-card>