import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SignoEmpresa } from 'src/app/interfaces/interface-bakend/entidades/signo-empresa.interface';
import { SignoEmpresaService } from '../../../../../services/entidades/signo-empresas.service';
import { GuiMsjService } from 'src/app/services/shared/gui-msj.service';

@Component({
    selector: 'app-empresa-alta',
    templateUrl: './empresa-alta.component.html',
    styleUrls: ['./empresa-alta.component.scss'],
})
export class EmpresaAltaComponent implements OnInit {
    form: FormGroup;
    estados: string[] = ['ACTIVA', 'SUSPENDIDA', 'BAJA'];

    constructor(
        private signoEmpresaService: SignoEmpresaService,
        private route: Router,
        private guiMsjService: GuiMsjService,
    ) {
        this.createForm();
    }

    ngOnInit(): void { }

    get nombreNoValido() {
        return this.form.get('nombre').invalid;
    }
    get fechaNoValida() {
        return this.form.get('fecha').invalid;
    }

    createForm() {
        this.form = new FormGroup({
            nombre: new FormControl('', [
                Validators.required,
                Validators.minLength(3),
                Validators.maxLength(50),
            ]),
            estado: new FormControl('', [
                Validators.required,
            ]),
            fecha: new FormControl('', [Validators.required]),
        });
    }
    async createEmpresa() {
        await this.guiMsjService.msjFormSubmit('Guardando');
        this.signoEmpresaService.create(await this.setDataForma())
        await this.guiMsjService.msjFormSubmit('DatosGuardados');
        this.route.navigateByUrl('/empresas-listado');

    }
    async setDataForma(): Promise<SignoEmpresa> {
        const empresa: SignoEmpresa = {
            nombre: this.form.value.nombre,
            estado: this.form.value.estado,
            fecha: this.form.value.fecha,
        };

        return empresa;
    }
}
