import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@services/auth/auth.service';
import { PersonasService } from '@services/shared/personas.service';
import { User } from '@interfaces/interface-bakend/seguridad/users/user.interface';

@Component({
  selector: 'app-register-new-familiar',
  templateUrl: './registro-nuevo-familiar.component.html',
  styleUrls: ['./registro-nuevo-familiar.component.scss']
})
export class RegisterNewFamiliar implements OnInit {
  user: User;

  constructor(
    private authService: AuthService,
    private personasService: PersonasService,
    private router: Router,
  ) {
    this.getUser();
  }

  ngOnInit(): void {
  }

  async getUser() {
    try {
      const userLogOn: User = await this.authService.getUserLogon();
      if (!userLogOn) {
        throw new Error('Afiliado no encontrado');
      }
      this.user = userLogOn;
    } catch (error) {
      console.error('Error al obtener el afiliado:', error);
    }
  }

  async darDeAltaFamiliar() {
    this.personasService.setPersonaSeleccionada(this.user);
    this.router.navigateByUrl('/grupofamiliar-alta');
  }

}
