import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Translators } from '../../../services/shared/translators.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { TermsOfServiceComponentHome } from './terms-of-service-home/terms-of-service-home.component';
import { User } from '@interfaces/interface-bakend/seguridad/users/user.interface';
import { AuthService } from '@services/auth/auth.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {

  idioma: Subscription;
  flagTitular = false;
  
  constructor(
      private translatorService: Translators,
      private router: Router,
      public dialog: MatDialog,
      private authService: AuthService,
  ) {
    this.idioma = this.translatorService.getIdiomaSeleccionado().subscribe(resp => {
      this.idioma = resp;
    });

  }

  async ngOnInit() {
    this.flagTitular = await this.titular();
  }

  openDialog() {
    this.dialog.open(TermsOfServiceComponentHome,{
      disableClose: true
    });
  }

  refreshHome() {
    this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/']);
    });
  }

  async titular(): Promise<boolean> {
    const afiliadoLogged = await this.authService.getUserLogon();
    return afiliadoLogged?.signoGecrosAfiliado?.parentesco === 'TITULAR';
  }

}


