import { Response } from 'src/app/interfaces/interface-bakend/response';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import { StorageService } from '../shared/storage.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { GuiMsjService } from '../shared/gui-msj.service';
import { SignoDoctor } from 'src/app/interfaces/interface-bakend/entidades/signo-doctor.interface';
import Swal from 'sweetalert2';

const URL_BACKEND = environment.urlBackend;

@Injectable({ providedIn: 'root' })
export class SignoDoctorService {

signoDoctorSeleccionado : SignoDoctor;

    constructor(
        private httpClient: HttpClient,
        private router: Router,
        private guiMsjService: GuiMsjService,
        private authService: AuthService,
        private storageService: StorageService
    ) {}
    async create(doctor: SignoDoctor): Promise<SignoDoctor> {
        const headers = new HttpHeaders({
            'x-token': await this.authService.loadJwToken(),
        });

        return new Promise(async (resolve, reject) => {
            await this.httpClient
                .post<Response>(`${URL_BACKEND}/signo-doctores`, doctor, {
                    headers,
                })
                .subscribe(
                    (resp) => {
                        Swal.close();

                        Swal.fire({
                            icon: 'success',
                            title: 'Doctor guardado satisfactoriamente',
                            showConfirmButton: false,
                            timer: 1100,
                        });

                        this.router.navigate(['/doctores-listado']);
                        resolve(resp.response);
                    },
                    (error) => {
                        console.error(error);
                        this.guiMsjService.msjFormSubmit('crearDoctorError');
                        this.router
                            .navigateByUrl('/', { skipLocationChange: true })
                            .then(async () => {
                                await this.router.navigateByUrl('/');
                            });
                        reject(error);
                    }
                );
        });
    }

    getDoctorSeleccionado(): SignoDoctor {
        return this.signoDoctorSeleccionado;
    }

    async getDoctores(): Promise<SignoDoctor[]> {
        return new Promise(async (resolve) => {
            const headers = new HttpHeaders({
                'x-token': await this.authService.loadJwToken(),
            });

            this.httpClient
                .get<Response>(`${URL_BACKEND}/signo-doctores/findAll`, {
                    headers,
                })
                .subscribe((resp: Response) => {
                    resolve(resp.response);
                });
        });
    }

    async getDoctorById(id: string): Promise<SignoDoctor> {
        return new Promise(async (resolve) => {
            const headers = new HttpHeaders({
                'x-token': await this.authService.loadJwToken(),
            });

            this.httpClient
                .get<Response>(`${URL_BACKEND}/signo-doctores/findOne/${id}`, {
                    headers,
                })
                .subscribe((resp: Response) => {
                    resolve(resp.response);
                });
        });
    }

    async updateDoctor(_id: string, doctor: SignoDoctor): Promise<SignoDoctor> {
        return new Promise(async (resolve) => {
            const headers = new HttpHeaders({
                'x-token': await this.authService.loadJwToken(),
            });
            this.httpClient
                .put<Response>(
                    `${URL_BACKEND}/signo-doctores/updateOne/${_id}`,
                    doctor,
                    { headers }
                )
                .subscribe(
                    (resp: Response) => {
                        resolve(resp.response);
                    },
                    (error) => {
                        console.error(error);
                        this.guiMsjService.msjFormSubmit('doctorDuplicado');
                    }
                );
        });
    }

    setDoctorSeleccionado(doctor: SignoDoctor) {
        this.signoDoctorSeleccionado = doctor;
        this.storageService.setDoctor( this.signoDoctorSeleccionado);
    }
}
