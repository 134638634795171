import { Component, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CartillaBuscadorComponent } from '../cartilla-buscador.component';
import { MapComponent } from '@components/entidades/common/map/map.component';
import { SignoCentroMedico } from '@interfaces/interface-bakend/entidades/signo-centro-medico.interface';

@Component({
  selector: 'app-dialog',
  templateUrl: './centros-card.component.html',
  styleUrls: ['./centros-card.component.css']
})
export class CentrosCardComponent {

  @ViewChild(MapComponent) mapComponent: MapComponent;
  
  constructor(
    public dialogRef: MatDialogRef<CartillaBuscadorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SignoCentroMedico
  ) {
  }

  ngAfterViewInit(): void {
    this.initMap()
  }

  // Método para cerrar el diálogo y pasar datos de vuelta
  closeDialog(data: any): void {
    this.dialogRef.close(data);
  }

  initMap() {
    if (!isNaN(this.data.latitud) && !isNaN(this.data.longitud)) {
      this.mapComponent.getLocation(this.data.latitud, this.data.longitud);
    }
  }
}
