import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { GuiMsjService } from 'src/app/services/shared/gui-msj.service';
import { SignoDoctor } from 'src/app/interfaces/interface-bakend/entidades/signo-doctor.interface';
import { Catalogo } from '../../../../../interfaces/interface-bakend/shared/catalogo/catalogo.interface';
import { SignoDoctorService } from 'src/app/services/entidades/signo-doctores.service';
import { CatalogoService } from '../../../../../services/shared/catalogo.service';
import { EntidadesKeys } from 'src/app/enum/entidades-keys.enum';
@Component({
    selector: 'app-doctor-modificacion',
    templateUrl: './doctor-modificacion.component.html',
    styleUrls: ['./doctor-modificacion.component.scss']
})
export class DoctorModificacionComponent implements OnInit {
    form: FormGroup;
    signoDoctor: SignoDoctor;
    especialidades: Catalogo[];
    centros: Catalogo[];
    especialidadSeleccionada: Catalogo;
    centrosSeleccionados: Catalogo[];

    flagLoaded: boolean = false;

    constructor(
        private router: Router,
        private catalogoService: CatalogoService,
        private signoDoctorService: SignoDoctorService,
        private guiMsjService: GuiMsjService
    ) {
        this.getCentrosPropiosEspecialidades();
        this.getCentrosPropios();
    }

    ngOnInit(): void {
        this.getEstadoDoctor();
        this.createForm();
    }

    get doctorNoValido() {
        return this.form.get('doctor').invalid;
    }

    get especialidadNoSeleccionada() {
        return this.form.get('especialidad').invalid;
    }

    async getCentrosPropiosEspecialidades() {
        try {
            const centrosPropiosEspecialidades = await this.catalogoService.findDescendantsByKey(EntidadesKeys.CENTROS_PROPIOS_ESPECIALIDADES);

            if (centrosPropiosEspecialidades) {
                this.especialidades = centrosPropiosEspecialidades;
            }
        } catch (error) {
            console.error("Error al cargar centros propios especialidades", error);
        }
    }

    async getCentrosPropios() {
        try {
            const centrosPropios = await this.catalogoService.findDescendantsByKey(EntidadesKeys.CENTROS_PROPIOS);

            if (centrosPropios) {
                this.centros = centrosPropios;
            }
        } catch (error) {
            console.error("Error al cargar centros propios ", error);
        }
    }

    async getDoctor() {
        return (this.signoDoctor =
            this.signoDoctorService.getDoctorSeleccionado());
    }

    async getEstadoDoctor() {
        if (this.getDoctor() === undefined) {
            await this.router.navigateByUrl('/doctores-listado');
        } else {
            await this.getDoctor();
            this.centrosSeleccionados = this.signoDoctor?.centros;
            this.especialidadSeleccionada = this.signoDoctor?.especialidad;
            this.flagLoaded = true;
        }
    }

    createForm() {
        this.form = new FormGroup({
            doctor: new FormControl(this.signoDoctor?.nombre, [
                Validators.required,
            ]),
            especialidad: new FormControl("", [
                Validators.required,
            ]),
            activo: new FormControl(this.signoDoctor?.activo, [
                Validators.required,
            ]),
            centro: new FormControl(""),
        });
    }

    async updateDoctor() {
        await this.guiMsjService.msjFormSubmit('Guardando');
        this.signoDoctorService.updateDoctor(this.signoDoctor._id, await this.setDataForma())
        await this.guiMsjService.msjFormSubmit('DatosGuardados');
        await this.router.navigateByUrl('/doctores-listado');
    }

    async setDataForma(): Promise<SignoDoctor> {

        const centrosObjectId = this.form.value.centro.map((centroId: string) => ({ _id: centroId }))

        const updateDoctor: SignoDoctor = {
            _id: this.signoDoctor._id,
            nombre: this.form.value.doctor || '',
            especialidad: {
                _id: this.form.value.especialidad,
            },
            activo: this.form.value.activo,
            centros: centrosObjectId
        };

        return updateDoctor;
    }
}
