import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ContactoDto } from 'src/app/interfaces/interface-bakend/shared/mailer/mailer.interface';
import { User } from '@interfaces/interface-bakend/seguridad/users/user.interface';
import { AuthService } from '@services/auth/auth.service';
import { GuiMsjService } from 'src/app/services/shared/gui-msj.service';
import { MailerService } from 'src/app/services/shared/mailer.service';
import { Translators } from 'src/app/services/shared/translators.service';
import { OpenPageService } from '../../../services/shared/open-page.service';
import { SignoSedeService } from '@services/entidades/signo-sedes.service';
import { Sede } from '@interfaces/interface-frontend/sede.interface';
import { sedes } from 'src/app/data/sedes.object';

@Component({
  selector: 'app-form-contacto',
  templateUrl: './form-contacto.component.html',
  styleUrls: ['./form-contacto.component.scss']
})
export class FormContactoComponent implements OnInit {

  form: FormGroup;
  idioma: Subscription;

  afiliadoLogged: User;
  sedes: Sede[] = sedes;
  sede: Sede;
  selectedSedeId: string = '';

  bgImage = [
    {
      img: 'assets/img/register-shape-sm.jpg'
    }
  ]

  constructor(private translatorService: Translators,
    private authService: AuthService,
    private mailService: MailerService,
    private guiMsjService: GuiMsjService,
    private router: Router,
    private openPageService: OpenPageService,
    private signoSedeService: SignoSedeService
  ) {

    this.getAfiliado();
    this.createForm();
    this.idioma = this.translatorService.getIdiomaSeleccionado().subscribe(resp => {
      this.idioma = resp;
    });
  }

  ngOnInit(): void {
  }

  async getAfiliado() {
    try {
      this.afiliadoLogged = await this.authService.getUserLogon();
      if (!this.afiliadoLogged) {
        throw new Error('No se encontro el afiliado.');
      }
      this.selectedSedeId = (await this.signoSedeService.getSedeById(this.afiliadoLogged.signoSedes[0].toString()))?.codigoGecro ?? '';
      this.getSedeAfiliadoLogIn(this.selectedSedeId);
    } catch (error) {
      console.error('Error al obtener datos del afiliado:', error);
    }
  }

  getSedeAfiliadoLogIn(sedeId: string) {
    try {
      this.sede = this.sedes.find(sede => sede.id === sedeId);
      if (!this.sede) {
        throw new Error('No se encontró la sede.');
      }
    } catch (error) {
      console.error('Error al buscar la sede:', error);
    }
  }

  createForm() {
    this.form = new FormGroup({
      email: new FormControl('', [Validators.required]),
      mensaje: new FormControl(''),
      proteccionDeDatos: new FormControl('', [Validators.requiredTrue]),
      clausula: new FormControl('', [Validators.requiredTrue]),
    });
  }

  get emailNoValido() {
    return this.form.get('email').invalid && this.form.get('email').touched;
  }

  async setDataForma(): Promise<ContactoDto> {

    const data: ContactoDto = {
      email: this.form.value.email,
      mensaje: this.form.value.mensaje,
      proteccionDeDatos: this.form.value.proteccionDeDatos,
      clausula: this.form.value.clausula,
    };

    return data;

  }

  async submit() {
    await this.guiMsjService.msjFormSubmit('Espere');
    await this.mailService.sendFormContact(await this.setDataForma());
    this.router.navigateByUrl('/');
  }

  openBlank(type: string) {
    this.openPageService.openBlank(type);
  }

}
