<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Inicio</a></li>
                <li><a routerLink="/configurar-administracion" #previousRoute>Configuración
                        Administración</a></li>
                <li>Listado Especialidades</li>
                <li><app-menu></app-menu></li>
            </ul>
            <h2>Especialidades</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape-signomedico.png" alt="image" width="10%"></div>
</div>

<app-loading *ngIf="!flagLoaded" class="m-5"></app-loading><br>

<div *ngIf="flagLoaded" class="profile-area pb-70">
    <div class="container">
        <div class="profile-courses-quizzes">
            <h3>Listado de Especialidades</h3>
            <br>
            <a routerLink="/especialidad-alta">
                <button mat-raised-button mat-button color="primary">
                    <mat-icon aria-hidden="false" aria-label="Example home icon">add</mat-icon>
                        Nueva Especialidad
                </button>
            </a>
            <br>
            <hr>
            <div *ngIf="flagLoaded">

                <mat-form-field>
                    <mat-label>Filtrar</mat-label>
                    <input matInput (keyup)="applyFilter($event)" placeholder="Especialidad..."
                        autocomplete="off" #input style="width: 40%;">
                </mat-form-field>

                <div class="mat-elevation-z8">

                    <table mat-table [dataSource]="dataSource" matSort>

                        <!-- Column -->
                        <ng-container matColumnDef="especialidad">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Especialidad </th>
                            <td mat-cell *matCellDef="let element">
                                {{element.concepto}} </td>
                        </ng-container>

                        <!-- Column -->
                        <ng-container matColumnDef="activa">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                Activa </th>
                            <td mat-cell *matCellDef="let element">
                                {{element.logico_1 ? "Si" : "No" ||
                                'SIN DATOS
                                ASOCIADOS'}} </td>
                        </ng-container>

                        <!-- Column -->
                        <ng-container matColumnDef="_id">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                Acciones </th>
                            <td mat-cell *matCellDef="let element">
                                <button style="margin-right: 5px;" (click)="verEspecialidad(element._id)"
                                    type="button" title="Modificar datos de la especialidad"
                                    class="btn btn-warning"><i class="fas fa-edit"></i></button>
                            </td>
                        </ng-container>

                        <!-- Row shown when there is no matching data. -->
                        <tr class="mat-row" *matNoDataRow>
                            <td class="mat-cell" colspan="4">No data matching
                                the
                                filter {{input.value}}</td>
                        </tr>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns">
                        </tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                            class="example-element-row"></tr>
                    </table>

                    <mat-paginator [pageSizeOptions]="[50, 100, 150, 200, 500, 800]"
                        aria-label="Select page of campus"></mat-paginator>
                </div>
            </div>
        </div>
    </div>
</div>