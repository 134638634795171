import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-botones-rapidos',
  templateUrl: './botones-rapidos.component.html',
  styleUrls: ['./botones-rapidos.component.scss']
})
export class BotonesRapidosComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
