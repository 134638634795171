<div class="new-familiar-register-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="new-familiar-content">
                    <span class="sub-title">NUEVO FAMILIAR</span>
                    <h2>Registre un nuevo familiar en Signo Médico 🏥</h2>
                    <p>Haga click en el botón <u><strong>Alta familiar a cargo</strong></u> para agregar a un familiar a nuestra obra social.</p>
                    <button (click)="darDeAltaFamiliar()" class="default-btn" type="button"><i class="flaticon-user"></i>Alta Familiar<span></span></button>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="new-familiar-register-image-style">
                    <img src="assets/img/signo-medico/family-1.jpg" alt="image">
                </div>
            </div>
        </div>
    </div>
</div>