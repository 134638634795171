import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { Solicitud } from '../../interfaces/interface-bakend/ofertas-laborales/solicitudes.interface';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Response } from '../../interfaces/interface-bakend/response';
import { Payments } from '../../interfaces/interface-bakend/payments/payments.interfaces';
import { SignoSedeSearch } from '@interfaces/interface-bakend/cartilla/cartilla.interface';
import { SignoCentroMedico } from '@interfaces/interface-bakend/entidades/signo-centro-medico.interface';

const URL_BACKEND = environment.urlBackend;

@Injectable({
  providedIn: 'root'
})
export class CartillaService {

  constructor(
      private authService: AuthService,
      private httpClient: HttpClient,
  ) { }

  async getBusquedaNomenclador(data: SignoSedeSearch): Promise<SignoCentroMedico[]> {
    const headers =  new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
    });

    return new Promise( resolve => {
        this.httpClient.post<Response>(`${ URL_BACKEND }/signo-centros/busquedaNomenclador`, data , { headers }).subscribe( resp => {
            console.log('resp', resp);
            resolve(resp.response);
        }, error => {
            console.log('error', error);
        });
    });
  }

}
