<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/dashboard">Inicio</a></li>
                <li><a routerLink="/tramites-configuracion">Administación Trámites</a></li>
            </ul>
            <h2>Administación Trámites</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape-signomedico.png" width="10%" alt="image"></div>
</div>

<div class="features-area pb-70 animate__animated animate__fadeIn">
    <div class="container">

        <div class="row m-5">

            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-features-box">
                    <div class="icon">
                        <i class="far fa-plus-square"></i>
                    </div>
                    <h3>Dashboard Solicitudes</h3>
                    <p>Estadistícas Gráficas</p>
                    <a routerLink="/signo-medico-estadisticas" class="btn btn-success">Ver dashboard</a>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-features-box">
                    <div class="icon">
                        <i class="fas fa-list-ul"></i>
                    </div>
                    <h3>Listado de Solicitudes</h3>
                    <p>Acceda al listado de Solicitudes del sistema</p>
                    <a routerLink="/ofertas-administracion-listado" class="btn btn-success">Ver Solicitud</a>
                    <!-- <a routerLink="/ofertas-administracion-listado" class="btn btn-primary">Ver Solicitud</a> -->
                </div>
            </div>
        </div>
    </div>
</div>