import { MailerService } from './../../../../../services/shared/mailer.service';
import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Solicitud } from '../../../../../interfaces/interface-bakend/ofertas-laborales/solicitudes.interface';
import { SolicitudesService } from '../../../../../services/ofertas-laborales/solicitudes.service';
import { User } from '../../../../../interfaces/interface-bakend/seguridad/users/user.interface';
import {
    MatDialog,
    MatDialogRef,
    MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { UploadFileSolicitudComponent } from '../../../common/upload-file-solicitud/upload-file-solicitud.component';
import { MediaService } from '../../../../../services/shared/media.service';
import { CatalogoService } from '../../../../../services/shared/catalogo.service';
import { GuiMsjService } from '../../../../../services/shared/gui-msj.service';
import { Actividad } from '../../../../../interfaces/interface-bakend/ofertas-laborales/actividad.interface';
import {
    APP_CONFIG_OFERTAS,
    IAppOfertas,
} from '../../../../../app.config/app.config-ofertas';
import { Subscription } from 'rxjs';
import { UserEntreUser } from '@interfaces/interface-bakend/seguridad/users/user-entre-user.interface';
import { UserEntreUserService } from '@services/shared/user-entre-user.service';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-ofertas-solicitud',
    templateUrl: './ofertas-solicitud.component.html',
    styleUrls: ['./ofertas-solicitud.component.scss'],
})
export class OfertasSolicitudComponent implements OnInit, OnDestroy {
    /* Estados */
    activarEnRevision: boolean = false;
    activarEnAuditoria: boolean = false;
    activarAprobar: boolean = false;
    activarRechazar: boolean = false;
    activarEnvio: boolean = false;
    activarCerrarModal: boolean = true;

    /* Habilitacion del Boton Aprobar solo si subio el archivo*/
    flagDisableButtonApprove: boolean = true;
    flagCargaCorrecta: boolean = false;
    selectedFile: File;
    selectedFileName: string = '';
    value: number = 0;
    isActive: boolean = false;
    subscriptionAvance: Subscription;

    motivoRevision: string = '';
    motivoRechazo: string = '';
    nombrePais: string;
    actividad: Actividad;
    solicitud: Solicitud;
    usuario: User;

    flagLoaded: boolean;
    flagSpinner: boolean = false;
    flagEnRevision: boolean = false;
    flagEnAuditoria: boolean = false;
    flagLabelAprobado: boolean = false;
    flagLabelRechazado: boolean = false;

    isCheckedAprobar: boolean = false;
    isCheckedRechazar: boolean = false;
    isCheckedEnRevision: boolean = false;
    isCheckedEnviarDocumentos: boolean = false;

    dataModal: any;

    estadoDocumentacion: string;

    imgs: string[] = [];
    files: string[] = [];

    adjuntoCatalogo: any;
    idioma: Subscription;
    newState: Subscription;

    habilitarEnviarObservacion: boolean = false;
    botonAuditoriaHabilitado: boolean = true;
    mostrarLeyenda: boolean = false;
    beneficiario: UserEntreUser;

    observacionEmailAuditoriaMedica: string = '';


    constructor(
        @Inject(APP_CONFIG_OFERTAS) private iAppOfertas: IAppOfertas,
        /* Modal Ref. */
        @Inject(MAT_DIALOG_DATA) public data: string,
        public dialogRef: MatDialogRef<OfertasSolicitudComponent>,
        /* Fin Modal Ref. */ // los datos los recibo por servicio
        private router: Router,
        private solicitudesService: SolicitudesService,
        private mediaService: MediaService,
        public dialog: MatDialog,
        private mailerService: MailerService,
        private catalogoService: CatalogoService,
        private guiMsjService: GuiMsjService,
        private userEntreUserService: UserEntreUserService,
    ) {
        this.newState = this.solicitudesService
            .getsetNewStater()
            .subscribe((resp) => {
                this.initPostChangeStatus();
                this.newState = resp;
            });
        this.mediaService.reiniciarContadorControlUpload();
        this.subscribeAvanceUpload();
    }

    ngOnInit(): void {
        this.getDataSolicitud();
        this.cargarAdjuntoContrato();
    }

    ngOnDestroy(): void {
    }

    initPostChangeStatus(): void {
        // solo cargo estado
        this.getDataSolicitud();
        this.activarCerrarModal = true;
    }

    onFileSelected(event: Event) {
        const fileInput = event.target as HTMLInputElement;
        const file: File = fileInput.files?.[0];

        if (!file) {
            // No se encontro archivo
            this.clearSelectionFile();
            return;
        }
        if (this.mediaService.controlSize(event)) {
            this.selectedFile = file;
            this.selectedFileName = file.name;
            this.isActive = true;
            this.flagDisableButtonApprove = false;
        } else {
            alert(
                'Archivo demasiado grande, seleccione un archivo de menos de 4MB'
            );
            this.clearSelectionFile();
            this.flagDisableButtonApprove = true;
        }
    }

    clearSelectionFile() {
        this.selectedFile = null;
        this.selectedFileName = '';
        this.isActive = false;
    }

    subscribeAvanceUpload() {
        this.subscriptionAvance = this.mediaService
            .getAvance()
            .subscribe((resp) => {
                if (resp) {
                    this.value = resp.value;
                } else {
                    this.value = 0;
                }
            });
    }

    /**
     * Utilizamos el upload de documentacion extra, obtenemos idAjunto y idUsuario
     */
    async uploadDocumentoAdjunto(selectedFile, autorizo: boolean) {
        let idUsuario;
        let idAdjunto = 'idAdjunto';

        // controlo si exite la propiedad, porque cuando el backend retorna un objeto no popula en ocaciones.
        if (this.solicitud.usuario.hasOwnProperty('_id')) {
            idUsuario = this.solicitud.usuario._id;
        } else {
            idUsuario = this.solicitud.usuario;
        }

        // Espero a que se complete la subida del archivo y obtengo el idAdjunto
        const upLoadedFile =
            await this.mediaService.uploadOneAttachJobOfferFromWeb(
                selectedFile,
                idUsuario,
                idAdjunto,
                this.solicitud._id,
                autorizo
            );

        if (upLoadedFile['ok']) {
            // Se obtiene idAdjunto
            const idAdjunto =
                upLoadedFile.solicitud.adjuntos[
                upLoadedFile.solicitud.adjuntos.length - 1
                ];

            // Llamo a actualizar estado
            // tslint:disable-next-line:max-line-length
            await this.actualizarEstadoSolicitud(this.solicitud._id, this.iAppOfertas.estado_4.number, false,  this.iAppOfertas.estado_4.name, true, "Autorizacion APROBADA - Se adjunta autorización en este email", idAdjunto);

            if (this.value === 100) {
                this.solicitudesService.setSolicitudSeleccionada(
                    upLoadedFile.solicitud
                );
                this.flagCargaCorrecta = true;
            }
        }
        else {
            this.flagCargaCorrecta = false;
            this.guiMsjService.msjFormSubmit('uploadDocumentERROR');
        }
    }

    async aprobarSolicitud(): Promise<void> {
        // Mostrar mensaje de procesamiento
        const loadingSwal = this.mostrarMensajeProcesando();
        this.flagDisableButtonApprove = true;
        if (this.mediaService.controlContador()) {
            await this.uploadDocumentoAdjunto(this.selectedFile, true);
            Swal.close();
            // this.guiMsjService.msjFormSubmit('uploadDocumentOK');
            this.mediaService.reiniciarContadorControlUpload();
            this.dialogRef.close(this.flagCargaCorrecta); // depende de si cargo o no el archivo correctamente
        } else {
            return;
        }
    }

    mostrarMensajeProcesando() {
        // Mostrar una barra de progreso con SweetAlert
        const swalInstance = Swal.mixin({
            title: 'Procesando',
            text: 'Por favor, espere...',
            allowOutsideClick: false,
            showConfirmButton: false,
            showCancelButton: false,
            showCloseButton: false,
            timerProgressBar: true,
        });
    
        return swalInstance;
    }
    async getDataSolicitud() {
        this.flagLoaded = false;
        await this.getEstadoSolicitud(await this.getSolicitud());
        await this.getArrayImgsAndFile((await this.getSolicitud()).adjuntos);
        await this.getBeneficiario(this.solicitud);
        this.flagLoaded = true;
    }

    async getSolicitud(): Promise<Solicitud> {
        this.solicitud =
            await this.solicitudesService.getSolicitudSeleccionada();
        this.actividad = this.solicitud.actividad;
        this.usuario = this.solicitud.usuario;
        this.nombrePais = this.solicitud.pais.name_es;

        console.log('solicitud', this.solicitud);
        return this.solicitud;
    }

    async getBeneficiario(solicitud: Solicitud) {
        this.beneficiario = await this.userEntreUserService.getBeneficiario(solicitud);
    }

    async getEstadoSolicitud(solicitud: Solicitud) {
        switch (solicitud.lastState.numberState) {
            case 0: // PRESENTADO
                this.activarRechazar = false;
                this.activarEnRevision = false;
                this.activarEnAuditoria = false;
                
                // puede rechazar la solicitud
                this.activarRechazar = true;

                this.estadoDocumentacion =
                    this.iAppOfertas.documentacion.pendiente;

                break;

            case 1: // DOCUMENTACION PRESENTADA
                this.activarEnAuditoria = true;
                this.activarAprobar = true;
                this.activarEnRevision = true;

                // puede rechazar la solicitud
                this.activarRechazar = true;

                this.estadoDocumentacion =
                    this.iAppOfertas.documentacion.pendiente;

                break;

            case 2: // EN REVISIÓN
                this.flagEnRevision = true;
                this.activarEnRevision = true;
                this.activarRechazar = true;
                this.activarEnAuditoria = true;
                this.activarAprobar = true;
                
                // puede rechazar la solicitud
                this.activarRechazar = true;


                this.estadoDocumentacion =
                    this.iAppOfertas.documentacion.completo;

                break;

            case 3: // EN AUDITORIA MÉDICA
                this.activarEnRevision = false;
                this.activarRechazar = true;
                this.activarEnAuditoria = true;
                this.activarAprobar = true;

                // puede rechazar la solicitud
                this.activarRechazar = true;
              
                this.estadoDocumentacion =
                    this.iAppOfertas.documentacion.completo;

                break;

            case 4: // APROBADA
                this.activarEnRevision = false;
                this.activarAprobar = false;
                this.activarEnAuditoria = false;
                this.flagLabelAprobado = true;

                // puede rechazar la solicitud
                this.activarRechazar = true;

                this.estadoDocumentacion =
                    this.iAppOfertas.documentacion.completo;

                break;

            case 5: // RECHAZADO
                this.activarRechazar = true;
                this.flagLabelRechazado = true;
               
                // puede rechazar la solicitud
                this.activarRechazar = true;

                this.estadoDocumentacion =
                    this.iAppOfertas.documentacion.completo;
                break;

            default:
                this.estadoDocumentacion =
                    this.iAppOfertas.documentacion.pendiente;
                break;
        }
    }

    async getArrayImgsAndFile(adjuntos: string[]) {
        await adjuntos.forEach((element) => {
            if (element.search('.pdf') !== -1) {
                this.files.push(element);
            } else {
                this.imgs.push(element);
            }
        });
    }

    /* CARGO FORM */
    async cargarAdjuntoContrato() {
        this.adjuntoCatalogo = await this.catalogoService.getFindByKey(
            'ADJUNTO_CONTRATO'
        );
    }

    /* Modal Documento  - Carga Contrato */
    openDialog(label: string, estado: string, idAdjunto: string): void {
        const dialogRef = this.dialog.open(UploadFileSolicitudComponent, {
            width: '800px',
            height: '800px',
            disableClose: true,
            data: {
                solicitud: this.solicitud,
                adjunto: idAdjunto,
                label,
                numberState: estado,
                labelButton: 'ENVIAR CONTRATO',
                accept: 'application/pdf', // image/jpeg, image/jpg, image/png,application/pdf
            },
        });

        dialogRef.afterClosed().subscribe(async (result) => {
            if (result) {
                //   await this.refreshComponent('ofertas-solicitud');
                await this.refreshComponent('ofertas-administracion-listado');

                const messageDto = await this.mailerService.prepareMailToSend(
                    this.solicitud.usuario,
                    await this.getSolicitud(),
                    true,
                    this.adjuntoCatalogo.properties._idAdjunto,
                    'TEMPLATE_MAILER_CONTRATO'
                );

                await this.mailerService.sendMail(messageDto);

                this.dataModal = result;
            } else {
                return;
            }
        });
    }

    /* REFRESH-FORM */
    refreshComponent(url) {
        this.router
            .navigateByUrl(url, { skipLocationChange: true })
            .then(async () => {
                await this.closeModal();
                await this.openDialogModaSolicitud();
                await this.router.navigate([url]);
            });
    }

    /* MODAL - CARGA DE SOLICITUD */
    openDialogModaSolicitud(): void {
        const dialogRef = this.dialog.open(OfertasSolicitudComponent, {
            width: '900px',
            height: '1000px',
            disableClose: true,
        });
        // this.flagLoadedModal = true;
        dialogRef.afterClosed().subscribe(async (result) => { });
        return;
    }

    /* MODAL CLOSE */
    closeModal(): void {
        this.dialogRef.close();
    }

    /* DESACTIVAR BOTONES */
    async desactivarBotones() {
        this.activarEnRevision = false;
        this.activarEnAuditoria = false;
        this.activarRechazar = false;
        this.activarAprobar = false;
        this.activarCerrarModal = false;
        this.isCheckedAprobar = false;
        this.isCheckedRechazar = false;
        this.isCheckedEnRevision = false;
    }

    /* LIMPIAR TEXTO TEXT AREAS */
    async limpiarTexto() {
        this.motivoRevision = '';
        this.motivoRechazo = '';
    }

    async habilitarBotonObservaciones(motivo: string) {
        this.habilitarEnviarObservacion = motivo.trim() !== '';
    }

    async botonDerivarAuditoriaMedica() {
        this.actualizarEstadoSolicitud(this.solicitud._id, this.iAppOfertas.estado_3.number, true, this.iAppOfertas.estado_3.name, true);
        this.botonAuditoriaHabilitado = false;
        this.mostrarLeyenda = true;
        this.solicitudesService.solicitudEnviarDocumentosYnotificar(this.solicitud._id, this.observacionEmailAuditoriaMedica);
    }
   // notificaAdmin: notificaAdmin || false,

    /* ACCIONES SOLICITUD */
    async actualizarEstadoSolicitud(
        _id: string,
        numberState: number,
        notificaAdmin: boolean,
        state?: string,
        notificar?: boolean,
        observaciones?: string,
        file?: string,
    ) {
        await this.desactivarBotones();
        await this.limpiarTexto();
        this.flagSpinner = true;
        this.solicitudesService
            .updateSolicitudState(
                _id,
                numberState,
                notificaAdmin,
                state,
                notificar,
                observaciones,
                file            )
            .then(async (solicitudUpdated: Solicitud) => {
                await this.solicitudesService.setSolicitudSeleccionada(
                    solicitudUpdated
                );
                this.flagSpinner = false;
                this.solicitudesService.setNewState(solicitudUpdated);
                this.guiMsjService.msjFormSubmit('solicitudEstadoOK');
            })
            .catch((error) => {
                console.log(error);
            });
    }


    async enviarDocumentosSolicitud(idSolicitud: string) {
        this.flagSpinner = true;
        // Mostrar el mensaje de confirmación al usuario
        const resultado = await Swal.fire({
            title: '¿Está seguro?',
            text: 'Si acepta, enviará todos los documentos adjuntos a Auditoría Médica junto con el detalle de la solicitud y las observaciones.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, enviar',
            cancelButtonText: 'Cancelar'
        });
        this.flagSpinner = false;

        // Si el usuario acepta, se envían los documentos
        if (resultado.isConfirmed) {
            // Llamar al servicio para enviar los documentos y mostrar el resultado en consola
            // tslint:disable-next-line:max-line-length
            const respuesta = await this.solicitudesService.solicitudEnviarDocumentosYnotificar(idSolicitud, this.observacionEmailAuditoriaMedica);

            this.observacionEmailAuditoriaMedica =  '';

            // Mostrar mensaje de éxito o error según el resultado
            Swal.fire({
                title: 'Documentos enviados',
                text: 'Los documentos han sido enviados exitosamente a Auditoría Médica.',
                icon: 'success'
            });
        } else {
            // El usuario canceló la operación
            Swal.fire({
                title: 'Operación cancelada',
                text: 'No se enviaron los documentos.',
                icon: 'info'
            });
        }
    }
    

}
