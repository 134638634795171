import { User } from './../../../../../interfaces/interface-bakend/seguridad/users/user.interface';
import { Component, Input, OnInit } from '@angular/core';
import { saveAs } from 'file-saver';
import { MediaOfertaLaboralFileUserPipe } from '../../../../../pipes/ofertas-laborales/mediaOfertaLaboralFileUser.Pipe';
import { ActividadDAdjunto } from '../../../../../interfaces/interface-bakend/ofertas-laborales/actividad-adjuntos.interface';
import { MatDialog } from '@angular/material/dialog';
import { OfertasVerPdfComponent } from '../../../common/ofertas-ver-pdf/ofertas-ver-pdf.component';
import { ActividadesService } from '../../../../../services/ofertas-laborales/actividades.service';

declare var require: any;
const FileSaver = require('file-saver');

@Component({
  selector: 'app-ofertas-files',
  templateUrl: './ofertas-files.component.html',
  styleUrls: ['./ofertas-files.component.scss']
})
export class OfertasFilesComponent implements OnInit {

    @Input() files: string[];
    @Input() usuario: User;

    // tslint:disable-next-line:ban-types
    filesWithName: Object[] = [];

    dataFile = {
        nombre: '',
        adjunto: '',
    };


    constructor(
        private actividadesService: ActividadesService,
        public dialog: MatDialog,
    ) { }

    ngOnInit(): void {
        this.pushNombreAdjunto(this.files);
    }

    download(idUsuario: string, adjunto: string, nombre: string) {
        FileSaver.saveAs(this.mediaOfertaLaboralFileUser(idUsuario, adjunto), nombre);
    }

    mediaOfertaLaboralFileUser(idUsuario: string, adjunto: string) {
        const mediaOfertaLaboralFileUser = new MediaOfertaLaboralFileUserPipe();
        return mediaOfertaLaboralFileUser.transform(idUsuario, adjunto);
    }

    async getNombreAdjunto(idAdjunto): Promise<string> {
        /* el nombre del adjunto es la primer cadena, luego es el nombre unico y final el id usuario*/
        return await this.actividadesService.getNombreAdjunto(idAdjunto);
    }

    async pushNombreAdjunto(file: string[]) {
         await file.forEach( async adjunto => {
            await this.getNombreAdjunto(adjunto).then( async nombre => {
               await this.filesWithName.push({nombre, adjunto});
            });
        });
    }

    /* Modal PDF  - Carga PDF */
    openDialogPdf(idUsuario: string, adjunto: string, tituloDocumento: string): void {

        const mediaOfertaLaboralFileUser = new MediaOfertaLaboralFileUserPipe();
        const path = mediaOfertaLaboralFileUser.transform(idUsuario, adjunto);

        const dialogRef = this.dialog.open(OfertasVerPdfComponent, {
          width: '800px',
          height: '800px',
          disableClose: true,
          data: {
                 path,
                 tituloDocumento,
                }
        });

        dialogRef.afterClosed().subscribe(async result => {
        });
    }

}
