import { Response } from 'src/app/interfaces/interface-bakend/response';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

const URL_BACKEND = environment.urlBackend;

@Injectable({ providedIn: 'root' })
export class GecrosAfiliadosService {

    constructor(
        private httpClient: HttpClient,
        private authService: AuthService,
    ) { }

    async getAfiliadoByIdentificacion(identificacion: string): Promise<any> {
        return new Promise(async (resolve) => {
            this.httpClient
                .get<Response>(`${URL_BACKEND}/signo-gecros/afiliadoByNDoc/${identificacion}`)
                .subscribe((resp: Response) => {
                    resolve(resp.response);
                });
        });
    }

    async statusGecros(): Promise<boolean> {
        try {

            const resp = await this.httpClient.get<Response>(`${URL_BACKEND}/signo-gecros/status`).toPromise();
    
            // Verifica si resp.response es un array y tiene al menos un elemento
            if (Array.isArray(resp.response) && resp.response.length > 0) {
                return true;
            }
    
            // Verifica si resp.response es una cadena no vacía
            if (typeof resp.response === 'string' && resp.response.trim() !== '') {
                return true;
            }
    
            // Si ninguno de los casos anteriores se cumple, devuelve false
            return false;
        } catch (error) {
            console.error('Error al obtener el estado de GECROS:', error);
            return false; // Devuelve false si hay un error
        }
    }
    

}
