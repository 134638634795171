<div class="page-title-area">
    <div class="container">
        <!-- <div>
            <button class="btn-tabla-main-color ms-3 mt-3" (click)="useLanguage('en')"><img src="assets/img/flags/usa.png" alt="English" width="20"> EN</button>
            <button class="btn-tabla-main-color ms-3 mt-3" (click)="useLanguage('es')"><img src="assets/img/flags/espana.png" alt="Español" width="20"> ES</button>
            <button class="btn-tabla-main-color ms-3 mt-3" (click)="useLanguage('ca')"><img src="assets/img/flags/catalunya.png" alt="Catala" width="20"> CA</button>
            <button class="btn-tabla-main-color ms-3 mt-3" (click)="useLanguage('it')"><img src="assets/img/flags/italia.png" alt="Italiano" width="20"> IT</button>
            <button class="btn-tabla-main-color ms-3 mt-3" (click)="useLanguage('ar')"><img src="assets/img/flags/arabia-saudita.png" alt="Arab" width="20"> AR</button>
            <button class="btn-tabla-main-color ms-3 mt-3" (click)="useLanguage('fr')"><img src="assets/img/flags/francia.png" alt="French" width="20"> FR</button>
            <button class="btn-tabla-main-color ms-3 mt-3" (click)="useLanguage('de')"><img src="assets/img/flags/alemania.png" alt="German" width="20"> DE</button>
            <button class="btn-tabla-main-color ms-3 mt-3" (click)="useLanguage('br')"><img src="assets/img/flags/brasil.png" alt="Portugués" width="20"> PT </button> 
        </div> -->
        <div class="page-title-content">
            <h2>
                <a routerLink="/"><img src="assets/img/logo-signomedico.png" alt="signomedico" width="50%"></a><br><br>{{ idioma | translate: "login.TITULO-BIENVENIDA" | async}}</h2>
        </div>
    </div>
</div>

<div class="profile-authentication-area ptb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-9 col-md-12">
                <div class="login-form animate_animated animate_fadeIn">
                    <h2>{{ idioma | translate: "login.REENVIAR-PIN-ACT" | async}}</h2>

                    <form [formGroup]="form" (ngSubmit)="resendPin()">
                        <div class="form-group">
                            <label>{{ idioma | translate: "form.EMAIL" | async}}</label>
                            <input formControlName="email" type="email" class="form-control text-lowercase" [placeholder]=" idioma | translate: 'form.placeholders.INGRESE-EMAIL-PIN' | async" [class.is-invalid]="emailNoValido">
                            <small *ngIf="emailNoValido" class="text-danger">{{ idioma | translate: "form.EMAIL-INVALIDO" | async}}</small>
                        </div>
                        <div class="form-group">
                            <ngx-recaptcha2 #captchaElem [siteKey]="siteKey" formControlName="recaptcha">
                            </ngx-recaptcha2>
                        </div>
                        <div class="row">
                            <div class="col-lg-6">
                                <button [disabled]="!form.valid" type="submit" disabled>{{ idioma | translate: "login.REENVIAR-PIN" | async}}</button>
                            </div>
                            <div class="col-lg-6">
                                <button [routerLink]="['/profile-authentication']" class="btn btn-warning" type="button">{{ idioma | translate: "form.BTN-CANCELAR" | async}}</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">

            </div>

        </div>
    </div>
</div>