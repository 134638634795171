import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { SignoCentroMedico } from 'src/app/interfaces/interface-bakend/entidades/signo-centro-medico.interface';
import { SignoCentroMedicoService } from 'src/app/services/entidades/signo-centros-medicos.service';
import { SignoLocalidad } from 'src/app/interfaces/interface-bakend/entidades/signo-localidad.interface';
import { SignoLocalidadService } from 'src/app/services/entidades/signo-localidades.service';
import { Catalogo } from 'src/app/interfaces/interface-bakend/shared/catalogo/catalogo.interface';
import { CatalogoService } from 'src/app/services/shared/catalogo.service';
import { GuiMsjService } from 'src/app/services/shared/gui-msj.service';
import { PerfilesService } from '@services/perfiles/perfiles.service';
import { AuthService } from '@services/auth/auth.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-centros-medicos-listado',
  templateUrl: './centros-medicos-listado.component.html',
  styleUrls: ['./centros-medicos-listado.component.scss']
})
export class CentrosMedicosListadoComponent implements OnInit {
  @ViewChild('previousRoute') previousRouteLink: ElementRef;

  /* Data Table*/
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] = [
    'nombre',
    'direccion',
    'zona',
    'localidad',
    'horarios',
    '_id',
  ];

  dataSource: MatTableDataSource<SignoCentroMedico>;
  /* Fin Data Table*/

  flagLoaded: boolean = false;
  keyPerfil: string = '';

  constructor(
    private router: Router,
    private signoCentroMedicoService: SignoCentroMedicoService,
    private signoLocalidadService: SignoLocalidadService,
    private catalogoService: CatalogoService,
    private guiMsjService: GuiMsjService,
    private authService: AuthService,
    private perfilesService: PerfilesService,
  ) {
    this.dataSource = new MatTableDataSource();
  }

  ngOnInit(): void {
    this.getPerfil().then(() => {
      this.perfilesService.allowedByUserLogon(this.router.url, this.previousRoute, this.keyPerfil);
    });
    this.loadDataPage();
  }

  async ngAfterViewInit() {
    await this.loadDataPage();
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.setFilterPredicate();
  }

  get previousRoute() {
    const previousRoute = this.previousRouteLink.nativeElement.getAttribute('href');
    return previousRoute;
  }

  async getPerfil() {
    try {
      const user = await this.authService.getUserLogon();
      if (user) {
        const { key } = user?.perfil;
        this.keyPerfil = key;
      }
    }
    catch (error) {
      console.log('ABM - Error al obtener el perfil del usuario', error);
      return '';
    }
  }

  async getZonaConcepto(_id: string): Promise<Catalogo> {
    try {
      const zona = await this.catalogoService.getFindOne(_id);
      if (zona) {
        return zona;
      } else {
        console.error("Zona no encontrada");
        return undefined;
      }
    } catch (error) {
      console.error(error, "Error al obtener la zona");
      return undefined;
    }
  }

  async getLocalidadNombre(_id: string): Promise<SignoLocalidad> {
    try {
      const localidad = await this.signoLocalidadService.getLocalidadById(_id);
      if (localidad) {
        return localidad;
      } else {
        console.error("Localidad no encontrada");
        return undefined;
      }
    } catch (error) {
      console.error(error, "Error al obtener la localidad");
      return undefined;
    }
  }

  async loadDataTable() {
    try {
      const signoCentrosMedicos = await this.signoCentroMedicoService.getCentroMedicos();

      this.dataSource = new MatTableDataSource(signoCentrosMedicos);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.flagLoaded = true;
    } catch (error) {
      console.error("Error al cargar el listado de centros médicos", error);
    }
  }

  async loadDataPage() {
    await this.loadDataTable();
  }

  async verCentroMedico(_id: string) {
    await this.guiMsjService.msjFormSubmit('Espere');
    Swal.showLoading();
    const centroMedico: SignoCentroMedico = await this.signoCentroMedicoService.getCentroMedicoById(_id);
    if (centroMedico != undefined) {
      this.router.navigateByUrl('/centro-medico-modificacion');
      this.signoCentroMedicoService.setCentroMedicoSeleccionado(centroMedico);
      Swal.close();
    }
    else {
      Swal.close();
    }
  }

  setFilterPredicate() {
    this.dataSource.filterPredicate = (data: SignoCentroMedico, filter: string): boolean => {
      const transformedFilter = filter.trim().toLowerCase();
      return (
        data.nombre.toLowerCase().includes(transformedFilter) ||
        data.calle.toLowerCase().includes(transformedFilter) ||
        data.altura.toLowerCase().includes(transformedFilter) ||
        data.horarios.toLowerCase().includes(transformedFilter) ||
        data.zona.concepto.toLowerCase().includes(transformedFilter) ||
        data.localidad.nombre.toLowerCase().includes(transformedFilter)
      );
    };
  }

  applyFilter(event: Event) {
    const filterValue = (event?.target as HTMLInputElement)?.value.trim().toLowerCase();
    
    const customFilter = (data: any, filter: string): boolean => {
      const nombre = data.nombre ? data.nombre.toLowerCase() : '';
      const calle = data.calle ? data.calle.toLowerCase() : '';
      const altura = data.altura ? data.altura.toLowerCase() : '';
      const horarios = data.horarios ? data.horarios.toLowerCase() : '';
      const zonaConcepto = data.zona?.concepto ? data.zona.concepto.toLowerCase() : '';
      const localidadNombre = data.localidad?.nombre ? data.localidad.nombre.toLowerCase() : '';

      return (
        nombre.includes(filter) ||
        calle.includes(filter) ||
        altura.includes(filter) ||
        horarios.includes(filter) ||
        zonaConcepto.includes(filter) ||
        localidadNombre.includes(filter)
      );
    };

    this.dataSource.filterPredicate = customFilter;
    this.dataSource.filter = filterValue;
  }

}
