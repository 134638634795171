import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { SignoCentroMedico } from 'src/app/interfaces/interface-bakend/entidades/signo-centro-medico.interface';
import { SignoCentroMedicoService } from 'src/app/services/entidades/signo-centros-medicos.service';
import { SignoLocalidad } from 'src/app/interfaces/interface-bakend/entidades/signo-localidad.interface';
import { SignoLocalidadService } from 'src/app/services/entidades/signo-localidades.service';
import { Catalogo } from '@interfaces/interface-bakend/shared/catalogo/catalogo.interface';
import { CatalogoService } from 'src/app/services/shared/catalogo.service';
import { GuiMsjService } from 'src/app/services/shared/gui-msj.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-centros-medicos-asociados-listado',
  templateUrl: './centros-medicos-asociados-listado.component.html',
  styleUrls: ['./centros-medicos-asociados-listado.component.scss']
})
export class CentrosMedicosAsociadosListadoComponent implements OnInit {

  catalogo: Catalogo;
  flagLoaded: boolean = false;
  // isLoadingResults: boolean = true;

  /* Data Table*/
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] = [
    'nombre',
    'direccion',
    'zona',
    'localidad',
    'horarios',
    '_id',
  ];

  dataSource: MatTableDataSource<SignoCentroMedico>;

  /* Fin Data Table*/

  constructor(
    private router: Router,
    private signoCentroMedicoService: SignoCentroMedicoService,
    private signoLocalidadService: SignoLocalidadService,
    private catalogoService: CatalogoService,
    private guiMsjService: GuiMsjService,
  ) {
    this.dataSource = new MatTableDataSource();
  }

  ngOnInit(): void {
    this.getEspecialidadSeleccionada();
  }

  async ngAfterViewInit() {
    await this.loadDataPage();
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  async getConcepto() {
    this.catalogo = this.catalogoService.getConceptoSeleccionado();
  }

  async getEspecialidadSeleccionada() {
    if (this.getConcepto() === undefined) {
      await this.router.navigateByUrl('/especialidad-modificacion');
    }
  }

  async getZonaConcepto(_id: string): Promise<Catalogo> {
    try {
      const zona = await this.catalogoService.getFindOne(_id);
      if (zona) {
        return zona;
      } else {
        console.error("Zona no encontrada");
        return undefined;
      }
    } catch (error) {
      console.error(error, "Error al obtener la zona");
      return undefined;
    }
  }

  async getLocalidadNombre(_id: string): Promise<SignoLocalidad> {
    try {
      const localidad = await this.signoLocalidadService.getLocalidadById(_id);
      if (localidad) {
        return localidad;
      } else {
        console.error("Localidad no encontrada");
        return undefined;
      }
    } catch (error) {
      console.error(error, "Error al obtener la localidad");
      return undefined;
    }
  }

  async loadDataTable() {
    try {
      const signoCentrosMedicosAsociados = await this.signoCentroMedicoService.getAllCentrosMedicosByEspecialidad(this.catalogo?._id);
      const centrosMedicos = await Promise.all(signoCentrosMedicosAsociados.map(async (centroMedico: SignoCentroMedico) => {        
        return centroMedico;
      }));

      this.dataSource = new MatTableDataSource(centrosMedicos);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    } catch (error) {
      console.error(`Error al cargar el listado de centros médicos asociados a ${this.catalogo?.concepto}`, error);
    }
  }

  async loadDataPage() {
    await this.loadDataTable();
    this.flagLoaded= true;
  }

  async verCentroMedico(_id: string) {
    await this.guiMsjService.msjFormSubmit('Espere');
    Swal.showLoading();
    const centroMedico: SignoCentroMedico = await this.signoCentroMedicoService.getCentroMedicoById(_id);
    if (centroMedico) {
      this.router.navigateByUrl('/centro-medico-modificacion');
      this.signoCentroMedicoService.setCentroMedicoSeleccionado(centroMedico);
      Swal.close();
    }
    else {
      Swal.close();
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event?.target as HTMLInputElement)?.value.trim().toLowerCase();
    const customFilter = (data: any, filter: string): boolean => {
      // Verifica si el valor del filtro está en las columnas
      return (
        data.nombre.toLowerCase().includes(filter) ||
        data.calle.toLowerCase().includes(filter) ||
        data.altura.toLowerCase().includes(filter) ||
        data.horarios.toLowerCase().includes(filter) ||
        data.zona.concepto.toLowerCase().includes(filter) ||
        data.localidad.nombre.toLowerCase().includes(filter)
      );
    };

    // Aplica el filtro personalizado
    this.dataSource.filter = filterValue;
    this.dataSource.filterPredicate = customFilter;
  }


}
