import { Response } from 'src/app/interfaces/interface-bakend/response';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import { StorageService } from '../shared/storage.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { GuiMsjService } from '../shared/gui-msj.service';
import { UserEntreUser } from '@interfaces/interface-bakend/seguridad/users/user-entre-user.interface';
import { User } from '@interfaces/interface-bakend/seguridad/users/user.interface';
import { PerfilesKeys } from '@enum/perfiles-keys.enum';
import Swal from 'sweetalert2';
import { Solicitud } from '@interfaces/interface-bakend/ofertas-laborales/solicitudes.interface';

const URL_BACKEND = environment.urlBackend;

@Injectable({ providedIn: 'root' })
export class UserEntreUserService {

  userEntreUser: UserEntreUser;

  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private guiMsjService: GuiMsjService,
    private authService: AuthService,
    private storageService: StorageService
  ) { }

  async create(user: UserEntreUser, msj: boolean): Promise<UserEntreUser> {
    const headers = new HttpHeaders({
      'x-token': await this.authService.loadJwToken(),
    });

    return new Promise(async (resolve, reject) => {
      this.httpClient
        .post<Response>(`${URL_BACKEND}/users-entre-users`, user, {
          headers,
        })
        .subscribe(
          (resp) => {
            Swal.close();

            if (msj) {
              Swal.fire({
                icon: 'success',
                title: 'Familiar asignado satisfactoriamente',
                showConfirmButton: false,
                timer: 1100,
              });
            }

            this.router.navigate(['/familiares']);

            // try {
            //   this.authService.getUserLogon()
            //     .then((userLogOn: User) => {
            //       if (userLogOn) {
            //         const { descripcion } = userLogOn.perfil;
            //         if (descripcion && descripcion === PerfilesKeys.SYS_ADMIN) {
            //           this.router.navigate(['/afiliados-listado']);
            //         }
            //         this.router.navigate(['/']);
            //       }
            //     });

            // }
            // catch (error) {
            //   console.error("El usuario no tiene un perfil asignado", error)
            // }

            resolve(resp.response);
          },
          (error) => {
            console.error(error);
            this.guiMsjService.msjFormSubmit('createAfiliadoError');
            this.router
              .navigateByUrl('/', { skipLocationChange: true })
              .then(async () => {
                await this.router.navigateByUrl('/');
              });
            reject(error);
          }
        );
    });
  }

  getUserEntreUser(): UserEntreUser {
    return this.userEntreUser;
  }

  async getUserEntreUserById(id: string): Promise<UserEntreUser> {
    return new Promise(async (resolve) => {
      const headers = new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });

      this.httpClient
        .get<Response>(`${URL_BACKEND}/users-entre-users/findOne/${id}`, {
          headers,
        })
        .subscribe((resp: Response) => {
          resolve(resp.response);
        });
    });
  }

  async updateUserEntreUser(_id: string, user: UserEntreUser): Promise<UserEntreUser> {
    return new Promise(async (resolve) => {
      const headers = new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });
      this.httpClient
        .put<Response>(
          `${URL_BACKEND}/users-entre-users/updateOne/${_id}`,
          user,
          { headers }
        )
        .subscribe(
          (resp: Response) => {
            resolve(resp.response);
          },
          (error) => {
            console.error(error);
            this.guiMsjService.msjFormSubmit('afiliadoDuplicado');
          }
        );
    });
  }

  async findGrupoFamiliar(titularId: string): Promise<UserEntreUser[]> {
    return new Promise(async resolve => {
      const headers = new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });

      this.httpClient.get<Response>(`${URL_BACKEND}/users-entre-users/findGrupoFamiliar/${titularId}`, { headers }).subscribe(resp => {
        resolve(resp.response);
      });

    });
  }

  async findTitular(adicionalId: string): Promise<UserEntreUser[]> {
    return new Promise(async resolve => {

      const headers = new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });

      this.httpClient.get<Response>(`${URL_BACKEND}/users-entre-users/findTitular/${adicionalId}`, { headers }).subscribe(resp => {
        resolve(resp.response);
      });

    });
  }

  setUserEntreUserSeleccionado(user: UserEntreUser) {
    this.userEntreUser = user;
    this.storageService.setUsuarioEntreUsuario(this.userEntreUser);
  }

  async getBeneficiario(solicitud: Solicitud) {
    let beneficiario;
    let idUsuario: string;
    if (solicitud?.usuario._id) {
      idUsuario = (solicitud?.usuario?._id);
    } else {
      idUsuario = solicitud.usuario.toString();
    }

    if (solicitud?.beneficiario?._id !== undefined)  { // contemplo como recibo el parametro de beneficiario
      const beneficiaries = await this.findGrupoFamiliar(idUsuario);
      beneficiario = beneficiaries.find(beneficiary => beneficiary.adicional._id === solicitud.beneficiario._id);
    } else  {
      const beneficiaries = await this.findGrupoFamiliar(idUsuario);
      beneficiario = beneficiaries.find(beneficiary => beneficiary.adicional._id === solicitud.beneficiario);
    }
    return beneficiario;
  }
  

}
