<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Documentación</h2>
        </div>
    </div>
</div>

<div class="andorra-document-content ptb-70  animate__animated animate__fadeIn">
    <div class="container">
        <div class="row  mx-auto">
            <div class="col-lg-12 col-md-12">
                <div class="document-content">
                    <h3>{{data.tituloDocumento}}</h3>
                    <hr>
                    <form>
                        <iframe [src]="path | safeResourceUrl" type="application/pdf" style="width: 100%; height:100vh;"></iframe>
                        <button (click)="closeModal()">Cerrar<span></span></button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
