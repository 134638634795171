import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SignoPlanService } from '@services/entidades/signo-planes.service';
import { SignoPlan } from '@interfaces/interface-bakend/entidades/signo-plan.interface';
import { Router } from '@angular/router';
import { GuiMsjService } from 'src/app/services/shared/gui-msj.service';
@Component({
  selector: 'app-plan-alta',
  templateUrl: './plan-alta.component.html',
  styleUrls: ['./plan-alta.component.scss']
})
export class PlanAltaComponent implements OnInit {
  form: FormGroup;

  constructor(
    private signoPlanService: SignoPlanService,
    private route: Router,
    private guiMsjService: GuiMsjService
  ) {
    this.createForm();
  }

  ngOnInit(): void {
  }

  get idRefNoValido() {
    return this.form.get('identificacion').invalid;
  }

  get planNoValido() {
    return this.form.get('denominacion').invalid;
  }

  createForm() {
    this.form = new FormGroup({
      identificacion: new FormControl('', [
        Validators.required,
        Validators.maxLength(50)
      ]),
      denominacion: new FormControl('', [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(50),
      ]),
    });
  }

  async createPlan() {
    await this.guiMsjService.msjFormSubmit('Guardando');
    this.signoPlanService.create(await this.setDataForma())
    await this.guiMsjService.msjFormSubmit('DatosGuardados');
    this.route.navigateByUrl('/planes-listado');
  }

  async setDataForma(): Promise<SignoPlan> {
    const plan: SignoPlan = {
      idRef: this.form.value.identificacion,
      denominacion: this.form.value.denominacion || "",
    };

    return plan;
  }

}
