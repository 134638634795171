<app-loading *ngIf="!this.flagCargando">
</app-loading>
<nav *ngIf="this.flagCargando" class="sb-topnav navbar navbar-expand navbar-light" style="background-color: #e3f2fd;">
    <div class="container-fluid">
        <a class="navbar-brand">Estadísticas</a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                <li class="nav-item">
                    <a class="nav-link active" aria-current="page" routerLink="/">Home</a>
                </li>
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              Opciones
                </a>
                    <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                        <li><a class="dropdown-item" routerLink="/">Inicio</a></li>
                        <li><a class="dropdown-item" routerLink="/signo-medico-estadisticas">Ofertas Estadísticas</a></li>
                        <li>
                            <hr class="dropdown-divider">
                        </li>
                        <li><a class="dropdown-item" href="#">Reportes</a></li>
                    </ul>
                </li>
            </ul>
            <div class="col-lg-1 col-md-1">
                <mat-icon matBadge={{this.cantTotalUsuarios}} matBadgeColor="primary">perm_identity</mat-icon>
            </div>
            <div class="col-lg-1 col-md-1">
                <mat-icon matBadge={{this.cantTotalSolicitudes}} matBadgeColor="primary">fact_check</mat-icon>
            </div>

        </div>
    </div>
</nav>


<div *ngIf="this.flagCargando" class="container-fluid px-4">

    <br>
    <div mat-dialog-actions align="end">
        <button (mouseover)="setDataFormarefreshComponents()" (click)="setDataFormarefreshComponents()" mat-mini-fab aria-label="">
        <mat-icon>refresh</mat-icon>
        </button>
    </div>
    <br>

    <div class="row">
        <div class="col-xl-3 col-md-6">
            <div class="card bg-primary-set text-white mb-4">
                <div class="card-body">
                    Presentados<span class="badge bg-secondary">{{this.cantPresentados}}</span>
                </div>
                <div class="card-footer d-flex align-items-center justify-content-between">
                    <a (click)="navigateToSolicitudes(this.iAppOfertas.estado_0.number)" class="small text-white stretched-link menu_links">Ver Detalle</a>
                    <div class="small text-white"><i class="fas fa-angle-right"></i></div>
                </div>
            </div>
        </div>
        <div class="col-xl-3 col-md-6">
            <div class="card bg-primary-set text-white mb-4">
                <div class="card-body">
                    En Revisión<span class="badge bg-secondary">{{this.cantEnRevision}}</span>
                </div>
                <div class="card-footer d-flex align-items-center justify-content-between">
                    <a (click)="navigateToSolicitudes(this.iAppOfertas.estado_3.number)" class="small text-white stretched-link menu_links">Ver Detalle</a>
                    <div class="small text-white"><i class="fas fa-angle-right"></i></div>
                </div>
            </div>
        </div>
        <div class="col-xl-3 col-md-6">
            <div class="card bg-primary-set text-white mb-4">
                <div class="card-body">
                    En Auditoría Médica<span class="badge bg-secondary">{{this.cantEnAuditoria}}</span>
                </div>
                <div class="card-footer d-flex align-items-center justify-content-between">
                    <a (click)="navigateToSolicitudes(this.iAppOfertas.estado_4.number)" class="small text-white stretched-link menu_links">Ver Detalle</a>
                    <div class="small text-white"><i class="fas fa-angle-right"></i></div>
                </div>
            </div>
        </div>
        <div class="col-xl-3 col-md-6">
            <div class="card bg-success text-white mb-4">
                <div class="card-body">
                    Aprobadas<span class="badge bg-secondary">{{this.cantEstadoAprobadas}}</span>
                </div>
                <div class="card-footer d-flex align-items-center justify-content-between">
                    <a (click)="navigateToSolicitudes(this.iAppOfertas.estado_4.number)" class="small text-white stretched-link menu_links">Ver Detalle</a>
                    <div class="small text-white"><i class="fas fa-angle-right"></i></div>
                </div>
            </div>
        </div>
        <div class="col-xl-3 col-md-6">
            <div class="card bg-danger text-white mb-4">
                <div class="card-body">
                    Rechazadas<span class="badge bg-secondary">{{this.cantEstadoRechazadas}}</span></div>
                <div class="card-footer d-flex align-items-center justify-content-between">
                    <a (click)="navigateToSolicitudes(this.iAppOfertas.estado_5.number)" class="small text-white stretched-link menu_links">Ver Detalle</a>
                    <div class="small text-white"><i class="fas fa-angle-right"></i></div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-xl-6">
            <div class="card mb-4">
                <div class="card-header">
                    <i class="fas fa-chart-pie me-1"></i> Solicitudes por estado
                </div>
                <div class="card-body">
                    <app-doughnut-chart [cantPresentados]=cantPresentados [cantDocumentosCargados]=cantDocumentosCargados [cantEnRevision]=cantEnRevision [cantEnAuditoria]=cantEnAuditoria [cantEstadoAprobadas]=cantEstadoAprobadas [cantEstadoRechazadas]=cantEstadoRechazadas></app-doughnut-chart>
                </div>
            </div>
        </div>
        <div class="col-xl-6">
            <div class="card mb-4">
                <div class="card-header">
                    <i class="fas fa-chart-bar me-1"></i> Solicitudes Progreso
                </div>
                <div class="card-body">
                    <div class="container">
                        <div class="row align-items-start">
                            <div class="col">
                                <p class="card-text">Presentados</p>
                                <div class="progress">
                                    <div class="progress-bar" style="background-color:#0d6efd" role="progressbar" [style.width]="porcPresentados + '%'" aria-valuenow="porcPresentados" aria-valuemin="0" aria-valuemax="100">{{porcPresentados | number:'1.0-0' }}%</div>
                                </div>
                                <br>
                                <br>
                                <p class="card-text">Documentos Cargados</p>
                                <div class="progress">
                                    <div class="progress-bar" style="background-color:#85929E" role="progressbar" [style.width]="porcDocCargados + '%'" aria-valuenow="porcDocCargados" aria-valuemin="0" aria-valuemax="100">{{porcDocCargados | number:'1.0-0' }}%</div>
                                </div>
                                <br>
                                <p class="card-text">En Revisión</p>
                                <div class="progress">
                                    <div class="progress-bar" style="background-color:#abcd5c" role="progressbar" [style.width]="porcEnRevision + '%'" aria-valuenow="porcEnRevision" aria-valuemin="0" aria-valuemax="100">{{porcEnRevision | number:'1.0-0' }}%</div>
                                </div>
                                <br>
                                <p class="card-text">En Auditoria Médica</p>
                                <div class="progress">
                                    <div class="progress-bar" style="background-color:#CD5C5C" role="progressbar" [style.width]="porcEnAuditoria + '%'" aria-valuenow="porcEnAuditoria" aria-valuemin="0" aria-valuemax="100">{{porcEnAuditoria | number:'1.0-0' }}%</div>
                                </div>
                                <br>
                                <p class="card-text">Aprobados</p>
                                <div class="progress">
                                    <div class="progress-bar  bg-success" role="progressbar" [style.width]="porcAprobados + '%'" aria-valuenow="porcAprobados" aria-valuemin="0" aria-valuemax="100">{{porcAprobados | number:'1.0-0' }}%</div>
                                </div>
                                <br>
                                <p class="card-text">Rechazados</p>
                                <div class="progress">
                                    <div class="progress-bar  bg-danger" role="progressbar" [style.width]="porcRechazados + '%'" aria-valuenow="porcRechazados" aria-valuemin="0" aria-valuemax="100">{{porcRechazados | number:'1.0-0' }}%</div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>


            </div>
        </div>
    </div>
    <!-- <div class="card mb-4">
        <div class="card-header">
            <i class="fas fa-table me-1"></i> Ofertas...
        </div>
        <div class="card-body">
            <app-ofertas-table [solicitudes]=solicitudes></app-ofertas-table>
        </div>
    </div> -->
</div>