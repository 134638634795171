import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { SignoEmpresa } from 'src/app/interfaces/interface-bakend/entidades/signo-empresa.interface';
import { SignoEmpresaService } from 'src/app/services/entidades/signo-empresas.service';
import { GuiMsjService } from 'src/app/services/shared/gui-msj.service';
import { PreviousRouteService } from '@services/shared/previous-route.service';
import Swal from 'sweetalert2';
import { AuthService } from '@services/auth/auth.service';
import { PerfilesService } from '@services/perfiles/perfiles.service';

@Component({
    selector: 'app-empresas-listado',
    templateUrl: './empresas-listado.component.html',
    styleUrls: ['./empresas-listado.component.scss'],
})
export class EmpresasListadoComponent implements OnInit {
    /* Data Table*/
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    displayedColumns: string[] = ['nombre', 'fecha', 'estado', '_id'];
    dataSource: MatTableDataSource<SignoEmpresa>;
    signoEmpresas: SignoEmpresa[];
    
    /* Fin Data Table*/

    flagLoaded: boolean = false;
    keyPerfil: string = '';
    routes: { url: string, label: string }[] = [];
    previousRoute: string = "/configurar-administracion";

    constructor(
        private signoEmpresaService: SignoEmpresaService,
        private router: Router,
        private previousRouteService: PreviousRouteService,
        private guiMsjService: GuiMsjService,
        private authService: AuthService,
        private perfilesService: PerfilesService,
    ) {
        this.dataSource = new MatTableDataSource();
    }

    ngOnInit(): void {

        this.previousRouteService.getRoutes().subscribe(routes => {
            this.routes = routes;
        }).unsubscribe();

        this.getPerfil().then(() => {
            this.perfilesService.allowedByUserLogon(this.router.url, this.previousRoute, this.keyPerfil);
          });
          
        this.loadDataPage();
    }

    async ngAfterViewInit() {
        await this.loadDataPage();
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
    }

      async getPerfil() {
        try {
          const user = await this.authService.getUserLogon();
          if (user) {
            const { key } = user?.perfil
            this.keyPerfil = key;
          }
        }
        catch (error) {
          console.log('ABM - Error al obtener el perfil del usuario', error);
          return '';
        }
      }

    async loadDataTable() {
        await this.signoEmpresaService
            .getEmpresas()
            .then((signoEmpresas: SignoEmpresa[]) => {
                this.dataSource = new MatTableDataSource(signoEmpresas);
                this.dataSource.sort = this.sort;
                this.dataSource.paginator = this.paginator;
                this.flagLoaded = true;
            });
    }

    async loadDataPage() {
        await this.loadDataTable();
        this.flagLoaded = true;
    }

    async verEmpresa(_id: string) {
        await this.guiMsjService.msjFormSubmit('Espere');
        Swal.showLoading();
        const empresa = await this.signoEmpresaService.getEmpresaById(_id);
        if (empresa !== undefined) {
            this.router.navigateByUrl('/empresa-modificacion');
            this.signoEmpresaService.setEmpresaSeleccionada(empresa);
            Swal.close();
        }
        else {
            Swal.close();
        }
    }

    applyFilter(event: Event) {
        let filterValue = (event?.target as HTMLInputElement)?.value
            .trim()
            .toLowerCase();

        this.dataSource.filter = filterValue;
    }

    filterByDate(date: string) {
        let inputDate = new Date(date);
        const filterData = this.signoEmpresas.filter(empresa => {
            let empresaDate = new Date(empresa.fecha);
            return empresaDate.getTime() === inputDate.getTime();
        });

        this.dataSource = new MatTableDataSource(filterData);
    }

}
