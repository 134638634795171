<div class="sidebar">
    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Menú">
      <mat-icon>menu</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item routerLink="/centros-medicos-listado">
        <mat-icon>local_hospital</mat-icon>
        <span>Listado de Centros Médicos</span>
      </button>
      <button mat-menu-item routerLink="/rubros-listado">
        <mat-icon>list_alt</mat-icon>
        <span>Listado de Rubros</span>
      </button>
      <button mat-menu-item routerLink="/especialidades-listado">
        <mat-icon>people</mat-icon>
        <span>Listado de Especialidades</span>
      </button>
      <button mat-menu-item routerLink="/zonas-listado">
        <mat-icon>map</mat-icon>
        <span>Listado de Zonas</span>
      </button>
      <button mat-menu-item routerLink="/localidades-listado">
        <mat-icon>location_on</mat-icon>
        <span>Listado de Localidades</span>
      </button>
      <button mat-menu-item routerLink="/centros-propios-listado">
        <mat-icon>storefront</mat-icon>
        <span>Listado de Centros Propios</span>
      </button>
      <button mat-menu-item routerLink="/doctores-listado">
        <mat-icon>person</mat-icon>
        <span>Listado de Centros Propios Doctores</span>
      </button>
      <button mat-menu-item routerLink="/centros-propios-especialidades-listado">
        <mat-icon>medical_services</mat-icon>
        <span>Listado de Centros Propios Especialidades</span>
      </button>
      <button mat-menu-item routerLink="/afiliados-listado">
        <mat-icon>people_alt</mat-icon>
        <span>Listado de Afiliados</span>
      </button>
      <button mat-menu-item routerLink="/sedes-listado">
        <mat-icon>business</mat-icon>
        <span>Listado de Sedes</span>
      </button>
      <button mat-menu-item routerLink="/autorizaciones-listado">
        <mat-icon>assignment</mat-icon>
        <span>Listado de Autorizaciones</span>
      </button>
      <button mat-menu-item routerLink="/empresas-listado">
        <mat-icon>business_center</mat-icon>
        <span>Listado de Empresas</span>
      </button>
      <button mat-menu-item routerLink="/planes-listado">
        <mat-icon>credit_card</mat-icon>
        <span>Listado de Planes</span>
      </button>
    </mat-menu>
</div>  

