<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Inicio</a></li>
                <li><a routerLink="/configurar-administracion">Configuración
                        Administración</a></li>
                <li><a routerLink="/centros-propios-listado">Listado de Centros Propios</a></li>
                <li>Modificar Centro</li>
            </ul>
            <h2>Modificar Centro</h2>
            <h4 *ngIf="this.catalogo">{{catalogo.concepto}}</h4>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape-signomedico.png" alt="image" width="10%"></div>
</div>

<div class="andorra-apply-content ptb-70  animate__animated animate__backInUp">
    <div class="container">
        <div class="row mx-auto">
            <div class="col-lg-12 col-md-12">
                <div class="apply-form">
                    <form [formGroup]="form" (ngSubmit)="put()">

                        <div class="row">

                            <div class="col-lg-6 col-md-6 col-md-12">
                                <div class="form-group">
                                    <mat-form-field class="example-full-width" appearance="fill"
                                        [style.width.%]=100>
                                        <mat-label>Centro propio</mat-label>
                                        <input formControlName="centro" matInput placeholder="Centro propio"
                                            [class.is-invalid]="centroNoValido" autocomplete="off">
                                        <mat-error *ngIf="centroNoValido">Ingrese un
                                            nombre de centro propio</mat-error>
                                    </mat-form-field>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6 col-md-12">
                                <div class="form-group">
                                    <mat-slide-toggle formControlName="activa">Activo</mat-slide-toggle>
                                </div>
                            </div>

                        </div>

                        <div class="row my-2">

                            <div class="col-lg-6 col-md-6 col-md-12">
                                <div class="form-group">
                                    <mat-form-field class="example-full-width" appearance="fill"
                                        [style.width.%]=100>
                                        <mat-label>Teléfono</mat-label>
                                        <input formControlName="telefono" matInput
                                            placeholder="Ingrese Teléfono" autocomplete="off">
                                    </mat-form-field>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6 col-md-12">
                                <div class="form-group">
                                    <mat-form-field class="example-full-width" appearance="fill"
                                        [style.width.%]=100>
                                        <mat-label>Dirección</mat-label>
                                        <input formControlName="direccion" matInput placeholder="Ingrese Dirección"
                                            autocomplete="off">
                                    </mat-form-field>
                                </div>
                            </div>

                        </div>

                        <div class="row">

                            <div class="col-lg-6 col-md-6 col-md-12">
                                <div class="form-group">
                                    <mat-form-field class="example-full-width" appearance="fill"
                                        [style.width.%]=100>
                                        <mat-label>Latitud</mat-label>
                                        <input formControlName="latitud" matInput
                                            placeholder="Ingrese Latitud" autocomplete="off"
                                            (change)="updatesCoordinates()"
                                            (input)="updateMarkerLocation()">
                                        <mat-error *ngIf="form.get('latitud').hasError('pattern')">Ingrese
                                            una latitud válida.</mat-error>
                                    </mat-form-field>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6 col-md-12">
                                <div class="form-group">
                                    <mat-form-field class="example-full-width" appearance="fill"
                                        [style.width.%]=100>
                                        <mat-label>Longitud</mat-label>
                                        <input formControlName="longitud" matInput
                                            placeholder="Ingrese Longitud" autocomplete="off"
                                            (change)="updatesCoordinates()"
                                            (input)="updateMarkerLocation()">
                                        <mat-error *ngIf="form.get('longitud').hasError('pattern')">Ingrese
                                            una longitud válida.</mat-error>
                                    </mat-form-field>
                                </div>
                            </div>

                        </div>

                        <div class="row">
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <app-map></app-map>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-lg-4 col-md-4">
                                <button type="button" routerLink="/centros-propios-listado"
                                    class="btn btn-warning">Cancelar</button>
                            </div>

                            <div class="col-lg-4 col-md-4">
                                <button [disabled]="!form.valid" type="submit">Guardar</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>