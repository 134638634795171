import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { GuiMsjService } from 'src/app/services/shared/gui-msj.service';
import { Catalogo } from '../../../../../interfaces/interface-bakend/shared/catalogo/catalogo.interface';
import { CatalogoService } from '../../../../../services/shared/catalogo.service';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-zona-modificacion',
    templateUrl: './zona-modificacion.component.html',
    styleUrls: ['./zona-modificacion.component.scss']
})
export class ZonaModificacionComponent implements OnInit {
    form: FormGroup;
    catalogo: Catalogo;
    flagLoaded = false;

    constructor(
        private router: Router,
        private catalogoService: CatalogoService,
        public guiMsjService: GuiMsjService
    ) {
    }

    ngOnInit(): void {
        this.getEstadoVariables();
        this.createForm();
    }

    get zonaNoValida() {
        return this.form.get('zona').invalid && this.form.get('zona').touched;
    }

    getZona() {
        return (this.catalogo = this.catalogoService.getConceptoSeleccionado());
    }

    async getEstadoVariables() {
        if (this.getZona() === undefined) {
            await this.router.navigateByUrl('/zonas-listado');
        } else {
            await this.getZona();
            this.flagLoaded = true;
        }
    }

    createForm() {
        this.form = new FormGroup({
            zona: new FormControl(this.catalogo?.concepto, [
                Validators.required,
                Validators.min(3),
            ]),
            activa: new FormControl(this.catalogo?.logico_1, [
                Validators.required,
            ]),
        });
    }

    async setDataForma(): Promise<Catalogo> {
        const data: Catalogo = {
            key: null,
            concepto: this.form.value.zona,
            logico_1: this.form.value.activa,
            parent: this.catalogo.parent,
            properties: {},
        };

        return data;
    }

    async updateZona() {
        await this.guiMsjService.msjFormSubmit('Guardando');
        await this.catalogoService.put(this.catalogo._id, await this.setDataForma())
        .then(async () => {
            Swal.fire({
                icon: 'success',
                title: 'Zona modificada satisfactoriamente',
                showConfirmButton: false,
                timer: 1100,
            });
            this.router.navigateByUrl('/zonas-listado');
        })
        .catch((error) => {
            console.error('Error al actualizar zona', error);
        });
    }

}
