import { Component, Input, OnInit } from '@angular/core';
import { ChartType } from 'chart.js';
import { Color, Label, MultiDataSet } from 'ng2-charts';
import { Subscription } from 'rxjs';
import { EstadisticasService } from '../../../services/ofertas-laborales/estadisticas.service';

@Component({
  selector: 'app-doughnut-chart',
  templateUrl: './doughnut-chart.component.html',
  styleUrls: ['./doughnut-chart.component.scss']
})
export class DoughnutChartComponent implements OnInit {

    @Input() cantEstadoParcial: number = 0;
    @Input() cantFormularioCargado: number = 0;
    @Input() cantDocumentosCargados: number = 0;
    @Input() cantPreaprobado: number = 0;
    @Input() cantPagoTotalSolicitado: number = 0;
    @Input() cantEstadoPagoTotal: number = 0;
    @Input() cantEstadoEsperaPasajes: number = 0;
    @Input() cantEstadoEsperaPcr: number = 0;
    @Input() cantDisponiblePreContrato: number = 0;
    @Input() cantEstadoAprobadas: number = 0;
    @Input() cantEstadoRechazadas: number = 0;

    refresh: Subscription;


    public doughnutChartLabels: Label[] = [
        'Pago Parcial',  'Formulario Cargado',
        'Documentos cargados', 'Pre- Aprobados',
        'Pago Total Solicitado', 'Pago Total Realizado',
        'En espera de pasajes', 'En espera de pcr',
        'Disponible Pre-Contrato', 'Aprobadas',
        'Rechazadas'
    ];

    public doughnutChartColors: Color[] = [{
        backgroundColor: ['#0d6efd', '#F9E79F', '#85929E', '#CD5C5C', '#85C1E9', '#DAF7A6', '#FFC300' , '#FFC300', '#FFA07A', '#2ecc71', '#e74c3c']
    }];

    public doughnutChartData: MultiDataSet = [];
    // = [
    //    [7, 3, 6, 2],
    // ];

    public doughnutChartType: ChartType = 'doughnut';


    constructor(
       private estadisticasService: EstadisticasService,
    ) {
        this.refresh = this.estadisticasService.refresh().subscribe(resp => {
            this.cargarDataSet();
        });
     }

    ngOnInit(): void {
        this.cargarDataSet();
    }

     // events
     public chartClicked({ event, active }: { event: MouseEvent, active: {}[] }): void {}

     public chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {}

     public cargarDataSet() {
         this.doughnutChartData = [];
         this.doughnutChartData.push([
                                    this.cantEstadoParcial, this.cantFormularioCargado,
                                    this.cantDocumentosCargados, this.cantPreaprobado,
                                    this.cantPagoTotalSolicitado, this.cantEstadoPagoTotal,
                                    this.cantEstadoEsperaPasajes, this.cantEstadoEsperaPcr,
                                    this.cantDisponiblePreContrato, this.cantEstadoAprobadas,
                                    this.cantEstadoRechazadas,
                                ]);
     }

}
