<div class="page-title-area">
  <div class="container">
    <div class="page-title-content">
      <ul>
        <li><a routerLink="/dashboard">Inicio</a></li>
        <li><a routerLink="/configurar-administracion">Configuración Administración</a></li>
        <li>Autorizaciones</li>
        <li>
          <div class="sidebar">
            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Menú">
              <mat-icon>menu</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item routerLink="/centros-medicos-listado">
                <mat-icon>local_hospital</mat-icon>
                <span>Listado de Centros Médicos</span>
              </button>
              <button mat-menu-item routerLink="/rubros-listado">
                <mat-icon>list_alt</mat-icon>
                <span>Listado de Rubros</span>
              </button>
              <button mat-menu-item routerLink="/especialidades-listado">
                <mat-icon>people</mat-icon>
                <span>Listado de Especialidades</span>
              </button>
              <button mat-menu-item routerLink="/zonas-listado">
                <mat-icon>map</mat-icon>
                <span>Listado de Zonas</span>
              </button>
              <button mat-menu-item routerLink="/localidades-listado">
                <mat-icon>location_on</mat-icon>
                <span>Listado de Localidades</span>
              </button>
              <button mat-menu-item routerLink="/centros-propios-listado">
                <mat-icon>storefront</mat-icon>
                <span>Listado de Centros Propios</span>
              </button>
              <button mat-menu-item routerLink="/doctores-listado">
                <mat-icon>person</mat-icon>
                <span>Listado de Centros Propios Doctores</span>
              </button>
              <button mat-menu-item routerLink="/centros-propios-especialidades-listado">
                <mat-icon>medical_services</mat-icon>
                <span>Listado de Centros Propios Especialidades</span>
              </button>
              <button mat-menu-item routerLink="/afiliados-listado">
                <mat-icon>people_alt</mat-icon>
                <span>Listado de Afiliados</span>
              </button>
              <button mat-menu-item routerLink="/sedes-listado">
                <mat-icon>business</mat-icon>
                <span>Listado de Sedes</span>
              </button>
              <button mat-menu-item routerLink="/autorizaciones-listado">
                <mat-icon>assignment</mat-icon>
                <span>Listado de Autorizaciones</span>
              </button>
              <button mat-menu-item routerLink="/empresas-listado">
                <mat-icon>business_center</mat-icon>
                <span>Listado de Empresas</span>
              </button>
              <button mat-menu-item routerLink="/planes-listado">
                <mat-icon>credit_card</mat-icon>
                <span>Listado de Planes</span>
              </button>
            </mat-menu>
          </div>
        </li>
      </ul>
      <h2>Autorizaciones</h2>
    </div>
  </div>
  <div class="shape9"><img src="assets/img/shape-signomedico.png" width="10%" alt="image"></div>
</div>

<div class="profile-area pb-70">
  <div class="container">
    <div class="profile-courses-quizzes">
      <div class="d-flex justify-content-between align-items-center">
        <h3>Aquí encontrará el estado de las <strong>autorizaciones</strong> realizadas</h3>
      </div>
      <app-loading *ngIf="!flagLoadedModal" class="m-5"></app-loading><br>

      <mat-card class="search-card">
        <div class="container mt-4" style="display: flex; align-items: center;">
          <mat-form-field class="mr-2">
            <mat-label>Desde</mat-label>
            <input matInput [matDatepicker]="pickerDesde" placeholder="Seleccione una fecha" [(ngModel)]="fechaDesde">
            <mat-datepicker-toggle matSuffix [for]="pickerDesde"></mat-datepicker-toggle>
            <mat-datepicker #pickerDesde></mat-datepicker>
          </mat-form-field>

          <!-- Espacio entre los campos de búsqueda -->
          <div style="width: 20px;"></div>

          <mat-form-field class="mr-2">
            <mat-label>Hasta</mat-label>
            <input matInput [matDatepicker]="pickerHasta" placeholder="Seleccione una fecha" [(ngModel)]="fechaHasta">
            <mat-datepicker-toggle matSuffix [for]="pickerHasta"></mat-datepicker-toggle>
            <mat-datepicker #pickerHasta></mat-datepicker>
          </mat-form-field>

          <div>
            <button mat-raised-button color="primary" class="button-margin" (click)="buscarPorFechas()">Buscar</button>
          </div>
        </div>
      </mat-card>

      <!-- Mostrar la cantidad de solicitudes encontradas -->
      <div *ngIf="flagLoaded">
        <br>
        <p>Cantidad de solicitudes encontradas: {{ cantidadSolicitudesTotal }}</p>
        <p>Cantidad de solicitudes pendientes: {{ cantidadSolicitudesPendientes }}</p>
        <p>Cantidad de solicitudes leídas: {{ cantidadSolicitudesLeidas }}</p>
        <!-- Acordeones para Leídas y Pendientes -->
        <mat-accordion>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>Leídas</mat-panel-title>
            </mat-expansion-panel-header>
            <div *ngFor="let key of getKeys(solicitudCounts.leidas)">
              {{ key }}: {{ solicitudCounts.leidas[key] }}
            </div>
          </mat-expansion-panel>

          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>Pendientes</mat-panel-title>
            </mat-expansion-panel-header>
            <div *ngFor="let key of getKeys(solicitudCounts.pendientes)">
              {{ key }}: {{ solicitudCounts.pendientes[key] }}
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>

      <hr style="margin-top: 20px; margin-bottom: 20px;">

      <div style="overflow-x: auto; width: 100%;">
        <!-- Agregar los tabs -->
        <mat-tab-group (selectedTabChange)="handleTabSelection($event)">
          <mat-tab *ngFor="let actividad of actividades" [label]="getTabLabel(actividad)"></mat-tab>
        </mat-tab-group>
        <!-- Contenido de cada tab -->

        <br>
        <mat-form-field appearance="fill" style="margin-right: 10px;">
          <mat-label>Selecciona una sede</mat-label>
          <mat-select [(ngModel)]="selectSede" (ngModelChange)="cargarSolicitudesPorSede($event)" name="sedes" multiple>
            <mat-option *ngFor="let sede of sedesByUsuario" [value]="sede._id">
              {{sede.nombre}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>Selecciona un estado</mat-label>
          <mat-select [(ngModel)]="selectedEstados" [disabled]="!solicitudes"
            (selectionChange)="cargarSolicitudesPorEstado($event.value)" name="estados" multiple>
            <mat-option *ngFor="let estado of iAppOfertas.selectEstados" [value]="estado.number">
              {{estado.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <div>
          <mat-form-field>
            <mat-label>Filtrar</mat-label>
            <input matInput (keyup)="applyFilter($event)" placeholder="Autorización...">
          </mat-form-field>

          <div class="mat-elevation-z8">
            <table mat-table [dataSource]="dataSource">

              <!-- Columna: Nro. Solicitud -->
              <ng-container matColumnDef="nro_solicitud">
                <th mat-header-cell *matHeaderCellDef> Nro. Solicitud </th>
                <td mat-cell *matCellDef="let element" class="column-cell"> {{element._nro}} </td>
              </ng-container>

              <!-- Columna: signoSedeNombre -->
              <ng-container matColumnDef="signoSedeNombre">
                <th mat-header-cell *matHeaderCellDef> Sede </th>
                <td mat-cell *matCellDef="let element" class="column-cell"> {{element.signoSedeNombre}} </td>
              </ng-container>

              <!-- Columna: Trámite -->
              <ng-container matColumnDef="trámite">
                <th mat-header-cell *matHeaderCellDef> Trámite </th>
                <td mat-cell *matCellDef="let element" class="column-cell"> {{element.actividad_nombre}} </td>
              </ng-container>

              <!-- Columna: Cobertura -->
              <ng-container matColumnDef="cobertura">
                <th mat-header-cell *matHeaderCellDef> Cobertura </th>
                <td mat-cell *matCellDef="let element" class="cobertura-cell column-cell"
                  [title]="element.usuario_gecros_cobertura">{{ element.usuario_gecros_cobertura }}</td>
              </ng-container>

              <!-- Columna: Fecha Solicitud -->
              <ng-container matColumnDef="fechaEstado">
                <th mat-header-cell *matHeaderCellDef> Fecha Solicitud </th>
                <td mat-cell *matCellDef="let element" class="column-cell"> {{element.createdAt | date:"dd/MM/yy"}}
                </td>
              </ng-container>

              <!-- Columna: Estado -->
              <ng-container matColumnDef="estado">
                <th mat-header-cell *matHeaderCellDef> Estado </th>
                <td mat-cell *matCellDef="let element" class="column-cell"
                  [ngStyle]="{'color': getColor(element.lastState_numberState)}">
                  {{ getStateName(element.lastState_numberState) }}
                </td>
              </ng-container>

              <!-- Columna: Progreso -->
              <ng-container matColumnDef="progreso">
                <th mat-header-cell *matHeaderCellDef> Progreso </th>
                <td mat-cell *matCellDef="let element" style="min-width: 120px;" class="column-cell">
                  <div class="progress">
                    <div class="progress-bar-background"></div>
                    <div class="progress-bar" [ngClass]="{
                                              'bg-danger': element.lastState_numberState === 5 || (element.lastState_numberState / 4) * 100 <= 20,
                                              'bg-warning': (element.lastState_numberState / 4) * 100 > 20 && (element.lastState_numberState / 4) * 100 <= 60,
                                              'bg-info': (element.lastState_numberState / 4) * 100 > 60 && (element.lastState_numberState / 4) * 100 <= 80,
                                              'bg-success': (element.lastState_numberState / 4) * 100 > 81 && (element.lastState_numberState / 4) * 100 <= 100
                                            }"
                      [style.width]="(element.lastState_numberState === 5 ? 100 : (element.lastState_numberState / 4) * 100) + '%'">
                      <span>{{ element.lastState_numberState === 5 ? 'RECHAZADO' : (element.lastState_numberState / 4) *
                        100 + '%'
                        }}</span>
                    </div>
                  </div>
                </td>
              </ng-container>

              <!-- Columna: Solicitante -->
              <ng-container matColumnDef="solicitante">
                <th mat-header-cell *matHeaderCellDef> Solicitante </th>
                <td mat-cell *matCellDef="let element" class="column-cell"> {{ element.solicitante | nombreExiste }}
                </td>
              </ng-container>

              <!-- Columna: Beneficiario -->
              <ng-container matColumnDef="beneficiario">
                <th mat-header-cell *matHeaderCellDef> Benef. </th>
                <td mat-cell *matCellDef="let element" class="column-cell"> {{ element.beneficiario }} </td>
              </ng-container>

              <!-- Columna: Parentesco -->
              <ng-container matColumnDef="parentesco">
                <th mat-header-cell *matHeaderCellDef> Parentesco </th>
                <td mat-cell *matCellDef="let element" class="column-cell"> {{ element.parentesco }} </td>
              </ng-container>

              <!-- Columna: Identificación -->
              <ng-container matColumnDef="identificacion">
                <th mat-header-cell *matHeaderCellDef> Identificación </th>
                <td mat-cell *matCellDef="let element" class="column-cell"> {{element.usuario_identificacion |
                  nombreExiste}} </td>
              </ng-container>

              <!-- Columna: Acciones -->
            <ng-container matColumnDef="id">
              <th mat-header-cell *matHeaderCellDef> Acciones </th>
              <td mat-cell *matCellDef="let element">
                <div style="display: inline-flex;">
                  <button *ngIf="flagLoadedModal" (click)="verSolicitud(element)" mat-raised-button class="btn btn-success"
                    style="margin-right: 5px;">
                    <i class="fas" [ngClass]="{'fa-eye': element.leido, 'fa-eye-slash': !element.leido}"
                      [ngStyle]="{'color': !element.leido ? 'blue' : ''}"></i>
                  </button>
                  <button *ngIf="flagLoadedModal" (click)="verDetalleGecros(element.usuario_identificacion)" mat-raised-button
                    class="btn btn-info" style="margin-right: 5px;">
                    <i class="fa fa-address-card"></i>
                  </button>
                  <button *ngIf="flagLoadedModal" (click)="verDetalleSede(element.signoSedeId, element.usuario_id)"
                    mat-raised-button class="btn btn-warning" style="margin-right: 5px;">
                    <i class="fa fa-home"></i>
                  </button>
                </div>
              </td>
            </ng-container>


              <!-- Definición de filas -->
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

              <!-- Fila que se muestra cuando no hay datos coincidentes -->
              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">No hay datos que coincidan con el filtro</td>
              </tr>

            </table>

            <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"
              aria-label="Selecciona página de solicitudes"></mat-paginator>

          </div>

          <!-- loading.component.html -->
          <br>
          <mat-progress-bar *ngIf="!flagLoaded" mode="indeterminate"></mat-progress-bar>
          <div *ngIf="!flagLoaded" class="loading-message">Procesando, por favor espere...</div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Loading Spinner -->
<div *ngIf="loading" class="loading-overlay">
  <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
</div>