


/* App. Setting */
import { InjectionToken } from '@angular/core';

export let APP_CONFIG_SHARED = new InjectionToken('app.config');

/* CONSTANTES SHARED  */
export interface IAppShared {

    estadoCivil: string;
    tiposIdentificaciones: string;
    paisDondeAplica: string;
    tiposIdioma: string;
    delegaciones: string
    disciplinas: string
}

export const AppShared: IAppShared = {
    estadoCivil: 'ESTADO_CIVIL_', // el _ es para concatenar el lenguaje por parametro y buscar en catalogo
    tiposIdentificaciones: 'TIPOS_IDENTIFICACION_', // el _ es para concatenar el lenguaje por parametro y buscar en catalogo
    paisDondeAplica: 'PAIS_DONDE_APLICA_',
    tiposIdioma: 'IDIOMAS',
    delegaciones: 'DELEGACIONES_ES', // NO tiene traducciones solo usan ES
    disciplinas: 'DISCIPLINAS_ES', // NO tiene traducciones solo usan ES
};
