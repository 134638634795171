import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Actividad, DocumentosEstadosSolicitar } from '../../../../interfaces/interface-bakend/ofertas-laborales/actividad.interface';
import { ActividadDAdjunto } from '../../../../interfaces/interface-bakend/ofertas-laborales/actividad-adjuntos.interface';
import { PaisDisponible } from '../../../../interfaces/interface-bakend/shared/paises/pais-disponible/pais-disponible.interface';
import { Catalogo } from '../../../../interfaces/interface-bakend/shared/catalogo/catalogo.interface';
import { MediaService } from '../../../../services/shared/media.service';
import { GuiMsjService } from '../../../../services/shared/gui-msj.service';
import { CatalogoService } from '../../../../services/shared/catalogo.service';
import { ValidadoresService } from '../../../../services/shared/validadores.service';
import { ActividadesService } from '../../../../services/ofertas-laborales/actividades.service';
import { PaisesService } from '../../../../services/shared/paises.service';
import { PerfilesService } from '../../../../services/perfiles/perfiles.service';
import { Curso } from 'src/app/interfaces/interface-bakend/cursos/curso.interface';
import { CursosService } from '../../../../services/cursos/cursos.service';
import { AppConfig } from '../../../../app.config/app.config';
import { APP_CONFIG_OFERTAS } from '../../../../app.config/app.config-ofertas';
import { IAppOfertas } from 'src/app/app.config/app.config-ofertas';

@Component({
  selector: 'app-ofertas-alta',
  templateUrl: './ofertas-alta.component.html',
  styleUrls: ['./ofertas-alta.component.scss']
})
export class OfertasAltaComponent implements OnInit {

  currentDate = new Date();
  form: FormGroup;
  actividad: Actividad;
  adjuntos: ActividadDAdjunto[];
  // cursosModulos: Curso[];
  paisesDisponibles: PaisDisponible[];
  tipoPuestos: Catalogo[] = [];
  modalidades: Catalogo[] = [];
  areas: Catalogo[] = [];
  
  // Upload
  flagSelectFile = false;
  selectedFile: any;
  value = 0;
  cargandoArchivo = false;
  subscriptionAvance: Subscription;
  condition = false;
  fileName = 'No file selected';
  file: File;

  /* ripple */
  centered = false;
  disabled = false;
  unbounded = false;

  /* img portada */
  fileAttr = 'Choose File';

  // arreglo de documentos que solicita 
  documentosEstadosSolicitar: DocumentosEstadosSolicitar [] = [];

  radius: number;
  color: string;

  flagProcesando = false;

  imageUrl: string | ArrayBuffer =
  './assets/img/uploadImg.png';

  constructor(private fb: FormBuilder,
              private router: Router,
              private actividadesService: ActividadesService,
              private paisesService: PaisesService,
              private mediaService: MediaService,
              private guiMsjService: GuiMsjService,
              private catalogoService: CatalogoService,
              private validadores: ValidadoresService ,
              private perfilesService: PerfilesService, 
              private cursosService: CursosService,
              @Inject(APP_CONFIG_OFERTAS) private iAppOfertas: IAppOfertas,
              ) {

    this.createForm();
    this.getAdjuntosAll();
    // this.getCursosModulo();
    this.getPaisesDisponibles();
    this.getTipoPuestos();
    this.getModalidades();
    this.getAreas();
  }

  ngOnInit(): void {
    this.perfilesService.allowedByUserLogon(this.router.url);
  }

  get nombreNoValido(){
    return this.form.get('nombre').invalid && this.form.get('nombre').touched;
  }

  get sueldoNoValido(){
    return this.form.get('sueldo').invalid && this.form.get('sueldo').touched;
  }

  get costoAdministrativoNoValido(){
    return this.form.get('costoAdministrativo').invalid && this.form.get('costoAdministrativo').touched;
  }

  get costoPostAprobacionNoValido(){
    return this.form.get('costoPostAprobacion').invalid && this.form.get('costoPostAprobacion').touched;
  }

  get importanteNoValido(){
    return this.form.get('importante').invalid && this.form.get('importante').touched;
  }

  get documentacionNoValida(){
    return this.form.get('documentacionRequerida').invalid && this.form.get('documentacionRequerida').touched;
  }

  get paisNoValido(){
    return this.form.get('nombre').invalid && this.form.get('pais').touched;
  }

  get adjuntoNoValido(){
    return this.form.get('adjuntos').invalid && this.form.get('adjuntos').touched;
  }

  get documentosEstadosSolicitarNoValido(){
    return this.form.get('documentosEstadosSolicitar').invalid // && this.form.get('documentosEstadosSolicitar').touched;
  }

  // get cursoModuloNoValido(){
  //   return this.form.get('cursoModulo').invalid && this.form.get('cursoModulo').touched;
  // }

  get vigenciaNoValida(){
    return this.form.get('vigenciaDesde').invalid && this.form.get('vigenciaDesde').touched;
  }

  get vigenciaNoValidaHasta(){
    return this.form.get('vigenciaHasta').invalid && this.form.get('vigenciaHasta').touched;
  }

  get descripcionNoValida(){
    return this.form.get('descripcion').invalid && this.form.get('descripcion').touched;
  }

  get detalleNoValido(){
    return this.form.get('detalle').invalid && this.form.get('detalle').touched;
  }


  createForm() {
    this.form = this.fb.group({
        nombre: new FormControl('', [ Validators.required]),
        pais: new FormControl('', [ Validators.required]),
        vigenciaDesde: new FormControl('', [ Validators.required]),
        vigenciaHasta: new FormControl('', [ Validators.required]),
        descripcion: new FormControl('', [ Validators.required]),
        documentacionRequerida: new FormControl('', ),
        importante: new FormControl('', ),
        // adjuntos: new FormControl('', ),
        detalle: new FormControl('', [ Validators.required]),
        requisitos: new FormControl('', ),
        finalizando: new FormControl('', ),
        vigente: new FormControl(true),
        imagen: new FormControl('', [ Validators.required]),
        documentosEstadosSolicitar: new FormControl('', [ Validators.required]),       
        tipoPuesto: new FormControl('',),
        documentos: new FormControl(''),
        area: new FormControl('',),
        resumenAvatar: new FormControl('',[ Validators.required]),
        modalidad: new FormControl('',),
        sueldo: new FormControl('', ),
        costoAdministrativo: new FormControl(0, [ Validators.required]),
        costoPostAprobacion: new FormControl(0, [ Validators.required]),
        circuito: new FormControl(2, [ Validators.required]),
        // cursoModulo: new FormControl('', [ Validators.required]),
    }, {
      validators: this.validadores.fechaFinMenor('vigenciaDesde', 'vigenciaHasta')
    });
  }
  async getAdjuntosAll() {
    this.adjuntos = await this.actividadesService.getAdjuntos();
  }

/*  SIGNO MEDICO NO LO USA 
    async getCursosModulo() {
    this.cursosModulos = await this.cursosService.getCursosModulos();
  } */

  async getPaisesDisponibles() {
    this.paisesDisponibles = await this.paisesService.getPaisesDisponibles();
  }

  async getTipoPuestos() {
    this.tipoPuestos = await this.catalogoService.findDescendantsByKey('TIPO_PUESTO');
  }

  async getModalidades() {
    this.modalidades = await this.catalogoService.findDescendantsByKey('MODALIDAD');
  }

  async getAreas() {
    this.areas = await this.catalogoService.findDescendantsByKey('AREA');
  }

  async post(){
    // await this.guiMsjService.msjFormSubmit('Guardando');
    this.flagProcesando = true;

    this.actividadesService.post(await this.setDataForma()).then( async (actividad: Actividad) => {
        await this.uploadFile(this.selectedFile, actividad._id);
        // await this.guiMsjService.msjFormSubmit('DatosGuardados');
        this.flagProcesando = false;
        this.router.navigateByUrl('/tramites-listado');
        return;
    });
  }

  async asignarDocumentosEstadosSolicitar(documentosSolicitar: string[]): Promise<DocumentosEstadosSolicitar[]> {
    documentosSolicitar.forEach((documentoSolicitarId: string) => {
      const documento = this.adjuntos.find(element => element._id == documentoSolicitarId);
      this.documentosEstadosSolicitar.push({documento :documento, entregado: false, solicitar:true, obligatorio:false});
    });
    return this.documentosEstadosSolicitar;
  }

  async setDataForma(): Promise<Actividad>{

    const data: Actividad = {

      i18n: 'es',
      nombre: this.form.value.nombre,
      descripcion: this.form.value.descripcion,
      paisDisponible: this.form.value.pais,
      // adjuntos: this.form.value.adjuntos,
      // cursos: this.form.value.cursoModulo,
      vigenciaDesde: this.form.value.vigenciaDesde,
      vigenciaHasta: this.form.value.vigenciaHasta,
      vigente: this.form.value.vigente,
      detalle: this.form.value.detalle,
      requisitos: this.form.value.requisitos,
      importante: this.form.value.importante,
      finalizando: this.form.value.finalizando,
      documentacionRequerida: this.form.value.documentacionRequerida,    
      resumenAvatar: this.form.value.resumenAvatar,
      costoAdministrativo: this.form.value.costoAdministrativo,
      costoPostAprobacion: this.form.value.costoPostAprobacion,
      circuito: 2, // aadidess usa solo este circuito
      // circuito: +this.form.value.circuito,
      documentosEstadosSolicitar:  await this.asignarDocumentosEstadosSolicitar(this.form.value.documentosEstadosSolicitar),
    };

    return data;

  }

  /* UPLOAD */

    /* subo archivo con observable*/
  subscribeAvanceUpload(){
      this.subscriptionAvance = this.mediaService.getAvance().subscribe(resp => {
      if (resp) {
          this.value = resp.value;
      } else {
          this.value = 0;
          }
      });
  }

  openFileSelected(event) {
      this.selectedFile = <File> event.target.files[0];
  }

  onChangeFile(file: File, event){
    if(file){
      this.openFileSelected(event);
      this.fileName = file.name;
      this.file = file;
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = e => {
        this.imageUrl = reader.result;
      }
    }
  }

  async uploadFile(selectedFile, idactividad) {
      this.cargandoArchivo = true;
      await this.mediaService.uploadImgActividad2(selectedFile, idactividad).then( resp => {
        if (resp) {
          if (this.value === 100) {this.cargandoArchivo = false; }
        } else {
          this.guiMsjService.msjFormSubmit('uploadDocumentERROR');
        }
      });
  }
   /* FIN UPLOAD */

}
