<div class="banner-section" *ngFor="let Image of bgImage;" style="background-image: url({{Image.img}});">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-12">
                <div class="banner-content">
                    <div class="row align-items-center">
                        <div class="banner-image">
                            <div class="banner-shape6"><img src="assets/img/escudo-1.png" alt="image"
                                    style="width: 30%;">
                            </div>
                        </div>
                        <h1>{{ idioma | translate: "home.BIENVENIDA" | async }}<br>Signo Médico</h1>
                    </div>
                    <div class="row align-items-center">
                        <p><strong>{{ idioma | translate: "home.BIENVENIDA-PARRAFO-2" | async }}</strong>
                        </p>
                    </div>
                    <div class="banner-image">
                        <div class="banner-shape9"><img src="assets/img/shape-signomedico.png" alt="image"
                                style="width: 30%;"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>