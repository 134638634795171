<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a class="text-white" routerLink="/">{{ idioma | translate: "navbar.UL-INICIO" | async}}</a></li>
                <li>{{ actividad.nombre }}</li>
            </ul>
            <h2>{{ actividad.nombre }}</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape-signomedico.png" alt="image" width="10%"></div>
</div>

<div class="courses-details-area pb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-7 col-md-12 col-sm-12">
                <app-detalle [actividad]=actividad [nombrePais]=nombrePais></app-detalle>
            </div>

            <div class="col-lg-5 col-md-12 col-sm-12">
                <div class="courses-details-info">
                    <ul class="info">
                        <li>
                            <div class="d-flex justify-content-between align-items-center">
                                <span><i class="flaticon-teacher"></i> {{ idioma | translate:
                                    "oferta-laboral.INFO-LUGAR" | async}}</span> {{ this.nombrePais }}
                            </div>
                        </li>
                        <li>
                            <div class="d-flex justify-content-between align-items-center">
                                <span><i class="flaticon-distance-learning"></i> {{ idioma | translate:
                                    "oferta-laboral.INFO-PUBLICADO" | async}}</span> {{ actividad.createdAt|
                                date:"dd/MM/yy" }}
                            </div>
                        </li>
                    </ul>
                    <app-loading *ngIf="flagProcesando" class="m-5"></app-loading><br>
                    <div class="courses-info">

                        <!-- Envio de solicitud por parte del titular o familiar -->

                        <div class="solicitante">
                            <label>{{ idioma | translate:
                                "oferta-laboral.BENEFICIARIO" | async}}</label>
                            <mat-form-field appearance="fill" [style.width.%]=100>
                                <mat-label>Seleccione el beneficiario</mat-label>
                                <mat-select [formControl]="formSolicitud.get('beneficiario')"
                                    [(ngModel)]="beneficiarioSelected"
                                    (selectionChange)="onBenefactorSelectedChange(beneficiarios)">
                                    <mat-option *ngFor="let beneficiario of beneficiarios" [value]="beneficiario._id">{{
                                        beneficiario.fullName+" -
                                        "+beneficiario?.parentesco}}
                                    </mat-option>
                                </mat-select>
                                <mat-hint>
                                    El afiliado que ha iniciado sesión podrá realizar una
                                    <u>solicitud</u> en nombre de un familiar a su
                                    cargo</mat-hint>
                                <mat-error *ngIf="formSolicitud.get('beneficiario').hasError('required')">Seleccione
                                    un solicitante</mat-error>
                            </mat-form-field>
                        </div>

                        <br>
                        <br>
                        <hr>
                        <br>
                        <br>

                        <button type="button" (click)="procesarSolicitudAutorizacion()"
                            [disabled]="formSolicitud.invalid || loading"
                            [ngClass]="{'custom-disabled': formSolicitud.invalid || loading}" class="default-btn">
                            <i class="fas fa-mouse-pointer"></i>SOLICITAR AUTORIZACIÓN
                        </button>
                        <br>
                        <br>
                        <mat-progress-bar *ngIf="this.loading" mode="indeterminate"></mat-progress-bar>
                        <div *ngIf="this.loading" class="alert alert-success m-3" width="100%" role="alert">
                            {{ idioma | translate: "form.MSJ-AUTORIZACION" | async}}
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

<div class="row">
    <app-btn-ofertas-listar></app-btn-ofertas-listar>
</div>