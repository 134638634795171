<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Inicio</a></li>
                <li>Configuración de Trámites</li>
            </ul>
            <h2>Configurar Trámites</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape-signomedico.png" width="10%" alt="image"></div>
</div>

<div class="features-area pb-70 animate__animated animate__fadeIn">
    <div class="features-container">
        <div class="row m-5">
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-features-box">
                    <div class="icon">
                        <i class="fas fa-list-ul"></i>
                    </div>
                    <h3>Listado de Trámites</h3>
                    <p>Acceda al listado de trámites del sistema</p>
                    <a routerLink="/tramites-listado" class="btn btn-primary">Ver Trámites</a>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-features-box">
                    <div class="icon">
                        <i class="far fa-plus-square"></i>
                    </div>
                    <h3>Nuevo Trámite</h3>
                    <p>Cree una nuevo trámite en el sistema</p>
                    <a routerLink="/tramite-alta" class="btn btn-success">Crear Trámites</a>
                </div>
            </div>
            <!-- <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-features-box">
                    <div class="icon">
                        <i class="fas fa-book-open"></i>
                    </div>
                    <h3><b>Catálogo</b></h3>
                    <p>Acceda a la configuración áreas, modalidades y tipo de puestos</p>
                    <a routerLink="/catalogo-configuracion" class="btn btn-info">
                        Ir a Catálogo
                    </a>
                </div>
            </div> -->
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-features-box">
                    <div class="icon">
                        <i class="fas fa-paperclip"></i>
                    </div>
                    <h3><b>Adjuntos</b></h3>
                    <p>Acceda a la configuración de adjuntos</p>
                    <a routerLink="/adjuntos-configuracion" class="btn btn-warning">
                        Ir a Adjuntos
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>