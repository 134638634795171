import { Response } from 'src/app/interfaces/interface-bakend/response';
import { environment } from 'src/environments/environment';
import { AuthService } from './../auth/auth.service';
import { StorageService } from '../../services/shared/storage.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { GuiMsjService } from '../../services/shared/gui-msj.service';
import { SignoSede } from 'src/app/interfaces/interface-bakend/entidades/signo-sede.interface';
import Swal from 'sweetalert2';
import { User } from 'src/app/interfaces/interface-bakend/seguridad/users/user.interface';

const URL_BACKEND = environment.urlBackend;

@Injectable({ providedIn: 'root' })
export class SignoSedeService {

signoSedeSeleccionada : SignoSede;

    constructor(
        private httpClient: HttpClient,
        private router: Router,
        private guiMsjService: GuiMsjService,
        private authService: AuthService,
        private storageService: StorageService
    ) {}
    async create(sede: SignoSede): Promise<SignoSede> {
        const headers = new HttpHeaders({
            'x-token': await this.authService.loadJwToken(),
        });

        return new Promise(async (resolve, reject) => {
            await this.httpClient
                .post<Response>(`${URL_BACKEND}/signo-sedes`, sede, {
                    headers,
                })
                .subscribe(
                    (resp) => {
                        Swal.close();

                        Swal.fire({
                            icon: 'success',
                            title: 'Sede guardada satisfactoriamente',
                            showConfirmButton: false,
                            timer: 1100,
                        });

                        this.router.navigate(['/sedes-listado']);
                        resolve(resp.response);
                    },
                    (error) => {
                        console.error(error);
                        this.guiMsjService.msjFormSubmit('crearSedeError');
                        this.router
                            .navigateByUrl('/', { skipLocationChange: true })
                            .then(async () => {
                                await this.router.navigateByUrl('/');
                            });
                        reject(error);
                    }
                );
        });
    }

    getSedeSeleccionada(): SignoSede {
        return this.signoSedeSeleccionada;
    }

    async getSedes(): Promise<SignoSede[]> {
        return new Promise(async (resolve) => {
            const headers = new HttpHeaders({
                'x-token': await this.authService.loadJwToken(),
            });

            this.httpClient
                .get<Response>(`${URL_BACKEND}/signo-sedes/findAll`, {
                    headers,
                })
                .subscribe((resp: Response) => {
                    resolve(resp.response);
                });
        });
    }

    async getSedeById(id: string): Promise<SignoSede> {
        return new Promise(async (resolve) => {
            const headers = new HttpHeaders({
                'x-token': await this.authService.loadJwToken(),
            });

            this.httpClient
                .get<Response>(`${URL_BACKEND}/signo-sedes/findOne/${id}`, {
                    headers,
                })
                .subscribe(
                    (resp: Response) => {
                    resolve(resp.response);
                },
                (error) => {
                    console.error("Error al encontrar sede",error);
                })
                
        });
    }

    async getSedeByCodigo(codigo: string): Promise<SignoSede> {
        return new Promise(async (resolve) => {
            const headers = new HttpHeaders({
                'x-token': await this.authService.loadJwToken(),
            });

            this.httpClient
                .get<Response>(`${URL_BACKEND}/signo-sedes/findOneByCodigo/${codigo}`, {
                    headers,
                })
                .subscribe(
                    (resp: Response) => {
                    resolve(resp.response);
                },
                (error) => {
                    console.error("Error al encontrar sede",error);
                })
                
        });
    }

    async updateSedeUser(_id: string, sede: SignoSede[]): Promise<User> {
        return new Promise( async resolve => {
    
            const headers =  new HttpHeaders({
                'x-token': await this.authService.loadJwToken(),
            });
            this.httpClient.put<Response>(`${URL_BACKEND}/users/updateSedeUser/${_id}`, sede, { headers }).subscribe( (resp: Response)  => {
                resolve(resp.response);
            }, error => {
                console.log('error', error);
                this.guiMsjService.msjFormSubmit('emailDuplicado');
            });
        });
      }

    async updateSede(_id: string, sede: SignoSede): Promise<SignoSede> {
        return new Promise(async (resolve) => {
            const headers = new HttpHeaders({
                'x-token': await this.authService.loadJwToken(),
            });
            this.httpClient
                .put<Response>(
                    `${URL_BACKEND}/signo-sedes/updateOne/${_id}`,
                    sede,
                    { headers }
                )
                .subscribe(
                    (resp: Response) => {
                        resolve(resp.response);
                    },
                    (error) => {
                        console.error(error);
                        this.guiMsjService.msjFormSubmit('sedeDuplicada');
                    }
                );
        });
    }

    setSedeSeleccionada(sede: SignoSede) {
        this.signoSedeSeleccionada = sede;
        this.storageService.setSede(this.signoSedeSeleccionada);
    }
}
