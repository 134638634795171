<div class="page-title-area">
  <div class="container">
    <div class="page-title-content">
      <ul>
        <li><a routerLink="/">Inicio</a></li>
        <li><a routerLink="/configurar-administracion"
             #previousRoute>Configuración
            Administración</a></li>
        <li>Listado de Centros Propios</li>
        <li><app-menu></app-menu></li>
      </ul>
      <h2>Centros Propios</h2>
    </div>
  </div>
  <div class="shape9"><img src="assets/img/shape-signomedico.png"
         alt="image"
         width="10%"></div>
</div>

<app-loading *ngIf="!flagLoaded"
             class="m-5"></app-loading><br>

<div *ngIf="flagLoaded"
     class="profile-area pb-70">
  <div class="container">
    <div class="profile-courses-quizzes">
      <h3>Listado de Centros Propios</h3>
      <br>
      <a routerLink="/centro-propio-alta"><button mat-raised-button
                mat-button
                color="primary"><mat-icon aria-hidden="false"
                    aria-label="Example home icon">add</mat-icon> Nuevo
          Centro</button></a>
      <br>
      <hr>
      <div *ngIf="flagLoaded">

        <mat-form-field>
          <mat-label>Filtrar</mat-label>
          <input matInput
                 (keyup)="applyFilter($event)"
                 placeholder="Centros propios..."
                 autocomplete="off"
                 #input>
        </mat-form-field>

        <div class="mat-elevation-z8">

          <div class="mat-elevation-z8">
            <table mat-table
                   [dataSource]="dataSource"
                   matSort>

              <!-- Columnas -->
              <ng-container matColumnDef="centro">
                <th mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header>Centro</th>
                <td mat-cell
                    *matCellDef="let element">{{ element.concepto }}</td>
              </ng-container>

              <ng-container matColumnDef="direccion">
                <th mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header>Dirección</th>
                <td mat-cell
                    *matCellDef="let element">{{ element.properties.direccion }}</td>
              </ng-container>

              <ng-container matColumnDef="telefono">
                <th mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header>Teléfono</th>
                <td mat-cell
                    *matCellDef="let element">{{ element.properties.telefono }}</td>
              </ng-container>

              <ng-container matColumnDef="latitud">
                <th mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header>Latitud</th>
                <td mat-cell
                    *matCellDef="let element">{{ element.properties.latitud }}</td>
              </ng-container>

              <ng-container matColumnDef="longitud">
                <th mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header>Longitud</th>
                <td mat-cell
                    *matCellDef="let element">{{ element.properties.longitud }}</td>
              </ng-container>

              <ng-container matColumnDef="activo">
                <th mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header>Activo</th>
                <td mat-cell
                    *matCellDef="let element">{{ element.logico_1 ? 'Si' : 'No' }}</td>
              </ng-container>

              <ng-container matColumnDef="_id">
                <th mat-header-cell
                    *matHeaderCellDef>Acciones</th>
                <td mat-cell
                    *matCellDef="let element">
                  <button style="margin-right: 5px;"
                          (click)="verCentro(element._id)"
                          type="button"
                          title="Modificar datos del centro propio"
                          class="btn btn-warning">
                    <i class="fas fa-edit"></i>
                  </button>
                </td>
              </ng-container>

              <!-- Filas sin datos -->
              <tr class="mat-row"
                  *matNoDataRow>
                <td class="mat-cell"
                    colspan="3">No hay datos que coincidan con el filtro.</td>
              </tr>

              <tr mat-header-row
                  *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row
                  *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>

            <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]"
                           showFirstLastButtons></mat-paginator>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>