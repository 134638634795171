<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/dashboard">Inicio</a></li>
                <li><a routerLink="/tramites-configuracion">Configuración de Trámites</a></li>
                <li>Configuración Adjuntos</li>
            </ul>
            <h2>Configurar Adjuntos</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape-signomedico.png" width="10%" alt="image"></div>
</div>

<div class="features-area pb-70 animate__animated animate__fadeIn">
    <div class="container">

        <div class="row m-5">
            <div class="col-lg-6 col-sm-6 col-md-6">
                <div class="single-features-box">
                    <div class="icon">
                        <i class="fas fa-list-ul"></i>
                    </div>
                    <h3>Listado de Adjuntos</h3>
                    <p>Acceda al listado de adjuntos para los trámites del sistema</p>
                    <a routerLink="/adjuntos-listado" class="btn btn-primary">Ver Adjuntos</a>
                </div>
            </div>
            
            <div class="col-lg-6 col-sm-6 col-md-6">
                <div class="single-features-box">
                    <div class="icon">
                        <i class="far fa-plus-square"></i>
                    </div>
                    <h3>Nuevo Adjunto</h3>
                    <p>Cree un nuevo Adjunto para los trámites en el sistema</p>
                    <a routerLink="/adjunto-alta" class="btn btn-success">Crear Adjunto</a>
                </div>
            </div>
        </div>
    </div>
</div>