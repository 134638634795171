import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { GuiMsjService } from 'src/app/services/shared/gui-msj.service';
import { Catalogo } from '../../../../../interfaces/interface-bakend/shared/catalogo/catalogo.interface';
import { EntidadesKeys } from 'src/app/enum/entidades-keys.enum';
import { SignoLocalidad } from 'src/app/interfaces/interface-bakend/entidades/signo-localidad.interface';
import { SignoLocalidadService } from 'src/app/services/entidades/signo-localidades.service';
import { CatalogoService } from 'src/app/services/shared/catalogo.service';
@Component({
  selector: 'app-localidad-alta',
  templateUrl: './localidad-alta.component.html',
  styleUrls: ['./localidad-alta.component.scss']
})
export class LocalidadAltaComponent implements OnInit {

  form: FormGroup;
  catalogo: Catalogo;
  zonas: Catalogo[];

  constructor(
    private router: Router,
    private signoLocalidadesService: SignoLocalidadService,
    private catalogoService: CatalogoService,
    public guiMsjService: GuiMsjService,
  ) {
      this.createForm();
      this.getZonas();
   }

  ngOnInit(): void {
  }

  get localidadNoValida(){
    return this.form.get('localidad').invalid && this.form.get('localidad').touched;
  }

  get zonaNoseleccionada() {
    return (
      this.form.get('zona').invalid &&
      this.form.get('zona').touched
    );
  }

  async getZonas() {
    this.zonas = await this.catalogoService.findDescendantsByKey(EntidadesKeys.ZONAS);
  }

  createForm() {
    this.form = new FormGroup({
        localidad: new FormControl('', [ Validators.required, Validators.min(3)]),
        zona: new FormControl('', [ Validators.required]),
        activa: new FormControl(true, [ Validators.required]),
    });
  }

  async setDataForma(): Promise<SignoLocalidad>{
    const data: SignoLocalidad = {
      nombre: this.form.value.localidad.trim(),
      activo: this.form.value.activa,
      zona: {
        _id: this.form.value.zona
      }
    };
    return data;
  }
  async post(){
    await this.guiMsjService.msjFormSubmit('Guardando');
    await this.signoLocalidadesService.create(await this.setDataForma()).then(()=>{
      this.router.navigateByUrl('/localidades-listado');
    }).catch((error) => {
      console.error('Error al guardar localidad', error);
    });
  }
}

