import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { GuiMsjService } from 'src/app/services/shared/gui-msj.service';
import { SignoSede } from 'src/app/interfaces/interface-bakend/entidades/signo-sede.interface';
import { SignoSedeService } from 'src/app/services/entidades/signo-sedes.service';

@Component({
    selector: 'app-sede-modificacion',
    templateUrl: './sede-modificacion.component.html',
    styleUrls: ['./sede-modificacion.component.scss'],
})
export class SedeModificacionComponent implements OnInit {
    form: FormGroup;
    signoSede: SignoSede;
    flagLoaded: boolean = false;

    constructor(
        private router: Router,
        private signoSedeService: SignoSedeService,
        private guiMsjService: GuiMsjService
    ) {}
    
    ngOnInit(): void {
        this.getEstadoSede();
        this.createForm();
    }

    get codigoNoValido() {
        return this.form.get('codigo').invalid;
    }

    get nombreNoValido() {
        return this.form.get('nombre').invalid;
    }

    get emailNoValido() {
        return this.form.get('email').invalid;
    }

    get telefonoNoValido() {
        return this.form.get('telefono').invalid;
    }

    get whatsappNoValido() {
        return this.form.get('whatsapp').invalid;
    }

    get detalleNoValido() {
        return this.form.get('detalle').invalid;
    }

    createForm() {
        this.form = new FormGroup({
            codigo: new FormControl(this.signoSede?.codigoGecro, [
                Validators.required,
            ]),
            nombre: new FormControl(this.signoSede?.nombre, [
                Validators.required,
                Validators.minLength(3),
                Validators.maxLength(50),
            ]),
            email: new FormControl(this.signoSede?.email, [
                Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,3}$'),
                Validators.email
            ]),
            telefono: new FormControl(this.signoSede?.telefono, [
                Validators.minLength(3),
                Validators.maxLength(50),
                Validators.pattern('[0-9+-]+$')
            ]),
            whatsapp: new FormControl(this.signoSede?.whatsapp, [
                Validators.minLength(3),
                Validators.maxLength(50),
                Validators.pattern('[0-9+-]+$')
            ]),
            consultorioInterno: new FormControl(this.signoSede?.consultorioInterno, [Validators.required]),
            detalle: new FormControl(this.signoSede?.detalle, [
                Validators.minLength(3),
                Validators.maxLength(256),
            ]),
        });
    }

    async getSede() {
        return (this.signoSede = this.signoSedeService.getSedeSeleccionada());
    }

    async getEstadoSede() {
        if (this.getSede() === undefined) {
            await this.router.navigateByUrl('/sedes-listado');
        } else {
             await this.getSede();
             this.flagLoaded = true;
        }
    }
    
    async updateSede() {
        await this.guiMsjService.msjFormSubmit('Guardando');
        this.signoSedeService.updateSede(this.signoSede._id, await this.setDataForma())
        await this.guiMsjService.msjFormSubmit('DatosGuardados');
        await this.router.navigateByUrl('/sedes-listado');
    }
    
    async setDataForma(): Promise<SignoSede> {
        const signoSede: SignoSede = {
            codigoGecro: this.form.value.codigo || "",
            _id: this.signoSede._id,
            nombre: this.form.value.nombre || '',
            email: this.form.value.email || '',
            telefono: this.form.value.telefono || '',
            whatsapp: this.form.value.whatsapp || '',
            consultorioInterno: this.form.value.consultorioInterno || false,
            detalle: this.form.value.detalle || '',
        };
        
        return signoSede;
    }
}
