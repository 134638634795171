import { Component, OnInit } from '@angular/core';
import { Translators } from '@services/shared/translators.service';
import { PerfilesService } from '@services/perfiles/perfiles.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { User } from '@interfaces/interface-bakend/seguridad/users/user.interface';
import { UsuarioService } from '@services/shared/usuario.service';
import { AuthService } from '@services/auth/auth.service';
import { GecrosAfiliadosService } from 'src/app/services/entidades/gecros-afiliados.service';
import { SignoGecrosAfiliado } from 'src/app/interfaces/interface-bakend/entidades/signo-greco-afiliado.interface';
import { GuiMsjService } from '@services/shared/gui-msj.service';
import { PreviousRouteService } from '@services/shared/previous-route.service';
import Swal from 'sweetalert2';
import { formatDate } from '@angular/common';
import { AppConfigService } from '@services/shared/app-config.service copy';

@Component({
  selector: 'app-credencial',
  templateUrl: './credencial.component.html',
  styleUrls: ['./credencial.component.scss']
})
export class CredencialComponent implements OnInit {
  idioma: Subscription;
  user: User;
  afiliadoFromGecros: SignoGecrosAfiliado;
  flagDataGecros: boolean = false;
  estados : string[] = ["Con Cobertura", "Sin Cobertura"];
  routes: { url: string, label: string }[] = [];
  currentDate: string;

  /**
   * La credencial esta conformado en tres partes:
   * n°sede(tresdigitos) - n° de documento del afiliado - parentesco(dosdigitos)
   */
  numeroAfiliado: string = "";

  bgImage = [
    {
      background: 'assets/img/signo-medico/bg-credencial-profile-2.jpg',
      credencial: 'assets/img/signo-medico/bg-credencial.png'
    }
  ]

  constructor(
    private router: Router,
    private previousRouteService: PreviousRouteService,
    private authService: AuthService,
    private translatorService: Translators,
    private perfilesService: PerfilesService,
    private userService: UsuarioService,
    private gecrosAfiliadosService: GecrosAfiliadosService,
    private guiMsjService: GuiMsjService,
    private appConfigService: AppConfigService,
  ) {

    this.idioma = this.translatorService.getIdiomaSeleccionado().subscribe(resp => {
      this.idioma = resp;
    });

    this.getUser();
  }

  ngOnInit(): void {    
    this.getfechaValidez();
    this.perfilesService.allowedByUserLogon(this.router.url);
    this.previousRouteService.getRoutes().subscribe(routes => {
      this.routes = routes;
    }).unsubscribe();
  }


  async getfechaValidez() {
    try {
      const dateString: string = await this.appConfigService.getServerTime(); // Obtener la fecha como string
      console.log('Fecha del servidor (string):', dateString);
  
      // Extraer los componentes de la fecha del string en formato ISO 8601
      const year = dateString.substring(0, 4);
      const month = dateString.substring(5, 7);
      const day = dateString.substring(8, 10);
  
      // Formatear la fecha en el formato "dd-MM-yyyy"
      this.currentDate = `${day}-${month}-${year}`;
      console.log('Fecha formateada:', this.currentDate);
  
    } catch (error) {
      console.error('Error al obtener la fecha del servidor:', error);
      // Manejar el error, por ejemplo asignar un valor predeterminado a this.currentDate
      this.currentDate = 'Error al obtener la fecha del servidor';
    }
  }

  async getUser() {
    try {
      if (this.userService.getUserLogged()) {
        this.user = await this.authService.getUserLogon();
      } else {
        this.user = this.userService.getUsuarioSeleccionado();
      }
      await this.guiMsjService.msjFormSubmit('Espere');
      Swal.showLoading();
      await this.credencialAfiliadoConFormato(this.user?.identificacion);
      this.userService.setUserLogged(true);
    } catch (error) {
      console.error('Error al obtener el afiliado:', error);
    }
  }

  /*
  *  La credencial del afiliado se crea a partir de los datos de GECROS
  */

  async credencialAfiliadoConFormato(identificacionAfiliado: string) {
    try {
      const isGecrosAvailable = await this.gecrosAfiliadosService.statusGecros();

      if (isGecrosAvailable) {
        const resp = await this.gecrosAfiliadosService.getAfiliadoByIdentificacion(identificacionAfiliado);
        if (resp.data && resp.data.length > 0) {
          this.afiliadoFromGecros = resp.data[0];
          this.flagDataGecros = true;
        } else {
          this.flagDataGecros = false;
          this.afiliadoFromGecros = undefined;
          await this.gecrosCaido(); // Llama a gecrosCaido si no hay datos en resp.data
        }
      } else {
        await this.gecrosCaido(); // Llama a gecrosCaido si GECROS no está disponible
      }

      Swal.close();
      // Numero de afiliado con "-"
      if ( this.afiliadoFromGecros ) {
        const numeroAfiliado = this.afiliadoFromGecros?.numeroAfiliado;
        this.numeroAfiliado = `${numeroAfiliado.slice(0, 3)}-${numeroAfiliado.slice(3, 11)}-${numeroAfiliado.slice(11)}`;
        this.flagDataGecros = true;
      } else {
        this.flagDataGecros = false;
      }
  
    } catch (error) {
      console.error(`Se ha producido un error al intentar formar la credencial del afiliado con identificacion ${this.user?.identificacion}`, error);
      await this.gecrosCaido(); // Llama a gecrosCaido si hay un error con statusGecros()
    }
  }
  


  async gecrosCaido() {
    this.afiliadoFromGecros = await this.userService.getFindOneDatosGecrosLocal(this.user._id);
  }

  getCurrentDate(): string {
    const date = new Date();
    return formatDate(date, 'dd/MM/yyyy', 'en-US');
  }


}
