


/* App. Setting */
import { InjectionToken } from '@angular/core';

export let APP_CONFIG_OFERTAS = new InjectionToken('app.config');

/* CONSTANTES OFERTAS LABORALES  */
export interface IAppOfertas{
    tipo_constantes: {name};
    estado_0: { number: number, name: string, detail?: string };
    estado_1: { number: number, name: string, detail?: string };
    estado_2: { number: number, name: string, detail?: string };
    estado_3: { number: number, name: string, detail?: string };
    estado_4: { number: number, name: string, detail?: string };
    estado_5: { number: number, name: string, detail?: string };
    
    selectEstados: { number: number, name: string, detail?: string }[];
    documentacion: { pendiente: string, completo: string };
    nombrePendienteCarga: { detalle: string };   

}

export const AppOfertas: IAppOfertas = {

    tipo_constantes: {name: 'OFERTA-LABORAL'},
    estado_0: {number: 0, name: '1/5-PRESENTADO', detail: 'progreso de su solicitud...'},
    estado_1: {number: 1, name: '2/5-DOCUMENTACIÓN PRESENTADA', detail: 'progreso de su solicitud...'},
    estado_2: {number: 2, name: '3/5-EN REVISION', detail: 'progreso de su solicitud...'},
    estado_3: {number: 3, name: '4/5-EN AUDITORÍA MÉDICA', detail: 'progreso de su solicitud...'},   
    estado_4: {number: 4, name: '5/5-APROBADO', detail: 'progreso de su solicitud...'},
    estado_5: {number: 5, name: 'RECHAZADO', detail: 'progreso de su solicitud...'},

    selectEstados: [
        {number: 0, name: '1/5-PRESENTADO', detail: 'progreso de su solicitud...'},
        {number: 1, name: '2/5-DOCUMENTACIÓN PRESENTADA', detail: 'progreso de su solicitud...'},
        {number: 2, name: '3/5-EN REVISION', detail: 'progreso de su solicitud...'},
        {number: 3, name: '4/5-EN AUDITORÍA MÉDICA', detail: 'progreso de su solicitud...'},   
        {number: 4, name: '5/5-APROBADO', detail: 'progreso de su solicitud...'},
        {number: 5, name: 'RECHAZADO', detail: 'progreso de su solicitud...'},
    
    ],
    documentacion: { pendiente: 'Pendiente', completo: 'Completo' },  
    nombrePendienteCarga: { detalle: 'carga pendiente' },
    
};

/* CONSTANTES SIGNO MÉDICO */
