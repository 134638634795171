import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Response } from 'src/app/interfaces/interface-bakend/response';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';

const URL_BACKEND = environment.urlBackend;

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {

  constructor(private httpClient: HttpClient,
              private authService: AuthService) { }
      async getServerTime(): Promise<string> {
        try {
          const headers = new HttpHeaders({
            'x-token': await this.authService.loadJwToken(),
          });
          const response: any = await this.httpClient.get(`${URL_BACKEND}/app-config/getServerTime`, { headers }).toPromise();
          return response.response as string; // Suponiendo que `response.response` contiene el valor de fecha como string
        } catch (error) {
          console.error('Error al obtener la fecha del servidor:', error);
          throw new Error('Error al obtener la fecha del servidor'); // Manejar el error o lanzarlo nuevamente según tus necesidades
        }
      }
}
