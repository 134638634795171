import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { GuiMsjService } from 'src/app/services/shared/gui-msj.service';
import { SignoEmpresa, SignoEmpresaEstado } from 'src/app/interfaces/interface-bakend/entidades/signo-empresa.interface';
import { SignoEmpresaService } from 'src/app/services/entidades/signo-empresas.service';
import { PreviousRouteService } from '@services/shared/previous-route.service';
import Swal from 'sweetalert2';
@Component({
    selector: 'app-empresa-modificacion',
    templateUrl: './empresa-modificacion.component.html',
    styleUrls: ['./empresa-modificacion.component.scss'],
})
export class EmpresaModificacionComponent implements OnInit {
    form: FormGroup;
    signoEmpresa: SignoEmpresa;
    flagLoaded: boolean = false;
    estados: string[] = ['ACTIVA', 'SUSPENDIDA', 'BAJA'];
    routes: { url: string, label: string }[] = [];

    constructor(
        private router: Router,
        private previousRouteService: PreviousRouteService,
        private signoEmpresaService: SignoEmpresaService,
        private guiMsjService: GuiMsjService
    ) { }

    ngOnInit(): void {
        this.previousRouteService.getRoutes().subscribe(routes => {
            this.routes = routes;
        }).unsubscribe();
        this.getEstadoEmpresa();
        this.createForm();
    }

    get nombreNoValido() {
        return this.form.get('nombre').invalid;
    }

    get fechaNoValida() {
        return this.form.get('fecha').invalid;
    }

    async getEmpresa() {
        return (this.signoEmpresa =
            this.signoEmpresaService.getEmpresaSeleccionada());
    }

    async getEstadoEmpresa() {
        if (this.getEmpresa() === undefined) {
            await this.router.navigateByUrl('/empresas-listado');
        } else {
            await this.getEmpresa();
            this.flagLoaded = true;
        }
    }

    createForm() {
        this.form = new FormGroup({
            nombre: new FormControl(this.signoEmpresa?.nombre, [
                Validators.required,
            ]),
            estado: new FormControl(this.signoEmpresa?.estado, [
                Validators.required,
            ]),
            fecha: new FormControl(this.signoEmpresa?.fecha, [
                Validators.required,
            ]),
        });
    }

    async setDataForma(): Promise<{ signoEmpresa: SignoEmpresa, signoEmpresaEstado: SignoEmpresaEstado }> {
        const signoEmpresa: SignoEmpresa = {
            _id: this.signoEmpresa._id,
            nombre: this.form.value.nombre || '',
            estado: this.form.value.estado || '',
            fecha: this.form.value.fecha || 'dd/mm/yyyy',
        };

        const signoEmpresaEstado: SignoEmpresaEstado = {
            empresaId: this.signoEmpresa._id,
            estado: this.form.value.estado || '',
            fechaEstado: this.form.value.fecha || 'dd/mm/yyyy',
        }

        return { signoEmpresa, signoEmpresaEstado };
    }

    async updateEmpresa() {
        await this.guiMsjService.msjFormSubmit('Guardando');
        const { signoEmpresa, signoEmpresaEstado } = await this.setDataForma();
        await this.signoEmpresaService.updateEmpresa(this.signoEmpresa._id, signoEmpresa);
        await this.signoEmpresaService.cambioEstado(signoEmpresaEstado);
        await this.guiMsjService.msjFormSubmit('DatosGuardados');
        await this.router.navigateByUrl('/empresas-listado');
    }

    async verAfiliadosAsociados() {
        await this.guiMsjService.msjFormSubmit('Espere');
        Swal.showLoading();
        this.router.navigateByUrl('/afiliados-asociados-listado');
        Swal.close();
    }

}
