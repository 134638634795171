import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CatalogoService } from 'src/app/services/shared/catalogo.service';
import { GuiMsjService } from 'src/app/services/shared/gui-msj.service';
import { EntidadesKeys } from 'src/app/enum/entidades-keys.enum';
import { CatalogoCentrosPropios } from 'src/app/interfaces/interface-bakend/shared/catalogo/catalogo.centros-propios.interface';
import { MapComponent } from '@components/entidades/common/map/map.component';

@Component({
    selector: 'app-centro-propio-alta',
    templateUrl: './centro-propio-alta.component.html',
    styleUrls: ['./centro-propio-alta.component.scss']
})
export class CentroPropioAltaComponent implements OnInit, AfterViewInit {
    form: FormGroup;
    catalogo: CatalogoCentrosPropios;
    @ViewChild(MapComponent) mapComponent: MapComponent;

    constructor(
        private router: Router,
        private catalogoService: CatalogoService,
        public guiMsjService: GuiMsjService
    ) {
    }

    ngOnInit(): void {
        this.getCatalogo();
        this.createForm();
    }

    ngAfterViewInit(): void {
        this.initMap()
        this.updateMap();
    }

    get centroNoValido() {
        return (
            this.form.get('centro').invalid &&
            this.form.get('centro').touched
        );
    }

    async getCatalogo() {
        this.catalogo = await this.catalogoService.getFindByKey(EntidadesKeys.CENTROS_PROPIOS);
    }

    initMap() {
        if (!isNaN(this.form.value.latitud) && !isNaN(this.form.value.longitud)) {
            this.mapComponent.getLocation(this.form.value.latitud, this.form.value.longitud);
        }
    }

    updateMap() {
        this.updatesCoordinates();
        if (!isNaN(this.form.value.latitud) && !isNaN(this.form.value.longitud)) {
            this.mapComponent.getLocation(this.form.value.latitud, this.form.value.longitud);
        }
    }

    updatesCoordinates() {
        this.mapComponent.coordinatesChanged.subscribe((coordinates) => {
            this.form.patchValue({
                latitud: coordinates.latitud,
                longitud: coordinates.longitud
            });
        });
    }

    updateMarkerLocation() {
        const latitud = this.form.get('latitud').value;
        const longitud = this.form.get('longitud').value;
        if (this.mapComponent && !isNaN(latitud) !== null && !isNaN(longitud) !== null) {
            this.mapComponent.getLocation(latitud, longitud);
        }
    }

    createForm() {
        this.form = new FormGroup({
            centro: new FormControl('', [
                Validators.required,
                Validators.min(3),
            ]),
            activa: new FormControl(true),
            direccion: new FormControl(''),
            telefono: new FormControl(''),
            latitud: new FormControl(-34.63104776379445, [
                Validators.pattern(/^-?\d*(\.\d+)?$/),
            ]),
            longitud: new FormControl(-58.46651124133983, [
                Validators.pattern(/^-?\d*(\.\d+)?$/),
            ])
        });
    }

    async setDataForma(): Promise<CatalogoCentrosPropios> {
        const data: CatalogoCentrosPropios = {
            key: null,
            concepto: this.form.value.centro,
            parent: this.catalogo._id,
            logico_1: this.form.value.activa,
            properties: {
                direccion: this.form.value.direccion,
                telefono: this.form.value.telefono,
                latitud: this.form.value.latitud,
                longitud: this.form.value.longitud
            }
        };

        return data;
    }

    async centroPropioCreate() {
        await this.guiMsjService.msjFormSubmit('Guardando');
        await this.catalogoService.post(await this.setDataForma()).then(async () => {
            await this.guiMsjService.msjFormSubmit('DatosGuardados');
            this.router.navigateByUrl('/centros-propios-listado');
        }).catch((error) => {
            console.error('Error al guardar localidad', error);
        });

    }
}
