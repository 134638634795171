import { AuthService } from './../../../../../services/auth/auth.service';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Translators } from './../../../../../services/shared/translators.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-modal-modificar-clave',
  templateUrl: './modal-modificar-clave.component.html',
  styleUrls: ['./modal-modificar-clave.component.scss']
})
export class ModalModificarClaveComponent implements OnInit {

    form: FormGroup;
    idioma: Subscription;
    showPassword: boolean;
    showPasswordConfirm: boolean;

    constructor(
        private authService: AuthService,
        private translatorService: Translators,
        /* Modal Ref. */
        public dialogRef: MatDialogRef<ModalModificarClaveComponent>,
        @Inject(MAT_DIALOG_DATA) public data: string // los datos los recibo - en este caso no recibo data
        /* Fin Modal Ref. */
    ) {
        this.idioma = this.translatorService.getIdiomaSeleccionado().subscribe(resp => {
            this.idioma = resp;
          });
          
        this.createForm();
     }

    ngOnInit(): void {
    }

    /* Creacion de formulario */
    createForm() {
        this.form = new FormGroup({
        password: new FormControl('',  [Validators.required, Validators.pattern(/^.{8}$/)]), // Solo dígitos, al menos 6
        confirmPassword: new FormControl('', [ Validators.required, Validators.maxLength(8) ])
    });
    }

    /* Cierro modal */
    closeModal(): void {
        this.dialogRef.close(this.data);
    }

    /* Modifico password*/
    async put(){

    if (this.form.invalid){

        return Object.values( this.form.controls ).forEach( control => {
            control.markAsTouched();
        });
    }

    await this.authService.newPassword(
        this.form.value.password,
        this.form.value.confirmPassword
    ).then( () => {
        this.closeModal();
    });

    }

    /* Validaciones */
    get passwordNoValido(){
        return this.form.get('password').invalid && this.form.get('password').touched;
    }

    get confirmPasswordNoValido(){
        const pass1 = this.form.get('password').value;
        const pass2 = this.form.get('confirmPassword').value;
        return ( pass1 === pass2 ) ? false : true;
    }

}
