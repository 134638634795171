import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { GuiMsjService } from 'src/app/services/shared/gui-msj.service';
import { Catalogo } from '../../../../../interfaces/interface-bakend/shared/catalogo/catalogo.interface';
import { CatalogoService } from '../../../../../services/shared/catalogo.service';
import { CatalogoLocalidades } from 'src/app/interfaces/interface-bakend/shared/catalogo/catalogo.localidades.interface';
import { EntidadesKeys } from 'src/app/enum/entidades-keys.enum';
import { SignoLocalidad } from 'src/app/interfaces/interface-bakend/entidades/signo-localidad.interface';
import { SignoLocalidadService } from 'src/app/services/entidades/signo-localidades.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-localidad-modificacion',
  templateUrl: './localidad-modificacion.component.html',
  styleUrls: ['./localidad-modificacion.component.scss']
})
export class LocalidadModificacionComponent implements OnInit {
  form: FormGroup;
  flagLoaded = false;
  zonaSeleccionada: any = undefined;
  zonas: Catalogo[];
  localidad: SignoLocalidad;

  constructor(
    private router: Router,
    private catalogoService: CatalogoService,
    public guiMsjService: GuiMsjService,
    private signoLocalidadesService: SignoLocalidadService
  ) {
    this.getZonas();
  }

  ngOnInit(): void {
    this.getEstadoVariables();
    this.createForm();
  }

  get localidadNoValida() {
    return this.form.get('localidad').invalid && this.form.get('localidad').touched;
  }

  get zonaNoseleccionada() {
    return (
      this.form.get('zona').invalid &&
      this.form.get('zona').touched
    );
  }

  getLocalidad() {
    return (this.localidad = this.signoLocalidadesService.getLocalidadSeleccionada());
  }

  async getZonas() {
    this.zonas = await this.catalogoService.findDescendantsByKey(EntidadesKeys.ZONAS);
  }

  async getEstadoVariables() {
    if (this.getLocalidad() === undefined) {
      await this.router.navigateByUrl('/localidades-listado');
    } else {
      await this.getLocalidad();
      this.zonaSeleccionada = this.localidad?.zona;
      this.flagLoaded = true;
    }
  }

  createForm() {
    this.form = new FormGroup({
      localidad: new FormControl(this.localidad?.nombre, [
        Validators.required,
        Validators.min(3),
      ]),
      zona: new FormControl(this.localidad?.zona, [Validators.required]),
      activa: new FormControl(this.localidad?.activo, [
        Validators.required,
      ]),
    });
  }

  async setDataForma(): Promise<SignoLocalidad> {
    const data: SignoLocalidad = {
      nombre: this.form.value.localidad.trim(),
      activo: this.form.value.activa,
      zona: {
        _id: this.form.value.zona
      }
    };
    return data;
  }

  async updateLocalidad() {
    await this.guiMsjService.msjFormSubmit('Guardando');
    await this.signoLocalidadesService.updateLocalidad(this.localidad._id, await this.setDataForma())
      .then(async () => {
        Swal.fire({
          icon: 'success',
          title: 'Localidad modificada satisfactoriamente',
          showConfirmButton: false,
          timer: 1100,
      });
        this.router.navigateByUrl('/localidades-listado');
      }).catch((error) => {
        console.error('Error al actualizar localidad', error);
      });
  }

}
