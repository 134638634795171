import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, ElementRef, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core'
import { environment } from '@env/environment';
import * as Mapboxgl from 'mapbox-gl';
@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit {

  @ViewChild('coordinates', { static: true }) coordinatesElement: ElementRef;
  @ViewChild('map', { static: true }) mapElement: ElementRef;
  private MapBox = (Mapboxgl as typeof Mapboxgl);
  private Map: Mapboxgl.Map;
  private styleMapBox = `mapbox://styles/mapbox/streets-v12`;
  private location?: [number, number] | undefined;
  private zoom = 15;

  @Input() latitud: number;
  @Input() longitud: number;
  @Output() coordinatesChanged = new EventEmitter<{ latitud: number, longitud: number }>();

  constructor(
  ) { this.MapBox.accessToken = environment.mapBoxToken; }

  ngOnInit(): void {
    this.createMapBox();
  }

  createMapBox() {
    this.updateMarker();
  }

  getLocation(latitud: number, longitud: number) {
    this.latitud = latitud;
    this.longitud = longitud;
    this.updateMarker();
  }

  updateMarker() {
    if (this.Map) {
      this.Map.remove();
      this.Map = null; 
    }

    if (!isNaN(this.latitud) && !isNaN(this.longitud)) {
      this.location = [this.longitud, this.latitud];
      this.Map = new Mapboxgl.Map({
        container: this.mapElement.nativeElement,
        style: this.styleMapBox,
        zoom: this.zoom,
        center: this.location
      })

      this.createMarker(this.location);

    }
  }

  createMarker(location: [number, number]) {
    const marker = new Mapboxgl.Marker({
      draggable: true
    }).setLngLat(location).addTo(this.Map);

    const lngLat = marker.getLngLat();
    this.coordinatesElement.nativeElement.innerHTML = `Latitud: ${lngLat.lat}<br />Longitud: ${lngLat.lng}`;
    marker.on('dragend', () => this.onDragEnd(marker));
  }

  onDragEnd(marker: Mapboxgl.Marker) {
    const lngLat = marker.getLngLat();
    this.coordinatesElement.nativeElement.style.display = 'block';
    this.coordinatesElement.nativeElement.innerHTML = `Latitud: ${lngLat.lat}<br />Longitud: ${lngLat.lng}`;
    this.latitud = lngLat.lat;
    this.longitud = lngLat.lng;
    this.coordinatesChanged.emit({ latitud: this.latitud, longitud: this.longitud })
  };

}

