import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { GuiMsjService } from 'src/app/services/shared/gui-msj.service';
import { Catalogo } from '../../../../../interfaces/interface-bakend/shared/catalogo/catalogo.interface';
import { CatalogoService } from '../../../../../services/shared/catalogo.service';
import { EntidadesKeys } from 'src/app/enum/entidades-keys.enum';
import { PerfilesService } from 'src/app/services/perfiles/perfiles.service';

@Component({
  selector: 'app-rubros-alta',
  templateUrl: './rubro-alta.component.html',
  styleUrls: ['./rubro-alta.component.scss']
})
export class RubroAltaComponent implements OnInit {

  form: FormGroup;
  catalogo: Catalogo;

  constructor(
    private router: Router,
    private catalogoService: CatalogoService,
    public guiMsjService: GuiMsjService,
  ) {
      this.createForm();
      this.getCatalogo();
   }

  ngOnInit(): void {
  }

  get rubroNoValido(){
    return this.form.get('rubro').invalid && this.form.get('rubro').touched;
  }

  async getCatalogo() {
    this.catalogo = await this.catalogoService.getFindByKey(EntidadesKeys.RUBROS);
  }

  createForm() {
    this.form = new FormGroup({
        rubro: new FormControl('', [ Validators.required, Validators.min(3)]),
        activo: new FormControl(false, [ Validators.required]),
    });
  }

  async setDataForma(): Promise<Catalogo>{

    const data: Catalogo = {
        key: null,
        concepto: this.form.value.rubro,
        logico_1: this.form.value.activo,
        parent: this.catalogo._id,
        
        properties: {},
    };

    return data;

  }

  async post(){
    await this.guiMsjService.msjFormSubmit('Guardando');
    this.catalogoService.post(await this.setDataForma());
    await this.guiMsjService.msjFormSubmit('DatosGuardados');
    this.router.navigateByUrl('/rubros-listado');
  }

}
