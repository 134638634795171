import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from '../components/pages/home/home.component';
import { NgModule } from '@angular/core';

/* COMPONENTES DE SHARED */
import { OfertasEstadisticasComponent } from '../components/ofertas-laborales/pages/ofertas-administracion/ofertas-estadisticas/ofertas-estadisticas.component';

/* COMPONENTES DE OFERTAS LABORALES  */
import { OfertasConfiguracionComponent } from '../components/ofertas-laborales/pages/ofertas-configuracion/ofertas-configuracion.component';
import { OfertasAltaComponent } from '../components/ofertas-laborales/pages/ofertas-alta/ofertas-alta.component';
import { OfertaModificacionComponent } from '../components/ofertas-laborales/pages/oferta-modificacion/oferta-modificacion.component';
import { OfertasLaboralesListadoComponent } from '../components/ofertas-laborales/pages/ofertas-laborales-listado/ofertas-laborales-listado.component';
import { AdjuntosConfiguracionComponent } from '../components/ofertas-laborales/pages/adjuntos-configuracion/adjuntos-configuracion.component';
import { ActividadDetalleComponent } from '../components/ofertas-laborales/pages/actividad-detalle/actividad-detalle.component';

// Personas/Afiliados
import { PersonasListadoComponent } from '../components/usuarios/pages/personas-listado/personas-listado.component';
import { PersonaModificacionComponent } from '../components/usuarios/pages/persona-modificacion/persona-modificacion.component';
import { GrupoFamiliarAltaComponent } from '@components/usuarios/pages/persona-modificacion/grupofamiliar-alta/grupofamiliar-alta.component';
import { PersonaAltaComponent } from '@components/usuarios/pages/persona-alta/persona-alta.component';

// Mi Cuenta
import { CuentaComponent } from '../components/usuarios/pages/cuenta/cuenta.component';

// Actividades Disponibles
import { UsuariosSolicitudesComponent } from '../components/ofertas-laborales/pages/usuario-solicitudes/usuario-solicitudes.component';
import { OfertasListarComponent } from '../components/ofertas-laborales/pages/ofertas-listar/ofertas-listar.component';
import { OfertasDisponiblesComponent } from '../components/ofertas-laborales/pages/ofertas-disponibles/ofertas-disponibles.component';
import { SolicitudComponent } from '../components/ofertas-laborales/pages/solicitud/solicitud.component';
import { OfertasAdministracionComponent } from '../components/ofertas-laborales/pages/ofertas-administracion/ofertas-administracion.component';
import { OfertasListadoComponent } from '../components/ofertas-laborales/pages/ofertas-administracion/ofertas-listado/ofertas-listado.component';
import { OfertasSolicitudComponent } from '../components/ofertas-laborales/pages/ofertas-administracion/ofertas-solicitud/ofertas-solicitud.component';
import { AdjuntosListadoComponent } from '../components/ofertas-laborales/pages/adjuntos-configuracion/adjuntos-listado/adjuntos-listado.component';
import { AdjuntoAltaComponent } from '../components/ofertas-laborales/pages/adjuntos-configuracion/adjunto-alta/adjunto-alta.component';
import { AdjuntoModificacionComponent } from '../components/ofertas-laborales/pages/adjuntos-configuracion/adjunto-modificacion/adjunto-modificacion.component';
import { QuienesSomos } from '../components/pages/quienes-somos/quienes-somos.component';
import { PlataformaGuia } from '../components/pages/plataforma-guia/plataforma-guia.component';
import { TermsOfServiceComponent } from '../components/pages/terms-of-service/terms-of-service.component';
import { FormContactoComponent } from '../components/pages/form-contacto/form-contacto.component';
import { TableFilterPreInscripcionesComponent } from '../components/cursos/pages/table-filter-pre-inscripciones/table-filter-pre-inscripciones.component';

// ABM Usuarios
import { UsuariosAdministracionComponent } from '../components/usuarios/pages/usuarios-administracion/usuarios-administracion.component';
import { UsuariosListadoComponent } from '../components/usuarios/pages/usuarios-listado/usuarios-listado.component';
import { UsuarioModificacionComponent } from '../components/usuarios/pages/usuario-modificacion/usuario-modificacion.component';
import { UsuariosAltaComponent } from '../components/usuarios/pages/usuarios-alta/usuarios-alta.component';

/* COMPONENTES ENTIDADES */

// Entidades Administracion
import { EntidadesAdministracionComponent } from '../components/entidades/entidades-administracion/entidades-administracion.component';

// ABM Sedes
import { SedesListadoComponent } from '../components/entidades/sedes/pages/sedes-listado/sedes-listado.component';
import { SedeModificacionComponent } from '../components/entidades/sedes/pages/sede-modificacion/sede-modificacion.component';
import { SedeAltaComponent } from '../components/entidades/sedes/pages/sede-alta/sede-alta.component';

// ABM Empresas
import { EmpresasListadoComponent } from '../components/entidades/empresas/pages/empresas-listado/empresas-listado.component';
import { EmpresaModificacionComponent } from '../components/entidades/empresas/pages/empresa-modificacion/empresa-modificacion.component';
import { EmpresaAltaComponent } from '../components/entidades/empresas/pages/empresa-alta/empresa-alta.component';
import { AfiliadosAsociadosListadoComponent } from '@components/entidades/empresas/pages/empresa-modificacion/afiliados-asociados-listado/afiliados-asociados-listado.component';

// ABM Rubros
import { RubroAltaComponent } from '../components/entidades/rubros/pages/rubro-alta/rubro-alta.component';
import { RubroModificacionComponent } from '../components/entidades/rubros/pages/rubro-modificacion/rubro-modificacion.component';
import { RubrosListadoComponent } from '../components/entidades/rubros/pages/rubros-listado/rubros-listado.component';

//ABM Especialidades
import { EspecialidadAltaComponent } from '../components/entidades/especialidades/pages/especialidad-alta/especialidad-alta.component';
import { EspecialidadModificacionComponent } from '../components/entidades/especialidades/pages/especialidad-modificacion/especialidad-modificacion.component';
import { EspecialidadesListadoComponent } from '../components/entidades/especialidades/pages/especialidades-listado/especialidades-listado.component';

// ABM Zonas
import { ZonaAltaComponent } from '../components/entidades/zonas/pages/zona-alta/zona-alta.component';
import { ZonaModificacionComponent } from '../components/entidades/zonas/pages/zona-modificacion/zona-modificacion.component';
import { ZonasListadoComponent } from '../components/entidades/zonas/pages/zonas-listado/zonas-listado.component';

// ABM Centros Propios Especialidades
import { CentroPropioEspecialidadAltaComponent } from '../components/entidades/centros-propios-especialidades/pages/centro-propio-especialidad-alta/centro-propio-especialidad-alta.component';
import { CentrosPropiosEspecialidadesListadoComponent } from '../components/entidades/centros-propios-especialidades/pages/centros-propios-especialidades-listado/centros-propios-especialidades-listado.component';
import { CentroPropioEspecialidadModificacionComponent } from '../components/entidades/centros-propios-especialidades/pages/centro-propio-especialidad-modificacion/centro-propio-especialidad-modificacion.component';

//ABM Centros Médicos
import { CentroMedicoAltaComponent } from '../components/entidades/centros-medicos/pages/centro-medico-alta/centro-medico-alta.component';
import { CentroMedicoModificacionComponent } from '../components/entidades/centros-medicos/pages/centro-medico-modificacion/centro-medico-modificacion.component';
import { CentrosMedicosListadoComponent } from '../components/entidades/centros-medicos/pages/centros-medicos-listado/centros-medicos-listado.component';
import { CentrosMedicosAsociadosListadoComponent } from '@components/entidades/especialidades/pages/especialidad-modificacion/centros-medicos-asociados-listado/centros-medicos-asociados-listado.component';

//ABM Doctores
import { DoctorAltaComponent } from '../components/entidades/doctores/pages/doctor-alta/doctor-alta.component';
import { DoctorModificacionComponent } from '../components/entidades/doctores/pages/doctor-modificacion/doctor-modificacion.component';
import { DoctoresListadoComponent } from '../components/entidades/doctores/pages/doctores-listado/doctores-listado.component';

//ABM Localidades
import { LocalidadAltaComponent } from '../components/entidades/localidades/pages/localidad-alta/localidad-alta.component';
import { LocalidadModificacionComponent } from '../components/entidades/localidades/pages/localidad-modificacion/localidad-modificacion.component';
import { LocalidadesListadoComponent } from '../components/entidades/localidades/pages/localidades-listado/localidades-listado.component';

//ABM Centros Propios
import { CentrosPropiosListadoComponent } from '../components/entidades/centros-propios/centros-propios-listado/centros-propios-listado.component';
import { CentroPropioAltaComponent } from '../components/entidades/centros-propios/centro-propio-alta/centro-propio-alta.component';
import { CentroPropioModificacionComponent } from '../components/entidades/centros-propios/centro-propio-modificacion/centro-propio-modificacion.component';

//ABM Planes
import { PlanesListadoComponent } from '@components/entidades/planes/pages/planes-listado/planes-listado.component';
import { PlanAltaComponent } from '@components/entidades/planes/pages/plan-alta/plan-alta.component';
import { PlanModificacionComponent } from '@components/entidades/planes/pages/plan-modificacion/plan-modificacion.component';

import { CredencialComponent } from '@components/entidades/credencial/credencial.component';
import { ErrorComponent } from '@components/entidades/common/error/error.component';
import { FamiliaresComponent } from '@components/entidades/credencial/familiares/familiares.component';
import { CartillaBuscadorComponent } from '@components/ofertas-laborales/pages/cartilla-buscador/cartilla-buscador.component';


/* ADMINISTRO RUTAS HIJAS DASHBOARD */
export const dashBoardRoutes: Routes = [
    { path: 'cuenta', component: CuentaComponent },
    { path: '', component: HomeComponent },
    { path: 'quienes-somos', component: QuienesSomos },
    { path: 'plataforma-guia', component: PlataformaGuia },
    { path: 'terminos-condiciones-registro', component: TermsOfServiceComponent },
    { path: 'formulario-contacto', component: FormContactoComponent },

    // { path: 'catalogo-configuracion', component: CatalogoConfiguracionComponent },

    // Rutas Trámites
    { path: 'tramites-listado', component: OfertasLaboralesListadoComponent },
    { path: 'tramites-configuracion', component: OfertasConfiguracionComponent },
    { path: 'tramite-alta', component: OfertasAltaComponent },
    { path: 'ofertas-administracion', component: OfertasAdministracionComponent },
    { path: 'oferta-modificacion', component: OfertaModificacionComponent },
    { path: 'ofertas-listar', component: OfertasListarComponent },
    { path: 'tramites-disponibles', component: OfertasDisponiblesComponent },
    { path: 'ofertas-solicitud', component: OfertasSolicitudComponent },
    { path: 'solicitud-tramites', component: ActividadDetalleComponent },
    { path: 'signo-medico-estadisticas', component: OfertasEstadisticasComponent, },

    // Rutas Adjuntos
    { path: 'adjuntos-configuracion', component: AdjuntosConfiguracionComponent },
    { path: 'adjuntos-listado', component: AdjuntosListadoComponent },
    { path: 'adjunto-alta', component: AdjuntoAltaComponent },
    { path: 'adjunto-modificacion', component: AdjuntoModificacionComponent },

    { path: 'usuario-solicitudes', component: UsuariosSolicitudesComponent },
    { path: 'solicitud', component: SolicitudComponent },

    { path: 'autorizaciones-listado', component: OfertasListadoComponent },
    { path: 'autorizaciones-filtros', component: TableFilterPreInscripcionesComponent },

    { path: 'afiliados-listado', component: PersonasListadoComponent },
    { path: 'afiliado-alta', component: PersonaAltaComponent },
    { path: 'afiliado-modificacion', component: PersonaModificacionComponent },
    { path: 'grupofamiliar-alta', component: GrupoFamiliarAltaComponent },

    { path: 'usuarios-administracion', component: UsuariosAdministracionComponent, },
    { path: 'usuarios-listado', component: UsuariosListadoComponent },
    { path: 'usuario-modificacion', component: UsuarioModificacionComponent },
    { path: 'usuario-alta', component: UsuariosAltaComponent },

    //Rutas Entidades Signo Médico
    { path: 'configurar-administracion', component: EntidadesAdministracionComponent },

    //Ruta ABM Sedes
    { path: 'sedes-listado', component: SedesListadoComponent },
    { path: 'sede-modificacion', component: SedeModificacionComponent },
    { path: 'sede-alta', component: SedeAltaComponent },

    //Ruta ABM Empresas
    { path: 'empresas-listado', component: EmpresasListadoComponent },
    { path: 'empresa-modificacion', component: EmpresaModificacionComponent },
    { path: 'empresa-alta', component: EmpresaAltaComponent },
    { path: 'afiliados-asociados-listado', component: AfiliadosAsociadosListadoComponent },

    //Ruta ABM Rubros
    { path: 'rubros-listado', component: RubrosListadoComponent },
    { path: 'rubro-modificacion', component: RubroModificacionComponent },
    { path: 'rubro-alta', component: RubroAltaComponent },

    //Ruta ABM Especialidades
    { path: 'especialidades-listado', component: EspecialidadesListadoComponent },
    { path: 'especialidad-modificacion', component: EspecialidadModificacionComponent },
    { path: 'especialidad-alta', component: EspecialidadAltaComponent },

    //Ruta ABM Zonas
    { path: 'zonas-listado', component: ZonasListadoComponent },
    { path: 'zona-modificacion', component: ZonaModificacionComponent },
    { path: 'zona-alta', component: ZonaAltaComponent },

    //Ruta ABM Centros Propios
    { path: 'centros-propios-listado', component: CentrosPropiosListadoComponent },
    { path: 'centro-propio-alta', component: CentroPropioAltaComponent },
    { path: 'centro-propio-modificacion', component: CentroPropioModificacionComponent },

    //Ruta ABM Doctores
    { path: 'doctores-listado', component: DoctoresListadoComponent },
    { path: 'doctor-modificacion', component: DoctorModificacionComponent },
    { path: 'doctor-alta', component: DoctorAltaComponent },

    //Ruta ABM Localidades
    { path: 'localidades-listado', component: LocalidadesListadoComponent },
    { path: 'localidad-modificacion', component: LocalidadModificacionComponent },
    { path: 'localidad-alta', component: LocalidadAltaComponent },

    //Ruta ABM Centros Médicos
    { path: 'centros-medicos-listado', component: CentrosMedicosListadoComponent },
    { path: 'centro-medico-alta', component: CentroMedicoAltaComponent },
    { path: 'centro-medico-modificacion', component: CentroMedicoModificacionComponent },
    { path: 'centros-medicos-asociados-listado', component: CentrosMedicosAsociadosListadoComponent },

     //Buscador cartulla
     { path: 'cartilla-buscador', component: CartillaBuscadorComponent },

    
    //Ruta ABM Centros Propios Especialidades
    {
        path: 'centros-propios-especialidades-listado', component: CentrosPropiosEspecialidadesListadoComponent,
    },
    {
        path: 'centro-propio-especialidad-modificacion',
        component: CentroPropioEspecialidadModificacionComponent,
    },
    {
        path: 'centro-propio-especialidad-alta',
        component: CentroPropioEspecialidadAltaComponent,
    },

    //Ruta ABM Planes
    { path: 'planes-listado', component: PlanesListadoComponent },
    { path: 'plan-modificacion', component: PlanModificacionComponent },
    { path: 'plan-alta', component: PlanAltaComponent },

    //Credenciales / Familiares
    { path: 'mi-credencial', component: CredencialComponent},
    { path: 'familiares', component: FamiliaresComponent},

    //Error
    { path: 'error', component: ErrorComponent },

    // **
    { path: '**', redirectTo: 'error' },
    
];

@NgModule({
    imports: [RouterModule.forRoot(dashBoardRoutes, { useHash: true })],
    exports: [RouterModule],
})
export class AppRoutingModule { }
