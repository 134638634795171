import { MediaService } from './../../../../services/shared/media.service';
import { Component, Inject, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Solicitud } from '../../../../interfaces/interface-bakend/ofertas-laborales/solicitudes.interface';
import { GuiMsjService } from '../../../../services/shared/gui-msj.service';
import { SolicitudesService } from '../../../../services/ofertas-laborales/solicitudes.service';
import { Translators } from '../../../../services/shared/translators.service';
import { StepperActualizacionService } from '@services/shared/stepper-actualizacion.service';

export interface DialogData {
    solicitud: Solicitud;
    adjunto: string;
    label: string;
    numberState: number;
    labelButton: string;
    accept: string; // image/jpeg, image/jpg, image/png, application/pdf
}


@Component({
  selector: 'app-upload-file-solicitud',
  templateUrl: './upload-file-solicitud.component.html',
  styleUrls: ['./upload-file-solicitud.component.scss']
})
export class UploadFileSolicitudComponent implements OnInit {

    activarCargaDocumento = false;
    value = 0;
    subscriptionAvance: Subscription;
    flagLoading = false;
    selectedFile: any;
    solicitud: Solicitud;
    flagDisableButton = false;

    idioma: Subscription;

    constructor(
        private mediaService: MediaService,
        private guiMsjService: GuiMsjService,
        private solicitudesService: SolicitudesService,
        private translatorService: Translators,
        private stepperActualizacionService: StepperActualizacionService,
        /* Modal Ref. */
        public dialogRef: MatDialogRef<UploadFileSolicitudComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData)
        /* Fin Modal Ref. */


        {
            this.idioma = this.translatorService.getIdiomaSeleccionado().subscribe(resp => {
                this.idioma = resp;
              });

            this.subscribeAvanceUpload();
        }

    ngOnInit(): void {
    }

    onFileSelected(event) {
        if (this.mediaService.controlSize(event)) {
            this.flagLoading = true;
            this.flagDisableButton = true;
            this.selectedFile = <File> event.target.files[0];
            this.uploadDocumentoAdjunto(this.selectedFile);
        } else {
            this.flagDisableButton = true;
            alert('File too Big, please select a file less than 4mb');
            return;
        }
    }

    uploadDocumentoAdjunto(selectedFile) {
        this.mediaService.uploadOneAttachJobOfferFromWeb(
                selectedFile, this.data.solicitud.usuario._id,
                this.data.adjunto, this.data.solicitud._id, 
                false
            ).then( async resp => {
            if (resp) {
                if (this.value === 100) {
                    this.flagLoading = false;
                    this.flagDisableButton = false;
                }
            } else {
                this.guiMsjService.msjFormSubmit('uploadDocumentERROR');
            }
        });
    }

    subscribeAvanceUpload(){
        this.subscriptionAvance = this.mediaService.getAvance().subscribe(resp => {
            if (resp) {
                this.value = resp.value;
            } else {
                this.value = 0;
                }
        });
    }

    async aceptar(): Promise<void> {
        this.flagDisableButton = true;
        this.flagLoading = true;
        await this.actualizarEstadoSolicitud(this.data.solicitud._id, this.data.numberState);
    }

    async closeModal(): Promise<void> {
        this.dialogRef.close(false);
    }

    async actualizarEstadoSolicitud(_id: string, numberState: number) {
        return new Promise((resolve => {
            this.solicitudesService.updateSolicitudState(_id, numberState, false).then( async (solicitudUpdated: Solicitud) => {
                if (solicitudUpdated) {
                    this.solicitudesService.setSolicitudSeleccionada(solicitudUpdated);
                    this.dialogRef.close(true);
                    this.guiMsjService.msjFormSubmit('uploadDocumentOK');

                    //Actualizacion del stepper cuando actualizo el estado de la solicitud
                    this.stepperActualizacionService.actualizarEstado(numberState);
                } else {
                    this.guiMsjService.msjFormSubmit('uploadDocumentError');
                }
          });
        }));
    }

}
