<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li *ngFor="let route of routes">
                    <a routerLink="{{route.url}}">{{route.label}}</a>
                </li>
                <li>
                    <div class="sidebar">
                        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Menú">
                          <mat-icon>menu</mat-icon>
                        </button>
                        <mat-menu #menu="matMenu">
                          <button mat-menu-item routerLink="/centros-medicos-listado">
                            <mat-icon>local_hospital</mat-icon>
                            <span>Listado de Centros Médicos</span>
                          </button>
                          <button mat-menu-item routerLink="/rubros-listado">
                            <mat-icon>list_alt</mat-icon>
                            <span>Listado de Rubros</span>
                          </button>
                          <button mat-menu-item routerLink="/especialidades-listado">
                            <mat-icon>people</mat-icon>
                            <span>Listado de Especialidades</span>
                          </button>
                          <button mat-menu-item routerLink="/zonas-listado">
                            <mat-icon>map</mat-icon>
                            <span>Listado de Zonas</span>
                          </button>
                          <button mat-menu-item routerLink="/localidades-listado">
                            <mat-icon>location_on</mat-icon>
                            <span>Listado de Localidades</span>
                          </button>
                          <button mat-menu-item routerLink="/centros-propios-listado">
                            <mat-icon>storefront</mat-icon>
                            <span>Listado de Centros Propios</span>
                          </button>
                          <button mat-menu-item routerLink="/doctores-listado">
                            <mat-icon>person</mat-icon>
                            <span>Listado de Centros Propios Doctores</span>
                          </button>
                          <button mat-menu-item routerLink="/centros-propios-especialidades-listado">
                            <mat-icon>medical_services</mat-icon>
                            <span>Listado de Centros Propios Especialidades</span>
                          </button>
                          <button mat-menu-item routerLink="/afiliados-listado">
                            <mat-icon>people_alt</mat-icon>
                            <span>Listado de Afiliados</span>
                          </button>
                          <button mat-menu-item routerLink="/sedes-listado">
                            <mat-icon>business</mat-icon>
                            <span>Listado de Sedes</span>
                          </button>
                          <button mat-menu-item routerLink="/autorizaciones-listado">
                            <mat-icon>assignment</mat-icon>
                            <span>Listado de Autorizaciones</span>
                          </button>
                          <button mat-menu-item routerLink="/empresas-listado">
                            <mat-icon>business_center</mat-icon>
                            <span>Listado de Empresas</span>
                          </button>
                          <button mat-menu-item routerLink="/planes-listado">
                            <mat-icon>credit_card</mat-icon>
                            <span>Listado de Planes</span>
                          </button>
                        </mat-menu>
                    </div>  
                    
                </li>
            </ul>
            <h2>Listado Afiliados</h2>            
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape-signomedico.png"
             alt="image"
             width="10%"></div>
</div>
<app-loading *ngIf="!flagLoaded"
             class="m-5">
</app-loading><br>

<div class="profile-area pb-70">
    <div class="container">
        <div class="profile-courses-quizzes">
            <h3>Listado de Afiliados</h3>
            <a routerLink="/afiliado-alta"><i class="fas fa-plus"></i> Agregar Afiliado</a>
            <hr>
            <mat-form-field>
                <mat-label>Filtrar</mat-label>
                <input matInput
                       (keyup)="applyFilter($event)"
                       placeholder="Afiliado.."
                       autocomplete="off">
            </mat-form-field>

            <div class="mat-elevation-z8">

                <table mat-table
                       [dataSource]="dataSource"
                       matSort>

                    <ng-container matColumnDef="nombre">
                        <th mat-header-cell
                            *matHeaderCellDef
                            mat-sort-header> Nombre </th>
                        <td mat-cell
                            *matCellDef="let element"> {{element.nombrePersona}} </td>
                    </ng-container>

                    <ng-container matColumnDef="apellido">
                        <th mat-header-cell
                            *matHeaderCellDef
                            mat-sort-header> Apellido </th>
                        <td mat-cell
                            *matCellDef="let element"> {{element.apellidoPersona}} </td>
                    </ng-container>

                    <ng-container matColumnDef="nacionalidad">
                        <th mat-header-cell
                            *matHeaderCellDef
                            mat-sort-header> Nacionalidad </th>
                        <td mat-cell
                            *matCellDef="let element"> {{element.paisNacimientoNombreEs}} </td>
                    </ng-container>

                    <ng-container matColumnDef="identificacion">
                        <th mat-header-cell
                            *matHeaderCellDef
                            mat-sort-header> Identificación </th>
                        <td mat-cell
                            *matCellDef="let element"> {{element.identificacion}} </td>
                    </ng-container>

                    <ng-container matColumnDef="fechaNacimiento">
                        <th mat-header-cell
                            *matHeaderCellDef
                            mat-sort-header> Fecha nacimiento </th>
                        <td mat-cell
                            *matCellDef="let element"> {{element.birth | date: 'dd-MM-yyyy':'UTC'}} </td>
                    </ng-container>

                    <ng-container matColumnDef="email">
                        <th mat-header-cell
                            *matHeaderCellDef
                            mat-sort-header> Email </th>
                        <td mat-cell
                            *matCellDef="let element"> {{element.email}} </td>
                    </ng-container>

                    <ng-container matColumnDef="telefono">
                        <th mat-header-cell
                            *matHeaderCellDef
                            mat-sort-header> Teléfono </th>
                        <td mat-cell
                            *matCellDef="let element"> {{element.telefono}} </td>
                    </ng-container>

                    <ng-container matColumnDef="_id">
                        <th mat-header-cell
                            *matHeaderCellDef
                            mat-sort-header> Acciones </th>
                        <td mat-cell
                            *matCellDef="let element"
                            class="acciones-cell">
                            <button style="margin-right: 5px;"
                                    (click)="element.signoSedes && verDetalle(element.signoSedes[0], element._id)"
                                    [disabled]="!element.signoSedes"
                                    type="button"
                                    title="Ver sede asignada"
                                    class="btn btn-primary"><i class="fas fa-home"></i></button>
                         
                             <button style="margin-right: 5px;"
                                    (click)="verDetalleGecros(element)"
                                    type="button"
                                    title="Ver datos usuario"
                                    class="btn btn-success"><i class="fas fa-eye"></i></button>
                          
                              <button  style="margin-right: 5px;" (click)="verPersona(element._id)"
                                    type="button"
                                    class="btn btn-warning"><i class="fas fa-edit"></i></button>
                                    
                            <button style="margin-right: 5px;" (click)="verUsuario(element._id)" type="button" title="Modificar datos usuario"
                              class="btn btn-warning"><i class="fas fa-user"></i>
                            </button>

                            <button type="button" style="margin-right: 2%;" title="Modificar contraseña" class="btn btn-danger"
                              (click)="this.openDialogModificarClave(element._id)">
                              <i class="fa fa-key" aria-hidden="true"></i>
                            </button>
                        </td>
                    </ng-container>

                    <tr mat-header-row
                        *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row
                        *matRowDef="let row; columns: displayedColumns;"
                        class="example-element-row"></tr>
                </table>

            </div>
            <mat-paginator [pageSizeOptions]="[5, 10, 25, 50, 100, 150, 200]"
                           showFirstLastButtons></mat-paginator>

        </div>
    </div>
</div>