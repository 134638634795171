import { Component, Inject, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';

import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { UsuarioService } from '../../../../services/shared/usuario.service';
import { User } from '../../../../interfaces/interface-bakend/seguridad/users/user.interface';
import { PerfilesService } from '../../../../services/perfiles/perfiles.service';
import { MatDialog } from '@angular/material/dialog';
import { ModalModificarClaveUsersComponent } from '../modal-modificar-clave/modal-modificar-clave.component';
import { CursosService } from 'src/app/services/cursos/cursos.service';
import { AlumnoTitulo } from 'src/app/interfaces/interface-bakend/cursos/titulo.interface';
import { GuiMsjService } from 'src/app/services/shared/gui-msj.service';
import Swal from 'sweetalert2'
import { ModalDetalleComponent } from '../modal-detalle/modal-detalle.component';
import { ModalDetalleGecrosComponent } from '../modal-detalle-gecros/modal-detalle-gecros.component';

@Component({
    selector: 'app-usuarios-listado',
    templateUrl: './usuarios-listado.component.html',
    styleUrls: ['./usuarios-listado.component.scss']
})
export class UsuariosListadoComponent implements OnInit, AfterViewInit {

    /* Data Table*/
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    displayedColumns: string[] =
        [
            'nombre', 'perfil', 'email', 'estado', 'aceptaTerminos', 'email_verified', 'pin', 'created', '_id'
        ];
    dataSource: any;
    usuarios: User[];


    /* Fin Data Table*/
    usuarioLoad = false;
    flagLoaded = false;

    constructor(
        private usuariosService: UsuarioService,
        private cursosService: CursosService,
        private router: Router,
        private perfilesService: PerfilesService,
        public dialog: MatDialog,
        private guiMsjService: GuiMsjService
    ) { }

    ngOnInit(): void {
        this.perfilesService.allowedByUserLogon(this.router.url);
    }

    async ngAfterViewInit() {
        await this.loadDataPage();
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.flagLoaded = true;
    }

    // tslint:disable-next-line: variable-name
    async verUsuario(_id: string) {
        await this.guiMsjService.msjFormSubmit('Espere');
        Swal.showLoading();
        await this.usuariosService.getFindOne(_id);
        await this.cursosService.getTitulosAlumnosFindAllByAlumno((await this.cursosService.findAlumnoByUsuario(_id))._id).then((titulos: AlumnoTitulo[]) => {
            this.cursosService.alumnoTituloData = titulos;
            Swal.close();
        });

        this.router.navigateByUrl('/usuario-modificacion');
    }

    async loadDataTable() {
        await this.usuariosService.getAllUsersGrilla().then((usuarios: User[]) => {
            this.dataSource = new MatTableDataSource(usuarios);
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
        });
    }

    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }

    async loadDataPage() {
        await this.loadDataTable();
        this.flagLoaded = true;
        return;
    }

    /* Modal Modificar Clave */
    openDialogModificarClave(idUsuario: string): void {

        const dialogRef = this.dialog.open(ModalModificarClaveUsersComponent, {
            width: '800px',
            height: '820px',
            disableClose: true,
            data: idUsuario,
        });

        dialogRef.afterClosed().subscribe(resp => {
            this.router.navigate(['/usuarios-listado']);
        });
    }

}
