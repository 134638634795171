import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SignoDoctor } from 'src/app/interfaces/interface-bakend/entidades/signo-doctor.interface';
import { Catalogo } from 'src/app/interfaces/interface-bakend/shared/catalogo/catalogo.interface';
import { SignoDoctorService } from 'src/app/services/entidades/signo-doctores.service';
import { CatalogoService } from 'src/app/services/shared/catalogo.service';
import { GuiMsjService } from 'src/app/services/shared/gui-msj.service';
import { EntidadesKeys } from 'src/app/enum/entidades-keys.enum';

@Component({
  selector: 'app-doctor-alta',
  templateUrl: './doctor-alta.component.html',
  styleUrls: ['./doctor-alta.component.scss']
})
export class DoctorAltaComponent implements OnInit {
  form: FormGroup;
  especialidades: Catalogo[];
  centros: Catalogo[];

  constructor(
    private signoDoctorService: SignoDoctorService,
    private route: Router,
    private guiMsjService: GuiMsjService,
    private catalogoService: CatalogoService
  ) {
    this.getCentrosPropiosEspecialidades();
    this.getCentrosPropios();
  }

  ngOnInit(): void {
    this.createForm();
  }

  get doctorNoValido() {
    return this.form.get('doctor').invalid;
  }

  get especialidadNoSeleccionada() {
    return (
      this.form.get('especialidad').invalid &&
      this.form.get('especialidad').touched
    );
  }

  async getCentrosPropiosEspecialidades() {
    try {
      const centrosPropiosEspecialidades = await this.catalogoService.findDescendantsByKey(EntidadesKeys.CENTROS_PROPIOS_ESPECIALIDADES);

      if (centrosPropiosEspecialidades) {
        this.especialidades = centrosPropiosEspecialidades;
      }
    } catch (error) {
      console.error("Error al cargar centros propios especialidades", error);
    }
  }

  async getCentrosPropios() {
    try {
      const centrosPropios = await this.catalogoService.findDescendantsByKey(EntidadesKeys.CENTROS_PROPIOS);

      if (centrosPropios) {
        this.centros = centrosPropios;
      }
    } catch (error) {
      console.error("Error al cargar centros propios ", error);
    }
  }

  createForm() {
    this.form = new FormGroup({
      doctor: new FormControl('', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(50),
      ]),
      activo: new FormControl(false, [
        Validators.required,
      ]),
      especialidad: new FormControl('', [Validators.required]),
      centro: new FormControl('')
    });
  }

  async createDoctor() {
    await this.guiMsjService.msjFormSubmit('Guardando');
    this.signoDoctorService.create(await this.setDataForma())
    await this.guiMsjService.msjFormSubmit('DatosGuardados');
    this.route.navigateByUrl('/doctores-listado');
  }
  
  async setDataForma(): Promise<SignoDoctor> {

    const centrosObjectId = this.form.value.centro.map((centroId: string) => ({ _id: centroId }))

    const doctor: SignoDoctor = {
      nombre: this.form.value.doctor,
      especialidad: {
        _id: this.form.value.especialidad,
      },
      activo: this.form.value.activo,
      centros: centrosObjectId,
    };

    return doctor;

  }
}
