import { Component, OnInit, Inject, NgZone } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from '@services/auth/auth.service';
import { GuiToolsService } from '@services/shared/gui-tools.service';
import { APP_CONFIG, IAppConfig } from 'src/app/app.config/app.config';
import { GuiMsjService } from 'src/app/services/shared/gui-msj.service';
import { MatDialog } from '@angular/material/dialog';
import { TermsConditions } from 'src/app/auth/profile-authentication/terms-conditions/terms-conditions.component';
import { Register } from 'src/app/interfaces/interface-frontend/register';
import { OpenPageService } from '@services/shared/open-page.service';
import { GecrosAfiliadosService } from 'src/app/services/entidades/gecros-afiliados.service';
import { SignoGecrosAfiliado } from 'src/app/interfaces/interface-bakend/entidades/signo-greco-afiliado.interface';
import { User } from '@interfaces/interface-bakend/seguridad/users/user.interface';
import { PersonasService } from '@services/shared/personas.service';
import { UsuarioService } from '@services/shared/usuario.service';
import { UserEntreUser } from '@interfaces/interface-bakend/seguridad/users/user-entre-user.interface';
import { UserEntreUserService } from '@services/shared/user-entre-user.service';
import { PreviousRouteService } from '@services/shared/previous-route.service';
import Swal from 'sweetalert2';
import { PersonaGecros } from '@interfaces/interface-bakend/seguridad/users/personas.interface';

@Component({
  selector: 'app-grupofamiliar-alta',
  templateUrl: './grupofamiliar-alta.component.html',
  styleUrls: ['./grupofamiliar-alta.component.scss']
})
export class GrupoFamiliarAltaComponent implements OnInit {

  usuario: User;
  registerForm: FormGroup;
  loading: Subscription;
  idioma: Subscription;

  loadingForm: boolean = false;
  flagFormaValido: boolean = false;
  flagDataGecros: boolean = false;
  flagDataGecrosEmail: boolean = false;
  viewSpinner: boolean = false;

  showPasswordRegister: boolean = false;
  showPasswordConfirm: boolean = false;
  check: boolean = false;

  idPasaporte: string;
  identificacion: string = "";

  dataGecros: SignoGecrosAfiliado = undefined;
  signoPlan: string = "";
  signoSedeCodigo: string;

  showTimeoutMessage: boolean = false;
  searchTimeout: any;

  parentescos: string[];
  parentescoSeleccionado: string;

  routes: { url: string, label: string }[] = [];

  familiarGecros: PersonaGecros;

  constructor(
    @Inject(APP_CONFIG) private iAppConfig: IAppConfig,
    private router: Router,
    private previousRouteService: PreviousRouteService,
    private authservice: AuthService,
    private guiToolsService: GuiToolsService,
    private personasService: PersonasService,
    private openPageService: OpenPageService,
    private gecrosAfiliadosService: GecrosAfiliadosService,
    private userService: UsuarioService,  // unificar
    private usuariosService: UsuarioService, // unificar
    private userEntreUser: UserEntreUserService,
    public guiMsjService: GuiMsjService,
    public dialog: MatDialog,
    private zone: NgZone,
  ) {

    this.loading = this.guiToolsService.getLoading().subscribe(resp => {
      this.loadingForm = resp;
    });

    this.createFormRegister();
  }

  ngOnInit(): void {
    this.getAfiliado();
    this.getParentesco();
    this.setIdPasaporte(this.iAppConfig.dniid);
    this.authservice.getCheck$().subscribe((acepta: boolean) => {
      this.check = acepta;
    }).unsubscribe();
    this.previousRouteService.getRoutes().subscribe(routes => {
      this.routes = routes;
    }).unsubscribe();
    this.createListenersStateForm();
  }

  getAfiliado() {
    if (this.personasService.getPersonaSeleccionada() !== undefined) {
      this.usuario = this.personasService.getPersonaSeleccionada();
    }
    else {
      this.router.navigateByUrl('/');
    }
  }

  async getParentesco() {
    this.parentescos = await this.userService.getParentescosSignoMedico();
    this.parentescos = this.parentescos.filter(parentesco => parentesco !== 'TITULAR');
  }

  setIdPasaporte(id: string) {
    this.idPasaporte = id;
  }

  get emailNoValido() {
    const emailControl = this.registerForm.get('email');
    const emailValue = emailControl.value;
    const emailPattern = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/;
    return !emailControl.valid && emailControl.touched && emailPattern.test(emailValue);
  }

  get passwordNoValido() {
    return this.registerForm.get('password').invalid && this.registerForm.get('password').touched;
  }

  get confirmPasswordNoValido() {
    const pass1 = this.registerForm.get('password').value;
    const pass2 = this.registerForm.get('confirmPassword').value;
    return (pass1 === pass2) ? false : true;
  }

  get confirmEmailNoValido() {
    const email1 = this.registerForm.get('email').value;
    const email2 = this.registerForm.get('confirmEmail').value;
    return (email1 === email2) ? false : true;
  }

  get nombreNoValido() {
    return this.registerForm.get('nombrePersona').invalid && this.registerForm.get('nombrePersona').touched;
  }

  get apellidoNoValido() {
    return this.registerForm.get('apellidoPersona').invalid && this.registerForm.get('apellidoPersona').touched;
  }

  get identificacionNoValida() {
    return this.registerForm.get('identificacion').invalid && this.registerForm.get('identificacion').touched;
  }

  get parentescoNoSeleccionado() {
    return (
      this.registerForm.get('parentesco').invalid &&
      this.registerForm.get('parentesco').touched
    );
  }

  createListenersStateForm() {
    this.registerForm.get('email')?.valueChanges.subscribe(() => {
      this.registerForm.get('confirmEmail')?.reset();
    });

    return this.registerForm.statusChanges.subscribe(async status => {
      if (status === 'VALID') {
        this.flagFormaValido = true;
      } else {
        this.flagFormaValido = false;
      }
    });
  }

  async registerUser() {
    if (this.registerForm.invalid) {
      Object.values(this.registerForm.controls).forEach(control => { control.markAsTouched(); });
      return;
    }

    await this.guiMsjService.msjFormSubmit('Espere');
    Swal.showLoading();
    try {
      let beneficiarioRegistrado: boolean;

      if (this.flagDataGecrosEmail) {
         const registrar: Register = await this.setDataForma(this.flagDataGecrosEmail);
         if (registrar) {beneficiarioRegistrado = await this.authservice.registerAfiliado(registrar); }
      } else {
         const registrar: Register = await this.setDataForma(this.flagDataGecrosEmail);
         if (registrar) {beneficiarioRegistrado = await this.authservice.registerAfiliado(registrar); }
      }

      if (beneficiarioRegistrado) {

          const registrado = await this.userEntreUser.create(await this.setDataAfiliado(), false);

      }
    } catch (error) {
      throw Error("El benificiario no ha sido registrado ")
    }
  }

  async setDataForma(email: boolean): Promise<Register | null> {
    let data: Register | null = null;
    let errorMessage = ''; // Mensaje de error para campos faltantes
  
    if (email) {
      // Validar campos de email
      const emailValue = this.registerForm.value.email;
      const confirmEmailValue = this.registerForm.value.confirmEmail;
      const passwordValue = this.registerForm.value.password;
      const confirmPasswordValue = this.registerForm.value.confirmPassword;
      const aceptaTerminosValue = this.registerForm.value.aceptaTerminos;
      const proteccionDeDatosValue = this.registerForm.value.proteccionDeDatos;
      const clausulaValue = this.registerForm.value.clausula;
  
      if (
        !emailValue ||
        !confirmEmailValue ||
        !passwordValue ||
        !confirmPasswordValue ||
        !aceptaTerminosValue ||
        !proteccionDeDatosValue ||
        !clausulaValue
      )  {
        errorMessage = 'Por favor, complete todos los campos.';
      } else if (
        aceptaTerminosValue !== true ||
        proteccionDeDatosValue !== true ||
        clausulaValue !== true
      ) {
        errorMessage = 'Debe aceptar los términos, protección de datos y cláusula.';
      } else {
        data = {
          email: emailValue,
          emailConfirm: confirmEmailValue,
          password: passwordValue,
          passwordConfirm: confirmPasswordValue,
          nombrePersona: this.registerForm.value.nombrePersona,
          apellidoPersona: this.registerForm.value.apellidoPersona,
          tipoIdentificacion: this.idPasaporte,
          identificacion: this.registerForm.value.identificacion,
          aceptaTerminos: aceptaTerminosValue,
          proteccionDeDatos: proteccionDeDatosValue,
          clausula: clausulaValue,
          fromWeb: true,
          fromApp: false,
          signoGecrosAfiliado: this.dataGecros,
          signoPlan: this.signoPlan !== undefined ? this.signoPlan : null,
          codigoGecros: this.signoSedeCodigo !== undefined ? this.signoSedeCodigo : null,
          notifica: email
        };
      }
    } else {

      // Aquí puedes implementar la lógica para generar el correo arameo
      const nombre = this.registerForm.value.nombrePersona;
      const apellido = this.registerForm.value.apellidoPersona;
      const identificacion = this.registerForm.value.identificacion;

      // Generar el correo electrónico arameo
      const primeraLetraNombre = nombre.charAt(0).toLowerCase();
      const correoArameo = `${identificacion}@${primeraLetraNombre}${apellido.toLowerCase()}.ar`;

      data = {
        email: correoArameo,
        emailConfirm: correoArameo,
        password: '12345678', // seteo por default
        passwordConfirm: '12345678', // seteo por default
        nombrePersona: nombre,
        apellidoPersona: apellido,
        tipoIdentificacion: this.idPasaporte,
        identificacion,
        aceptaTerminos: true,
        proteccionDeDatos: true,
        clausula: true,
        fromWeb: true,
        fromApp: false,
        signoGecrosAfiliado: this.dataGecros,
        signoPlan: this.signoPlan !== undefined ? this.signoPlan : null,
        codigoGecros: this.signoSedeCodigo !== undefined ? this.signoSedeCodigo : null,
        notifica: email
      };
    }

    if (!data) {
      // Si data sigue siendo null, significa que faltan campos obligatorios
      // Mostrar mensaje de error
      console.log(errorMessage);
      // Mostrar mensaje de error en una ventana emergente o similar
      Swal.fire({
        title: 'Error',
        text: errorMessage,
        icon: 'error',
        showCancelButton: false,
        showConfirmButton: true,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Entendido'
      });
    }

    console.log('data', data);
    return data;
  }


  // async setDataForma(email: boolean): Promise<Register> {
  //   let data: Register;
  //   if (email) {
  //     data = {
  //       email: this.registerForm.value.email,
  //       emailConfirm: this.registerForm.value.confirmEmail,
  //       password: this.registerForm.value.password,
  //       passwordConfirm: this.registerForm.value.confirmPassword,
  //       nombrePersona: this.registerForm.value.nombrePersona,
  //       apellidoPersona: this.registerForm.value.apellidoPersona,
  //       tipoIdentificacion: this.idPasaporte,
  //       identificacion: this.registerForm.value.identificacion,
  //       aceptaTerminos: this.registerForm.value.aceptaTerminos,
  //       proteccionDeDatos: this.registerForm.value.proteccionDeDatos,
  //       clausula: this.registerForm.value.clausula,
  //       fromWeb: true,
  //       fromApp: false,
  //       signoGecrosAfiliado: this.dataGecros,
  //       signoPlan: this.signoPlan != undefined ? this.signoPlan : null,
  //       codigoGecros: this.signoSedeCodigo != undefined ? this.signoSedeCodigo : null,
  //       notifica: email
  //     };
  //   } else {
  //     const nombre = this.registerForm.value.nombrePersona;
  //     const apellido = this.registerForm.value.apellidoPersona;
  //     const identificacion = this.registerForm.value.identificacion;

  //     // Generar el correo electrónico arameo
  //     const primeraLetraNombre = nombre.charAt(0).toLowerCase();
  //     const correoArameo = `${identificacion}@${primeraLetraNombre}${apellido.toLowerCase()}.ar`;

  //     data = {
  //       email: correoArameo,
  //       emailConfirm: correoArameo,
  //       password: '12345678', // seteo por default
  //       passwordConfirm: '12345678', // seteo por default
  //       nombrePersona: nombre,
  //       apellidoPersona: apellido,
  //       tipoIdentificacion: this.idPasaporte,
  //       identificacion,
  //       aceptaTerminos: true,
  //       proteccionDeDatos: true,
  //       clausula: true,
  //       fromWeb: true,
  //       fromApp: false,
  //       signoGecrosAfiliado: this.dataGecros,
  //       signoPlan: this.signoPlan != undefined ? this.signoPlan : null,
  //       codigoGecros: this.signoSedeCodigo != undefined ? this.signoSedeCodigo : null,
  //       notifica: email
  //     };

  //   }


  //   return data;
  // }

  async setDataAfiliado(): Promise<UserEntreUser> {
    try {
      const titular = this.usuario;

      const adicional = await this.userService.getFindOneByIdentification(this.identificacion);

      if (!adicional) {
        throw new Error("No se encontró el familiar registrado en el sistema");
      }

      const parentescoSeleccionado = this.registerForm.get('parentesco').value;

      if (!parentescoSeleccionado) {
        throw new Error("Parentesco no especificado");
      }

      const data: UserEntreUser = {
        titular: titular,
        adicional: adicional,
        parentesco: parentescoSeleccionado
      };

      return data;
    } catch (error) {
      console.error("Error al asociar los datos del familiar al titular", error.message);
      throw error;
    }
  }

  async searchAfiliado() {
    if (this.identificacion && this.identificacion.trim() !== '') {
      this.viewSpinner = true;
      this.showTimeoutMessage = false;

      clearTimeout(this.searchTimeout);

      this.searchTimeout = new Promise<void>((_, reject) => {
        setTimeout(() => {
          this.zone.run(() => {
            this.showTimeoutMessage = true;
          });
          reject(new Error('Timeout'));
        }, 3000);
      });

      try {
        const resp = await Promise.race([this.gecrosAfiliadosService.getAfiliadoByIdentificacion(this.identificacion),
        this.searchTimeout
        ]);

        if (
          resp.data[0] !== undefined &&
          this.usuariosService.noEsTitular(resp.data[0], true) &&
          !await this.familiarExiste(this.usuario, resp.data[0])
         ) {
          this.familiarGecros = resp.data[0] ;
          this.flagDataGecros = true;

          const nombresAfiliado: string[] = resp.data[0]?.nombreAfiliado.trim().split(/\s+/);
          const apellido = nombresAfiliado.pop().trim();
          const nombresSinApellido = nombresAfiliado.join(' ');
          
          if (resp.data[0]?.email?.trim()) {
            this.flagDataGecrosEmail = true;
            this.dataGecros = resp.data[0];
            this.signoSedeCodigo = resp.data[0]?.numeroAfiliado.substring(0, 3);
            this.signoPlan = resp.data[0]?.plan;
            this.registerForm.controls['nombrePersona'].setValue(nombresSinApellido);
            this.registerForm.controls['apellidoPersona'].setValue(apellido);
            this.registerForm.controls['email'].setValue(resp.data[0]?.email?.trim() || null);
            this.registerForm.controls['confirmEmail'].setValue(resp.data[0]?.email?.trim() || null);
          } else {
            this.flagDataGecrosEmail = false;
            this.dataGecros = resp.data[0];
            this.signoSedeCodigo = resp.data[0]?.numeroAfiliado.substring(0, 3);
            this.signoPlan = resp.data[0]?.plan;
            this.registerForm.controls['nombrePersona'].setValue(nombresSinApellido);
            this.registerForm.controls['apellidoPersona'].setValue(apellido);
            this.registerForm.controls['email'].setValue( null);
            this.registerForm.controls['confirmEmail'].setValue(null);
          }

           // cargo parentesco si lo encuentro en el arreglo de parentescos
          const parentescoSeleccionado = this.parentescos.find(parentesco => parentesco === resp.data[0]?.parentesco?.trim());
          this.registerForm.controls['parentesco'].setValue(parentescoSeleccionado ? parentescoSeleccionado : null);

          if (parentescoSeleccionado) {
            this.registerForm.controls['parentesco'].disable(); // Deshabilitar el campo si parentescoSeleccionado es verdadero
          } else {
            this.registerForm.controls['parentesco'].enable(); // Habilitar el campo si parentescoSeleccionado es falso
          }

        } else {
          this.flagDataGecros = false;
          this.dataGecros = undefined;
          this.signoSedeCodigo = null;
          this.signoPlan = null;
          this.registerForm.controls['nombrePersona'].setValue(null);
          this.registerForm.controls['apellidoPersona'].setValue(null);
          this.registerForm.controls['email'].setValue(null);
          this.registerForm.controls['confirmEmail'].setValue(null);
          this.registerForm.controls['parentesco'].setValue(null);
        }
      } catch (error) {
        console.error("El DNI del asociado es vacío ", error);
        this.showTimeoutMessage = true;
      } finally {
        clearTimeout(this.searchTimeout);
        this.viewSpinner = false;
      }
    } else {
      this.viewSpinner = false;
    }
  }

  // CREACION DE FORMULARIO

  createFormRegister() {
    this.registerForm = new FormGroup({
      email: new FormControl('', [, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$'),]),
      confirmEmail: new FormControl('', [, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$'),]),
      password: new FormControl('',  [, Validators.pattern(/^.{8}$/)]), // Solo dígitos, al menos 6
      confirmPassword: new FormControl('', [ Validators.minLength(8)]),
      nombrePersona: new FormControl('', [Validators.required, Validators.minLength(3)]),
      apellidoPersona: new FormControl('', [Validators.required, Validators.minLength(3)]),
      identificacion: new FormControl('', [Validators.required, Validators.minLength(8), Validators.maxLength(15)]),
      parentesco: new FormControl('', [Validators.required]),
      aceptaTerminos: new FormControl('',),
      proteccionDeDatos: new FormControl('',),
      clausula: new FormControl(false,),
      email_verified: new FormControl(''),
    });
  }

  verTerminos() {
    this.openDialog();
  }

  openDialog() {
    const dialogRef = this.dialog.open(TermsConditions, {

      width: '900px',
      height: '800px',
      disableClose: true,

    });
    dialogRef.afterClosed().subscribe();
    return;
  }

  openBlank(type: string) {
    this.openPageService.openBlank(type);
  }

  async familiarExiste(titular: User, familiar: PersonaGecros): Promise<boolean> {
    const grupoFamiliar = await this.userEntreUser.findGrupoFamiliar(titular._id);
    const documentoEncontrado = grupoFamiliar.some((miembro) => miembro?.adicional?.identificacion === familiar.documento);

    if (documentoEncontrado) {
        this.flagDataGecros = false;
        Swal.fire({
            title: 'El familiar ya ha sido registrado previamente.',
            text: 'Familiar dado de alta',
            icon: 'info',
            showClass: {
                popup: 'animate__animated animate__fadeInDown'
            },
            hideClass: {
                popup: 'animate__animated animate__fadeOutUp'
            }
        });
        return true;
    } else {
        console.log(`El documento ${familiar.documento} no está presente en el grupo familiar.`);
        return false;
    }
  }



}
