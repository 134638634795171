import { Component, OnInit, Inject, Output } from '@angular/core';
import { Router } from '@angular/router';
import { APP_CONFIG_OFERTAS, IAppOfertas } from '../../../../../app.config/app.config-ofertas';
import { Solicitud } from '../../../../../interfaces/interface-bakend/ofertas-laborales/solicitudes.interface';
import { SolicitudesService } from '../../../../../services/ofertas-laborales/solicitudes.service';
import { InfoSolicitud } from '../../../../../interfaces/interface-bakend/ofertas-laborales/actividad.interface';
import { EstadisticasService } from '../../../../../services/ofertas-laborales/estadisticas.service';
import { GuiToolsService } from '../../../../../services/shared/gui-tools.service';
import { PerfilesService } from '../../../../../services/perfiles/perfiles.service';
import { UsuarioService } from 'src/app/services/shared/usuario.service';

@Component({
  selector: 'app-ofertas-estadisticas',
  templateUrl: './ofertas-estadisticas.component.html',
  styleUrls: ['./ofertas-estadisticas.component.scss']
})
export class OfertasEstadisticasComponent implements OnInit {

    constructor(
        @Inject(APP_CONFIG_OFERTAS) private iAppOfertas: IAppOfertas,
        private estadisticasService: EstadisticasService,
        private solicitudesService: SolicitudesService,
        private router: Router,
        private guitoolsService: GuiToolsService,
        private perfilesService: PerfilesService,
        private usuariosService: UsuarioService,
    ) { }

    solicitudes: Solicitud[];

    cantPresentados: number = 0;
    cantDocumentoCargados: number = 0;
    cantEnRevision: number = 0;
    cantEnAuditoria: number = 0;
    cantEstadoAprobadas: number = 0;
    cantEstadoRechazadas: number = 0;

    cantTotalSolicitudes: number = 0;

    cantTotalUsuarios: number = 0;

    porcPresentados: number = 0;
    porcDocCargados: number = 0;
    porcEnRevision: number = 0;
    porcEnAuditoria: number = 0;
    porcAprobados: number = 0;
    porcRechazados: number = 0;

    flagCargando = false;

    ngOnInit(): void {
        this.perfilesService.allowedByUserLogon(this.router.url);
        this.cargarEstadisitcas();
    }

    async cargarEstadisitcas() {
        await this.setValuesCero();
        await this.getInfoDashBoard();
        await this.cargarSolicitudes();
        await this.cargarCantTotalUsuarios();
        this.flagCargando = true;
    }

    async setDataFormarefreshComponents() {
        await this.cargarEstadisitcas();
        this.estadisticasService.setFlagRefresh(true);
    }

    async setValuesCero() {
        this.cantPresentados = 0;
        this.cantEnRevision = 0;
        this.cantEnAuditoria = 0; 
        this.cantEstadoAprobadas = 0;
        this.cantEstadoRechazadas = 0;

        this.cantTotalSolicitudes = 0;
        this.cantTotalUsuarios = 0;

        this.porcPresentados = 0;
        this.porcDocCargados = 0;
        this.porcEnRevision = 0;
        this.porcEnAuditoria = 0;
        this.porcAprobados = 0;
        this.porcRechazados = 0;
    }

    async getInfoDashBoard() {
        await this.estadisticasService.getInfoDashBoard().then( async (resp: InfoSolicitud[]) => {

            /* CANTIDADES */
            await this.cargarCantidades(resp);

            /* PORCENTAJE */
            await this.cargarPorcentajes(resp);

            /* TOTAL SOLICITUDES */
            await this.cargarTotalCantidad(resp);


        });
    }

    async cargarSolicitudes() {
        await this.estadisticasService.getFindAll().then( resp => {
            this.solicitudes = resp;
        });
    }

    async cargarPorcentajes( info ) {
        if (info.find(x => x.numberState === this.iAppOfertas.estado_0.number) !== undefined) {
            this.porcPresentados = info.find(x => x.numberState === this.iAppOfertas.estado_0.number).porcentaje;
        }

        if (info.find(x => x.numberState === this.iAppOfertas.estado_1.number) !== undefined) {
            this.porcDocCargados = info.find(x => x.numberState === this.iAppOfertas.estado_1.number).porcentaje;
        }

        if (info.find(x => x.numberState === this.iAppOfertas.estado_2.number) !== undefined) {
            this.porcEnRevision = info.find(x => x.numberState === this.iAppOfertas.estado_2.number).porcentaje;
        }

        if (info.find(x => x.numberState === this.iAppOfertas.estado_3.number) !== undefined) {
            this.porcEnAuditoria = info.find(x => x.numberState === this.iAppOfertas.estado_3.number).porcentaje;
        }

        if (info.find(x => x.numberState === this.iAppOfertas.estado_4.number) !== undefined) {
            this.porcAprobados = info.find(x => x.numberState === this.iAppOfertas.estado_4.number).porcentaje;
        }

        if (info.find(x => x.numberState === this.iAppOfertas.estado_5.number) !== undefined) {
            this.porcRechazados = info.find(x => x.numberState === this.iAppOfertas.estado_5.number).porcentaje;
        }
    }

    async cargarCantidades( info ) {

        if ( info.find(x => x.numberState === this.iAppOfertas.estado_0.number) !== undefined) {
            this.cantPresentados = info.find(x => x.numberState === this.iAppOfertas.estado_0.number).cantidad;
         }

        if ( info.find(x => x.numberState === this.iAppOfertas.estado_1.number) !== undefined) {
            this.cantDocumentoCargados = info.find(x => x.numberState === this.iAppOfertas.estado_1.number).cantidad;
         }

        if ( info.find(x => x.numberState === this.iAppOfertas.estado_2.number) !== undefined) {
            this.cantEnRevision  = info.find(x => x.numberState === this.iAppOfertas.estado_2.number).cantidad;
         }

        if (info.find(x => x.numberState === this.iAppOfertas.estado_3.number) !== undefined) {
            this.cantEnAuditoria =  info.find(x => x.numberState === this.iAppOfertas.estado_3.number).cantidad;
         }

        if (info.find(x => x.numberState === this.iAppOfertas.estado_4.number) !== undefined) {
            this.cantEstadoAprobadas = info.find(x => x.numberState === this.iAppOfertas.estado_4.number).cantidad;
         }

        if ( info.find(x => x.numberState === this.iAppOfertas.estado_5.number) !== undefined) {
            this.cantEstadoRechazadas  = info.find(x => x.numberState === this.iAppOfertas.estado_5.number).cantidad;
         }

    }

    async cargarTotalCantidad( info ) {
        this.cantTotalSolicitudes = 0;
        if (info.length > 0) {
            info.forEach( element => {
                this.cantTotalSolicitudes += element.cantidad;
            });
        }
    }

    async navigateToSolicitudes(estado: number) {
        await this.solicitudesService.setEstadoSolicitud(estado);
        this.router.navigateByUrl('/ofertas-administracion-listado');
    }

    async cargarCantTotalUsuarios() {
        this.cantTotalUsuarios =  await this.usuariosService.getCountUsers();
    }


}

