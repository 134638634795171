
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">{{ idioma | translate: "navbar.UL-INICIO" | async}}</a></li>
                <li>{{ idioma | translate: "navbar.UL-OFERTA-LABORAL" | async}}</li>
            </ul>
            <h2>{{ idioma | translate: "navbar.LI-VER-OFERTAS" | async}}</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>
<app-ofertas-listar></app-ofertas-listar>