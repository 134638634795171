import { EstadosPipe } from './../pipes/ofertas-laborales/estadosPipe';
import { NgModule } from '@angular/core';
import { DomSanitizerPipe } from '../pipes/dom-sanitizer.pipe';
import { FormatNumberPipe } from '../pipes/formatNumber.pipe';
import { ImagenPipe } from '../pipes/imagen.pipe';
import { LinkExterno } from '../pipes/linkExterno.pipe';
import { MediaCursosPipe } from '../pipes/ofertas-laborales/mediaCursosPipe';
import { MediaUsuariosPipe } from '../pipes/usuarios/mediaUsuariosPipe';
import { MediaOfertaLaboralPipe } from '../pipes/ofertas-laborales/mediaOfertaLaboralPipe';
import { SafePipe } from '../pipes/safe.pipe';
import { VideoPipe } from '../pipes/video.pipe.';
import { DetalleEstadosPipe } from '../pipes/ofertas-laborales/detalleEstadosPipe';
import { MediaOfertaLaboralFileUserPipe } from '../pipes/ofertas-laborales/mediaOfertaLaboralFileUser.Pipe';
import { MediaOfertaLaboralNombreAdjuntorPipe } from '../pipes/ofertas-laborales/mediaOfertaLaboralNombreAdjunto.Pipe';
import { NombreExistePipe } from '../pipes/ofertas-laborales/nombre-existe.pipe';
import { TranslatePipe } from '../pipes/translate.pipe';
import { TipoPreguntaPipe } from '../pipes/examenes/tipo-pregunta.pipe';
import { ComprobanteTransferenciaPipe } from '../pipes/ofertas-laborales/comprobante-transferencia.pipe';
import { MediaExamenesPipe } from '../pipes/examenes/mediaExamenesPipe';
import { SafeResourceUrlPipe } from '../pipes/safeResourceUrl.pipe';
import { BypassSecurityTrustHtml } from '../pipes/bypassSecurityTrustHtml.pipe';
import { FechaPickerPipe } from '../pipes/ofertas-laborales/fecha-picker.pipe'


@NgModule({
  declarations: [
    MediaOfertaLaboralPipe,
    MediaCursosPipe,
    MediaUsuariosPipe,
    MediaExamenesPipe,
    EstadosPipe,
    DetalleEstadosPipe,
    MediaOfertaLaboralFileUserPipe,
    MediaOfertaLaboralNombreAdjuntorPipe,
    NombreExistePipe,
    TranslatePipe,
    TipoPreguntaPipe,
    ComprobanteTransferenciaPipe,
    SafeResourceUrlPipe,
    BypassSecurityTrustHtml,
    FechaPickerPipe,
    DomSanitizerPipe, ImagenPipe, SafePipe, FormatNumberPipe,  VideoPipe, LinkExterno],
  exports: [
    MediaOfertaLaboralPipe,
    MediaCursosPipe,
    MediaUsuariosPipe,
    MediaExamenesPipe,
    EstadosPipe,
    DetalleEstadosPipe,
    MediaOfertaLaboralFileUserPipe,
    MediaOfertaLaboralNombreAdjuntorPipe,
    NombreExistePipe,
    TranslatePipe,
    TipoPreguntaPipe,
    ComprobanteTransferenciaPipe,
    SafeResourceUrlPipe,
    BypassSecurityTrustHtml,
    FechaPickerPipe,
    DomSanitizerPipe, ImagenPipe, SafePipe, FormatNumberPipe, VideoPipe, LinkExterno ]
})
export class PipesModule { }
