import { Response } from './../../interfaces/interface-bakend/response';
import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import { User } from 'src/app/interfaces/interface-bakend/seguridad/users/user.interface';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { StorageService } from './storage.service';
import { AuthService } from '../auth/auth.service';
import { GuiMsjService } from './gui-msj.service';
import { Register } from '@interfaces/interface-frontend/register';

const URL_BACKEND = environment.urlBackend;

@Injectable({
  providedIn: 'root'
})
export class PersonasService {

  personaSeleccionada: User;

  constructor(
    private httpClient: HttpClient,
    private storageService: StorageService,
    private authService: AuthService,
    private guiMsjService: GuiMsjService,
  ) { }

  getAllPersonas(): Promise<User[]> {
    return new Promise<User[]>(async (resolve, reject) => {

      const headers = new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });

      this.httpClient.get<Response>(`${URL_BACKEND}/users/getPersonasList`, { headers }).subscribe((resp: Response) => {
        resolve(resp.response);
      });
    });
  }

  getById(id: string): Promise<User> {
    return new Promise(async resolve => {

      const headers = new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });

      this.httpClient.get<Response>(`${URL_BACKEND}/users/findOne/${id}`, { headers }).subscribe((resp: Response) => {
        resolve(resp.response);
      });
    });
  }

  getAllPersonasByEmpresa(EmpresaId: string): Promise<User[]> {
    return new Promise(async (resolve, reject) => {

      const headers = new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });

      this.httpClient.get<Response>(`${URL_BACKEND}/users/findAllBySignoMedicoEmpresa/${EmpresaId}`, { headers })
        .subscribe((resp: Response) => {
          resolve(resp.response);
        },
          (error: Error) => {
            console.error(error);
            reject(error.message);
          });
    });
  }

  setPersonaSeleccionada(persona: User) {
    this.personaSeleccionada = persona;
    this.storageService.setPersona(this.personaSeleccionada);
  }

  getPersonaSeleccionada(): User {
    return this.personaSeleccionada;
  }


  createUser(usuario: Register): Promise<boolean> {
    return new Promise(async resolve => {

      const headers = new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });

      this.httpClient.post<Response>(`${URL_BACKEND}/users/`, usuario, { headers }).subscribe((resp: Response) => {
      if (resp) {
        resolve(true);
      } else {
        resolve(false);
      }
     
      }, error => {
        resolve(false);
        console.log('error', error);
        this.guiMsjService.msjFormSubmit('emailDuplicado');
      });
    });
  }

}
