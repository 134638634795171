import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MapComponent } from '@components/entidades/common/map/map.component';
import { CatalogoCentrosPropios } from 'src/app/interfaces/interface-bakend/shared/catalogo/catalogo.centros-propios.interface';
import { CatalogoService } from 'src/app/services/shared/catalogo.service';
import { GuiMsjService } from 'src/app/services/shared/gui-msj.service';

@Component({
  selector: 'app-centro-propio-modificacion',
  templateUrl: './centro-propio-modificacion.component.html',
  styleUrls: ['./centro-propio-modificacion.component.scss']
})
export class CentroPropioModificacionComponent implements OnInit, AfterViewInit {
  form: FormGroup;
  catalogo: CatalogoCentrosPropios;
  @ViewChild(MapComponent) mapComponent: MapComponent;
  flagLoaded = false;

  constructor(
    private router: Router,
    private catalogoService: CatalogoService,
    public guiMsjService: GuiMsjService
  ) {
  }

  ngOnInit(): void {
    this.getEstadoVariables();
    this.createForm();
  }

  ngAfterViewInit(): void {
    this.initMap()
    this.updateMap();
  }

  get centroNoValido() {
    return (
      this.form.get('centro').invalid &&
      this.form.get('centro').touched
    );
  }

  async getConcepto() {
    this.catalogo = this.catalogoService.getConceptoSeleccionado();
  }

  async getEstadoVariables() {
    if (this.catalogoService.getConceptoSeleccionado() === undefined) {
      this.router.navigateByUrl('/centros-propios-listado');
      return;
    } else {
      await this.getConcepto();
      this.flagLoaded = true;
    }
  }

  initMap() {
    if (!isNaN(this.form.value.latitud) && !isNaN(this.form.value.longitud)) {
      this.mapComponent.getLocation(this.form.value.latitud, this.form.value.longitud);
    }
  }

  updateMap() {
    this.updatesCoordinates();
    if (!isNaN(this.form.value.latitud) && !isNaN(this.form.value.longitud)) {
      this.mapComponent.getLocation(this.form.value.latitud, this.form.value.longitud);
    }
  }

  updatesCoordinates() {
    this.mapComponent.coordinatesChanged.subscribe((coordinates) => {
      this.form.patchValue({
        latitud: coordinates.latitud,
        longitud: coordinates.longitud
      });
    });
  }

  updateMarkerLocation() {
    const latitud = this.form.get('latitud').value;
    const longitud = this.form.get('longitud').value;
    if (this.mapComponent && !isNaN(latitud) !== null && !isNaN(longitud) !== null) {
      this.mapComponent.getLocation(latitud, longitud);
    }
  }

  createForm() {
    this.form = new FormGroup({
      centro: new FormControl(this.catalogo?.concepto, [
        Validators.required,
        Validators.min(3),
      ]),
      activa: new FormControl(this.catalogo?.logico_1),
      direccion: new FormControl(this.catalogo?.properties?.direccion),
      telefono: new FormControl(this.catalogo?.properties?.telefono),
      latitud: new FormControl(this.catalogo?.properties?.latitud),
      longitud: new FormControl(this.catalogo?.properties?.longitud)
    });
  }

  async setDataForma(): Promise<CatalogoCentrosPropios> {
    const data: CatalogoCentrosPropios = {
      key: null,
      concepto: this.form.value.centro,
      parent: this.catalogo.parent,
      logico_1: this.form.value.activa,
      properties: {
        direccion: this.form.value.direccion,
        telefono: this.form.value.telefono,
        latitud: this.form.value.latitud,
        longitud: this.form.value.longitud
      }
    };

    return data;
  }

  async put() {
    await this.guiMsjService.msjFormSubmit('Guardando');
    this.catalogoService.put(this.catalogo._id, await this.setDataForma());
    await this.guiMsjService.msjFormSubmit('DatosGuardados');
    this.router.navigateByUrl('/centros-propios-listado');
  }
}

