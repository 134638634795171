<div class="error-area ptb-100">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="error-content">
                    <img src="assets/img/error-sm.png" alt="image">
                    <h3><strong style="color: rgb(100, 31, 31);">Error 404:</strong> Pagina no encontrada</h3>
                    <p>La página que estás buscando podría haber sido eliminada, haber cambiado su nombre o estar temporalmente no disponible.</p>
                    <div class="btn-box">
                        <a routerLink="/" class="default-btn"><i class="flaticon-history"></i>Volver<span></span></a>
                        <a routerLink="/" class="default-btn"><i class="flaticon-home"></i>Inicio<span></span></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>