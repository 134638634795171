import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { EntidadesKeys } from '@enum/entidades-keys.enum';
import { SignoLocalidad } from '@interfaces/interface-bakend/entidades/signo-localidad.interface';
import { SignoPlan } from '@interfaces/interface-bakend/entidades/signo-plan.interface';
import { Catalogo } from '@interfaces/interface-bakend/shared/catalogo/catalogo.interface';
import { SignoPlanService } from '@services/entidades/signo-planes.service';
import { CatalogoService } from '@services/shared/catalogo.service';
import { SignoLocalidadService } from 'src/app/services/entidades/signo-localidades.service';
import { CartillaService } from '../../../../services/cartilla/cartilla.service';
import { SignoSedeSearch } from '@interfaces/interface-bakend/cartilla/cartilla.interface';
import { MatDialog } from '@angular/material/dialog';
import { CentrosCardComponent } from './centros-card/centros-card.component';
import { SignoCentroMedico } from '@interfaces/interface-bakend/entidades/signo-centro-medico.interface';
import Swal from 'sweetalert2';
import { SignoCentroMedicoService } from '@services/entidades/signo-centros-medicos.service';
import { User } from '../../../../interfaces/interface-bakend/seguridad/users/user.interface';
import { AuthService } from '@services/auth/auth.service';

@Component({
  selector: 'app-cartilla-buscador',
  templateUrl: './cartilla-buscador.component.html',
  styleUrls: ['./cartilla-buscador.component.scss']
})
export class CartillaBuscadorComponent implements OnInit {
  form: FormGroup;
  centroControl = new FormControl();
  filteredCentros!: Observable<SignoCentroMedico[]>;

  btnMostrarMiCartilla: boolean = true;
  btnMostrarBusquedaCercania: boolean = true;
  btnMostrarBusquedaNombre: boolean = true;
  btnMostrarBusquedaEspecialidad: boolean = true;

  mostrarBusquedaEspecialidad: boolean = false;
  mostrarBusquedaCentro: boolean = false;
  mostrarBusquedaRubro: boolean = false;
  mostrarBusquedaZona: boolean = false;
  mostrarBusquedaPlan: boolean = false;
  mostrarBusquedaLocalidad: boolean = false;

  btnForm: boolean = false;

  planes: SignoPlan[] = [];
  rubros: Catalogo[] = [];
  especialidades: Catalogo[] = [];
  zonas: Catalogo[] = [];
  localidades: SignoLocalidad[];
  centrosPropios: Catalogo[];
  centrosMedicos: SignoCentroMedico[];
  centrosCercanos: Catalogo[];
  flagCargando = true;
  flagBuscando = false;

  signoSedeSearch: SignoSedeSearch;
  botonPresionado: string;

  resultadosCentros: SignoSedeSearch[];
  sinResultados: boolean = false;
  userLocation: { latitude: number, longitude: number } = { latitude: 0, longitude: 0 };

  usuario: User;

  constructor(
    private signoPlanService: SignoPlanService,
    private catalogoService: CatalogoService,
    private signoLocalidadesService: SignoLocalidadService,
    private cartillaService: CartillaService,
    private signoCentroMedicoService: SignoCentroMedicoService,
    private authService: AuthService,
    public dialog: MatDialog
  ) { }

  async ngOnInit(): Promise<void> {
    this.usuario = await this.authService.getUserLogon();
    this.createForm();
    this.getcargaSelectores();
    this.getGeo();

    this.filteredCentros = this.centroControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filterCentros(value))
    );
  }

  async getcargaSelectores() {
    await this.getCentrosPropios();
    await this.getCentrosMedicos();
    await this.getPlanes();
    await this.getZonas();
    await this.getRubros();
    await this.getLocalidades();
    await this.getEspecialidades();
    this.flagCargando = false;
  }

  createForm() {
    this.form = new FormGroup({
      plan: new FormControl(''),
      rubro: new FormControl(''),
      especialidad: new FormControl(''),
      zona: new FormControl(''),
      localidad: new FormControl(''),
      centro: this.centroControl
    });
    this.setDefaultPlan(); // Establecer el plan por defecto al crear el formulario
  }

  setDefaultPlan() {
      const planDefault = this.usuario?.signoGecrosAfiliado?.plan;
      if (planDefault) {
          const planObj = this.planes.find(plan => plan.denominacion.toLowerCase() === planDefault.toLowerCase());
          if (planObj) {
              this.form.get('plan').setValue(planObj._id);
          }
      }
  }


  noMostrarCampos() {
    this.mostrarBusquedaPlan = false;
    this.mostrarBusquedaZona = false;
    this.mostrarBusquedaCentro = false;
    this.mostrarBusquedaRubro = false;
    this.mostrarBusquedaEspecialidad = false;
    this.mostrarBusquedaLocalidad = false;
    this.btnForm = false;
  }

  mostrarCampos(campos: string[], boton: string) {
    // Reiniciar el formulario
    this.form.reset();
    this.setDefaultPlan(); // Restablecer el plan por defecto

    this.botonPresionado = boton;

    // Oculta los demás formularios en función del botón presionado
    switch (boton) {
      case 'CARTILLA':
        this.btnMostrarMiCartilla = true;
        this.btnMostrarBusquedaCercania = false;
        this.btnMostrarBusquedaNombre = false;
        this.btnMostrarBusquedaEspecialidad = false;

        this.mostrarBusquedaPlan = true;
        this.mostrarBusquedaZona = true;
        this.mostrarBusquedaCentro = true;
        this.mostrarBusquedaRubro = true;
        this.mostrarBusquedaEspecialidad = true;
        this.mostrarBusquedaLocalidad = true;
        break;
      case 'CERCANIA':
        this.btnMostrarMiCartilla = false;
        this.btnMostrarBusquedaCercania = true;
        this.btnMostrarBusquedaNombre = false;
        this.btnMostrarBusquedaEspecialidad = false;

        this.mostrarBusquedaPlan = true;
        this.mostrarBusquedaZona = false;
        this.mostrarBusquedaCentro = false;
        this.mostrarBusquedaRubro = true;
        this.mostrarBusquedaEspecialidad = true;
        this.mostrarBusquedaLocalidad = false;
        break;
      case 'NOMBRE':
        this.btnMostrarMiCartilla = false;
        this.btnMostrarBusquedaCercania = false;
        this.btnMostrarBusquedaNombre = true;
        this.btnMostrarBusquedaEspecialidad = false;

        this.mostrarBusquedaPlan = true;
        this.mostrarBusquedaZona = true;
        this.mostrarBusquedaCentro = true;
        this.mostrarBusquedaRubro = true;
        this.mostrarBusquedaEspecialidad = false;
        this.mostrarBusquedaLocalidad = true;
        break;
      case 'ESPECIALIDAD':
        this.btnMostrarMiCartilla = false;
        this.btnMostrarBusquedaCercania = false;
        this.btnMostrarBusquedaNombre = false;
        this.btnMostrarBusquedaEspecialidad = true;

        this.mostrarBusquedaPlan = true;
        this.mostrarBusquedaZona = true;
        this.mostrarBusquedaCentro = false;
        this.mostrarBusquedaRubro = true;
        this.mostrarBusquedaEspecialidad = true;
        this.mostrarBusquedaLocalidad = true;
        break;
      default:
        break;
    }
    this.btnForm = true;
  }

  buscarDoctores() {
    const formData = this.form.value;
    // Lógica para la búsqueda de doctores
  }

  restaurarBotones() {
    this.sinResultados = false;
    this.flagBuscando = false;
    this.resultadosCentros = [];
    this.btnMostrarMiCartilla = true;
    this.btnMostrarBusquedaCercania = true;
    this.btnMostrarBusquedaNombre = true;
    this.btnMostrarBusquedaEspecialidad = true;
    this.noMostrarCampos();
    // Lleva al usuario al inicio del HTML
    window.scrollTo(0, 0);
  }

  async getPlanes() {
    this.planes = await this.signoPlanService.getPlanes();
  }

  async getRubros() {
    this.rubros = await this.catalogoService.findDescendantsByKey(EntidadesKeys.RUBROS);
  }

  async getEspecialidades() {
    this.especialidades = await this.catalogoService.findDescendantsByKey(EntidadesKeys.ESPECIALIDADES);
  }

  async getZonas() {
    this.zonas = await this.catalogoService.findDescendantsByKey(EntidadesKeys.ZONAS);
  }

  async getLocalidades() {
    this.localidades = await this.signoLocalidadesService.getLocalidades();
  }

  async getCentrosPropios() {
    this.centrosPropios = await this.catalogoService.findDescendantsByKey(EntidadesKeys.CENTROS_PROPIOS);
  }

  async getCentrosMedicos() {
    this.centrosMedicos = await this.signoCentroMedicoService.getCentroMedicos();
    console.log(' this.centrosMedicos', this.centrosMedicos);
  }

  async cartillaBusqueda(data: SignoSedeSearch, botonBusqueda: string) {
    if (botonBusqueda == 'CERCANIA') {
      return this.filtrarCentrosCercanos(await this.cartillaService.getBusquedaNomenclador(data));
    }
    return await this.cartillaService.getBusquedaNomenclador(data);
  }

  async busquedaNomenclador() {
    try {
      // Reiniciar el objeto de búsqueda
      this.signoSedeSearch = {};

      this.resultadosCentros = [];

      this.flagBuscando = true;

      // Obtener los valores del formulario
      const formData = this.form.value;

      // el boton es el presionado
      const boton = this.botonPresionado;

      // Asignar valores al objeto this.signoSedeSearch según el botón presionado
      switch (boton) {
        case 'CARTILLA':
          this.signoSedeSearch.plan = formData.plan;
          this.signoSedeSearch.rubro = formData.rubro;
          this.signoSedeSearch.especialidad = formData.especialidad;
          this.signoSedeSearch.zona = formData.zona;
          this.signoSedeSearch.localidad = formData.localidad;
          break;
        case 'CERCANIA':
          this.signoSedeSearch.plan = formData.plan;
          this.signoSedeSearch.rubro = formData.rubro;
          this.signoSedeSearch.especialidad = formData.especialidad;
          break;
        case 'NOMBRE':
          this.signoSedeSearch.plan = formData.plan;
          this.signoSedeSearch.rubro = formData.rubro;
          this.signoSedeSearch.nombre = formData.centro;
          this.signoSedeSearch.localidad = formData.localidad;
          break;
        case 'ESPECIALIDAD':
          this.signoSedeSearch.plan = formData.plan;
          this.signoSedeSearch.rubro = formData.rubro;
          this.signoSedeSearch.zona = formData.zona;
          this.signoSedeSearch.nombre = formData.centro;
          this.signoSedeSearch.especialidad = formData.especialidad;
          break;
        default:
          break;
      }

      // Realizar la búsqueda
      const data: any = await this.cartillaBusqueda(this.signoSedeSearch, boton);

      if (data && data.length > 0) {
        // Si hay resultados, asignarlos a resultadosCentros
        this.resultadosCentros = data;
        this.flagBuscando = false;
        this.sinResultados = false;
      } else {
        this.sinResultados = true;
        this.flagBuscando = false;
        console.log('No se encontraron resultados');
      }

    } catch (error) {
      console.error('Error en la búsqueda:', error);
    }
  }

  openMap(centro: SignoCentroMedico): void {
    const dialogRef = this.dialog.open(CentrosCardComponent, {
      width: '1200px',
      data: centro
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  // Función para obtener la ubicación del usuario
  getGeo() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        this.userLocation.latitude = position.coords.latitude;
        this.userLocation.longitude = position.coords.longitude;
        console.log('Tu ubicación:', position.coords.latitude, position.coords.longitude);
      }, error => {
        console.error('Error al obtener la ubicación:', error);
      });
    } else {
      console.error('Geolocalización no es compatible con este navegador.');
    }
  }

  // Función para filtrar los centros más cercanos y mostrar un mensaje si no hay
  filtrarCentrosCercanos(resultadosCentros: SignoCentroMedico[]) {
    const distanciaMaxima = 10; // Definir la distancia máxima en kilómetros

    // Filtrar los centros que están dentro de la distancia máxima
    const centrosCercanos = resultadosCentros.filter(centro => {
      const distancia = this.calcularDistancia(this.userLocation.latitude, this.userLocation.longitude, centro.latitud, centro.longitud);
      return distancia <= distanciaMaxima;
    });

    // Mostrar mensaje si no hay centros cercanos
    if (centrosCercanos.length === 0) {
      this.mostrarMensajeSinCentros();
    }

    return centrosCercanos;
  }

  // Función para calcular la distancia entre dos puntos en un plano cartesiano
  calcularDistancia(lat1: number, lon1: number, lat2: number, lon2: number) {
    const R = 6371; // Radio de la Tierra en kilómetros
    const dLat = this.deg2rad(lat2 - lat1);
    const dLon = this.deg2rad(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c; // Distancia en kilómetros
    return d;
  }

  // Función auxiliar para convertir grados a radianes
  deg2rad(deg: number) {
    return deg * (Math.PI / 180);
  }

  mostrarMensajeSinCentros() {
    Swal.fire({
      icon: 'info',
      title: 'No hay centros cercanos',
      text: 'No se encontraron centros médicos cercanos a tu ubicación actual.'
    });
  }

  private _filterCentros(value: string): SignoCentroMedico[] {
    const filterValue = value.toLowerCase();
    return this.centrosMedicos.filter(centro => centro.nombre.toLowerCase().includes(filterValue));
  }
}
