import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { GuiMsjService } from 'src/app/services/shared/gui-msj.service';
import { Catalogo } from '../../../../../interfaces/interface-bakend/shared/catalogo/catalogo.interface';
import { CatalogoDinamico } from '../../../../../interfaces/interface-bakend/shared/catalogo/catalogo-dinamico.interface';
import { CatalogoService } from '../../../../../services/shared/catalogo.service';
import { EntidadesKeys } from 'src/app/enum/entidades-keys.enum';
import { PerfilesService } from 'src/app/services/perfiles/perfiles.service';

@Component({
    selector: 'app-especialidad-alta',
    templateUrl: './especialidad-alta.component.html',
    styleUrls: ['./especialidad-alta.component.scss'],
})
export class EspecialidadAltaComponent implements OnInit {
    form: FormGroup;
    catalogo: CatalogoDinamico;
    rubros: Catalogo[];

    constructor(
        private router: Router,
        private catalogoService: CatalogoService,
        public guiMsjService: GuiMsjService
    ) {
        this.getRubros();
    }

    ngOnInit(): void {
        this.getCatalogo();
        this.createForm();
    }

    get especialidadNoValida() {
        return (
            this.form.get('especialidad').invalid &&
            this.form.get('especialidad').touched
        );
    }

    get rubrosNoSeleccionado() {
        return (
            this.form.get('rubro').invalid &&
            this.form.get('rubro').touched
        );
    }

    async getCatalogo() {
        this.catalogo = await this.catalogoService.getFindByKey(EntidadesKeys.ESPECIALIDADES);
    }

    async getRubros() {
        this.catalogoService
            .findDescendantsByKey(EntidadesKeys.RUBROS)
            .then((rubros: Catalogo[]) => {
                this.rubros = rubros;
            });
    }

    createForm() {
        this.form = new FormGroup({
            especialidad: new FormControl('', [
                Validators.required,
                Validators.min(3),
            ]),
            rubro: new FormControl('', [Validators.required]),
            activa: new FormControl(false, [Validators.required]),
        });
    }

    async setDataForma(): Promise<CatalogoDinamico> {
        const data: CatalogoDinamico = {
            key: null,
            concepto: this.form.value.especialidad,
            logico_1: this.form.value.activa,
            parent: this.catalogo._id,
            properties: {
                rubro: this.form.value.rubro,
            },
        };

        return data;
    }

    async post() {
        await this.guiMsjService.msjFormSubmit('Guardando');
        this.catalogoService.post(await this.setDataForma());
        await this.guiMsjService.msjFormSubmit('DatosGuardados');
        this.router.navigateByUrl('/especialidades-listado');
    }
}
