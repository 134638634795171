import { Injectable, Inject } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Response } from '../../interfaces/interface-bakend/response';
import { Solicitud, State, SolicitudSearch, SolicitudSearchElement } from '../../interfaces/interface-bakend/ofertas-laborales/solicitudes.interface';
import { StorageService } from '../shared/storage.service';
import { GuiMsjService } from '../shared/gui-msj.service';
import { User } from '../../interfaces/interface-bakend/seguridad/users/user.interface';
import { IAppOfertas, APP_CONFIG_OFERTAS } from '../../app.config/app.config-ofertas';
import { CardData } from '../../interfaces/interface-frontend/shared/cardData.interface';
import { AuthService } from '../auth/auth.service';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { Actividad } from 'src/app/interfaces/interface-bakend/ofertas-laborales/actividad.interface';
import { Curso } from 'src/app/interfaces/interface-bakend/cursos/curso.interface';

const URL_BACKEND = environment.urlBackend;

@Injectable({
    providedIn: 'root'
})
export class SolicitudesService {

    solicitud: Solicitud;
    estado: number;
    token: any;

    private _state = new Subject<any>();


    constructor(
        @Inject(APP_CONFIG_OFERTAS) private iAppOfertas: IAppOfertas,
        private httpClient: HttpClient,
        private storageService: StorageService,
        private guiMsjService: GuiMsjService,
        private authService: AuthService,
        private router: Router,
    ) { }

    setNewState(solicitud: Solicitud) {
        this._state.next({ state: solicitud });
    }

    getsetNewStater(): Observable<any> {
        return this._state.asObservable();
    }

    enviarDocumentacion(solicitud: Solicitud, user: User): Promise<Solicitud> {
        return new Promise(async resolve => {

            const data = {
                _idSolicitud: solicitud._id,
                _idUser: user._id,
            };

            const headers = new HttpHeaders({
                'x-token': await this.authService.loadJwToken(),
            });

            this.httpClient.post<Response>(`${URL_BACKEND}/solicitudes/sendDocumentsJobOffer/`, data, { headers })
                .subscribe((resp: Response) => {
                    resolve(resp.response);
                }, error => {
                    this.guiMsjService.msjFormSubmit('documentosEnviadosERROR');
                    console.log('error', error);
                });
        });
    }

    updateSolicitud(_id: string, solicitud: Solicitud): Promise<Solicitud> {
        return new Promise(async resolve => {

            const headers = new HttpHeaders({
                'x-token': await this.authService.loadJwToken(),
            });

            // Cast titular, beneficiario, usuario a string
            const updatedSolicitud = {
                ...solicitud,
                titular: typeof solicitud.titular === 'object' ? solicitud.titular._id : (solicitud.titular as string)?.toString() || '',
                beneficiario: typeof solicitud.beneficiario === 'object' ? solicitud.beneficiario._id : (solicitud.beneficiario as string)?.toString() || '',
                usuario: (solicitud.usuario as string)?.toString() || ''
            };

            this.httpClient.put<Response>(`${URL_BACKEND}/solicitudes/updateOne/${_id}`, updatedSolicitud, { headers })
                .subscribe((resp: Response) => {
                    resolve(resp.response);
                }, error => {
                    console.log('error', error);
                });
        });
    }

    updateSolicitudLeido(_id: string, solicitud: Solicitud): Promise<Solicitud> {
        return new Promise(async resolve => {

            const headers = new HttpHeaders({
                'x-token': await this.authService.loadJwToken(),
            });

            // Cast titular, beneficiario, usuario a string
            const updatedSolicitud = {
                _id: solicitud._id,
                leido: solicitud.leido,
                pais: solicitud.pais,
                actividad: solicitud.actividad,
            };

            this.httpClient.put<Response>(`${URL_BACKEND}/solicitudes/updateOne/${_id}`, updatedSolicitud, { headers })
                .subscribe((resp: Response) => {
                    resolve(resp.response);
                }, error => {
                    console.log('error', error);
                });
        });
    }



    // tslint:disable-next-line:max-line-length
    updateSolicitudState(_id: string, stateNumber: number, notificaAdmin: boolean,  state?: string, notificar?: boolean, observaciones?: string, file?: string ): Promise<Solicitud> {
        return new Promise(async resolve => {

            const data = {
                numberState: stateNumber,
                state: state || '',
                notificar: notificar || false,
                observaciones: observaciones || '',
                file: file || '',
                solicitud: _id,
                notificaAdmin: notificaAdmin || false,
            };

            const headers = new HttpHeaders({
                'x-token': await this.authService.loadJwToken(),
            });

            // tslint:disable-next-line:max-line-length
            this.httpClient.post<Response>(`${URL_BACKEND}/solicitudes/addNewState/`, data, { headers })
                .subscribe((resp: Response) => {
                    resolve(resp.response);
                }, error => {
                    console.log('error', error);
                });
        });
    }

    async getSolicitudesByUser(idUser: string): Promise<Solicitud[]> {
        return new Promise(async resolve => {

            const headers = new HttpHeaders({
                'x-token': await this.authService.loadJwToken(),
            });

            this.httpClient.get<Response>(`${URL_BACKEND}/solicitudes/findByIdUser/${idUser}`, { headers })
                .subscribe(resp => {
                    resolve(resp.response);
                });
        });
    }

    async getSolicitudById(id: string): Promise<Solicitud> {
        return new Promise(async resolve => {

            const headers = new HttpHeaders({
                'x-token': await this.authService.loadJwToken(),
            });

            this.httpClient.get<Response>(`${URL_BACKEND}/solicitudes/findOne/${id}`, { headers })
                .subscribe(resp => {
                    resolve(resp.response);
                });
        });
    }

    async getSolicitudesByEstado(estado: number): Promise<Solicitud[]> {
        return new Promise(async resolve => {

            const headers = new HttpHeaders({
                'x-token': await this.authService.loadJwToken(),
            });

            this.httpClient.get<Response>(`${URL_BACKEND}/solicitudes/findByStatus/${estado}`, { headers })
                .subscribe(resp => {
                    resolve(resp.response);
                });
        });
    }

    async getSolicitudesBySede(sede: any, fecha: any): Promise<Solicitud[]> {
        return new Promise(async resolve => {

            const data = {
                signoSedes: sede.signoSedes,
                fechaDesde: fecha.fechaDesde,
                fechaHasta: fecha.fechaHasta
            };

            console.log('data', data);

            

            const headers = new HttpHeaders({
                'x-token': await this.authService.loadJwToken(),
            });

            this.httpClient.post<Response>(`${URL_BACKEND}/solicitudes/findAllBySede/`, data, { headers })
                .subscribe(resp => {
                    resolve(resp.response);
                });
        });
    }

    postCreateSolicitud(solicitud: Solicitud): Promise<Solicitud> {

        return new Promise(async resolve => {

            const headers = new HttpHeaders({
                'x-token': await this.authService.loadJwToken(),
            });

            this.httpClient.post<Response>(`${URL_BACKEND}/solicitudes/`, solicitud, { headers }).subscribe(resp => {
                resolve(resp.response);
            }, error => {
                console.log('error', error);
                this.guiMsjService.msjFormSubmit('crearSolicitudError');
            });
        });
    }

    async postComprarSolicitud(solicitud: Solicitud): Promise<Solicitud> {
        const headers = new HttpHeaders({
            'x-token': await this.authService.loadJwToken(),
        });

        return new Promise(async resolve => {
            await this.httpClient.post<Response>
                (`${URL_BACKEND}/solicitudes/comprarSolicitud`, solicitud, { headers }).subscribe(resp => {
                    resolve(resp.response);
                }, error => {
                    this.guiMsjService.msjFormSubmit('crearSolicitudError');
                    this.router.navigateByUrl('/', { skipLocationChange: true }).then(async () => {
                        await this.router.navigateByUrl('/');
                    });
                });
        });

    }

    async create(solicitud: Solicitud): Promise<Solicitud> {

        const headers = new HttpHeaders({
            'x-token': await this.authService.loadJwToken(),
        });

        return new Promise(async resolve => {
            await this.httpClient.post<Response>
                (`${URL_BACKEND}/solicitudes`, solicitud, { headers }).subscribe(resp => {
                    resolve(resp.response);
                }, error => {
                    this.guiMsjService.msjFormSubmit('crearSolicitudError');
                    this.router.navigateByUrl('/', { skipLocationChange: true }).then(async () => {
                        await this.router.navigateByUrl('/');
                    });
                });
        });
    }

    async getSolicitudesByFilter(inscripcion: string, estados: number[]): Promise<SolicitudSearchElement[]> {
        return new Promise(async resolve => {

            const headers = new HttpHeaders({
                'x-token': await this.authService.loadJwToken(),
            });

            let actividades = []
            actividades.push(inscripcion);
            const data: SolicitudSearch = {
                actividades: actividades,
                states: estados,
            }

            this.httpClient.post<Response>(`${URL_BACKEND}/solicitudes/findBySearchConditions/`, data, { headers })
                .subscribe(resp => {
                    resolve(resp.response);
                });
        });
    }

    async solicitudEnviarDocumentosYnotificar(idSolicitud: string, observacion: string): Promise<boolean> {
        try {
            const headers = new HttpHeaders({
                'x-token': await this.authService.loadJwToken(),
            });
            
            // Incluye la observación en el cuerpo de la petición
            const body = {
                observacion  // Envío de la observación
            };
            
            const response = await this.httpClient.post<{ success: boolean }>(
                `${URL_BACKEND}/solicitudes/solicitudEnviarDocumentosYnotificar/${idSolicitud}`, // El idSolicitud va en la URL
                body, // Ahora enviamos el body con la observación
                { headers }
            ).toPromise();
        
            return response.success;
        } catch (error) {
            console.error('Error en solicitudEnviarDocumentosYnotificar', error);
            this.guiMsjService.msjFormSubmit('Error en solicitudEnviarDocumentosYnotificar');
            return false; // Manejo del error y retorno de false
        }
    }
    

    setSolicitudSeleccionada(solicitud: Solicitud) {
        this.solicitud = solicitud;
        this.storageService.setSolicitud(solicitud);
    }

    getSolicitudSeleccionada() {
        return this.solicitud;
    }

    cleanPais() {
        this.solicitud = null;
    }

    getEstadoSolicitudSeleccionado() {
        // falta storageService
        return this.estado;
    }

    setEstadoSolicitud(estado: number) {
        // falta storageService
        this.estado = estado;
    }

    cleanEstado() {
        // falta storageService
        this.estado = null;
    }

}
