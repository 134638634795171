import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Catalogo } from '../../../../../interfaces/interface-bakend/shared/catalogo/catalogo.interface';
import { CatalogoService } from '../../../../../services/shared/catalogo.service';
import { EntidadesKeys } from 'src/app/enum/entidades-keys.enum';
import { GuiMsjService } from 'src/app/services/shared/gui-msj.service';
import Swal from 'sweetalert2';
import { PerfilesService } from 'src/app/services/perfiles/perfiles.service';
import { AuthService } from '@services/auth/auth.service';

@Component({
    selector: 'app-zonas-listado',
    templateUrl: './zonas-listado.component.html',
    styleUrls: ['./zonas-listado.component.scss'],
})
export class ZonasListadoComponent implements OnInit, AfterViewInit {
    @ViewChild('previousRoute') previousRouteLink : ElementRef;

    /* Data Table*/
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    displayedColumns: string[] = ['zona', 'activo', '_id'];
    dataSource: MatTableDataSource<Catalogo>;
    zonas: Catalogo[] = [];
    /* Fin Data Table*/

    flagLoaded: boolean = false;
    keyPerfil: string = '';

    constructor(
        private catalogoService: CatalogoService,
        private guiMsjService: GuiMsjService,
        private perfilesService: PerfilesService,
        private router: Router,
        private authService: AuthService,
    ) { }

    ngOnInit(): void {
        this.getPerfil().then(() => {
            this.perfilesService.allowedByUserLogon(this.router.url,  this.previousRoute, this.keyPerfil);
        });
        this.loadDataPage();
    }

    async ngAfterViewInit() {
        await this.loadDataPage();
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
    }

    get previousRoute() {
        const previousRoute = this.previousRouteLink.nativeElement.getAttribute('href');
        return previousRoute;
    }

    async getPerfil() {
        try {
            const user = await this.authService.getUserLogon();
            if (user) {
                const { key } = user?.perfil
                this.keyPerfil = key;
            }
        }
        catch (error) {
            console.log('ABM - Error al obtener el perfil del usuario', error);
            return '';
        }
    }

    async verZona(_id: string) {
        await this.guiMsjService.msjFormSubmit('Espere');
        Swal.showLoading();
        const zona: Catalogo = await this.catalogoService.getFindOne(_id);
        if (zona !== undefined) {
            this.router.navigateByUrl('/zona-modificacion');
            this.catalogoService.setConcepto(zona);
        }
        else {
            Swal.close();
        }
    }

    async loadDataTable() {
        await this.catalogoService
            .findDescendantsByKey(EntidadesKeys.ZONAS)
            .then((zonas: Catalogo[]) => {
                this.dataSource = new MatTableDataSource(zonas);
                this.dataSource.sort = this.sort;
                this.dataSource.paginator = this.paginator;
            });
    }

    applyFilter(event: Event) {
        const filterValue = (event?.target as HTMLInputElement)?.value.trim().toLowerCase();
        const customFilter = (data: any, filter: string): boolean => {
            // Verifica si el valor del filtro está en las columnas
            return (
                data.concepto.toLowerCase().includes(filter) ||
                (data.logico_1 ? 'Si' : 'No').toLowerCase().includes(filter)
            );
        };

        // Aplica el filtro personalizado
        this.dataSource.filter = filterValue;
        this.dataSource.filterPredicate = customFilter;
    }

    async loadDataPage() {
        await this.loadDataTable();
        this.flagLoaded = true;
    }
}
