import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from '../../services/auth/auth.service';
import { NavigationService } from '../../services/shared/navigation.service';
import { MenuesService } from 'src/app/services/menues/menues.service';
import { User } from 'src/app/interfaces/interface-bakend/seguridad/users/user.interface';
import { Menu } from '../../interfaces/interface-bakend/seguridad/acl/menu.interface';
import { DiccionarioKeys } from '@enum/idiomas-diccionario-keys.enum';
import { TiposWebKeys } from '@enum/tiposweb-keys.enum';
import { IdiomaKeys } from '@enum/idiomas-diccionario-keys.enum';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  classApplied = false;

  menuDescendats: [] = [];
  usuario: User;
  menues: Menu[] = [];
  buildMenu: any[] = [];

  flagMenuLoaded = false;

  constructor(
    private authservice: AuthService,
    private navigation: NavigationService,
    private menuesService: MenuesService,
  ) { }

  ngOnInit(): void {
    this.cargarMenuPerfil();
  }

  logout() {
    this.authservice.logout();
  }

  toggleClass() {
    this.classApplied = !this.classApplied;
  }

  back(): void {
    this.navigation.back();
  }

  async cargarMenuPerfil() {
    try {
      this.usuario = await this.authservice.getUserLogon();
      this.menues = await this.menuesService.findRootNodes(TiposWebKeys.WEB, DiccionarioKeys.I18NFILE, IdiomaKeys.ES);
      const promises = this.menues.map(async (menu: any) => {
        const descendants = this.menuesService.getFindInmediateDescendants(menu._id, DiccionarioKeys.I18NFILE, IdiomaKeys.ES);
        return {
          menu,
          descendants: await descendants,
        };
      });

      this.buildMenu = await Promise.all(promises);
      this.flagMenuLoaded = true;
    } catch (error) {
      console.error('Navbar - Error al cargar el menú del perfil', error);
    }
  }

}
