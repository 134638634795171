import { URL_LABELS } from './../../app.config/routes/url-labels.routes';
import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PreviousRouteService {
  private previousRoutes: { url: string, label: string }[] = [{ url: '/', label: 'Inicio' }];
  private routes$: BehaviorSubject<{ url: string, label: string }[]>;
  private currentUrl: string;

  constructor(private router: Router) {
    this.saveRoutes();
  }

  private saveRoutes() {
    this.currentUrl = this.router.url;
    this.routes$ = new BehaviorSubject(this.previousRoutes);
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {

      const currentLabel = this.getLabelFromUrl(event.url);
      if (event.url !== this.currentUrl && !this.previousRoutes.some(route => route.url === event.url) && currentLabel !== '') {

        // Si el usuario está navegando hacia adelante, agregar la nueva ruta 
        this.previousRoutes.push({ url: event.url, label: currentLabel });
        if (this.previousRoutes.length > 4 && this.previousRoutes[0].url === '/') {
          this.previousRoutes.splice(1, 1);
        } else {
          if (this.previousRoutes.length > 4 && this.previousRoutes[0].url !== '/') {
            this.previousRoutes.shift();
          }
        }

        // Si el usuario está navegando hacia atrás, obtengo la poscion de la ruta y elimino todas las rutas hacia adelante 
        const index = this.previousRoutes.findIndex(route => route.url === event.url);
        if (index !== -1) {
          this.previousRoutes.splice(index + 1);
        }

      }
      if (event.url === '/') {
        this.resetRoutes();
      }
      this.routes$.next(this.previousRoutes);
      this.currentUrl = event.url;
    });
  }


  public resetRoutes(): void {
    this.previousRoutes = [{ url: '/', label: 'Inicio' }];
    this.routes$.next(this.previousRoutes);
  }

  private getLabelFromUrl(url: string): string {
    return URL_LABELS[url] || '';
  }

  public getRoutes(): Observable<{ url: string, label: string }[]> {
    return this.routes$;
  }

}