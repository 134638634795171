<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li *ngFor="let route of routes">
                    <a routerLink="{{route.url}}">{{route.label}}</a>
                </li>
            </ul>
            <h2>Modificar Afiliado</h2>
            <h2 *ngIf="usuario">{{usuario?.nombrePersona}} {{usuario?.apellidoPersona}}</h2>
        </div>
    </div>
</div>

<app-loading *ngIf="!flagLoaded"
             class="m-5"></app-loading><br>
              
<div class="andorra-apply-content ptb-70  animate__animated animate__fadeIn">
    <div class="container"
         *ngIf="flagLoaded">
        <div class="profile-courses-quizzes">
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="apply-form">
                        <div class="card-header">
                            <h3 class="text-center text-info"
                                *ngIf="usuario">
                                {{usuario?.nombrePersona}} {{usuario?.apellidoPersona}}</h3>
                        </div>
                        <br>
                        <mat-progress-bar *ngIf='flagProcesando'
                                          mode="indeterminate"></mat-progress-bar>
                        <br>

                          <div>
                            <button (click)="mostrarDatosGecros()" class="btn btn-primary">Mostrar Datos Gecros</button>
                          </div>

                         <br>

                          
                          <div *ngIf="mostrarCard">
                            <div class="card">
                              <div class="card-header">
                                DATOS GECROS
                              </div>
                              <div class="card-body">
                                <ul>
                                  <li><strong>ID:</strong> {{ usuario.signoGecrosAfiliado._id }}</li>
                                  <li><strong>Nombre Afiliado:</strong> {{ usuario.signoGecrosAfiliado.nombreAfiliado }}</li>
                                  <li><strong>CUIL:</strong> {{ usuario.signoGecrosAfiliado.cuil }}</li>
                                  <li><strong>Número de Afiliado:</strong> {{ usuario.signoGecrosAfiliado.numeroAfiliado }}</li>
                                  <li><strong>Sexo:</strong> {{ usuario.signoGecrosAfiliado.sexo }}</li>
                                  <li><strong>Estado:</strong> {{ usuario.signoGecrosAfiliado.estado }}</li>
                                  <li><strong>Convenio:</strong> {{ usuario.signoGecrosAfiliado.convenio }}</li>
                                  <li><strong>Plan:</strong> {{ usuario.signoGecrosAfiliado.plan }}</li>
                                  <li><strong>Fecha de Nacimiento:</strong> {{ usuario.signoGecrosAfiliado.fechaNacimiento }}</li>
                                  <li><strong>Legajo:</strong> {{ usuario.signoGecrosAfiliado.legajo }}</li>
                                  <li><strong>Email:</strong> {{ usuario.signoGecrosAfiliado.email }}</li>
                                  <li><strong>Agente Cuenta:</strong> {{ usuario.signoGecrosAfiliado.agenteCuenta }}</li>
                                  <li><strong>Parentesco:</strong> {{ usuario.signoGecrosAfiliado.parentesco }}</li>
                                  <li><strong>Observaciones:</strong> {{ usuario.signoGecrosAfiliado.observaciones }}</li>
                                  <li><strong>Medico Cabecera:</strong> {{ usuario.signoGecrosAfiliado.medicoCabecera }}</li>
                                  <li><strong>Coberturas:</strong> {{ usuario.signoGecrosAfiliado.coberturas }}</li>
                                  <li><strong>Created At:</strong> {{ usuario.signoGecrosAfiliado.createdAt }}</li>
                                  <li><strong>Updated At:</strong> {{ usuario.signoGecrosAfiliado.updatedAt }}</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          
                        <ngx-tabset>
                            <ngx-tab tabTitle="Información Básica">
                                <form [formGroup]="formAfiliado"
                                      (ngSubmit)="onSubmit()">
                                    <div class="row">
                                        <div class="col-lg-6 col-md-6 col-sm-6">

                                            <div class="form-group"
                                                 [class.required]="true">
                                                <label>{{ idioma | translate: "form.NOMBRE" |
                                                    async}}</label>
                                                <input formControlName="nombre"
                                                       type="text"
                                                       [placeholder]=" idioma | translate: 'form.placeholders.NOMBRE' | async"
                                                       class="form-control uppercase"
                                                       [class.is-invalid]="nombre"
                                                       required>
                                                <mat-error *ngIf="nombre"
                                                           class="text-danger">Ingrese un nombre</mat-error>
                                            </div>

                                            <div class="form-group"
                                                 [class.required]="true">
                                                <label>{{ idioma | translate: "form.APELLIDO" |
                                                    async}}</label>
                                                <input formControlName="apellido"
                                                       type="text"
                                                       [placeholder]=" idioma | translate: 'form.placeholders.APELLIDO' | async"
                                                       class="form-control uppercase"
                                                       [class.is-invalid]="apellido"
                                                       required>
                                                <mat-error *ngIf="apellido"
                                                           class="text-danger">Ingrese un
                                                    apellido</mat-error>
                                            </div>

                                            <div class="form-group"
                                                 [class.required]="true">
                                                <mat-form-field appearance="fill">
                                                    <label for="tipoIdentificacion">{{ idioma |
                                                        translate: "form.TIPO-ID" |
                                                        async}}</label>
                                                    <mat-select formControlName="tipoIdentificacion"
                                                                (ngModelChange)="onChangeTipoIdentificacion($event);">
                                                        <mat-option *ngFor="let ti of tiposIdentificaciones"
                                                                    value="{{ti.concepto}}"
                                                                    [class.is-invalid]="tipoIdentificacion">{{ti.concepto}}
                                                        </mat-option>
                                                        <mat-error *ngIf="tipoIdentificacion"
                                                                   class="text-danger">Ingrese un tipo de
                                                            identificación</mat-error>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>

                                            <div class="form-group"
                                                 [class.required]="true">
                                                <label>{{ idioma | translate: "form.NRO-ID" |
                                                    async}}</label>
                                                <input formControlName="identificacion"
                                                       type="text"
                                                       [placeholder]=" idioma | translate: 'form.placeholders.ID-NO' | async"
                                                       class="form-control"
                                                       [class.is-invalid]="identificacion">
                                                <mat-error *ngIf="identificacion"
                                                           class="text-danger">Ingrese una
                                                    identificación</mat-error>
                                                <div *ngIf="this.flagExisteIdentificacion">
                                                    <mat-error class="text-danger">{{ idioma |
                                                        translate:
                                                        "form.MSJ-ID-EXISTE" |
                                                        async}}</mat-error>
                                                    <div class="p-3 mb-2 bg-primary text-white">{{
                                                        idioma | translate:
                                                        "form.MSJ-EMAIL-EXISTE" | async}}</div>
                                                </div>
                                            </div>

                                            <div class="form-group"
                                                 [class.required]="true">
                                                <mat-form-field appearance="fill">
                                                    <label for="estadoCivilDataList"
                                                           class="form-label">{{
                                                        idioma | translate:
                                                        "form.ESTADO-CIVIL" | async}}</label>
                                                    <mat-select formControlName="estadoCivil"
                                                                (ngModelChange)="onChangeEstadoCivil($event)">
                                                        <mat-option *ngFor="let estadoCivil of estadosCiviles"
                                                                    value="{{estadoCivil.concepto}}"
                                                                    [class.is-invalid]="estadoCivil">{{estadoCivil.concepto}}</mat-option>
                                                        <mat-error *ngIf="estadoCivil"
                                                                   class="text-danger">Seleccione el estado
                                                            civil</mat-error>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>

                                            <div class="form-group"
                                                 [class.required]="true">
                                                <mat-form-field appearance="fill"
                                                                [style.width.%]=100>
                                                    <label class="form-label">{{
                                                        idioma | translate:
                                                        "form.PLAN" | async}}</label>
                                                    <mat-select formControlName="signoPlan"
                                                                [class.is-invalid]="plan"
                                                                [disabled]="!planes"
                                                                [(ngModel)]="selectedPlan">
                                                        <mat-option *ngFor="let plan of planes"
                                                                    value="{{ plan._id }}">{{
                                                            plan.denominacion
                                                            }}</mat-option>
                                                    </mat-select>
                                                    <mat-error *ngIf="plan"
                                                               class="text-danger">Seleccione
                                                        un plan</mat-error>
                                                </mat-form-field>
                                            </div>
                                        </div>

                                        <div class="col-lg-6 col-md-6">

                                            <div class="form-group"
                                                 [class.required]="true">
                                                <label>{{ idioma | translate:
                                                    "form.FECHA-NACIMIENTO" |
                                                    async}}</label>
                                                <input formControlName="birth"
                                                       type="date"
                                                       class="form-control"
                                                       [placeholder]=" idioma | translate: 'form.placeholders.FORMATO-FECHA' | async"
                                                       [class.is-invalid]="birth"
                                                       required>
                                                <mat-error *ngIf="birth"
                                                           class="text-danger">Ingrese la fecha de
                                                    nacimiento</mat-error>
                                            </div>

                                            <div class="form-group"
                                                 [class.required]="true">
                                                <label for="paisesDataList"
                                                       class="form-label">{{
                                                    idioma
                                                    | translate:
                                                    "form.PAIS-NACIMIENTO" | async}}</label>
                                                <input formControlName="paisNacimiento"
                                                       class="form-control"
                                                       (ngModelChange)="onChangePais($event)"
                                                       list="paises"
                                                       id="datalistPaisesDisponibles"
                                                       [placeholder]=" idioma | translate: 'form.BUSCADOR-TEXT-INPUT' | async"
                                                       [class.is-invalid]="paisNacimiento"
                                                       >
                                                <mat-error *ngIf="paisNacimiento"
                                                           class="text-danger">Ingrese un pais de
                                                    nacimiento</mat-error>
                                                <datalist id="paises">
                                                    <option *ngFor="let pais of paises"
                                                            [value]="pais.name_es">
                                                        {{pais.name_es}}
                                                    </option>
                                                </datalist>
                                            </div>

                                            <div class="form-group"
                                                 [class.required]="true">
                                                <label class="form-label">{{ idioma | translate:
                                                    "form.CODIGO-AREA" |
                                                    async}}</label>
                                                <input formControlName="paisCodeArea"
                                                       class="form-control"
                                                       [placeholder]=" idioma | translate: 'form.placeholders.CODIGO-AREA' | async"
                                                       [class.is-invalid]="paisCodeArea"
                                                       >
                                                <mat-error *ngIf="paisCodeArea"
                                                           class="text-danger">Ingrese un código de
                                                    área</mat-error>
                                            </div>

                                            <div class="form-group"
                                                 [class.required]="true">
                                                <label>{{ idioma | translate: "form.NUMERO-TELEFONO"
                                                    |
                                                    async}}</label>
                                                <div class="input-group">
                                                    <input formControlName="telefono"
                                                           type="text"
                                                           [placeholder]=" idioma | translate: 'form.placeholders.TELEFONO' | async"
                                                           class="form-control"
                                                           [class.is-invalid]="telefono"
                                                           >
                                                    <mat-error *ngIf="telefono"
                                                               class="text-danger">Ingrese un número de
                                                        telefono</mat-error>
                                                </div>
                                            </div>

                                            <div class="form-group"
                                                 [class.required]="true">
                                                <mat-form-field appearance="fill"
                                                                [style.width.%]=100>
                                                    <label class="form-label">{{
                                                        idioma | translate:
                                                        "form.SEDE" | async}}</label>
                                                    <mat-select formControlName="signoSedes"
                                                                [class.is-invalid]="sede"
                                                                [disabled]="!sedes"
                                                                [(ngModel)]="selectedSede"
                                                                required>
                                                        <mat-option *ngFor="let sede of sedes"
                                                                    value="{{ sede._id }}">{{ sede.nombre
                                                            }}</mat-option>
                                                    </mat-select>
                                                    <mat-error *ngIf="sede"
                                                               class="text-danger">Seleccione
                                                        una sede</mat-error>
                                                </mat-form-field>
                                            </div>

                                            <div class="form-group"
                                                 [class.required]="true">
                                                <mat-form-field appearance="fill"
                                                                [style.width.%]=100>
                                                    <label class="form-label">{{
                                                        idioma | translate:
                                                        "form.EMPRESA" | async}}</label>
                                                    <mat-select formControlName="signoEmpresas"
                                                                [class.is-invalid]="empresa"
                                                                [disabled]="!empresas"
                                                                [(ngModel)]="selectedEmpresa"
                                                                >
                                                        <mat-option *ngFor="let empresa of empresas"
                                                                    value="{{ empresa._id }}">{{
                                                            empresa.nombre
                                                            }}</mat-option>
                                                    </mat-select>
                                                    <mat-error *ngIf="empresa"
                                                               class="text-danger">Seleccione
                                                        una empresa</mat-error>
                                                </mat-form-field>
                                            </div>

                                        </div>

                                    </div>

                                    <button [disabled]="!formAfiliado.valid"
                                            [ngClass]="{'custom-disabled': !formAfiliado.valid}"
                                            type="submit"
                                            class="animate__animated animate__fadeIn">GUARDAR</button>
                                    <button routerLink="/afiliados-listado"
                                            class="animate__animated animate__fadeIn">CANCELAR</button>
                                    <br>
                                    <br>
                                </form>
                            </ngx-tab>

                            <!-- Gestio Afiliado -->

                            <ngx-tab tabTitle="Gestión">
                                <form [formGroup]="formAfiliado"
                                      (ngSubmit)="onSubmit()">
                                    <div class="row">

                                        <div class="container overflow-hidden">
                                            <div class="row flex-row-m flex-row-l">
                                                <div class="alert alert-info">
                                                    <strong>¡Información!</strong> Si el usuario al que se
                                                    le cambia de <strong>Estado</strong> es titular, se le
                                                    cambiara el mismo a sus familiares. En el caso de
                                                    <u>suspensión</u>, si un familiar posee alguna clausula,
                                                    quedará excluido.
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-6 col-md-6 col-sm-12 col">

                                            <div class="form-group"
                                                 [class.required]="true">
                                                <mat-form-field appearance="fill"
                                                                [style.width.%]=100>
                                                    <mat-label>Estado</mat-label>
                                                    <mat-select formControlName="estado"
                                                                [class.is-invalid]="estado">
                                                        <mat-option *ngFor="let estado of estados"
                                                                    [value]="estado">{{ estado }}
                                                        </mat-option>
                                                    </mat-select>
                                                    <mat-error *ngIf="estado"
                                                               class="text-danger">Seleccione
                                                        un estado</mat-error>
                                                </mat-form-field>
                                            </div>

                                            <div class="form-group">
                                                <mat-form-field color="accent"
                                                                appearance="fill"
                                                                [style.width.%]=100>
                                                    <mat-label>Fecha de Cobertura</mat-label>
                                                    <input formControlName="fechaCobertura"
                                                           matInput
                                                           [matDatepicker]="picker2"
                                                           autocomplete="off">
                                                    <mat-datepicker-toggle matSuffix
                                                                           [for]="picker2"></mat-datepicker-toggle>
                                                    <mat-datepicker #picker2
                                                                    color="accent"></mat-datepicker>
                                                </mat-form-field>
                                            </div>

                                            <div class="container d-flex flex-column">

                                                <div class="form-group">
                                                    <div class="row d-flex p-3">
                                                        <mat-label>PMI</mat-label>
                                                        <mat-slide-toggle formControlName="pmi"
                                                                          [(ngModel)]="isPMI"
                                                                          (change)="updateValuesPMI($event.checked)"
                                                                          color="{{isPMI ? 'primary' : 'warn'}}">
                                                            {{isPMI ? 'Si' : 'No'}}
                                                        </mat-slide-toggle>
                                                    </div>
                                                </div>

                                                <div class="form-group">
                                                    <div class="row d-flex p-3">
                                                        <mat-label>Discapacidad</mat-label>
                                                        <mat-slide-toggle formControlName="discapacidad"
                                                                          [(ngModel)]="isDisability"
                                                                          (change)="updateValuesDisability($event.checked)"
                                                                          color="{{isDisability ? 'primary' : 'warn'}}">
                                                            {{isDisability ? 'Si' : 'No'}}
                                                        </mat-slide-toggle>
                                                    </div>
                                                </div>

                                                <div class="form-group">
                                                    <div class="row d-flex p-3">
                                                        <mat-label>Otras Cronicidades</mat-label>
                                                        <mat-slide-toggle formControlName="otrasCronicidades"
                                                                          [(ngModel)]="isChronicities"
                                                                          (change)="updateValuesChronicities($event.checked)"
                                                                          color="{{isChronicities ? 'primary' : 'warn'}}">
                                                            {{isChronicities ? 'Si' : 'No'}}
                                                        </mat-slide-toggle>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>

                                        <div class="col-lg-6 col-md-6 col-sm-12 col">

                                            <div class="form-group">
                                                <mat-form-field color="warn"
                                                                appearance="fill"
                                                                [style.width.%]=100>
                                                    <mat-label>Fecha</mat-label>
                                                    <input formControlName="fechaVigencia"
                                                           matInput
                                                           [matDatepicker]="picker1"
                                                           [class.is-invalid]="fechaVigenciaNoValida"
                                                           autocomplete="off">
                                                    <mat-error *ngIf="fechaVigenciaNoValida">Ingrese una
                                                        fecha</mat-error>
                                                    <mat-datepicker-toggle matSuffix
                                                                           [for]="picker1"></mat-datepicker-toggle>
                                                    <mat-datepicker #picker1
                                                                    color="warn"></mat-datepicker>
                                                </mat-form-field>
                                            </div>

                                            <div class="form-group">
                                                <mat-form-field color="accent"
                                                                appearance="fill"
                                                                [style.width.%]=100>
                                                    <mat-label>Fecha de Baja</mat-label>
                                                    <input formControlName="fechaBaja"
                                                           matInput
                                                           [matDatepicker]="picker3"
                                                           [class.is-invalid]="fechaNoValida"
                                                           autocomplete="off">
                                                    <mat-error *ngIf="fechaNoValida">Ingrese una
                                                        fecha</mat-error>
                                                    <mat-datepicker-toggle matSuffix
                                                                           [for]="picker3"></mat-datepicker-toggle>
                                                    <mat-datepicker #picker3
                                                                    color="accent"></mat-datepicker>
                                                </mat-form-field>
                                            </div>

                                            <div class="form-group">
                                                <mat-form-field color="primary"
                                                                appearance="fill"
                                                                [style.width.%]=100>
                                                    <mat-label>Vencimiento PMI</mat-label>
                                                    <input formControlName="fechaVtoPMI"
                                                           matInput
                                                           [matDatepicker]="picker4"
                                                           autocomplete="off">
                                                    <mat-datepicker-toggle matSuffix
                                                                           [for]="picker4"></mat-datepicker-toggle>
                                                    <mat-datepicker #picker4
                                                                    color="primary"></mat-datepicker>
                                                </mat-form-field>
                                            </div>

                                            <div class="form-group">
                                                <mat-form-field color="primary"
                                                                appearance="fill"
                                                                [style.width.%]=100>
                                                    <mat-label>Vencimiento Discapacidad</mat-label>
                                                    <input formControlName="fechaVtoDiscapacidad"
                                                           matInput
                                                           [matDatepicker]="picker5"
                                                           autocomplete="off">
                                                    <mat-datepicker-toggle matSuffix
                                                                           [for]="picker5"></mat-datepicker-toggle>
                                                    <mat-datepicker #picker5
                                                                    color="primary"></mat-datepicker>
                                                </mat-form-field>
                                            </div>

                                            <div class="form-group">
                                                <mat-form-field color="primary"
                                                                appearance="fill"
                                                                [style.width.%]=100>
                                                    <mat-label>Vencimiento Otras Cronicidades</mat-label>
                                                    <input formControlName="fechaVtoOtrasCronicidades"
                                                           matInput
                                                           [matDatepicker]="picker6"
                                                           [class.is-invalid]="fechaNoValidaCronicidades"
                                                           autocomplete="off">
                                                    <mat-error *ngIf="fechaNoValidaCronicidades">Ingrese una
                                                        fecha de discapacidad</mat-error>
                                                    <mat-datepicker-toggle matSuffix
                                                                           [for]="picker6"></mat-datepicker-toggle>
                                                    <mat-datepicker #picker6
                                                                    color="primary"></mat-datepicker>
                                                </mat-form-field>
                                            </div>

                                        </div>

                                    </div>

                                    <button [disabled]="!formAfiliado.valid"
                                            [ngClass]="{'custom-disabled': !formAfiliado.valid}"
                                            type="submit"
                                            class="animate__animated animate__fadeIn">GUARDAR</button>

                                    <button routerLink="/afiliados-listado"
                                            class="animate__animated animate__fadeIn">CANCELAR</button>
                                </form>
                            </ngx-tab>
                        </ngx-tabset>
                    </div>
                </div>
            </div>

            <hr>

            <div class="row mx-auto"
                 *ngIf="flagLoaded">
                <div class="col-lg-12 col-md-12 col-sm-12 ">

                    <div class="card-header">
                        <h3 class="text-center">Grupo Familiar</h3>
                    </div>

                    <app-loading *ngIf="flagCargandoGrupoFamiliar"
                                 class="m-5"></app-loading><br>

                    <br>
                    <a routerLink="/grupofamiliar-alta"><button mat-raised-button
                                mat-button
                                color="primary"><mat-icon aria-hidden="false"
                                      aria-label="alta miembro grupo familiar">add</mat-icon> Nuevo
                            Familiar</button></a>
                    <br>
                    <hr>
                    <br>

                    <div class="profile-courses-quizzes">
                        <div class="table-responsive"
                             *ngIf="flagGrupoFamiliar">

                            <table mat-table
                                   [dataSource]="dataSource"
                                   matSort
                                   class="table-familiar table-striped w-100">

                                <tbody>
                                    <!-- Column -->
                                    <ng-container matColumnDef="nombre">
                                        <th mat-header-cell
                                            *matHeaderCellDef
                                            mat-sort-header> Nombre y Apellido </th>
                                        <td mat-cell
                                            *matCellDef="let element"> {{element?.nombrePersona+ ' '
                                            +element?.apellidoPersona}} </td>
                                    </ng-container>

                                    <!-- Column -->
                                    <ng-container matColumnDef="identificacion">
                                        <th mat-header-cell
                                            *matHeaderCellDef
                                            mat-sort-header> Documento </th>
                                        <td mat-cell
                                            *matCellDef="let element"> {{element?.identificacion}} </td>
                                    </ng-container>

                                    <!-- Column -->
                                    <ng-container matColumnDef="domicilio">
                                        <th mat-header-cell
                                            *matHeaderCellDef
                                            mat-sort-header> Domicilio </th>
                                        <td mat-cell
                                            *matCellDef="let element">
                                            {{
                                            formatDomicilio(element?.signoGecrosAfiliado.domicilios[0].calle,
                                            element?.signoGecrosAfiliado.domicilios[0].numero) }} </td>
                                    </ng-container>

                                    <!-- Column -->
                                    <ng-container matColumnDef="email">
                                        <th mat-header-cell
                                            *matHeaderCellDef
                                            mat-sort-header> Email </th>
                                        <td mat-cell
                                            *matCellDef="let element"> {{element?.email}} </td>
                                    </ng-container>

                                    <!-- <ng-container matColumnDef="telefono">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Telefono </th>
                                <td mat-cell *matCellDef="let element"> {{element.telefono}} </td>
                            </ng-container> -->

                                    <!-- Column -->
                                    <ng-container matColumnDef="sede">
                                        <th mat-header-cell
                                            *matHeaderCellDef
                                            mat-sort-header> Sede </th>
                                        <td mat-cell
                                            *matCellDef="let element"> {{element?.signoSedes[0].nombre}}
                                        </td>
                                    </ng-container>

                                    <!-- Column -->
                                    <ng-container matColumnDef="plan">
                                        <th mat-header-cell
                                            *matHeaderCellDef
                                            mat-sort-header> Plan </th>
                                        <td mat-cell
                                            *matCellDef="let element"> {{element?.signoPlan}} </td>
                                    </ng-container>

                                    <!-- Column -->
                                    <ng-container matColumnDef="empresa">
                                        <th mat-header-cell
                                            *matHeaderCellDef
                                            mat-sort-header> Empresa </th>
                                        <td mat-cell
                                            *matCellDef="let element"> {{element?.signoEmpresas[0].nombre}}
                                        </td>
                                    </ng-container>

                                    <!-- <ng-container matColumnDef="credencial">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Credencial </th>
                                <td mat-cell *matCellDef="let element"> {{crendencial}}</td>
                            </ng-container> -->

                                    <!-- <ng-container matColumnDef="vencimiento">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Vencimiento </th>
                                <td mat-cell *matCellDef="let element"> {{vencimiento}}</td>
                            </ng-container>

                            <ng-container matColumnDef="estado">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Estado </th>
                                <td mat-cell *matCellDef="let element"> {{estado}}</td>
                            </ng-container> -->

                                    <!-- Column -->
                                    <!-- <ng-container matColumnDef="_id">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
                                <td mat-cell *matCellDef="let element">
                                    <button style="margin-right: 5px;" (click)="editarTituloAlumno(element)"
                                        type="button" title="Modificar titulo" class="btn btn-warning"><i
                                            class="fas fa-edit"></i></button>
                                    <button type="button" (click)="borrarTitulo(element._id)"
                                        style="margin-right: 2%;" title="Borar titulo"
                                        class="btn btn-danger">
                                        <i class="fa fa-trash fa-fw" aria-hidden="true"></i>
                                    </button>
                                </td>
                            </ng-container> -->

                                    <tr mat-header-row
                                        *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row
                                        *matRowDef="let row; columns: displayedColumns;"
                                        class="example-element-row"></tr>
                                </tbody>
                            </table>

                            <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>

                        </div>
                        <div *ngIf="!flagGrupoFamiliar">
                            <p>El afiliado no posee familiares a cargo</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>