<div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12">
        <div class="d-flex justify-content-between align-items-center mb-3">
            <h4>
                <strong>{{data.nombre}}</strong> - 
                <strong>{{data.zona.concepto}}</strong>&nbsp;
                <strong>{{ data.localidad.nombre }}</strong> - 
                <strong *ngIf="data.telefonos">Tel: {{data.telefonos}}&nbsp;</strong>
                <strong *ngIf="data.celular"><a href="https://api.whatsapp.com/send?phone={{ data.celular }}" target="_blank">
                    <i class="fab fa-whatsapp fa-lg" style="color: #060606; margin-right: 5px;"></i> {{ data.celular }}
                </a></strong>
            </h4>
            <button mat-icon-button (click)="closeDialog(null)">
                <mat-icon>close</mat-icon>
            </button>
        </div>
        <app-map></app-map>
    </div>
</div>