import { Component, Inject, OnInit } from '@angular/core';
import { UsuariosListadoComponent } from '../usuarios-listado/usuarios-listado.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SignoSedeService } from 'src/app/services/entidades/signo-sedes.service';
import { SignoSede } from 'src/app/interfaces/interface-bakend/entidades/signo-sede.interface';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UsuarioService } from 'src/app/services/shared/usuario.service';
import { GuiMsjService } from 'src/app/services/shared/gui-msj.service';
import { PersonasListadoComponent } from '../personas-listado/personas-listado.component';

@Component({
  selector: 'app-modal-detalle',
  templateUrl: './modal-detalle.component.html',
  styleUrls: ['./modal-detalle.component.scss']
})
export class ModalDetalleComponent implements OnInit {

  sede: SignoSede = undefined;
  flag: boolean = false;
  sedes: SignoSede[];
  sedeAsignada: string = undefined;
  form: FormGroup;
  btnActualizar: boolean = false;
  guardandoSede: boolean = false;

  constructor(public dialogRef: MatDialogRef<PersonasListadoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private signoSedeService: SignoSedeService,
    private guiMsjService: GuiMsjService, 
    private router: Router, private usuarioService: UsuarioService) {
    this.getUser(data.idUser);
  }

  async ngOnInit(): Promise<void> {
    await this.buildForm();
    await this.getSedes();
  }

  async getSedeById(id: string) {
    if (id != undefined) {
      this.sede = await this.signoSedeService.getSedeById(id);
      this.sedeAsignada = this.sede != undefined ? this.sede._id : null;
    } else {
      this.flag = true;
    }
  }

  async getUser(id: string){
    const signoSede: any = (await this.usuarioService.getFindOne(id)).signoSedes[0];
    await this.getSedeById(signoSede);
  }

  async getSedes() {
    this.sedes = await this.signoSedeService.getSedes();
  }

  async buildForm() {
    this.form = new FormGroup({
      signoSedes: new FormControl('', Validators.required),
    });
  }

  async guardarSede() {
    this.btnActualizar = true;
    this.guardandoSede = true;
    const sede = [this.form.value.signoSedes];
    await this.signoSedeService.updateSedeUser(this.data.idUser, sede).then(()=>{
      this.guiMsjService.msjFormSubmit('configGuardadaOK');
      this.dialogRef.close(this.data);
    }).catch((error)=>{
      this.btnActualizar = false;
      this.guardandoSede = false;
      this.guiMsjService.msjFormSubmit('GuardarError', error);
    });
  }
}
