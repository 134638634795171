import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { PaisDisponible } from '../../../../interfaces/interface-bakend/shared/paises/pais-disponible/pais-disponible.interface';
import { Actividad } from '../../../../interfaces/interface-bakend/ofertas-laborales/actividad.interface';
import { User } from 'src/app/interfaces/interface-bakend/seguridad/users/user.interface';
import { State } from '../../../../interfaces/interface-bakend/ofertas-laborales/solicitudes.interface';
import { ActividadesService } from '../../../../services/ofertas-laborales/actividades.service';
import { PaisesService } from '../../../../services/shared/paises.service';
import { Subscription } from 'rxjs';
import { Translators } from '../../../../services/shared/translators.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { GuiMsjService } from 'src/app/services/shared/gui-msj.service';
import { SolicitudesService } from '../../../../services/ofertas-laborales/solicitudes.service';
import { IAppOfertas, APP_CONFIG_OFERTAS } from '../../../../app.config/app.config-ofertas';
import { UsuarioService } from '@services/shared/usuario.service';
import { DocumentosEstadosSolicitar } from '../../../../interfaces/interface-bakend/ofertas-laborales/actividad.interface';
import { UserEntreUser } from '@interfaces/interface-bakend/seguridad/users/user-entre-user.interface';
import Swal from 'sweetalert2';

interface Beneficiario {
    _id: string;
    fullName: string;
    titular: boolean
    parentesco?: string;
}

@Component({
    selector: 'app-actividad-detalle',
    templateUrl: './actividad-detalle.component.html',
    styleUrls: ['./actividad-detalle.component.scss'],
})

export class ActividadDetalleComponent implements OnInit {
    idioma: Subscription;
    afiliadoLogged: User;

    flagProcesando: boolean = false;
    nombrePais: string;

    /*Solicitud*/
    solicitud: any;
    titularId: string;
    beneficiarioId: string;
    pais: PaisDisponible;
    actividad: Actividad;
    state: State;

    /*Fin Solicitud*/

    beneficiariosLoaded: Promise<void>;
    beneficiario: FormControl;
    formSolicitud: FormGroup;
    beneficiarios: Beneficiario[] = [];
    beneficiarioSelected: string;
    afiliadoTitular: string;
    afiliadoBeneficiario: string;

    loading: boolean = false; // Nueva propiedad para manejar el estado de carga

    constructor(
        @Inject(APP_CONFIG_OFERTAS) private iAppOfertas: IAppOfertas,
        private router: Router,
        private actividadesService: ActividadesService,
        private paisesService: PaisesService,
        private translatorService: Translators,
        private authService: AuthService,
        private solicitudesService: SolicitudesService,
        private guiMsjService: GuiMsjService,
        private formBuilder: FormBuilder,
        private usuarioService: UsuarioService
    ) {
        this.idioma = this.translatorService
            .getIdiomaSeleccionado()
            .subscribe((resp) => {
                this.idioma = resp;
            });
        this.beneficiario = new FormControl('', [Validators.required]);
        this.formSolicitud = this.formBuilder.group({
            beneficiario: this.beneficiario
        });
    }

    ngOnInit(): void {
        this.getEstadoVariables();
        // this.createListenersStateForm();
    }

    // createListenersStateForm() {
    //     return this.formSolicitud.statusChanges.subscribe(async status => {
    //         console.log(this.formSolicitud);
    //         console.log(status);
    //         console.log(this.beneficiarioSelected)
    //     });
    // }

    async getEstadoVariables() {
        try {
            if (this.
                getActividad() === undefined) {
                this.router.navigateByUrl('/');
                return;
            } else {
                await this.getUser();
                await this.getActividad();
                await this.getPais();
                await this.loadSelectorBeneficiarios();
            }
        }
        catch (error) {
            console.error("Trámite no encontrado")
        }
    }

    async getUser() {
        this.afiliadoLogged = await this.authService.getUserLogon();
    }

    async getActividad() {
        this.actividad = this.actividadesService.getActividadSeleccionada();
        return this.actividad;
    }

    async getPais() {
        this.nombrePais = this.paisesService.getPaisSeleccionado().pais.name_es;
        return (this.pais = this.paisesService.getPaisSeleccionado());
    }

    saveBeneficiarioLogueado(fullName: string, identificacionAfiliadoLogged: string, adicionalesArray: UserEntreUser[], titularArray: UserEntreUser[]) {

        let dataBeneficiarioLogueado: Beneficiario = {
            _id: '',
            fullName: '',
            titular: false,
            parentesco: ''
        };

        const esTitular = ((titularArray.length === 0) || (titularArray.length === 0 && adicionalesArray.length === 0)) ? true : false;

        if (esTitular) {
            dataBeneficiarioLogueado = {
                _id: identificacionAfiliadoLogged,
                fullName: fullName,
                titular: esTitular,
                parentesco: "TITULAR",
            }
        }
        else {
            dataBeneficiarioLogueado = {
                _id: identificacionAfiliadoLogged,
                fullName: fullName,
                titular: esTitular,
                parentesco: titularArray[0].parentesco ? titularArray[0].parentesco : adicionalesArray[0].parentesco,
            }
        }

        this.beneficiarios.push(dataBeneficiarioLogueado);
    }

    saveBeneficarioAdicional(identificacionAfiliadoLogged: string, adicionalesArray: UserEntreUser[]) {
        if (adicionalesArray.length > 0) {
            const adicionales = adicionalesArray.map(item => ({ adicional: item.adicional, parentesco: item.parentesco, titular: item.titular }));
            adicionales.map(async adicional => {
                const adicionalParsed = adicional.adicional.toString();
                let titular = (adicionalParsed === identificacionAfiliadoLogged) ? true : false;
                return this.usuarioService.getFindOne(adicionalParsed)
                    .then((afiliado: User) => {
                        const dataAdicional: Beneficiario = {
                            _id: afiliado._id,
                            fullName: afiliado.fullName,
                            titular: titular,
                            parentesco: adicional.parentesco
                        };
                        this.beneficiarios.push(dataAdicional);
                    })
                    .catch(error => {
                        console.error("Error al obtener familiares a cargo", error);
                    });
            });
        }
    }

    async loadSelectorBeneficiarios() {
        try {
            if (!this.afiliadoLogged) {
                throw Error("Afiliado no encontrado");
            }
            else {
                const { fullName, _id, signoAdicionales, signoTitular } = this.afiliadoLogged;  //obtengo datos necesarios del afiliado logueado
                this.saveBeneficiarioLogueado(fullName, _id, signoAdicionales, signoTitular)
                this.saveBeneficarioAdicional(_id, signoAdicionales);
            }
        } catch (error) {
            console.error('Error al cargar beneficiarios', error);
        }
    }

    onBenefactorSelectedChange(beneficiarios: Beneficiario[]) {
        const EXISTE_BENEFICIARIOS_Y_BENEFICIARIO_SELECCIONADO = beneficiarios && beneficiarios.length > 0 && this.beneficiarioSelected;
        if (EXISTE_BENEFICIARIOS_Y_BENEFICIARIO_SELECCIONADO) {

            const tipoBeneficiario = beneficiarios.find(beneficiario => beneficiario._id === this.beneficiarioSelected);
            const TITULAR_A_TITULAR = tipoBeneficiario.titular && tipoBeneficiario._id === this.afiliadoLogged._id;
            const TITULAR_A_BENEFICIARIO = !tipoBeneficiario.titular;
            const BENEFICIARIO = (!tipoBeneficiario.titular && tipoBeneficiario._id === this.afiliadoLogged._id);

            if (tipoBeneficiario) {
                if (TITULAR_A_TITULAR) {
                    this.titularId = this.beneficiarioSelected;
                    this.beneficiarioId = this.beneficiarioSelected;
                } else if ( TITULAR_A_BENEFICIARIO || BENEFICIARIO) {
                    this.titularId = this.afiliadoLogged?.signoAdicionales[0]?.titular.toString() ? this.afiliadoLogged?.signoAdicionales[0]?.titular.toString() : this.afiliadoLogged?.signoTitular[0]?.titular.toString();
                    this.beneficiarioId = this.beneficiarioSelected;
                } else {
                    throw new Error("Beneficiario seleccionado no es válido");
                }
            } else {
                throw new Error("Beneficiario seleccionado no se encuentra en los beneficiarios disponibles");
            }
        } else {
            throw new Error("No hay beneficiarios para cargar");
        }
    }

    async procesarSolicitudAutorizacion() {

        // Verificar si el afiliado tiene una sede asignada
        if (!this.afiliadoLogged || !this.afiliadoLogged.signoSedes || !this.afiliadoLogged.signoSedes.length) {
            await Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'No tiene una sede asignada. Comuníquese con administración, sepa disculpar.',
            });
            return; // Detener la ejecución si no tiene una sede asignada
        }
        
        // tslint:disable-next-line:max-line-length
        // const signoSede = this.afiliadoLogged !== undefined && this.afiliadoLogged.signoSedes[0] !== undefined ? this.afiliadoLogged.signoSedes[0] : null;

        const signoSede = this.afiliadoLogged.signoSedes[0];


        const confirm = await this.guiMsjService.msjConfirmCancel();
        if (confirm) {
            this.flagProcesando = true;
            this.loading = true; // Mostrar el indicador de carga

            this.solicitud = {
                actividad: this.actividadesService.getActividadSeleccionada()._id,
                pais: this.paisesService.getPaisSeleccionado().pais._id,
                usuario: (await this.authService.getUserLogon())._id,
                beneficiario: this.beneficiarioId ? this.beneficiarioId : null,
                titular: this.titularId ? this.titularId : null,
                state: this.state = { numberState: this.iAppOfertas.estado_0.number },
                type: this.iAppOfertas.tipo_constantes.name,
                documentosEstadosSolicitar: this.actividadesService.getActividadSeleccionada().documentosEstadosSolicitar,
                signoSede: signoSede != undefined ? { _id: signoSede } : null
            };

            // Creo solicitud
            this.solicitudesService.create(this.solicitud).then((response) => {
                this.flagProcesando = false;
                this.loading = false; // Ocultar el indicador de carga
                this.solicitudesService.setSolicitudSeleccionada(response);
                this.router.navigateByUrl('/solicitud');
            }).catch(() => {
                this.flagProcesando = false;
                this.loading = false; // Ocultar el indicador de carga en caso de error
            });
        } else { return; }
    }
    // async procesarSolicitudAutorizacion() {

    //     const signoSede = this.afiliadoLogged !== undefined && this.afiliadoLogged.signoSedes[0] !== undefined ? this.afiliadoLogged.signoSedes[0] : null;

    //     const confirm = await this.guiMsjService.msjConfirmCancel();
    //     if (confirm) {

    //         this.flagProcesando = true;
    //         this.loading = true; // Mostrar el indicador de carga

    //         this.solicitud = {
    //             actividad: this.actividadesService.getActividadSeleccionada()._id,
    //             pais: this.paisesService.getPaisSeleccionado().pais._id,
    //             usuario: (await this.authService.getUserLogon())._id,
    //             beneficiario: this.beneficiarioId ? this.beneficiarioId : null,
    //             titular: this.titularId ? this.titularId : null,
    //             state: this.state = { numberState: this.iAppOfertas.estado_0.number },
    //             type: this.iAppOfertas.tipo_constantes.name,
    //             documentosEstadosSolicitar: this.actividadesService.getActividadSeleccionada().documentosEstadosSolicitar,
    //             signoSede: signoSede != undefined ? { _id: signoSede } : null
    //         };

    //         // Creo solicitud
    //         this.solicitudesService.create(this.solicitud).then((response) => {
    //             this.flagProcesando = false;
    //             this.solicitudesService.setSolicitudSeleccionada(response);
    //             this.router.navigateByUrl('/solicitud');
    //         });
    //     } else return;
    // }
}
