import { Component, OnInit, Input } from '@angular/core';
import { Solicitud } from '@interfaces/interface-bakend/ofertas-laborales/solicitudes.interface';
import { User } from 'src/app/interfaces/interface-bakend/seguridad/users/user.interface';
import { Subscription } from 'rxjs';
import { Translators } from '../../../../../services/shared/translators.service';
import { GuiToolsService } from 'src/app/services/shared/gui-tools.service';
import { DocumentosService } from 'src/app/services/documentos/documentos.service';
import { SolicitudesService } from '../../../../../services/ofertas-laborales/solicitudes.service';

@Component({
    selector: 'app-documentos-enviados',
    templateUrl: './documentos-enviados.component.html',
    styleUrls: ['./documentos-enviados.component.scss'],
})
export class DocumentosEnviadosComponent implements OnInit {
    @Input() solicitud: Solicitud;
    @Input() usuario: User;
    flagLoaded = false;
    files: string[] = [];
    imgs: string[] = [];
    idioma: Subscription;
    _documentosSubscription: Subscription;
    constructor(
        private translatorService: Translators,
        private solicitudesService: SolicitudesService
    ) {
        this.idioma = this.translatorService
            .getIdiomaSeleccionado()
            .subscribe((resp) => {
                this.idioma = resp;
            });
    }

    ngOnInit(): void {
        this.getDataSolicitud();
    }

    async getDataSolicitud() {
        this.flagLoaded = false;
        await this.getArrayImgsAndFile(this.solicitud.adjuntos);
        this.flagLoaded = true;
    }

    async getArrayImgsAndFile(adjuntos: string[]) {
        if (adjuntos) {
            await Promise.all(
                adjuntos.map(async (element) => {
                    if (element.search('.pdf') !== -1) {
                        this.files.push(element);
                    } else {
                        this.imgs.push(element);
                    }
                })
            );
        }
    }
}
