<div class="page-title-area">
  <div class="container">
    <div class="page-title-content">
      <ul>
        <li><a routerLink="/">Inicio</a></li>
      </ul>
      <h2>Cartilla Médica</h2>
    </div>
  </div>
  <div class="shape9"><img src="assets/img/shape-signomedico.png" alt="image" width="10%"></div>
</div>

<div *ngIf="flagCargando" class="cargando-mensaje">
  <span>Cargando, espere por favor...</span>
  <mat-progress-bar mode="indeterminate" color="#73253f"></mat-progress-bar>
</div>

<div *ngIf="!flagCargando" class="profile-area pb-70 animate__animated animate__fadeIn">
  <div class="container">
    <div class="profile-courses-quizzes">
      <div class="andorra-apply-content ptb-70 animate__animated animate__fadeIn">
        <div class="container">
          <div class="row mx-auto">
            <div class="col-lg-12 col-md-12">
              <div class="apply-form">
                <!-- Botones de búsqueda -->
                <form>
                  <div class="row">
                    <div class="col-lg-3 col-md-3" *ngIf="btnMostrarBusquedaCercania">
                      <button type="button" (click)="mostrarCampos(['plan', 'rubro', 'especialidad'], 'CERCANIA')">
                        Buscar por Cercanía
                      </button>
                    </div>
                    <div class="col-lg-3 col-md-3" *ngIf="btnMostrarBusquedaNombre">
                      <button type="button" (click)="mostrarCampos(['plan', 'rubro', 'centro'], 'NOMBRE')">
                        Buscar por Centro
                      </button>
                    </div>
                    <div class="col-lg-3 col-md-3" *ngIf="btnMostrarBusquedaEspecialidad">
                      <button type="button"
                        (click)="mostrarCampos(['plan', 'rubro', 'zona', 'centro'], 'ESPECIALIDAD')">
                        Buscar por Especialidad
                      </button>
                    </div>
                  </div>
                </form>

                <br>

                <!-- Formulario -->
                <form [formGroup]="form" (ngSubmit)="busquedaNomenclador()">
                  <div class="row">
                    <!-- Selector de Plan -->
                    <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12" *ngIf="mostrarBusquedaPlan">
                      <div class="form-group">
                        <mat-form-field class="example-full-width" appearance="fill" [style.width.%]="100">
                          <mat-label>Plan</mat-label>
                          <mat-select formControlName="plan">
                            <mat-option [value]="null">Ninguno</mat-option>
                            <mat-option *ngFor="let plan of planes" [value]="plan._id">{{ plan.denominacion }}</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>
                    <!-- Selector de Rubro -->
                    <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12" *ngIf="mostrarBusquedaRubro">
                      <div class="form-group">
                        <mat-form-field class="example-full-width" appearance="fill" [style.width.%]="100">
                          <mat-label>Rubro</mat-label>
                          <mat-select formControlName="rubro">
                            <mat-option [value]="null">Ninguno</mat-option>
                            <mat-option *ngFor="let rubro of rubros" [value]="rubro._id">{{ rubro.concepto }}</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>
                    <!-- Selector de Centro -->
                    <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12" *ngIf="mostrarBusquedaCentro">
                      <div class="form-group">
                        <mat-form-field class="example-full-width" appearance="fill" [style.width.%]="100">
                          <mat-label>Centro</mat-label>
                          <input type="text" matInput [matAutocomplete]="auto" [formControl]="centroControl">
                          <mat-autocomplete #auto="matAutocomplete">
                            <mat-option [value]="null">Ninguno</mat-option>
                            <mat-option *ngFor="let centro of filteredCentros | async" [value]="centro.nombre">{{ centro.nombre }}</mat-option>
                          </mat-autocomplete>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <!-- Selector de Zona -->
                    <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12" *ngIf="mostrarBusquedaZona">
                      <div class="form-group">
                        <mat-form-field class="example-full-width" appearance="fill" [style.width.%]="100">
                          <mat-label>Zona</mat-label>
                          <mat-select formControlName="zona">
                            <mat-option [value]="null">Ninguno</mat-option>
                            <mat-option *ngFor="let zona of zonas" [value]="zona._id">{{ zona.concepto }}</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>
                    <!-- Selector de Especialidad -->
                    <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12" *ngIf="mostrarBusquedaEspecialidad">
                      <div class="form-group">
                        <mat-form-field class="example-full-width" appearance="fill" [style.width.%]="100">
                          <mat-label>Especialidad</mat-label>
                          <mat-select formControlName="especialidad">
                            <mat-option [value]="null">Ninguno</mat-option>
                            <mat-option *ngFor="let especialidad of especialidades" [value]="especialidad._id">{{ especialidad.concepto }}</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>
                    <!-- Selector de Localidad -->
                    <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12" *ngIf="mostrarBusquedaLocalidad">
                      <div class="form-group">
                        <mat-form-field class="example-full-width" appearance="fill" [style.width.%]="100">
                          <mat-label>Localidad</mat-label>
                          <mat-select formControlName="localidad">
                            <mat-option [value]="null">Ninguno</mat-option>
                            <mat-option *ngFor="let localidad of localidades" [value]="localidad._id">{{ localidad.nombre }}</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>

                  <mat-progress-bar *ngIf="flagBuscando" mode="indeterminate"></mat-progress-bar>

                  <!-- Contador de resultados -->
                  <div *ngIf="resultadosCentros && resultadosCentros.length > 0" class="contador-resultados">
                    <p>Centros encontrados: {{ resultadosCentros.length }}</p>
                  </div>

                  <div class="row fixed-buttons" *ngIf="btnForm">
                    <div class="col-lg-6 col-md-6">
                      <button type="submit" class="btn-icon">
                        <mat-icon>search</mat-icon>
                        <span>Consultar</span>
                      </button>
                    </div>
                    <div class="col-lg-6 col-md-6">
                      <button type="button" class="btn btn-warning" (click)="restaurarBotones()">
                        <mat-icon style="margin-right: 5px;">cancel</mat-icon>
                        <span>Cancelar</span>
                      </button>
                    </div>
                  </div>
                  <br>
                  <!-- Vista de resultados de búsqueda -->
                  <ng-container *ngIf="resultadosCentros">
                    <a *ngFor="let centro of resultadosCentros" class="card-link">
                      <mat-card class="centro-card">
                        <mat-card-header>
                          <mat-card-title>{{ centro.nombre }}</mat-card-title>
                          <mat-card-subtitle>{{ centro.calle }} {{ centro.altura }}</mat-card-subtitle>
                        </mat-card-header>
                        <mat-card-content>
                          <p>{{ centro.descripcion }}</p>
                          <div class="info-wrapper">
                            <mat-icon>place</mat-icon>
                            <p class="info">{{ centro.localidad.nombre }} {{ centro.zona.concepto }}</p>
                          </div>
                          <div class="telefono-wrapper">
                            <mat-icon>phone</mat-icon>
                            <p class="telefono">{{ centro.telefonos }}</p>
                          </div>
                          <div class="telefono-wrapper" *ngIf="centro.celular">
                            <a href="https://api.whatsapp.com/send?phone={{ centro.celular }}" target="_blank">
                              <i class="fab fa-whatsapp fa-2x fa-lg" style="color: #060606; margin-right: 5px;"></i>
                              <span style="font-size: 21px;color: #5c636a;">{{ centro.celular }}</span>
                            </a>
                          </div>
                          <br>
                          <span class="ver-ubicacion" style="font-size: 21px;" (click)="openMap(centro)">Ver Ubicación</span>
                        </mat-card-content>
                      </mat-card>
                    </a>

                    <div class="no-results" *ngIf="sinResultados && !flagBuscando">No se han encontrado resultados</div>
                  </ng-container>

                 

                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>

<style>
  .example-card {
    max-width: 400px;
  }

  .example-header-image {
    background-image: url('https://material.angular.io/assets/img/examples/shiba1.jpg');
    background-size: cover;
  }

  .telefono-wrapper {
    display: flex;
    align-items: center;
  }

  .telefono-wrapper mat-icon {
    margin-right: 8px;
  }

  .telefono {
    margin: 0;
  }

  .info-wrapper,
  .telefono-wrapper {
    display: flex;
    align-items: center;
    margin-top: 8px;
  }

  .info-wrapper mat-icon,
  .telefono-wrapper mat-icon {
    margin-right: 8px;
  }

  .info,
  .telefono {
    margin: 0;
  }

  .btn-icon {
    display: flex;
    align-items: center;
  }

  .btn btn-warning,
  .btn-icon mat-icon {
    margin-right: 5px;
    /* Ajusta el margen entre el ícono y el texto según sea necesario */
  }

  .btn-icon mat-icon,
  .btn btn-warning,
  .btn mat-icon {
    vertical-align: middle;
  }

  /* Estilo para el efecto hover */
  .centro-card:hover {
    transform: scale(1.05);
    /* Aumenta el tamaño de la tarjeta */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    /* Agrega una sombra suave */
  }

  .no-results {
    color: red;
    margin-top: 16px;
    font-size: 14px;
  }

  .fixed-buttons {
    position: sticky;
    top: 0;
    z-index: 1000;
    background: white;
    padding: 10px 0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .contador-resultados {
    margin-top: 20px;
    font-weight: bold;
    font-size: 16px;
    color: #333;
  }
</style>
