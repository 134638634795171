import { Response } from 'src/app/interfaces/interface-bakend/response';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import { StorageService } from '../shared/storage.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { GuiMsjService } from '../shared/gui-msj.service';
import { SignoEmpresa, SignoEmpresaEstado } from 'src/app/interfaces/interface-bakend/entidades/signo-empresa.interface';
import Swal from 'sweetalert2';

const URL_BACKEND = environment.urlBackend;

@Injectable({ providedIn: 'root' })
export class SignoEmpresaService {

signoEmpresaSeleccionada : SignoEmpresa;

    constructor(
        private httpClient: HttpClient,
        private router: Router,
        private guiMsjService: GuiMsjService,
        private authService: AuthService,
        private storageService: StorageService
    ) {}

    async create(empresa: SignoEmpresa): Promise<SignoEmpresa> {
        const headers = new HttpHeaders({
            'x-token': await this.authService.loadJwToken(),
        });

        return new Promise(async (resolve, reject) => {
            await this.httpClient
                .post<Response>(`${URL_BACKEND}/signo-empresas`, empresa, {
                    headers,
                })
                .subscribe(
                    (resp) => {
                        Swal.close();

                        Swal.fire({
                            icon: 'success',
                            title: 'Empresa guardada satisfactoriamente',
                            showConfirmButton: false,
                            timer: 1100,
                        });

                        this.router.navigate(['/empresas-listado']);
                        resolve(resp.response);
                    },
                    (error) => {
                        console.error(error);
                        this.guiMsjService.msjFormSubmit('crearEmpresaError');
                        this.router
                            .navigateByUrl('/', { skipLocationChange: true })
                            .then(async () => {
                                await this.router.navigateByUrl('/');
                            });
                        reject(error);
                    }
                );
        });
    }

    getEmpresaSeleccionada(): SignoEmpresa {
        return this.signoEmpresaSeleccionada;
    }

    async getEmpresas(): Promise<SignoEmpresa[]> {
        return new Promise(async (resolve) => {
            const headers = new HttpHeaders({
                'x-token': await this.authService.loadJwToken(),
            });

            this.httpClient
                .get<Response>(`${URL_BACKEND}/signo-empresas/findAll`, {
                    headers,
                })
                .subscribe((resp: Response) => {
                    resolve(resp.response);
                });
        });
    }

    async getEmpresaById(id: string): Promise<SignoEmpresa> {
        return new Promise(async (resolve) => {
            const headers = new HttpHeaders({
                'x-token': await this.authService.loadJwToken(),
            });

            this.httpClient
                .get<Response>(`${URL_BACKEND}/signo-empresas/findOne/${id}`, {
                    headers,
                })
                .subscribe((resp: Response) => {
                    resolve(resp.response);
                });
        });
    }

    async updateEmpresa(_id: string, empresa: SignoEmpresa): Promise<SignoEmpresa> {
        return new Promise(async (resolve) => {
            const headers = new HttpHeaders({
                'x-token': await this.authService.loadJwToken(),
            });
            this.httpClient
                .put<Response>(
                    `${URL_BACKEND}/signo-empresas/updateOne/${_id}`,
                    empresa,
                    { headers }
                )
                .subscribe(
                    (resp: Response) => {
                        resolve(resp.response);
                    },
                    (error) => {
                        console.error(error);
                        this.guiMsjService.msjFormSubmit('empresaDuplicada');
                    }
                );
        });
    }

    setEmpresaSeleccionada(empresa: SignoEmpresa) {
        this.signoEmpresaSeleccionada = empresa;
        this.storageService.setEmpresa( this.signoEmpresaSeleccionada);
    }

    async cambioEstado(empresaEstado: SignoEmpresaEstado) {
        const headers = new HttpHeaders({
            'x-token': await this.authService.loadJwToken(),
        });

        return new Promise(async (resolve, reject) => {
            this.httpClient
                .post<Response>(`${URL_BACKEND}/signo-empresas/cambioEstado`, empresaEstado, {
                    headers,
                })
                .subscribe(
                    (resp) => {
                        Swal.close();

                        Swal.fire({
                            icon: 'success',
                            title: 'Empresa modificada satisfactoriamente',
                            showConfirmButton: false,
                            timer: 1100,
                        });

                        this.router.navigate(['/empresas-listado']);
                        resolve(resp.response);
                    },
                    (error) => {
                        console.error(error);
                        this.guiMsjService.msjFormSubmit('modificarEmpresaError');
                        this.router
                            .navigateByUrl('/', { skipLocationChange: true })
                            .then(async () => {
                                await this.router.navigateByUrl('/');
                            });
                        reject(error);
                    }
                );
        });
    }
}
