import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { User } from '../../../../interfaces/interface-bakend/seguridad/users/user.interface';
import { UsuarioService } from '../../../../services/shared/usuario.service';
import { Perfil } from '../../../../interfaces/interface-bakend/seguridad/acl/perfil.interface';
import { AuthService } from '../../../../services/auth/auth.service';
import { Register } from '../../../../interfaces/interface-frontend/register';
import { GuiMsjService } from 'src/app/services/shared/gui-msj.service';
import { Router } from '@angular/router';
import { PerfilesService } from '../../../../services/perfiles/perfiles.service';
import { SignoSedeService } from 'src/app/services/entidades/signo-sedes.service';
import { SignoSede } from 'src/app/interfaces/interface-bakend/entidades/signo-sede.interface';

@Component({
  selector: 'app-usuarios-alta',
  templateUrl: './usuarios-alta.component.html',
  styleUrls: ['./usuarios-alta.component.scss']
})
export class UsuariosAltaComponent implements OnInit {

  forma: FormGroup;
  flagValidForma: boolean;
  selectPerfiles: Perfil[];
  flagRegistrado = false;
  sedes: SignoSede[];
  sedesSeleccionadas: any;

  constructor(
      private usarioService: UsuarioService,
      private authservice: AuthService,
      private guiMsjService: GuiMsjService,
      private router: Router,
      private perfilesService: PerfilesService,
      private signoSedeService: SignoSedeService,
      private fb: FormBuilder
  ) {
      this.createForm();
      this.getPerfiles();
      this.getSedes();
  }

  ngOnInit(): void {
    this.perfilesService.allowedByUserLogon(this.router.url);
  }

  createForm(){
    this.forma = this.fb.group({
      'nombrePersona': ['',[ Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      'apellidoPersona': ['', [ Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      'nickname': ['', [Validators.required, Validators.minLength(5), Validators.maxLength(20)]],
      'email': ['', [Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')]],
      'emailConfirm': ['', [ Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')]],
      'perfil': ['', Validators.required],
      'password': ['',  [, Validators.pattern(/^.{8}$/)]],
      'confirmPassword': ['', Validators.required],
      'signoSedes': ['', Validators.required]
    });
  }

  createListenersStateForm() {
    return this.forma.statusChanges.subscribe( async status => {
        if (status === 'VALID') {
            this.flagValidForma = true;
        } else {
            this.flagValidForma = false;

        }
    });
  }

  async setDataForma(): Promise<Register>{

    const dataRegister: Register = {

        nombrePersona: this.forma.value.nombrePersona,
        apellidoPersona: this.forma.value.apellidoPersona,
        nickname: this.forma.value.nickname,
        email: this.forma.value.email,
        emailConfirm: this.forma.value.emailConfirm,
        perfil: this.forma.value.perfil,
        password: this.forma.value.password,
        passwordConfirm: this.forma.value.confirmPassword,
        fromWeb: true,
        fromApp: false,
        signoSedes: this.forma.value.signoSedes,
        aceptaTerminos: true,
        proteccionDeDatos: true,
        clausula: true,
        notifica: true,

    };


    console.log('dataRegister', dataRegister);
    return dataRegister;
  }

  async getPerfiles() {
      this.selectPerfiles = await  this.usarioService.getPerfiles();
  }

  async getSedes(){
    this.sedes = await this.signoSedeService.getSedes();
  }

  async ngSubmit(){
    this.flagRegistrado = true;
    const flag = await this.authservice.registerManualUser(await this.setDataForma());
    if (flag) {
        this.forma.reset();
        this.flagRegistrado = false;
    } else {
        this.forma.reset();
        this.flagRegistrado = false;
        this.guiMsjService.msjFormSubmit('UsuarioManualCreadoError');
    }
  }

 /* validaciones form */

  get nombrePersona() {
    return this.forma.get('nombrePersona').invalid && this.forma.get('nombrePersona').touched;
  }

  get apellidoPersona() {
    return this.forma.get('apellidoPersona').invalid && this.forma.get('apellidoPersona').touched;
  }

  get nickname() {
    return this.forma.get('nickname').invalid && this.forma.get('nickname').touched;
  }

  get email() {
    return this.forma.get('email').invalid && this.forma.get('email').touched;
  }

  get emailConfirm() {
    return this.forma.get('emailConfirm').invalid && this.forma.get('emailConfirm').touched;
  }

  get perfil() {
    return this.forma.get('perfil').invalid && this.forma.get('perfil').touched;
  }

  get sede() {
    return this.forma.get('signoSedes').invalid && this.forma.get('signoSedes').touched;
  }

  get password() {
    return this.forma.get('password').invalid && this.forma.get('password').touched;
  }

  get confirmPassword() {
    return this.forma.get('confirmPassword').invalid && this.forma.get('confirmPassword').touched;
  }

  get confirmPassworValido(){
    const pass1 = this.forma.get('password').value;
    const pass2 = this.forma.get('confirmPassword').value;
    return ( pass1 === pass2 ) ? false : true;
  }

  get confirmEmail(){
    const pass1 = this.forma.get('email').value;
    const pass2 = this.forma.get('emailConfirm').value;
    return ( pass1 === pass2 ) ? false : true;
  }
}
