import { ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Personalizados
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { ProfileAuthenticationComponent } from './profile-authentication/profile-authentication.component';
import { ActivateAccountComponent } from './activate-account/activate-account.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '../modulos/material.module';
import { ResendPinComponent } from './resend-pin/resend-pin.component';
import { PipesModule } from '../modulos/pipes.module';
import { SharedModule } from '../shared/shared.module';
import { NgxCaptchaModule } from 'ngx-captcha';
import { TermsConditions } from './profile-authentication/terms-conditions/terms-conditions.component';
import { HomeModule } from '../modulos/home.module';

@NgModule({
  declarations: [
    LoginComponent,
    RegisterComponent,
    ProfileAuthenticationComponent,
    ActivateAccountComponent,
    ResetPasswordComponent,
    ResendPinComponent,
    TermsConditions,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    MaterialModule,
    PipesModule,
    SharedModule,
    NgxCaptchaModule,
    HomeModule,
  ]
})
export class AuthModule { }
