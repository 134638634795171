
<app-vendor-about></app-vendor-about>


<app-botones-rapidos></app-botones-rapidos>

<app-register-new-familiar *ngIf="flagTitular"></app-register-new-familiar>

<!-- TRAMITES -->
<!-- <div class="container">

    <br>
    <div class="section-title">
        <h3>{{ idioma | translate: "home.TRAMITES-TITULO" | async}} </h3>
        <p>{{ idioma | translate: "home.TRAMITES-DESCRIPCION" | async}} </p>
    </div>

    <app-ofertas-listar [flagViewTitle]="false" [highValue]="3"></app-ofertas-listar>

    <div class="col-lg-12 col-md-12">
        <div class="courses-info">
            <a routerLink="/tramites-disponibles" class="default-btn"><i class="flaticon-people"></i>{{ idioma | translate: "form.VER-TRAMITES-BTN" | async}} <span></span></a>
        </div>
    </div>
</div> -->

<div class="subscribe-area bg-f9f9f9 ptb-70">
    <div class="shape4"><img src="assets/img/monitor.png" alt="image" width="50%"></div>
    <div class="shape14"><img src="assets/img/hospital-1.png" alt="image" width="50%"></div>
</div>
