import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';
import { Subscription } from 'rxjs';
import { Translators } from './translators.service';
import { TranslatePipe } from '../../pipes/translate.pipe';
@Injectable({
    providedIn: 'root',
})
export class GuiMsjService {
    idioma: Subscription;
    msjPrincipal: string;
    msjSecundario: string;
    msjBtn: string;

    constructor(
        private translatorService: Translators,
        private translatePipe: TranslatePipe,
    ) {
        this.idioma = this.translatorService
            .getIdiomaSeleccionado()
            .subscribe((resp) => {
                this.idioma = resp;
            });
    }

    async msjFormSubmit(type: string, message?: string): Promise<void> {
        switch (type) {
            case 'loginOk':
                this.msjPrincipal = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.LOGIN_SUCCESSFULLY'
                );
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: this.msjPrincipal,
                    showConfirmButton: false,
                    timer: 2000,
                });
                break;

            case 'loginError':
                this.msjPrincipal = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.ERROR'
                );
                this.msjSecundario = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.ERROR-LOGIN'
                );
                Swal.fire(this.msjPrincipal, this.msjSecundario, 'error');
                break;

            case 'createUserError':
                this.msjPrincipal = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.ERROR'
                );
                this.msjSecundario = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.CONTACTAR-AC'
                );
                Swal.fire(this.msjPrincipal, this.msjSecundario, 'error');
                break;

            case 'createAfiliadoError':
                this.msjPrincipal = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.ERROR'
                );
                this.msjSecundario = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.CONTACTAR-AC'
                );
                Swal.fire(this.msjPrincipal, this.msjSecundario, 'error');
                break;

            case 'duplicateAccount':
                this.msjPrincipal = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.EMAIL-REGISTRADO-1'
                );
                this.msjSecundario = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.EMAIL-REGISTRADO-2'
                );
                Swal.fire(this.msjPrincipal, this.msjSecundario, 'error');
                break;

            case 'createUserOk':
                this.msjSecundario = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.REGISTER-OK'
                );
                Swal.fire('', this.msjSecundario, 'success');
                break;

            case 'createAfiliadoOk':
                this.msjSecundario = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.REGISTER-AFILIADO-OK'
                );
                Swal.fire('', this.msjSecundario, 'success');
                break;

            case 'resetPassword':
                this.msjSecundario = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.PASSWORD-RECUPERADO-OK'
                );
                Swal.fire('', this.msjSecundario, 'success');
                break;

            case 'resetPasswordError':
                this.msjPrincipal = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.ERROR'
                );
                this.msjSecundario = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.RESET-PASSWORD-ERROR'
                );
                Swal.fire(this.msjPrincipal, this.msjSecundario, 'error');
                break;

            case 'activateAccount':
                this.msjSecundario = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.ACTIVACION-OK'
                );
                Swal.fire('', this.msjSecundario, 'success');
                break;

            case 'activateAccountError':
                this.msjPrincipal = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.ERROR'
                );
                this.msjSecundario = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.ACTIVACION-ERROR'
                );
                Swal.fire(this.msjPrincipal, this.msjSecundario, 'error');
                break;

            case 'pinResendOk':
                this.msjSecundario = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.PIN-ENVIADO'
                );
                Swal.fire('', this.msjSecundario, 'success');
                break;

            case 'pinResendError':
                this.msjPrincipal = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.ERROR'
                );
                this.msjSecundario = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.CUENTA-YA-VERIFICADA'
                );
                Swal.fire(this.msjPrincipal, this.msjSecundario, 'error');
                break;

            case 'errorTokenLogon':
                this.msjPrincipal = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.ERROR'
                );
                this.msjSecundario = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.ERROR-TOKEN-LOGON'
                );
                Swal.fire(this.msjPrincipal, this.msjSecundario, 'error');
                break;

            case 'newPasswordOk':
                this.msjSecundario = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.NUEVO-PASSWORD-OK'
                );
                Swal.fire('', this.msjSecundario, 'success');
                break;

            case 'newPasswordError':
                this.msjPrincipal = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.ERROR'
                );
                this.msjSecundario = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.CONTACTAR-AC'
                );
                Swal.fire(this.msjPrincipal, this.msjSecundario, 'error');
                break;

            case 'formularioOfertaCargado':
                this.msjPrincipal = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.FORMULARIO'
                );
                this.msjSecundario = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.CARGADO'
                );
                Swal.fire(this.msjPrincipal, this.msjSecundario, 'success');
                break;

            /* MSJ Entidades */
            case 'planDuplicado':
                this.msjPrincipal = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.SIGNO-PLAN.PLAN'
                );
                this.msjSecundario = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.SIGNO-PLAN.PLAN-DUPLICADO'
                );
                Swal.fire(this.msjPrincipal, this.msjSecundario, 'error');
                break;

            case 'empresaDuplicada':
                this.msjPrincipal = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.SIGNO-EMPRESA.EMPRESA'
                );
                this.msjSecundario = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.SIGNO-EMPRESA.EMPRESA-DUPLICADA'
                );
                Swal.fire(this.msjPrincipal, this.msjSecundario, 'error');
                break;

            case 'sedeDuplicada':
                this.msjPrincipal = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.SIGNO-SEDE.SEDE'
                );
                this.msjSecundario = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.SIGNO-SEDE.SEDE-DUPLICADA'
                );
                Swal.fire(this.msjPrincipal, this.msjSecundario, 'error');
                break;

            case 'doctorDuplicado':
                this.msjPrincipal = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.SIGNO-DOCTOR.DOCTOR'
                );
                this.msjSecundario = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.SIGNO-DOCTOR.DOCTOR-DUPLICADO'
                );
                Swal.fire(this.msjPrincipal, this.msjSecundario, 'error');
                break;

            case 'localidadDuplicada':
                this.msjPrincipal = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.SIGNO-LOCALIDAD.LOCALIDAD'
                );
                this.msjSecundario = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.SIGNO-LOCALIDAD.LOCALIDAD-DUPLICADA'
                );
                Swal.fire(this.msjPrincipal, this.msjSecundario, 'error');
                break;

            case 'centroMedicoDuplicado':
                this.msjPrincipal = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.SIGNO-CENTRO-MEDICO.CENTRO-MEDICO'
                );
                this.msjSecundario = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.SIGNO-CENTRO-MEDICO.CENTRO-MEDICO-DUPLICADO'
                );
                Swal.fire(this.msjPrincipal, this.msjSecundario, 'error');
                break;

            case 'afiliadoDuplicado':
                this.msjPrincipal = await this.translatePipeFunction(
                    this.idioma,
                    'afiliado.AFILIADO'
                );
                this.msjSecundario = await this.translatePipeFunction(
                    this.idioma,
                    'afiliado.AFILIADO-DUPLICADO'
                );
                Swal.fire(this.msjPrincipal, this.msjSecundario, 'error');
                break;

                /* MSJ Termina Entidades */

            case 'emailDuplicado':
                this.msjPrincipal = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.EMAIL'
                );
                this.msjSecundario = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.EMAIL-DUPLICADO'
                );

                Swal.fire(this.msjPrincipal, this.msjSecundario, 'error');
                break;

            case 'formularioCargado':
                this.msjPrincipal = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.ENVIADO'
                );
                this.msjSecundario = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.FORMULARIO-ENVIADO'
                );

                Swal.fire(this.msjPrincipal, this.msjSecundario, 'success');

                break;

            case 'uploadDocumentOK':
                this.msjPrincipal = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.PROCESADO'
                );
                this.msjSecundario = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.DOCUMENTO-CARGADO'
                );

                Swal.fire(
                    this.msjPrincipal,
                    this.msjSecundario,
                    'success'
                ).then(() => {});
                break;

            case 'uploadDocumentERROR':
                this.msjPrincipal = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.ATENCION'
                );
                this.msjSecundario = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.ERROR-PROCESAR-DOC'
                );

                Swal.fire(this.msjPrincipal, this.msjSecundario, 'warning');
                break;

            case 'documentosEnviadosOK':
                this.msjPrincipal = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.PROCESADO'
                );
                this.msjSecundario = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.DOCUMENTOS-ENVIADOS'
                );

                Swal.fire(this.msjPrincipal, this.msjSecundario, 'success');
                break;

            case 'documentosEnviadosERROR':
                this.msjPrincipal = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.ATENCION'
                );
                this.msjSecundario = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.ERROR-PROCESAR-DOC'
                );

                Swal.fire(this.msjPrincipal, this.msjSecundario, 'warning');
                break;

            case 'solicitudDocCompleta':
                this.msjPrincipal = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.SOLICITUD'
                );
                this.msjSecundario = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.DOCUMENTACION-ADJUNTA'
                );

                Swal.fire(this.msjPrincipal, this.msjSecundario, 'success');
                break;

            case 'solicitudDocCompleta':
                this.msjPrincipal = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.SOLICITUD'
                );
                this.msjSecundario = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.DOCUMENTACION-COMPLETA'
                );

                Swal.fire(this.msjPrincipal, this.msjSecundario, 'success');
                break;

            case 'solicitudEstadoOK':
                this.msjPrincipal = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.SOLICITUD'
                );
                this.msjSecundario = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.CAMBIO-ESTADO-CORRECTO'
                );

                Swal.fire(this.msjPrincipal, this.msjSecundario, 'success');
                break;

            case 'solicitudEstadoError':
                this.msjPrincipal = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.SOLICITUD'
                );
                this.msjSecundario = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.CAMBIO-ESTADO-ERROR'
                );

                Swal.fire(this.msjPrincipal, this.msjSecundario, 'error');
                break;

            case 'envioEmailSolicitarPagoOK':
                this.msjPrincipal = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.SOLICITUD'
                );
                this.msjSecundario = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.SOLICITUD-PAGO-TOTAL-OK'
                );

                Swal.fire(this.msjPrincipal, this.msjSecundario, 'success');
                break;

            case 'envioEmailSolicitarPagoError':
                this.msjPrincipal = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.SOLICITUD'
                );
                this.msjSecundario = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.SOLICITUD-PAGO-ERROR'
                );

                Swal.fire(this.msjPrincipal, this.msjSecundario, 'warning');
                break;

            case 'crearSolicitudOK':
                this.msjPrincipal = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.SOLICITUD'
                );
                this.msjSecundario = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.ENVIADA-OK'
                );

                Swal.fire(this.msjPrincipal, this.msjSecundario, 'success');

                break;

            case 'crearSolicitudError':
                this.msjPrincipal = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.SOLICITUD'
                );
                this.msjSecundario = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.CREAR-SOLICITUD-ERROR'
                );

                Swal.fire(this.msjPrincipal, this.msjSecundario, 'warning');

                break;

            case 'crearPlanOK':
                this.msjPrincipal = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.SIGNO-PLAN.PLAN'
                );
                this.msjSecundario = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.SIGNO-PLAN.PLAN-CREADO-OK'
                );

                Swal.fire(this.msjPrincipal, this.msjSecundario, 'success');

                break;

            case 'crearPlanError':
                this.msjPrincipal = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.SIGNO-PLAN.PLAN'
                );
                this.msjSecundario = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.SIGNO-PLAN.PLAN-CREADO-ERROR'
                );

                Swal.fire(this.msjPrincipal, this.msjSecundario, 'warning');

                break;

            case 'crearEmpresaOK':
                this.msjPrincipal = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.SIGNO-EMPRESA.EMPRESA'
                );
                this.msjSecundario = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.SIGNO-EMPRESA.EMPRESA-CREADA-OK'
                );

                Swal.fire(this.msjPrincipal, this.msjSecundario, 'success');

                break;

            case 'modificarEmpresaOK':
            this.msjPrincipal = await this.translatePipeFunction(
                this.idioma,
                'mensajes.SIGNO-EMPRESA.EMPRESA'
            );
            this.msjSecundario = await this.translatePipeFunction(
                this.idioma,
                'mensajes.SIGNO-EMPRESA.EMPRESA-MODIFICADA-OK'
            );

            Swal.fire(this.msjPrincipal, this.msjSecundario, 'success');

            break;

            case 'crearEmpresaError':
                this.msjPrincipal = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.SIGNO-EMPRESA.EMPRESA'
                );
                this.msjSecundario = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.SIGNO-EMPRESA.EMPRESA-CREADA-ERROR'
                );

                Swal.fire(this.msjPrincipal, this.msjSecundario, 'warning');

                break;

            case 'modificarEmpresaError':
                this.msjPrincipal = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.SIGNO-EMPRESA.EMPRESA'
                );
                this.msjSecundario = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.SIGNO-EMPRESA.EMPRESA-MODIFICADA-ERROR'
                );

                Swal.fire(this.msjPrincipal, this.msjSecundario, 'warning');

                break;

            case 'crearSedeOK':
                this.msjPrincipal = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.SIGNO-SEDE.SEDE'
                );
                this.msjSecundario = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.SIGNO-SEDE.SEDE-CREADA-OK'
                );

                Swal.fire(this.msjPrincipal, this.msjSecundario, 'success');

                break;

            case 'crearSedeError':
                this.msjPrincipal = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.SIGNO-SEDE.SEDE'
                );
                this.msjSecundario = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.SIGNO-SEDE.SEDE-CREADA-ERROR'
                );

                Swal.fire(this.msjPrincipal, this.msjSecundario, 'warning');

                break;
            case 'crearDoctorOK':
                this.msjPrincipal = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.SIGNO-DOCTOR.DOCTOR'
                );
                this.msjSecundario = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.SIGNO-DOCTOR.DOCTOR-CREADO-OK'
                );

                Swal.fire(this.msjPrincipal, this.msjSecundario, 'success');

                break;

            case 'crearDoctorError':
                this.msjPrincipal = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.SIGNO-DOCTOR.DOCTOR'
                );
                this.msjSecundario = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.SIGNO-DOCTOR.DOCTOR-CREADO-ERROR'
                );

                Swal.fire(this.msjPrincipal, this.msjSecundario, 'warning');

                break;
            case 'crearLocalidadOK':
                this.msjPrincipal = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.SIGNO-LOCALIDAD.LOCALIDAD'
                );
                this.msjSecundario = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.SIGNO-LOCALIDAD.LOCALIDAD-CREADA-OK'
                );

                Swal.fire(this.msjPrincipal, this.msjSecundario, 'success');

                break;

            case 'crearLocalidadError':
                this.msjPrincipal = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.SIGNO-LOCALIDAD.LOCALIDAD'
                );
                this.msjSecundario = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.SIGNO-LOCALIDAD.LOCALIDAD-CREADA-ERROR'
                );

                Swal.fire(this.msjPrincipal, this.msjSecundario, 'warning');

                break;
            case 'crearCentroMedicoOK':
                this.msjPrincipal = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.SIGNO-CENTRO-MEDICO.CENTRO-MEDICO'
                );
                this.msjSecundario = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.SIGNO-CENTRO-MEDICO.CENTRO-MEDICO-CREADO-OK'
                );

                Swal.fire(this.msjPrincipal, this.msjSecundario, 'success');

                break;

            case 'crearCentroMedicoError':
                this.msjPrincipal = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.SIGNO-CENTRO-MEDICO.CENTRO-MEDICO'
                );
                this.msjSecundario = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.SIGNO-CENTRO-MEDICO.CENTRO-MEDICO-CREADO-ERROR'
                );

                Swal.fire(this.msjPrincipal, this.msjSecundario, 'warning');

                break;

            case 'pagoSolicitudOK':
                this.msjPrincipal = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.SOLICITUD'
                );
                this.msjSecundario = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.SOLICITUD-PAGO-PROCESADA'
                );

                Swal.fire(this.msjPrincipal, this.msjSecundario, 'success');
                break;

            case 'pagoSolicitudError':
                this.msjPrincipal = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.SOLICITUD'
                );
                this.msjSecundario = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.SOLICITUD-PAGO-PROCESADA-ERROR'
                );

                Swal.fire(this.msjPrincipal, this.msjSecundario, 'warning');
                break;

            case 'UploadError':
                this.msjPrincipal = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.UPLOAD'
                );
                this.msjSecundario = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.UPLOAD-ERROR'
                );

                Swal.fire(this.msjPrincipal, this.msjSecundario, 'warning');
                break;

            case 'pagarSaldoOK':
                this.msjPrincipal = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.SOLICITUD'
                );
                this.msjSecundario = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.SOLICITUD-PAGO-PROCESADA'
                );

                Swal.fire(this.msjPrincipal, this.msjSecundario, 'success');
                break;

            case 'pagarSaldoCursosModulosOK':
                this.msjPrincipal = 'Procesado Correctamente';
                this.msjSecundario = 'Cursos/módulos ya disponibles';

                Swal.fire(this.msjPrincipal, this.msjSecundario, 'success');
                break;

            case 'pagarSaldoError':
                this.msjPrincipal = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.SOLICITUD'
                );
                this.msjSecundario = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.SOLICITUD-PAGO-PROCESADA-ERROR'
                );

                Swal.fire(this.msjPrincipal, this.msjSecundario, 'warning');
                break;

            /* CURSOS */
            case 'pagoCursoOK':
                this.msjPrincipal = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.CURSO'
                );
                this.msjSecundario = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.PAGO-CURSO-OK'
                );

                Swal.fire(this.msjPrincipal, this.msjSecundario, 'success');
                break;

            case 'pagoCursoError':
                this.msjPrincipal = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.CURSO'
                );
                this.msjSecundario = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.SOLICITUD-PAGO-PROCESADA-ERROR'
                );

                Swal.fire(this.msjPrincipal, this.msjSecundario, 'warning');
                break;

            case 'ProfesorCreadoError':
                this.msjPrincipal = 'Error';
                this.msjSecundario = 'No se ha dado de alta al profesor...';
                Swal.fire(this.msjPrincipal, this.msjSecundario, 'warning');
                break;

            /* PAYPAL */
            case 'paypalOK':
                this.msjPrincipal = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.PAGO-EXITOSO'
                );
                this.msjSecundario = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.PAGO-PAYPAL-OK'
                );

                Swal.fire(this.msjPrincipal, this.msjSecundario, 'success');
                break;

            case 'paypalError':
                this.msjPrincipal = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.ERROR'
                );
                this.msjSecundario = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.PAGO-PAYPAL-ERROR'
                );

                Swal.fire(this.msjPrincipal, this.msjSecundario, 'warning');
                break;

            case 'NotifyServiceFailed':
                this.msjPrincipal = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.ERROR'
                );
                this.msjSecundario = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.PAGO-PAYPAL-SISTEMA-ERROR'
                );

                Swal.fire(this.msjPrincipal, this.msjSecundario, 'warning');
                break;


            /* AFILIADO - FAMILIARES */
            case 'afiliadoModificadoOK':
                this.msjPrincipal = await this.translatePipeFunction(
                    this.idioma,
                    'afiliados.AFILIADO-MODIFICADO-PRINCIPAL'
                );
                this.msjSecundario = await this.translatePipeFunction(
                    this.idioma,
                    'afiliados.AFILIADO-MODIFICADO-SECUNDARIO'
                );

                Swal.fire(this.msjPrincipal, this.msjSecundario, 'success');
                break;

            case 'afiliadoModificadoError':
                this.msjPrincipal = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.ERROR'
                );
                this.msjSecundario = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.MODIFICAR-AFILIADO-ERROR'
                );
                Swal.fire(this.msjPrincipal, this.msjSecundario, 'warning');
                break;

            case 'SinFamiliares':
                this.msjPrincipal = await this.translatePipeFunction(
                    this.idioma,
                    'afiliados.SIN-FAMILIARES-PRINCIPAL'
                );
                this.msjSecundario = await this.translatePipeFunction(
                    this.idioma,
                    'afiliados.SIN-FAMILIARES-SECUNDARIO'
                );

                Swal.fire(this.msjPrincipal, this.msjSecundario, 'info');
                break;

            /* Cursos administracion */
            case 'configGuardadaOK':
                this.msjPrincipal = 'Guardado';
                this.msjSecundario = 'configuración correcta';
                Swal.fire(this.msjPrincipal, this.msjSecundario, 'success');
                break;

            case 'configGuardadaERROR':
                this.msjPrincipal = 'Error';
                this.msjSecundario =
                    'la configuración general no  pudo ser guardad';
                Swal.fire(this.msjPrincipal, this.msjSecundario, 'warning');
                break;

            case 'configGuardadaServiceERROR':
                this.msjPrincipal = 'Error';
                this.msjSecundario =
                    'la configuración general no  pudo ser guardad a nivel de servicio...';
                Swal.fire(this.msjPrincipal, this.msjSecundario, 'warning');
                break;

            case 'configGuardadaServiceERROR':
                this.msjPrincipal = 'Error';
                this.msjSecundario =
                    'no se puedo asignar el curso al alumno...';
                Swal.fire(this.msjPrincipal, this.msjSecundario, 'warning');
                break;

            /* Administracion de usuarios */
            case 'createManulUserOk':
                this.msjSecundario = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.REGISTER-OK'
                );
                Swal.fire('', this.msjSecundario, 'success');
                break;

            case 'createManualUserError':
                this.msjPrincipal = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.ERROR'
                );
                this.msjSecundario = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.ALTA-USR-ERROR'
                );
                Swal.fire(this.msjPrincipal, this.msjSecundario, 'error');
                break;

            case 'errorSendMailRegistroError':
                this.msjPrincipal = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.ERROR'
                );
                this.msjSecundario = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.ENVIO-MAIL-USR-ERROR'
                );
                Swal.fire(this.msjPrincipal, this.msjSecundario, 'error');
                break;

            case 'UsuarioModificadoOK':
                this.msjSecundario = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.DATOS-GUARDADOS'
                );
                Swal.fire('Modificado', this.msjSecundario, 'success');
                break;

            case 'UsuarioModificadoError':
                this.msjPrincipal = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.ERROR'
                );
                this.msjSecundario = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.ERROR-GUARDAR'
                );
                Swal.fire(this.msjPrincipal, this.msjSecundario, 'error');
                break;

            /* Examenes */
            case 'modificarRespuesta':
                this.msjSecundario =
                    'Puede modificar la respuesta, pero debera cargar todas las opciones nuevamente..';
                Swal.fire('ATENCIÓN', this.msjSecundario, 'info');
                break;

            case 'modificarRespuestaMultimedia':
                this.msjSecundario =
                    'No Puede modificar la respuesta, debe eliminarla (una o más respuestas contienen multimedia)..';
                Swal.fire('ATENCIÓN', this.msjSecundario, 'info');
                break;

            case 'respuestasCargadas':
                this.msjPrincipal = 'Respuestas cargadas y Examen configurado';
                this.msjSecundario =
                    'Su examen fue generado de forma correcta.';
                Swal.fire(this.msjPrincipal, this.msjSecundario, 'success');
                break;

            case 'recuperatorioConfig':
                this.msjPrincipal = 'Control configuración Recuperatorio';
                this.msjSecundario =
                    'El campo Porcentaje Min. debe ser 60% y Nota mínima - Cantidad Recuperatorio deben esta cargados..';
                Swal.fire(this.msjPrincipal, this.msjSecundario, 'warning');
                break;

            case 'recuperatorioFechas':
                this.msjPrincipal = 'Control fechas recuperatorio';
                this.msjSecundario =
                    'Debe introducir las fechas del recuperatorio.';
                Swal.fire(this.msjPrincipal, this.msjSecundario, 'warning');
                break;

            case 'recuperatorioCant':
                this.msjPrincipal = 'Control de recuperatorio';
                this.msjSecundario =
                    'Debe definir la cantidad de recuperatorios.';
                Swal.fire(this.msjPrincipal, this.msjSecundario, 'warning');
                break;

            case 'respuestasConfiguradas':
                this.msjPrincipal = 'Respuestas configuradas';
                this.msjSecundario =
                    'No puede agregar más respuestas, debido al tipo de pregunta...';
                Swal.fire(this.msjPrincipal, this.msjSecundario, 'warning');
                break;

            case 'agregarConcepto':
                this.msjPrincipal = 'ATENCIÓN';
                this.msjSecundario =
                    'Debe agregar al menos un concepto, para agregar opciones (columnas)...';
                Swal.fire(this.msjPrincipal, this.msjSecundario, 'warning');
                break;

            case 'maxOpciones':
                this.msjPrincipal = 'ATENCIÓN';
                this.msjSecundario = 'Las opciones son limitadas...';
                Swal.fire(this.msjPrincipal, this.msjSecundario, 'warning');
                break;

            case 'ingrseTipoPregunta':
                this.msjPrincipal = 'ATENCIÓN';
                this.msjSecundario = 'seleccione un tipo de pregunta...';
                Swal.fire(this.msjPrincipal, this.msjSecundario, 'warning');
                break;

            case 'examenAsignado':
                this.msjPrincipal = 'Examen Asignado';
                this.msjSecundario = 'el examen fue asignado correctamente...';
                Swal.fire(this.msjPrincipal, this.msjSecundario, 'success');
                break;

            case 'examenAsignadoError':
                this.msjPrincipal = 'ATENCIÓN';
                this.msjSecundario = 'el examen fue asignado correctamente...';
                Swal.fire(this.msjPrincipal, this.msjSecundario, 'error');
                break;

            case 'finalizarAsignacionExamen':
                this.msjPrincipal = 'FINALIZADO';
                this.msjSecundario =
                    'el examen fue creado y asignado correctamente...';
                Swal.fire(this.msjPrincipal, this.msjSecundario, 'success');
                break;

            /* Mensajes Genéricos */
            case 'Espere':
                this.msjSecundario = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.ESPERE'
                );
                Swal.fire('', this.msjSecundario, 'info');
                break;

            case 'Guardando':
                this.msjSecundario = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.GUARDANDO'
                );
                Swal.fire({
                    allowOutsideClick: false,
                    icon: 'info',
                    text: this.msjSecundario,
                });
                Swal.showLoading();
                break;

            case 'DatosGuardados':
                this.msjSecundario = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.DATOS-GUARDADOS'
                );
                Swal.fire({
                    icon: 'success',
                    title: this.msjSecundario,
                    showConfirmButton: false,
                    timer: 1100,
                });
                break;

            /* Formulario de contacto */

            case 'ContactoEnviado':
                this.msjSecundario = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.CONTACTO-ENVIADO'
                );
                Swal.fire('', this.msjSecundario, 'info');
                break;

            case 'ErrorContacto':
                this.msjSecundario = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.CONTACTO-ERROR'
                );
                Swal.fire('', this.msjSecundario, 'error');
                break;

            /* fin formulario contacto */
            case 'GuardarError':
                this.msjSecundario = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.ERROR-GUARDAR'
                );
                Swal.fire('', this.msjSecundario, 'error');
                break;

            case 'Guardando':
                this.msjSecundario = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.GUARDANDO'
                );
                Swal.fire('', this.msjSecundario, 'info');
                break;

            /* PERMISO RUTA DENEGADO*/
            case 'SinPermisoParaUrl':
                this.msjSecundario = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.SIN-PERMISO-PARA-URL'
                );
                Swal.fire({

                    title: this.msjSecundario,
                    html: `El rol <strong>${message}</strong> no tiene permisos para acceder a esta información`,
                    icon: 'info',
                    backdrop: `rgba(230,230,230,0.9)`,
                    showCloseButton: true,
                })
                break;

            /* COMPROBANTES*/
            case 'comprobanteCreadoOK':
                this.msjSecundario = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.OK-GUARDAR-COMPROBANTE'
                );
                Swal.fire('', this.msjSecundario, 'success');
                break;

            case 'comprobanteCreadoERROR':
                this.msjSecundario = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.ERROR-GUARDAR-COMPROBANTE'
                );
                Swal.fire('', this.msjSecundario, 'warning');
                break;

            /* INVITADO */
            case 'invitado':
                this.msjSecundario = await this.translatePipeFunction(
                    this.idioma,
                    'mensajes.INVITADO'
                );
                Swal.fire('', this.msjSecundario, 'warning');
                break;

            /* GRILLA */
            case 'grillaSinDatos':
                this.msjSecundario = 'No hay datos para la selección..';
                Swal.fire('', this.msjSecundario, 'warning');
                break;

            default:
                break;
        }
    }

    async msjConfirmCancel(): Promise<boolean> {
        this.msjPrincipal = await this.translatePipeFunction(
            this.idioma,
            'mensajes.MSJ-SEGURO-0'
        );
        this.msjSecundario = await this.translatePipeFunction(
            this.idioma,
            'mensajes.MSJ-SEGURO-2'
        );
        this.msjBtn = await this.translatePipeFunction(
            this.idioma,
            'mensajes.BTN-AUTORIZAR'
        );

        return new Promise<boolean>((resolve) => {
            Swal.fire({
                title: this.msjPrincipal,
                text: this.msjSecundario,
                icon: 'info',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: this.msjBtn,
            }).then((result) => {
                if (result.isConfirmed) {
                    resolve(true);
                } else {
                    resolve(false);
                }
            });
        });
    }

    async translatePipeFunction(value, pMsj) {
        const msj = await this.translatePipe.transform(value, pMsj);
        return msj;
    }

    async msjBackend(msj: string) {
        Swal.fire({
          position: 'center',
          icon: 'warning',
          title: msj || 'Error General contacte administración' ,
          showConfirmButton: true,
        });
    }
}
