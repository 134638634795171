import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MapComponent } from '@components/entidades/common/map/map.component';
import { SignoLocalidad } from 'src/app/interfaces/interface-bakend/entidades/signo-localidad.interface';
import { SignoLocalidadService } from 'src/app/services/entidades/signo-localidades.service';
import { SignoCentroMedico } from 'src/app/interfaces/interface-bakend/entidades/signo-centro-medico.interface';
import { SignoCentroMedicoService } from 'src/app/services/entidades/signo-centros-medicos.service';
import { Catalogo } from 'src/app/interfaces/interface-bakend/shared/catalogo/catalogo.interface';
import { CatalogoService } from 'src/app/services/shared/catalogo.service';
import { EntidadesKeys } from 'src/app/enum/entidades-keys.enum';
import { GuiMsjService } from 'src/app/services/shared/gui-msj.service';
import { SignoPlan } from '@interfaces/interface-bakend/entidades/signo-plan.interface';
import { SignoPlanService } from '@services/entidades/signo-planes.service';

@Component({
  selector: 'app-centro-medico-alta',
  templateUrl: './centro-medico-alta.component.html',
  styleUrls: ['./centro-medico-alta.component.scss']
})
export class CentroMedicoAltaComponent implements OnInit, AfterViewInit {
  form: FormGroup;
  zonas: Catalogo[];
  localidades: SignoLocalidad[];
  rubros: Catalogo[];
  especialidades: Catalogo[];
  planes: SignoPlan[];

  filteredEspecialidades: Catalogo[] = [];
  filteredRubros: Catalogo[] = [];

  @ViewChild(MapComponent) mapComponent: MapComponent;

  constructor(
    private signoCentroMedicoService: SignoCentroMedicoService,
    private catalogoService: CatalogoService,
    private signoLocalidadService: SignoLocalidadService,
    private router: Router,
    private guiMsjService: GuiMsjService,
    private signoPlanService: SignoPlanService
  ) {
    this.getZonas();
    this.getEspecialidades();
    this.getLocalidades();
    this.getRubros();
    this.getPlanes();
  }

  ngOnInit(): void {
    this.createForm();
  }

  ngAfterViewInit(): void {
    this.initMap();
    this.updateMap();
  }

  get centroMedicoNoValido() {
    return this.form.get('nombre').invalid && this.form.get('nombre').touched;
  }

  get telefonosNoValidos() {
    return this.form.get('telefonos').invalid && this.form.get('telefonos').touched;
  }

  get celularNoValido() {
    return this.form.get('celular').invalid && this.form.get('celular').touched;
  }

  get calleNoValida() {
    return this.form.get('calle').invalid && this.form.get('calle').touched;
  }

  get alturaNoValida() {
    return this.form.get('altura').invalid && this.form.get('altura').touched;
  }

  get localidadNoValida() {
    return this.form.get('localidad').invalid && this.form.get('localidad').touched;
  }

  get zonaNoValida() {
    return this.form.get('zona').invalid && this.form.get('zona').touched;
  }

  async getEspecialidades() {
    try {
      const especialidades = await this.catalogoService.findDescendantsByKey(EntidadesKeys.ESPECIALIDADES);
      if (especialidades) {
        this.especialidades = especialidades;
        this.filteredEspecialidades = especialidades;
      }
    } catch (error) {
      console.error("Error al cargar las especialidades", error);
    }
  }

  async getZonas() {
    try {
      const zonas = await this.catalogoService.findDescendantsByKey(EntidadesKeys.ZONAS);
      if (zonas) {
        this.zonas = zonas;
      }
    } catch (error) {
      console.error("Error al cargar las zonas", error);
    }
  }

  async getPlanes() {
    try {
      const planes = await this.signoPlanService.getPlanes();
      if (planes) {
        this.planes = planes;
      }
    } catch (error) {
      console.error("Error al cargar los planes", error);
    }
  }

  async getRubros() {
    try {
      const rubros = await this.catalogoService.findDescendantsByKey(EntidadesKeys.RUBROS);
      if (rubros) {
        this.rubros = rubros;
        this.filteredRubros = rubros;
      }
    } catch (error) {
      console.error("Error al cargar los rubros", error);
    }
  }

  async getLocalidades() {
    try {
      const localidades = await this.signoLocalidadService.getLocalidades();
      if (localidades) {
        this.localidades = localidades;
      }
    } catch (error) {
      console.error("Error al cargar las localidades", error);
    }
  }

  async getLocalidadSeleccionada(_id: string): Promise<SignoLocalidad> {
    try {
      const localidad = await this.signoLocalidadService.getLocalidadById(_id);
      if (localidad) {
        const localidadFiltrada: SignoLocalidad = {
          "_id": localidad?._id,
          "nombre": localidad?.nombre,
          "activo": localidad?.activo,
          "zona": localidad?.zona
        };
        return localidadFiltrada;
      }
    } catch (error) {
      console.error("Error al obtener la localidad seleccionada", error);
    }
  }

  initMap() {
    if (!isNaN(this.form.value.latitud) && !isNaN(this.form.value.longitud)) {
      this.mapComponent.getLocation(this.form.value.latitud, this.form.value.longitud);
    }
  }

  updateMap() {
    this.updatesCoordinates();
    if (!isNaN(this.form.value.latitud) && !isNaN(this.form.value.longitud)) {
      this.mapComponent.getLocation(this.form.value.latitud, this.form.value.longitud);
    }
  }

  updatesCoordinates() {
    this.mapComponent.coordinatesChanged.subscribe((coordinates) => {
      this.form.patchValue({
        latitud: coordinates.latitud,
        longitud: coordinates.longitud
      });
    });
  }

  updateMarkerLocation() {
    const latitud = this.form.get('latitud').value;
    const longitud = this.form.get('longitud').value;
    if (this.mapComponent && !isNaN(latitud) !== null && !isNaN(longitud) !== null) {
      this.mapComponent.getLocation(latitud, longitud);
    }
  }

  createForm() {
    this.form = new FormGroup({
      nombre: new FormControl('', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(50),
      ]),
      activo: new FormControl(''),
      calle: new FormControl('', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(50),
      ]),
      altura: new FormControl('', [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(50),
      ]),
      pisoDpto: new FormControl('', [
        Validators.minLength(1),
        Validators.maxLength(50),
      ]),
      telefonos: new FormControl('', [
        Validators.minLength(3),
        Validators.maxLength(50),
        Validators.required,
      ]),
      celular: new FormControl('', [
        Validators.minLength(3),
        Validators.maxLength(50)
      ]),
      horarios: new FormControl('', [
        Validators.minLength(3),
        Validators.maxLength(50),
      ]),
      zona: new FormControl('', [
        Validators.required,
      ]),
      localidad: new FormControl('', [
        Validators.required,
      ]),
      latitud: new FormControl(-34.63104776379445, [
        Validators.pattern(/^-?\d*(\.\d+)?$/)]),
      longitud: new FormControl(-58.46651124133983, [
        Validators.pattern(/^-?\d*(\.\d+)?$/)]),
      rubros: new FormControl([]),
      especialidades: new FormControl([]),
      planes: new FormControl([])
    });
  }

  async createCentroMedico() {
    await this.guiMsjService.msjFormSubmit('Guardando');
    this.signoCentroMedicoService.create(await this.setDataForma());
    await this.guiMsjService.msjFormSubmit('DatosGuardados');
    this.router.navigateByUrl('/centros-medicos-listado');
  }

  async setDataForma(): Promise<SignoCentroMedico> {
    const especaialidadesObjectId = this.form.value.especialidades.map((especialidadId: string) => ({ _id: especialidadId }));
    const rubrosObjectId = this.form.value.rubros.map((rubroId: string) => ({ _id: rubroId }));
    const localidad = await this.getLocalidadSeleccionada(this.form.value.localidad);
    const planesObjectId = this.form.value.planes.map((planesId: string) => ({ _id: planesId }));

    const centroMedico: SignoCentroMedico = {
      nombre: this.form.value.nombre || '',
      activo: this.form.value.activo || false,
      calle: this.form.value.calle || '',
      altura: this.form.value.altura || '',
      pisoDpto: this.form.value.pisoDpto || '',
      telefonos: this.form.value.telefonos || '',
      celular: this.form.value.celular || '',
      zona: {
        _id: this.form.value.zona,
      },
      localidad: localidad,
      horarios: this.form.value.horarios || '',
      longitud: Number(this.form.value.longitud) || 0.0,
      latitud: Number(this.form.value.latitud) || 0.0,
      rubros: rubrosObjectId,
      especialidades: especaialidadesObjectId,
      planes: planesObjectId
    };

    return centroMedico;
  }

   filterEspecialidades(query: string) {
    const selectedEspecialidades = this.form.get('especialidades').value;
    // tslint:disable-next-line:max-line-length
    this.filteredEspecialidades = this.especialidades.filter(especialidad => especialidad.concepto.toLowerCase().includes(query.toLowerCase()) ||
      selectedEspecialidades.includes(especialidad._id)
    );
  }

  filterRubros(query: string) {
    const selectedRubros = this.form.get('rubros').value;
    this.filteredRubros = this.rubros.filter(rubro => rubro.concepto.toLowerCase().includes(query.toLowerCase()) ||
      selectedRubros.includes(rubro._id)
    );
  }

}
