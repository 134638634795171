import { Component, OnInit } from '@angular/core';
import { UsuarioService } from 'src/app/services/shared/usuario.service';
import { Router } from '@angular/router';
import { PerfilesService } from '../../../services/perfiles/perfiles.service';
import { PreviousRouteService } from '@services/shared/previous-route.service';

@Component({
  selector: 'app-entidades-administracion',
  templateUrl: './entidades-administracion.component.html',
  styleUrls: ['./entidades-administracion.component.scss']
})
export class EntidadesAdministracionComponent implements OnInit {

  routes: { url: string, label: string }[] = [{ url: '/', label: 'Inicio' }]

  constructor(
    private router: Router,
    private perfilesService: PerfilesService,
    private previousRouteService: PreviousRouteService,
  ) { }

  ngOnInit(): void {
    this.perfilesService.allowedByUserLogon(this.router.url);
    this.previousRouteService.getRoutes().subscribe(routes => {
      this.routes = routes;
    });
  }
  // this.getCountUsuarios();
}

// async getCountUsuarios(){
//   await this.usuarioService.getCountUsers().then((users: any) => {
//     this.countUsuarios = users;
//   });
// }
