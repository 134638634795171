import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SignoGecrosAfiliado } from 'src/app/interfaces/interface-bakend/entidades/signo-greco-afiliado.interface';
import { User } from 'src/app/interfaces/interface-bakend/seguridad/users/user.interface';
import { GecrosAfiliadosService } from 'src/app/services/entidades/gecros-afiliados.service';
import { UsuarioService } from 'src/app/services/shared/usuario.service';
import Swal from 'sweetalert2';
import { UsuariosListadoComponent } from '../usuarios-listado/usuarios-listado.component';
import { PersonasListadoComponent } from '../personas-listado/personas-listado.component';

@Component({
  selector: 'app-modal-detalle-gecros',
  templateUrl: './modal-detalle-gecros.component.html',
  styleUrls: ['./modal-detalle-gecros.component.scss']
})
export class ModalDetalleGecrosComponent implements OnInit {

  gecrosAfiliado: SignoGecrosAfiliado;
  flagLoad: boolean = false;
  btnSincronizar = false;
  progressBar = false;

  constructor(
    public dialogRef: MatDialogRef<PersonasListadoComponent>,
    private gecrosAfiliadosService: GecrosAfiliadosService,
    private usuarioService: UsuarioService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit(): void {
    this.getAfiliado();
  }

  async getAfiliado() {
    await this.gecrosAfiliadosService.getAfiliadoByIdentificacion(this.data.identificacion).then((resp) => {
      this.gecrosAfiliado = resp.data[0];
      this.flagLoad = true;
    });
  }

  convertirFecha(fechaNacimientoStr: string): string {
    const partesFecha = fechaNacimientoStr.split('/');
    const dia = partesFecha[0];
    const mes = partesFecha[1];
    const anio = partesFecha[2];
  
    const fechaNacimiento = new Date(Date.UTC(+anio, +mes - 1, +dia));
  
    // Formatear la fecha en ISO8601
    const fechaNacimientoISOStr = fechaNacimiento.toISOString().split('T')[0];
  
    return fechaNacimientoISOStr;
  }

  async updateDataGecros() {
    this.btnSincronizar = true;
    this.progressBar = true;
    // separo los nombres por espacio y los guardo en un arreglo
    const nombresAfiliado: string[] = this.gecrosAfiliado?.nombreAfiliado.trim().split(/\s+/);

    // guarda solo el apellido
    const apellido = nombresAfiliado.pop().trim(); // Elimina y guarda el último elemento del arreglo

    // guardo los nombres solamente
    const nombresSinApellido = nombresAfiliado.join(' ');

    const fechaNacimientoISOStr = this.convertirFecha(this.gecrosAfiliado.fechaNacimiento);

    const updateUser: User = {
      nombrePersona: nombresSinApellido,
      apellidoPersona: apellido,
      birth: fechaNacimientoISOStr,
      signoGecrosAfiliado: this.gecrosAfiliado,
      _id: this.data.user._id
    }

    await this.usuarioService.updateOne(updateUser)
    .then(async () => {
      await Swal.fire('Sincronizado', 'Los datos se sincronizaron correctamente', 'success');
      this.dialogRef.close(this.data);
      window.location.reload();
      this.progressBar = false;
    }).catch((error) => {
      Swal.fire('Error al modificar', error, 'error');
      this.btnSincronizar = false;
      this.progressBar = false;
    });
  }

  async sincronizarDatosGecros() {
    const confirmacion = await Swal.fire({
      title: '¿Estás seguro?',
      text: 'Confirma sincronizar los datos de Gecros con Signo Médico?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sí',
      cancelButtonText: 'No',
    });

    if (confirmacion.isConfirmed) {
      await this.updateDataGecros();
    }
  }
}
