import { NgModule } from '@angular/core';

// Imports
import { OfertasLaboralesModule } from './ofertas-laborales.module';
import { ChartsModule } from 'ng2-charts';
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from '../app-routing.module';
import { MaterialModule } from './material.module';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CountUpModule } from 'ngx-countup';
import { StickyNavModule } from 'ng2-sticky-nav';
import { TabsModule } from 'ngx-tabset';
import { NgxScrollTopModule } from 'ngx-scrolltop';
import { LightboxModule } from 'ngx-lightbox';
import { AccordionModule } from 'ngx-accordion';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { PipesModule } from './pipes.module';
import { CommonComponentsModule } from './common.components.module';
import { DatetimePickerModule } from './datetimepicker.module';



//...........................................ABMs Signo Médico..............................................

import { EntidadesAdministracionComponent } from '../components/entidades/entidades-administracion/entidades-administracion.component';

//ABM Sedes
import { SedesListadoComponent } from '../components/entidades/sedes/pages/sedes-listado/sedes-listado.component';
import { SedeModificacionComponent } from '../components/entidades/sedes/pages/sede-modificacion/sede-modificacion.component';
import { SedeAltaComponent } from '../components/entidades/sedes/pages/sede-alta/sede-alta.component';

//ABM Empresas
import { EmpresasListadoComponent } from '../components/entidades/empresas/pages/empresas-listado/empresas-listado.component';
import { EmpresaAltaComponent } from '../components/entidades/empresas/pages/empresa-alta/empresa-alta.component';
import { EmpresaModificacionComponent } from '../components/entidades/empresas/pages/empresa-modificacion/empresa-modificacion.component';
import { AfiliadosAsociadosListadoComponent } from '../components/entidades/empresas/pages/empresa-modificacion/afiliados-asociados-listado/afiliados-asociados-listado.component';

//ABM Rubros
import { RubroAltaComponent } from '../components/entidades/rubros/pages/rubro-alta/rubro-alta.component';
import { RubroModificacionComponent } from '../components/entidades/rubros/pages/rubro-modificacion/rubro-modificacion.component';
import { RubrosListadoComponent } from '../components/entidades/rubros/pages/rubros-listado/rubros-listado.component';

//ABM Especialidades
import { EspecialidadAltaComponent } from '../components/entidades/especialidades/pages/especialidad-alta/especialidad-alta.component';
import { EspecialidadModificacionComponent } from '../components/entidades/especialidades/pages/especialidad-modificacion/especialidad-modificacion.component';
import { EspecialidadesListadoComponent } from '../components/entidades/especialidades/pages/especialidades-listado/especialidades-listado.component';

//ABM Zonas
import { ZonaAltaComponent } from '../components/entidades/zonas/pages/zona-alta/zona-alta.component';
import { ZonaModificacionComponent } from '../components/entidades/zonas/pages/zona-modificacion/zona-modificacion.component';
import { ZonasListadoComponent } from '../components/entidades/zonas/pages/zonas-listado/zonas-listado.component';

// ABM Centros Propios Especialidades
import { CentroPropioEspecialidadAltaComponent } from '../components/entidades/centros-propios-especialidades/pages/centro-propio-especialidad-alta/centro-propio-especialidad-alta.component';
import { CentrosPropiosEspecialidadesListadoComponent } from '../components/entidades/centros-propios-especialidades/pages/centros-propios-especialidades-listado/centros-propios-especialidades-listado.component';
import { CentroPropioEspecialidadModificacionComponent } from '../components/entidades/centros-propios-especialidades/pages/centro-propio-especialidad-modificacion/centro-propio-especialidad-modificacion.component';

//ABM Centros Propios
import { CentroPropioAltaComponent } from '../components/entidades/centros-propios/centro-propio-alta/centro-propio-alta.component';
import { CentrosPropiosListadoComponent } from '../components/entidades/centros-propios/centros-propios-listado/centros-propios-listado.component';
import { CentroPropioModificacionComponent } from '../components/entidades/centros-propios/centro-propio-modificacion/centro-propio-modificacion.component';

//ABM Doctores
import { DoctorAltaComponent } from '../components/entidades/doctores/pages/doctor-alta/doctor-alta.component';
import { DoctorModificacionComponent } from '../components/entidades/doctores/pages/doctor-modificacion/doctor-modificacion.component';
import { DoctoresListadoComponent } from '../components/entidades/doctores/pages/doctores-listado/doctores-listado.component';

//ABM Localidades
import { LocalidadAltaComponent } from '../components/entidades/localidades/pages/localidad-alta/localidad-alta.component';
import { LocalidadModificacionComponent } from '../components/entidades/localidades/pages/localidad-modificacion/localidad-modificacion.component';
import { LocalidadesListadoComponent } from '../components/entidades/localidades/pages/localidades-listado/localidades-listado.component';

//ABM Centros Médicos
import { CentroMedicoAltaComponent } from '../components/entidades/centros-medicos/pages/centro-medico-alta/centro-medico-alta.component';
import { CentroMedicoModificacionComponent } from '../components/entidades/centros-medicos/pages/centro-medico-modificacion/centro-medico-modificacion.component';
import { CentrosMedicosListadoComponent } from '../components/entidades/centros-medicos/pages/centros-medicos-listado/centros-medicos-listado.component';
import { CentrosMedicosAsociadosListadoComponent } from '../components/entidades/especialidades/pages/especialidad-modificacion/centros-medicos-asociados-listado/centros-medicos-asociados-listado.component';

//ABM Planes
import { PlanAltaComponent } from '@components/entidades/planes/pages/plan-alta/plan-alta.component';
import { PlanModificacionComponent } from '@components/entidades/planes/pages/plan-modificacion/plan-modificacion.component';
import { PlanesListadoComponent } from '@components/entidades/planes/pages/planes-listado/planes-listado.component';

//Cartilla buscador
import { CartillaBuscadorComponent } from '@components/ofertas-laborales/pages/cartilla-buscador/cartilla-buscador.component';


import { MapComponent } from '../components/entidades/common/map/map.component';
import { CredencialComponent } from '@components/entidades/credencial/credencial.component';
import { FamiliaresComponent } from '@components/entidades/credencial/familiares/familiares.component';
import { ErrorComponent } from '@components/entidades/common/error/error.component';
import { MenuComponent } from '@components/entidades/entidades-administracion/menu/menu.component';
import { CentrosCardComponent } from '@components/ofertas-laborales/pages/cartilla-buscador/centros-card/centros-card.component';


@NgModule({
    declarations: [
        EntidadesAdministracionComponent,
        MenuComponent,
        EmpresasListadoComponent,
        EmpresaModificacionComponent,
        EmpresaAltaComponent,
        AfiliadosAsociadosListadoComponent,
        SedesListadoComponent,
        SedeModificacionComponent,
        SedeAltaComponent,
        RubrosListadoComponent,
        RubroModificacionComponent,
        RubroAltaComponent,
        EspecialidadesListadoComponent,
        EspecialidadModificacionComponent,
        EspecialidadAltaComponent,
        ZonasListadoComponent,
        ZonaModificacionComponent,
        ZonaAltaComponent,
        CentroPropioEspecialidadAltaComponent,
        CentrosPropiosEspecialidadesListadoComponent,
        CentroPropioEspecialidadModificacionComponent,
        CentroPropioAltaComponent,
        CentrosPropiosListadoComponent,
        CentroPropioModificacionComponent,
        CentroMedicoAltaComponent,
        CentroMedicoModificacionComponent,
        CentrosMedicosListadoComponent,
        CentrosMedicosAsociadosListadoComponent,
        DoctorAltaComponent,
        DoctorModificacionComponent,
        DoctoresListadoComponent,
        LocalidadAltaComponent,
        LocalidadModificacionComponent,
        LocalidadesListadoComponent,
        PlanAltaComponent,
        PlanModificacionComponent,
        PlanesListadoComponent,
        MapComponent,
        CredencialComponent,
        ErrorComponent,
        CartillaBuscadorComponent,
        CentrosCardComponent
    ],
    imports: [
        OfertasLaboralesModule,
        PipesModule,
        ChartsModule,
        CommonModule,
        AppRoutingModule,
        MaterialModule,
        CarouselModule,
        BrowserAnimationsModule,
        CountUpModule,
        StickyNavModule,
        TabsModule,
        NgxScrollTopModule,
        LightboxModule,
        AccordionModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        CommonComponentsModule,
        DatetimePickerModule,
    ],
    exports: [
        EntidadesAdministracionComponent,
        MenuComponent
    ],
})
export class Entidades {}
