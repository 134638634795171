import { Component, OnInit, Inject, ViewChild, AfterViewInit, SimpleChanges, ElementRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { APP_CONFIG_OFERTAS, IAppOfertas } from 'src/app/app.config/app.config-ofertas';
import { OfertasSolicitudComponent } from 'src/app/components/ofertas-laborales/pages/ofertas-administracion/ofertas-solicitud/ofertas-solicitud.component';
import { Solicitud } from '@interfaces/interface-bakend/ofertas-laborales/solicitudes.interface';
import { SolicitudesService } from 'src/app/services/ofertas-laborales/solicitudes.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Actividad } from 'src/app/interfaces/interface-bakend/ofertas-laborales/actividad.interface';
import { Curso } from 'src/app/interfaces/interface-bakend/cursos/curso.interface';
import { APP_CONFIG_SHARED } from 'src/app/app.config/app.config-shared';
import { CursosService } from 'src/app/services/cursos/cursos.service';
import { ActividadesService } from '../../../../services/ofertas-laborales/actividades.service';
import { IAppShared } from '../../../../app.config/app.config-shared';
import { SolicitudSearch, SolicitudSearchElement } from '../../../../interfaces/interface-bakend/ofertas-laborales/solicitudes.interface';
import { GuiMsjService } from '../../../../services/shared/gui-msj.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-table-filter-pre-inscripciones',
  templateUrl: './table-filter-pre-inscripciones.component.html',
  styleUrls: ['./table-filter-pre-inscripciones.component.scss']
})
export class TableFilterPreInscripcionesComponent implements OnInit{

 /* Data Table*/
 @ViewChild('TABLE') table: ElementRef;
 @ViewChild(MatSort) sort: MatSort;
 @ViewChild(MatPaginator) paginator: MatPaginator;
 displayedColumns: string[] =
 [
    'actividadNombre', 'identificacion', 'solicitante' , 'tramite', 'estado', 'data'
 ];
 
 dataSource: any;
 solicitudes: Solicitud [];
 /* Fin Data Table*/

 flagLoadedModal = false;
 flagLoadedTable = false;
 flagLoaded = false;

 activarButton = true;

 toppings = new FormControl();
 formaActividades = new FormControl();

 cursoList: any[] = [];

 // Selectores
 actividades: Actividad[];
 cursos: Curso[]=[];
//  disciplinas: Catalogo[] = [];
//  delegaciones: Catalogo[]=[];
 estados: any[];
 forma: FormGroup;

 selectionChangeActividad: Actividad;
 

 constructor(
     @Inject(APP_CONFIG_OFERTAS) public iAppOfertas: IAppOfertas,
     @Inject(APP_CONFIG_SHARED) public iAppShared: IAppShared,
     private solicitudesService: SolicitudesService,
     public dialog: MatDialog,
     private cursosService: CursosService,
     private actividadesService: ActividadesService,
     private guiMsjService: GuiMsjService,
 ) { }

 ngOnInit(): void {
    this.initComponent();
   
 }

 async initComponent() {
  this.createForm();
  await this.initSelectores();
  this.createListenersStateForm(); 
 }

  createForm() {
    this.forma = new FormGroup({
        actividades: new FormControl('',  [ Validators.required]),
        // cursos: new FormControl('', [ Validators.required]),
        // delegaciones: new FormControl('', [ Validators.required]),
        // disciplinas: new FormControl('', [ Validators.required]),
        estados: new FormControl('', [ Validators.required] ),
    });
  }
  
  async initSelectores() {
    await this.getActividades();
    // await this.getDelegaciones();
    // await this.getDisciplinas();
    await this.getEstadosSolicitud();
    this.flagLoaded = true;		
  }

  async verSolicitud(solicitud) {
    this.flagLoadedModal = true;
    this.solicitudesService.getSolicitudById(solicitud.data.id).then(( (sol: Solicitud) => {
          this.solicitudesService.setSolicitudSeleccionada(sol);          
          this.openDialogModaSolicitud();
    }));
 }
  
  /* Modal - Carga Solicitud */
  openDialogModaSolicitud(): void {
      const dialogRef = this.dialog.open(OfertasSolicitudComponent, {
          width: '900px',
          height: '1000px',
          disableClose: true,
      });
      this.flagLoadedModal = false;
      dialogRef.afterClosed().subscribe(async result => {});
      return;
  }
  async getActividades(){
    this.actividades = await this.actividadesService.getActividades();
  }

  async getModulosCursos() {
    this.cursos  = await this.cursosService.getCursosModulos();
  }

 ngOnChangesActividad(value: string){
    const actividad = this.actividades.find(obj => obj._id === value);
    // this.cursos = actividad.cursos;
 }

  createListenersStateForm() {  
    return this.forma.statusChanges.subscribe( async status => {
    });
  }

  async cargarGrilla(forma) {
    this.forma.setErrors({ 'invalid': true });
    this.dataSource = null;
    this.flagLoadedTable = true;
    await this.loadDataTable(forma.value.actividades, forma.value.estados);
    
  }

  async loadDataTable(inscripcion: string, estados: number[]) {
    this.solicitudesService.getSolicitudesByFilter(inscripcion, estados ).then( ( solds: SolicitudSearchElement[] ) => {
      this.formatDataTable(solds).then( ( data: any[] ) => { 
          //console.log(solds);
          this.dataSource = new MatTableDataSource(data);
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
          this.flagLoadedTable = false;
          if (data.length === 0 ){
            this.guiMsjService.msjFormSubmit('grillaSinDatos')
          }
        });
    });
  }

  formatDataTable( solicitudes: SolicitudSearchElement[]) {
    return new Promise( async resolve => {
      const format: any[] = [];
      let elemento: {
        actividadNombre: string,
        // cursoNombre: string,
        // delegacionNombre: string, // delegacionId
        // disciplinaNombre: string, // disciplinaId
        identificacion: string,
        solicitante: string,
        tramite: string,  
        estado: number,  
        data: SolicitudSearchElement,      
      };

       // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < solicitudes.length; i++) {
        elemento = {
          actividadNombre: solicitudes[i].actividadNombre,
          // cursoNombre: solicitudes[i].cursos[0].nombre,
          // delegacionNombre: solicitudes[i].delegacionNombre,
          // disciplinaNombre: solicitudes[i].disciplinaNombre,
          identificacion: solicitudes[i].identificacion,
          solicitante: solicitudes[i].solicitante,
          tramite: solicitudes[i].tramite,     
          estado: solicitudes[i].estado,     
          data: solicitudes[i],
        };
        format.push(elemento);
      }
      resolve(format);
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  // async getDelegaciones() {
  //   this.delegaciones = await this.catalogoService.findDescendantsByKeyByLenguage(this.iAppShared.delegaciones, null);		
  // } 

  // async getDisciplinas() {
  //   this.disciplinas  = await this.catalogoService.findDescendantsByKeyByLenguage(this.iAppShared.disciplinas ,null);		
  // }

  async getEstadosSolicitud() {
    this.estados  = await this.iAppOfertas.selectEstados;
  }

  exportTOExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.table.nativeElement);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    
    /* save to file */
    XLSX.writeFile(wb, 'SheetJS.xlsx');
  }

}

// https://stackoverflow.com/questions/50398284/angular-material-data-table-export-to-excel
// https://stackblitz.com/edit/angular-uyanwz?file=main.ts