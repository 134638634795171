import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { SignoPlan } from '@interfaces/interface-bakend/entidades/signo-plan.interface';
import { SignoPlanService } from '@services/entidades/signo-planes.service';
import { GuiMsjService } from 'src/app/services/shared/gui-msj.service';
import { MatDialog } from '@angular/material/dialog';
import { PerfilesService } from '@services/perfiles/perfiles.service';
import Swal from 'sweetalert2';
import { AuthService } from '@services/auth/auth.service';

@Component({
  selector: 'app-planes-listado',
  templateUrl: './planes-listado.component.html',
  styleUrls: ['./planes-listado.component.scss']
})
export class PlanesListadoComponent implements OnInit {
  @ViewChild('previousRoute') previousRouteLink: ElementRef;

  /* Data Table*/
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] = [
    'identificacion',
    'denominacion',
    '_id',
  ];

  dataSource: MatTableDataSource<SignoPlan>;
  signoPlanes: SignoPlan[];

  /* Fin Data Table*/

  flagLoaded: boolean = false;
  keyPerfil: string = '';

  constructor(
    private signoPlanService: SignoPlanService,
    private router: Router,
    private guiMsjService: GuiMsjService,
    private perfilesService: PerfilesService,
    public dialog: MatDialog,
    private authService: AuthService,
  ) {
    this.dataSource = new MatTableDataSource();
  }

  ngOnInit(): void {
    this.getPerfil().then(() => {
      this.perfilesService.allowedByUserLogon(this.router.url, this.previousRoute, this.keyPerfil);
    });
    this.loadDataPage();
  }

  async ngAfterViewInit() {
    await this.loadDataPage();
    this.sort.direction = 'asc';
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  get previousRoute() {
    const previousRoute = this.previousRouteLink.nativeElement.getAttribute('href');
    return previousRoute;
  }

  async getPerfil() {
    try {
      const user = await this.authService.getUserLogon();
      if (user) {
        const { key } = user?.perfil
        this.keyPerfil = key;
      }
    }
    catch (error) {
      console.log('ABM - Error al obtener el perfil del usuario', error);
      return '';
    }
  }

  async loadDataTable() {
    await this.signoPlanService
      .getPlanes()
      .then((signoPlan: SignoPlan[]) => {
        this.dataSource = new MatTableDataSource(signoPlan);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.flagLoaded = true;
      });
  }

  async loadDataPage() {
    await this.loadDataTable();
    this.flagLoaded = true;
  }

  async verPlan(_id: string) {
    await this.guiMsjService.msjFormSubmit('Espere');
    Swal.showLoading();
    const plan = await this.signoPlanService.getPlanById(_id);
    if (plan !== undefined) {
      Swal.close();
      this.router.navigateByUrl('/plan-modificacion');
      this.signoPlanService.setPlanSeleccionado(plan);
    }
    else {
      Swal.close();
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event?.target as HTMLInputElement)?.value.trim().toLowerCase();
    const customFilter = (data: any, filter: string): boolean => {
      // Verifica si el valor del filtro está en las columnas
      return (
        data.idRef.toString().toLowerCase().includes(filter) ||
        data.denominacion.toLowerCase().includes(filter)
      );
    };

    // Aplica el filtro personalizado
    this.dataSource.filter = filterValue;
    this.dataSource.filterPredicate = customFilter;
  }

}
