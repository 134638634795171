import { environment } from './../../../environments/environment';
import {
    HttpClient,
    HttpEventType,
    HttpHeaderResponse,
    HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { Observable, Subject } from 'rxjs';
import { GuiMsjService } from './gui-msj.service';
import { Solicitud } from '@interfaces/interface-bakend/ofertas-laborales/solicitudes.interface';

const URL_BACKEND = environment.urlBackend;

interface UploadResponse {
    ok: boolean;
    solicitud: Solicitud;
}

@Injectable({
    providedIn: 'root',
})
export class MediaService {
    private avance = new Subject<any>();
    contadorControl = 0;

    constructor(
        private authservice: AuthService,
        private httpClient: HttpClient,
        private guiMsjService: GuiMsjService,
    ) {}

    /* pre inscripción UPLOAD*/
    // SUBE A TMP LUEGO CUANDO TERMINO LA CARGA DE DOCUMENTO LOS PASA A POST
    uploadAttachJobOfferFromWeb(
        selectedFile: any,
        idUsers: string,
        idAdjunto: string,
        idSolicitud?: string
    ) {

        /* Cuando es un documento extra, no tiene _id de adjunto y lo seteo el id de la solicitud*/
        if (idAdjunto === 'idAdjunto') {
            idAdjunto = idSolicitud;
        }

        return new Promise(async (resolve) => {
            await this.authservice.getJwToken().then((token: any) => {
                const fd = new FormData();
                const headers = new HttpHeaders({
                    'x-token': token,
                    idUsers,
                    idAdjunto,
                });

                fd.append('file', selectedFile, selectedFile.name);

                this.httpClient
                    .post(
                        `${URL_BACKEND}/media/uploadAttachJobOfferFromWeb`,
                        fd,
                        {
                            reportProgress: true,
                            observe: 'events',
                            headers,
                        }
                    )
                    .subscribe(
                        (event: any) => {
                            if (event.type === HttpEventType.UploadProgress) {
                                this.sendAvance(
                                    Math.round(
                                        (event.loaded / event.total) * 100
                                    )
                                );
                            } else {
                                if (event.type === HttpEventType.Response) {
                                    console.log('4-SUBIDO');

                                    if (event.body['ok']) {
                                        resolve(true);
                                    } else {
                                        resolve(false);
                                    }
                                }
                            }
                        },
                        (error) => {
                            this.guiMsjService.msjFormSubmit('UploadError');
                        }
                    );
            });
        });
    }

    uploadOneAttachJobOfferFromWeb(
        selectedFile: any,
        idUsers: string,
        idAdjunto: string,
        idSolicitud: string,
        autorizo: boolean,
    ): Promise<UploadResponse> {
        /* Cuando es un documento extra, no tiene _id de adjunto y lo seteo con el id de la solicitud*/
        if (idAdjunto === 'idAdjunto') {
            idAdjunto = idSolicitud;
        }
        return new Promise(async (resolve, reject) => {
            await this.authservice.getJwToken().then((token: any) => {
                const fd = new FormData();
                const headers = new HttpHeaders({
                    'x-token': token,
                    idUsers,
                    idSolicitud,
                    idAdjunto,
                    autorizo: autorizo.toString() // Convertir a string
                });

                fd.append('file', selectedFile, selectedFile.name);

                this.httpClient
                    .post(
                        `${URL_BACKEND}/media/uploadOneAttachJobOfferFromWeb`,
                        fd,
                        {
                            reportProgress: true,
                            observe: 'events',
                            headers,
                        }
                    )
                    .subscribe(
                        (event: any) => {
                            if (event.type === HttpEventType.UploadProgress) {
                                this.sendAvance(
                                    Math.round(
                                        (event.loaded / event.total) * 100
                                    )
                                );
                            } else {
                                if (event.type === HttpEventType.Response) {
                                    if (event.body['ok']) {
                                        resolve(event.body);
                                    } else {
                                        resolve({
                                            ok: false,
                                        } as UploadResponse);
                                    }
                                }
                            }
                        },
                        (error: Error) => {
                            reject(error);
                            this.guiMsjService.msjFormSubmit('UploadError');
                        }
                    );
            });
        });
    }

    /* CURSOS UPLOAD*/
    uploadImgCursoPresentacion(selectedFile: any, idcurso: string) {
        return new Promise(async (resolve) => {
            await this.authservice.getJwToken().then((token: any) => {
                const fd = new FormData();
                const headers = new HttpHeaders({
                    'x-token': token,
                    idcurso,
                });

                fd.append('file', selectedFile, selectedFile.name);

                this.httpClient
                    .post(`${URL_BACKEND}/media/uploadImgCurso`, fd, {
                        reportProgress: true,
                        observe: 'events',
                        headers,
                    })
                    .subscribe(
                        (event: any) => {
                            if (event.type === HttpEventType.UploadProgress) {
                                // console.log( 'Upload Progress => ' + Math.round(event.loaded / event.total * 100));
                                this.sendAvance(
                                    Math.round(
                                        (event.loaded / event.total) * 100
                                    )
                                );
                            } else {
                                if (event.type === HttpEventType.Response) {
                                    if (event.body['ok']) {
                                        resolve(true);
                                    } else {
                                        resolve(false);
                                    }
                                }
                            }
                        },
                        (error) => {
                            console.log('error', error);
                            this.guiMsjService.msjFormSubmit('UploadError');
                        }
                    );
            });
        });
    }

    /* COMPROBANTE UPLOAD*/
    uploadComprobanteTransferencia(
        selectedFile: any,
        idUsuario: string,
        idBankTransfer: string
    ) {
        return new Promise(async (resolve) => {
            await this.authservice.getJwToken().then((token: any) => {
                const fd = new FormData();
                const headers = new HttpHeaders({
                    'x-token': token,
                    idusuario: idUsuario,
                    idbanktransfer: idBankTransfer,
                });

                fd.append('file', selectedFile, selectedFile.name);

                this.httpClient
                    .post(
                        `${URL_BACKEND}/media/uploadComprobanteTransferencia`,
                        fd,
                        {
                            reportProgress: true,
                            observe: 'events',
                            headers,
                        }
                    )
                    .subscribe(
                        (event: any) => {
                            if (event.type === HttpEventType.UploadProgress) {
                                // console.log( 'Upload Progress => ' + Math.round(event.loaded / event.total * 100));
                                this.sendAvance(
                                    Math.round(
                                        (event.loaded / event.total) * 100
                                    )
                                );
                            } else {
                                if (event.type === HttpEventType.Response) {
                                    if (event.body['ok']) {
                                        resolve(true);
                                    } else {
                                        resolve(false);
                                    }
                                }
                            }
                        },
                        (error) => {
                            console.log('error', error);
                            this.guiMsjService.msjFormSubmit('UploadError');
                        }
                    );
            });
        });
    }

    uploadMp4CursoPresentacion(selectedFile: any, idcurso: string) {
        return new Promise(async (resolve) => {
            await this.authservice.getJwToken().then((token: any) => {
                const fd = new FormData();
                const headers = new HttpHeaders({
                    'x-token': token,
                    idcurso,
                });

                fd.append('file', selectedFile, selectedFile.name);

                this.httpClient
                    .post(`${URL_BACKEND}/media/uploadMp4Curso`, fd, {
                        reportProgress: true,
                        observe: 'events',
                        headers,
                    })
                    .subscribe(
                        (event: any) => {
                            // console.log(event)
                            if (event.type === HttpEventType.UploadProgress) {
                                // console.log( 'Upload Progress => ' + Math.round(event.loaded / event.total * 100));
                                this.sendAvance(
                                    Math.round(
                                        (event.loaded / event.total) * 100
                                    )
                                );
                            } else {
                                if (event.type === HttpEventType.Response) {
                                    if (event.body['ok']) {
                                        resolve(true);
                                    } else {
                                        resolve(false);
                                    }
                                }
                            }
                        },
                        (error) => {
                            console.log('error', error);
                            this.guiMsjService.msjFormSubmit('UploadError');
                        }
                    );
            });
        });
    }

    /* ACTIVIDADES UPLOAD*/
    uploadImgActividad2(selectedFile: any, idactividad: string) {
        return new Promise(async (resolve) => {
            await this.authservice.getJwToken().then((token: any) => {
                const fd = new FormData();
                const headers = new HttpHeaders({
                    'x-token': token,
                    idactividad,
                });

                fd.append('file', selectedFile, selectedFile.name);

                this.httpClient
                    .post(`${URL_BACKEND}/media/uploadImgActividad`, fd, {
                        reportProgress: true,
                        observe: 'events',
                        headers,
                    })
                    .subscribe((event: any) => {
                        if (event.type === HttpEventType.UploadProgress) {
                            // console.log( 'Upload Progress => ' + Math.round(event.loaded / event.total * 100));
                            this.sendAvance(
                                Math.round((event.loaded / event.total) * 100)
                            );
                        } else {
                            if (event.type === HttpEventType.Response) {
                                if (event.body['ok']) {
                                    resolve(true);
                                } else {
                                    resolve(false);
                                }
                            }
                        }
                    });
            });
        });
    }

    /* IMG PERFIL UPLOAD*/
    uploadImgPerfil(selectedFile: any, idusers: string) {
        return new Promise(async (resolve) => {
            await this.authservice.getJwToken().then((token: any) => {
                const fd = new FormData();
                const headers = new HttpHeaders({
                    'x-token': token,
                    idusers,
                });

                fd.append('file', selectedFile, selectedFile.name);

                this.httpClient
                    .post(`${URL_BACKEND}/media/uploadImgPerfil`, fd, {
                        reportProgress: true,
                        observe: 'events',
                        headers,
                    })
                    .subscribe((event: any) => {
                        if (event.type === HttpEventType.UploadProgress) {
                            // console.log( 'Upload Progress => ' + Math.round(event.loaded / event.total * 100));
                            this.sendAvance(
                                Math.round((event.loaded / event.total) * 100)
                            );
                        } else {
                            if (event.type === HttpEventType.Response) {
                                if (event.body['ok']) {
                                    resolve(true);
                                } else {
                                    resolve(false);
                                }
                            }
                        }
                    });
            });
        });
    }

    /* LECCIONES UPLOAD*/
    // tslint:disable-next-line: variable-name
    uploadLeccion(selectedFile: any, idleccion: string, type: string) {
        return new Promise(async (resolve) => {
            await this.authservice.getJwToken().then((token: any) => {
                const fd = new FormData();
                const headers = new HttpHeaders({
                    'x-token': token,
                    idleccion,
                    type,
                });

                fd.append('file', selectedFile, selectedFile.name);

                this.httpClient
                    .post(`${URL_BACKEND}/media/uploadFileLeccion`, fd, {
                        reportProgress: true,
                        observe: 'events',
                        headers,
                    })
                    .subscribe(
                        (event: any) => {
                            if (event.type === HttpEventType.UploadProgress) {
                                // console.log( 'Upload Progress => ' + Math.round(event.loaded / event.total * 100));
                                this.sendAvance(
                                    Math.round(
                                        (event.loaded / event.total) * 100
                                    )
                                );
                            } else {
                                if (event.type === HttpEventType.Response) {
                                    if (event.body['ok']) {
                                        resolve(true);
                                    } else {
                                        resolve(false);
                                    }
                                }
                            }
                        },
                        (error) => {
                            console.log('error', error);
                            this.guiMsjService.msjFormSubmit('UploadError');
                        }
                    );
            });
        });
    }

    // tslint:disable-next-line: variable-name
    uploadUpdateLeccion(selectedFile: any, idleccion: string, type: string) {
        return new Promise(async (resolve) => {
            await this.authservice.getJwToken().then((token: any) => {
                const fd = new FormData();
                const headers = new HttpHeaders({
                    'x-token': token,
                    idleccion,
                    type,
                });

                fd.append('file', selectedFile, selectedFile.name);

                this.httpClient
                    .put(`${URL_BACKEND}/media/uploadFileLeccionUpdate`, fd, {
                        reportProgress: true,
                        observe: 'events',
                        headers,
                    })
                    .subscribe((event: any) => {
                        if (event.type === HttpEventType.UploadProgress) {
                            // console.log( 'Upload Progress => ' + Math.round(event.loaded / event.total * 100));
                            this.sendAvance(
                                Math.round((event.loaded / event.total) * 100)
                            );
                        } else {
                            if (event.type === HttpEventType.Response) {
                                if (event.body['ok']) {
                                    resolve(true);
                                } else {
                                    resolve(false);
                                }
                            }
                        }
                    });
            });
        });
    }

    /* Controlar size file*/
    controlSize(f): boolean {
        const fsize = f.target.files[0].size;
        const file = Math.round(fsize / 1024);
        const maxSizeInKB = 51200; // 50MB en KB (50 * 1024)
        let flag: boolean;

        /// The size of the file.
        if (file >= maxSizeInKB) {
            flag = false;
        } else {
            flag = true;
        }
        return flag;
    }

    /* Controlar size file of img - mp4 and pdf*/
    controlSize50mb(f): boolean {
        const fsize = f.target.files[0].size;
        const file = Math.round(fsize / 1024);
        let flag: boolean;

        /// The size of the file. 50mg
        // if (file >= 51200) {
        if (file >= 204800) {
            flag = false;
        } else {
            flag = true;
        }
        return flag;
    }

    /* OBSERBABLE UPLOAD*/
    clearAvance() {
        this.avance.next();
    }

    getAvance(): Observable<any> {
        return this.avance.asObservable();
    }

    sendAvance(avance: any) {
        this.avance.next({ value: avance });
    }

    getTypeFile(type): string {
        switch (type) {
            case 'image/jpeg': // img - image/png
                return 'img';
                break;

            case 'image/png': // img - image/png
                return 'img';
                break;

            case 'video/mp4': // mp4 - video/mp4
                return 'mp4';
                break;

            case 'application/pdf': // pdf - application/pdf
                return 'pdf';
                break;

            default:
                return 'sinEspecificar';
                break;
        }
    }

    uploadFileExamen(
        idExamen: string,
        pos: string,
        file: any,
        type: string,
        idP?: string,
        posRespuesta?: string
    ) {
        return new Promise(async (resolve) => {
            await this.authservice.getJwToken().then((token: any) => {
                const fd = new FormData();

                const headers = new HttpHeaders({
                    'x-token': token,
                    idExamen,
                    pos,
                    type, // respuesta "R" o pregunta "P"
                    idPregunta: idP || 'SIN-ID',
                    posRespuesta: posRespuesta || 'SIN-POS-RESPUESTA',
                });

                fd.append('file', file, file.name);

                // this.httpClient.post(`${ URL_BACKEND}/media/uploadFileLeccion`, fd, {
                this.httpClient
                    .post(`${URL_BACKEND}/media/uploadFileExamen`, fd, {
                        reportProgress: true,
                        observe: 'events',
                        headers,
                    })
                    .subscribe(
                        (event: any) => {
                            if (event.type === HttpEventType.UploadProgress) {
                                console.log(
                                    'Upload Progress => ' +
                                        Math.round(
                                            (event.loaded / event.total) * 100
                                        )
                                );
                                this.sendAvance(
                                    Math.round(
                                        (event.loaded / event.total) * 100
                                    )
                                );
                            } else {
                                if (event.type === HttpEventType.Response) {
                                    if (event.body['ok']) {
                                        resolve(event.body);
                                    } else {
                                        resolve(false);
                                    }
                                }
                            }
                        },
                        (error) => {
                            console.log('error', error);
                            this.guiMsjService.msjFormSubmit('UploadError');
                        }
                    );
            });
        });
    }

    /* CURSOS UPLOAD*/
    uploadImgPortadaExamen(selectedFile: any, idexamen: string) {
        return new Promise(async (resolve) => {
            await this.authservice.getJwToken().then((token: any) => {
                const fd = new FormData();
                const headers = new HttpHeaders({
                    'x-token': token,
                    idexamen,
                });

                fd.append('file', selectedFile, selectedFile.name);

                this.httpClient
                    .post(`${URL_BACKEND}/media/uploadImgenExamen`, fd, {
                        reportProgress: true,
                        observe: 'events',
                        headers,
                    })
                    .subscribe(
                        (event: any) => {
                            if (event.type === HttpEventType.UploadProgress) {
                                // console.log( 'Upload Progress => ' + Math.round(event.loaded / event.total * 100));
                                this.sendAvance(
                                    Math.round(
                                        (event.loaded / event.total) * 100
                                    )
                                );
                            } else {
                                if (event.type === HttpEventType.Response) {
                                    if (event.body['ok']) {
                                        resolve(true);
                                    } else {
                                        resolve(false);
                                    }
                                }
                            }
                        },
                        (error) => {
                            console.log('error', error);
                            this.guiMsjService.msjFormSubmit('UploadError');
                        }
                    );
            });
        });
    }

    getTipoArchivo(tipo: string): string {
        // tslint:disable-next-line:prefer-const
        let tipoRachivo: string;
        switch (tipo) {
            case 'application/pdf':
                tipoRachivo = 'pdf';
                break;
            case 'video/mp4':
                tipoRachivo = 'mp4';
                break;
            case 'image/png':
                tipoRachivo = 'img';
                break;
            case 'image/jpeg':
                tipoRachivo = 'img';
                break;
            case 'image/jpg':
                tipoRachivo = 'img';
                break;
            default:
                break;
        }

        return tipoRachivo;
    }

    controlContador(): boolean {
        let flag = false;
        if (this.contadorControl > 1) {
            alert('Ya selecciono el documento, se esta procesando..');
            flag = false;
        } else {
            flag = true;
        }
        return flag;
    }

    incrementarContadorControlUpload() {
        this.contadorControl++;
    }

    reiniciarContadorControlUpload() {
        this.contadorControl = 0;
    }

    getContadorControlUpload() {
        return this.contadorControl;
    }
}
