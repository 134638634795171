<div class="page-title-area">
  <div class="container">
    <div class="page-title-content">
      <ul>
        <li><a routerLink="/">Inicio</a></li>
        <li><a routerLink="/configurar-administracion">Configuración
          Administración</a></li>
        <li><a routerLink="/localidades-listado">Listado de Localidades</a></li>
        <li>Modificar Localidad</li>
      </ul>
      <h2>Modificar Localidad</h2>
      <h4 *ngIf="this.localidad">{{localidad.nombre}}</h4>

    </div>
  </div>
  <div class="shape9"><img src="assets/img/shape-signomedico.png" alt="image" width="10%"></div>
</div>

<div class="andorra-apply-content ptb-70  animate__animated animate__fadeInBottomRight">
  <div class="container">
    <div class="row mx-auto">
      <div class="col-lg-12 col-md-12">
        <div class="apply-form">
          <form [formGroup]="form" (ngSubmit)="updateLocalidad()">

            <div class="row">
              <div class="col-lg-6">

                <div class="form-group">
                  <mat-form-field class="example-full-width" appearance="fill" [style.width.%]=100>
                    <mat-label>Localidad</mat-label>
                    <input formControlName="localidad" matInput placeholder="Zona"
                      [class.is-invalid]="localidadNoValida" autocomplete="off">
                    <mat-error *ngIf="localidadNoValida">Ingrese una
                      localidad</mat-error>
                  </mat-form-field>
                </div>

                <div class="form-group">
                  <mat-form-field appearance="fill" class="example-full-width" [style.width.%]=80>
                    <mat-label>Zona</mat-label>
                    <mat-select formControlName="zona" [(ngModel)]="zonaSeleccionada">
                      <mat-option *ngFor="let zona of zonas"
                        [value]="zona._id">{{zona.concepto}}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="zonaNoseleccionada">Seleccione una zona
                    </mat-error>
                  </mat-form-field>
                </div>

                <div class="form-group">
                  <mat-slide-toggle formControlName="activa">Activa</mat-slide-toggle>
                </div>

              </div>

              <div class="row">
                <div class="col-lg-4 col-md-4">
                  <button type="button" routerLink="/localidades-listado" class="btn btn-warning">Cancelar</button>
                </div>

                <div class="col-lg-4 col-md-4">
                  <button [disabled]="!form.valid" type="submit">Guardar</button>
                </div>

              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>