import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { GuiMsjService } from 'src/app/services/shared/gui-msj.service';
import { Catalogo } from '../../../../../interfaces/interface-bakend/shared/catalogo/catalogo.interface';
import { CatalogoService } from '../../../../../services/shared/catalogo.service';
import { EntidadesKeys } from 'src/app/enum/entidades-keys.enum';
import { PerfilesService } from 'src/app/services/perfiles/perfiles.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-zona-alta',
  templateUrl: './zona-alta.component.html',
  styleUrls: ['./zona-alta.component.scss']
})
export class ZonaAltaComponent implements OnInit {

  form: FormGroup;
  catalogo: Catalogo;

  constructor(
    private router: Router,
    private catalogoService: CatalogoService,
    public guiMsjService: GuiMsjService,
  ) {
    this.createForm();
    this.getCatalogo();
  }

  ngOnInit(): void {
  }

  get zonaNoValida() {
    return this.form.get('zona').invalid && this.form.get('zona').touched;
  }

  async getCatalogo() {
    this.catalogo = await this.catalogoService.getFindByKey(EntidadesKeys.ZONAS);
  }

  createForm() {
    this.form = new FormGroup({
      zona: new FormControl('', [Validators.required, Validators.min(3)]),
      activa: new FormControl(false, [Validators.required]),
    });
  }

  async setDataForma(): Promise<Catalogo> {

    const data: Catalogo = {
      key: null,
      concepto: this.form.value.zona,
      logico_1: this.form.value.activa,
      parent: this.catalogo._id,

      properties: {},
    };

    return data;

  }
  async post() {
    await this.guiMsjService.msjFormSubmit('Guardando');
    this.catalogoService.post(await this.setDataForma());
    await this.guiMsjService.msjFormSubmit('DatosGuardados');
    this.router.navigateByUrl('/zonas-listado');
  }

  async createZona() {
    await this.guiMsjService.msjFormSubmit('Guardando');
    this.catalogoService
      .post(await this.setDataForma())
      .then(async () => {
        Swal.fire({
          icon: 'success',
          title: 'Zona guardada satisfactoriamente',
          showConfirmButton: false,
          timer: 1100,
      });
        this.router.navigateByUrl('/zonas-listado');
        return;
      })
      .catch((error) => {
        console.error('Error al crear zona', error);
      });
  }
}
