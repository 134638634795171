import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PerfilesService } from '../../../../services/perfiles/perfiles.service';

@Component({
  selector: 'app-adjuntos-configuracion',
  templateUrl: './adjuntos-configuracion.component.html',
  styleUrls: ['./adjuntos-configuracion.component.scss']
})
export class AdjuntosConfiguracionComponent implements OnInit {

  constructor(
      private perfilesService: PerfilesService,
      private router: Router,
  ) { }

  ngOnInit(): void {
    // this.perfilesService.allowedByUserLogon(this.router.url);
  }

}
