import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MapComponent } from '@components/entidades/common/map/map.component';
import { SignoCentroMedico } from 'src/app/interfaces/interface-bakend/entidades/signo-centro-medico.interface';
import { SignoCentroMedicoService } from 'src/app/services/entidades/signo-centros-medicos.service';
import { SignoLocalidad } from 'src/app/interfaces/interface-bakend/entidades/signo-localidad.interface';
import { SignoLocalidadService } from 'src/app/services/entidades/signo-localidades.service';
import { CatalogoService } from 'src/app/services/shared/catalogo.service';
import { Catalogo } from 'src/app/interfaces/interface-bakend/shared/catalogo/catalogo.interface';
import { EntidadesKeys } from 'src/app/enum/entidades-keys.enum';
import { GuiMsjService } from 'src/app/services/shared/gui-msj.service';
import { SignoPlanService } from '@services/entidades/signo-planes.service';
import { SignoPlan } from '@interfaces/interface-bakend/entidades/signo-plan.interface';

@Component({
  selector: 'app-centro-medico-modificacion',
  templateUrl: './centro-medico-modificacion.component.html',
  styleUrls: ['./centro-medico-modificacion.component.scss']
})
export class CentroMedicoModificacionComponent implements OnInit, AfterViewInit {
  form: FormGroup;
  signoCentroMedico: SignoCentroMedico;
  zonas: Catalogo[];
  localidades: SignoLocalidad[];
  rubros: Catalogo[];
  especialidades: Catalogo[];
  especialidadesSeleccionadas: Catalogo[];
  rubrosSeleccionados: Catalogo[];
  zonaSeleccionada: Catalogo;
  localidadSeleccionada: SignoLocalidad;
  planesSeleccionados: SignoPlan[];
  planes: SignoPlan[];

  @ViewChild(MapComponent) mapComponent: MapComponent;

  flagLoaded: boolean = false;

  constructor(
    private signoCentroMedicoService: SignoCentroMedicoService,
    private catalogoService: CatalogoService,
    private signoLocalidadService: SignoLocalidadService,
    private signoPlanService: SignoPlanService,
    private router: Router,
    private guiMsjService: GuiMsjService,

  ) {
    this.getZonas();
    this.getLocalidades();
    this.getRubros();
    this.getEspecialidades();
    this.getPlanes();
  }

  ngOnInit(): void {
    this.getEstadoCentroMedico()
    this.createForm();
  }

  ngAfterViewInit(): void {
    this.initMap()
    this.updateMap();
  }

  get centroMedicoNoValido() {
    return this.form.get('nombre').invalid && this.form.get('nombre').touched;
  }

  get telefonosNoValidos() {
    return this.form.get('telefonos').invalid && this.form.get('telefonos').touched;
  }

  get celularNoValido() {
    return this.form.get('celular').invalid && this.form.get('celular').touched;
  }

  get calleNoValida() {
    return this.form.get('calle').invalid && this.form.get('calle').touched;
  }

  get alturaNoValida() {
    return this.form.get('altura').invalid && this.form.get('altura').touched;
  }

  get localidadNoValida() {
    return this.form.get('localidad').invalid && this.form.get('localidad').touched;
  }

  get zonaNoValida() {
    return this.form.get('zona').invalid && this.form.get('zona').touched;
  }

  async getEspecialidades() {
    try {
      const especialidades = await this.catalogoService.findDescendantsByKey(EntidadesKeys.ESPECIALIDADES);

      if (especialidades) {
        this.especialidades = especialidades;
      }
    } catch (error) {
      console.error("Error al cargar las especialidades", error);
    }
  }

  async getZonas() {
    try {
      const zonas = await this.catalogoService.findDescendantsByKey(EntidadesKeys.ZONAS);

      if (zonas) {
        this.zonas = zonas;
      }
    } catch (error) {
      console.error("Error al cargar las zonas", error);
    }
  }

  async getPlanes() {
    try {
      const planes = await this.signoPlanService.getPlanes();
      if (planes) {
        this.planes = planes;
      }
    } catch (error) {
      console.error("Error al cargar los planes", error);
    }
  }

  async getRubros() {
    try {
      const rubros = await this.catalogoService.findDescendantsByKey(EntidadesKeys.RUBROS);

      if (rubros) {
        this.rubros = rubros;
      }
    } catch (error) {
      console.error("Error al cargar los rubros", error);
    }
  }

  async getLocalidades() {
    try {
      const localidades = await this.signoLocalidadService.getLocalidades();

      if (localidades) {
        this.localidades = localidades;
      }
    } catch (error) {
      console.error("Error al cargar las localidades", error);
    }
  }

  async getLocalidadSeleccionada(_id: string): Promise<SignoLocalidad> {
    try {
      const localidad = await this.signoLocalidadService.getLocalidadById(_id);
      if (localidad) {
        const localidadFiltrada: SignoLocalidad = {
          "_id": localidad?._id,
          "nombre": localidad?.nombre,
          "activo": localidad?.activo,
          "zona": localidad?.zona
        };
        return localidadFiltrada;
      }
    } catch (error) {
      console.error("Error al obtener la localidad seleccionada", error);
    }
  }

  async getCentroMedico() {
    return (this.signoCentroMedico =
      this.signoCentroMedicoService.getCentroMedicoSeleccionado());
  }

  async getSelectores() {
    this.zonaSeleccionada = this.signoCentroMedico?.zona;
    this.localidadSeleccionada = this.signoCentroMedico?.localidad;
    this.rubrosSeleccionados = this.signoCentroMedico?.rubros;
    this.especialidadesSeleccionadas = this.signoCentroMedico?.especialidades;
    this.planesSeleccionados = this.signoCentroMedico?.planes;
  }

  async getEstadoCentroMedico() {
    if (this.getCentroMedico() === undefined) {
      await this.router.navigateByUrl('/centros-medicos-listado');
    } else {
      await this.getCentroMedico();
      await this.getSelectores();
      this.flagLoaded = true;
    }
  }

  initMap() {
    if (!isNaN(this.form.value.latitud) && !isNaN(this.form.value.longitud)) {
      this.mapComponent.getLocation(this.form.value.latitud, this.form.value.longitud);
    }
  }

  updateMap() {
    this.updatesCoordinates();
    if (!isNaN(this.form.value.latitud) && !isNaN(this.form.value.longitud)) {
      this.mapComponent.getLocation(this.form.value.latitud, this.form.value.longitud);
    }
  }

  updatesCoordinates() {
    this.mapComponent.coordinatesChanged.subscribe((coordinates) => {
      console.log('this.form', this.form);
      this.form.patchValue({
        latitud: coordinates.latitud,
        longitud: coordinates.longitud
      });
    });
  }

  updateMarkerLocation() {
    const latitud = this.form.get('latitud').value;
    const longitud = this.form.get('longitud').value;
    if (this.mapComponent && !isNaN(latitud) !== null && !isNaN(longitud) !== null) {
      this.mapComponent.getLocation(latitud, longitud);
    }
  }

  createForm() {
    this.form = new FormGroup({
      nombre: new FormControl(this.signoCentroMedico?.nombre, [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(250),
      ]),
      activo: new FormControl(this.signoCentroMedico?.activo),
      calle: new FormControl(this.signoCentroMedico?.calle, [
        // Validators.required,
        Validators.minLength(1),
        Validators.maxLength(250),
      ]),
      altura: new FormControl(this.signoCentroMedico?.altura, [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(250),
      ]),
      pisoDpto: new FormControl(this.signoCentroMedico?.pisoDpto, [
        Validators.minLength(1),
        Validators.maxLength(250),
      ]),
      telefonos: new FormControl(this.signoCentroMedico?.telefonos, [
        Validators.minLength(1),
        Validators.maxLength(250),
        Validators.required,
      ]),
      celular: new FormControl(this.signoCentroMedico?.celular, [
        Validators.minLength(1),
        Validators.maxLength(250),
      ]),
      horarios: new FormControl(this.signoCentroMedico?.horarios, [
        Validators.minLength(1),
        Validators.maxLength(250),
      ]),
      zona: new FormControl('', [
        Validators.required,
      ]),
      localidad: new FormControl('', [
        Validators.required,
      ]),
      latitud: new FormControl(this.signoCentroMedico?.latitud, [
        Validators.pattern(/^-?\d*(\.\d+)?$/),
      ]),
      longitud: new FormControl(this.signoCentroMedico?.longitud, [
        Validators.pattern(/^-?\d*(\.\d+)?$/),
      ]),
      rubros: new FormControl('',),
      especialidades: new FormControl(''),
      planes: new FormControl(''),
    });
  }

  async updateCentroMedico() {
    await this.guiMsjService.msjFormSubmit('Guardando');
    this.signoCentroMedicoService.updateCentroMedico(this.signoCentroMedico._id, await this.setDataForma())
    await this.guiMsjService.msjFormSubmit('DatosGuardados');
    this.router.navigateByUrl('/centros-medicos-listado');
  }

  async setDataForma(): Promise<SignoCentroMedico> {

    const especaialidadesObjectId = this.form.value.especialidades.map((especialidadId: string) => ({ _id: especialidadId }))
    const rubrosObjectId = this.form.value.rubros.map((rubroId: string) => ({ _id: rubroId }))
    const localidad = await this.getLocalidadSeleccionada(this.form.value.localidad);
    const planesObjectId = this.form.value.planes.map((planesId: string) => ({ _id: planesId }))

    const centroMedico: SignoCentroMedico = {
      nombre: this.form.value.nombre || '',
      activo: this.form.value.activo || false,
      calle: this.form.value.calle || '',
      altura: this.form.value.altura || '',
      pisoDpto: this.form.value.pisoDpto || '',
      telefonos: this.form.value.telefonos || '',
      celular: this.form.value.celular || '',
      zona: {
        _id: this.form.value.zona,
      },
      localidad: localidad,
      horarios: this.form.value.horarios || '',
      longitud: Number(this.form.value.longitud) || 0.0,
      latitud: Number(this.form.value.latitud) || 0.0,
      rubros: rubrosObjectId,
      especialidades: especaialidadesObjectId,
      planes: planesObjectId
    };

    return centroMedico;
  }
}
