<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Inicio</a></li>
                <li><a routerLink="/usuarios-administracion">Configuración Usuario</a></li>
                <li>Modificar Usuario</li>
            </ul>
            <h2>Modificar Usuario</h2>
        </div>
    </div>
</div>

<app-loading *ngIf="!flagLoaded || flagDisableButton" class="m-5"></app-loading><br>

<div *ngIf="flagLoaded" class="andorra-apply-content ptb-70 animate__animated animate__fadeIn">
    <div class="container">
        <div class="row mx-auto">
            <div class="col-lg-12 col-md-12">
                <div class="apply-form">
                    <form [formGroup]="forma" (ngSubmit)="ngSubmit()">
                        <div class="form-group">
                            <mat-form-field class="example-full-width" appearance="fill" [style.width.%]=100>
                                <mat-label>Nombre Persona</mat-label>
                                <input [disabled]=true formControlName="nombrePersona"
                                    [class.is-invalid]="nombrePersona" matInput placeholder="Ingrese un nombre"
                                    autocomplete="off">
                                <small *ngIf="nombrePersona" class="text-danger">Ingrese el nombre</small>
                            </mat-form-field>
                        </div>

                        <div class="form-group">
                            <mat-form-field class="example-full-width" appearance="fill" [style.width.%]=100>
                                <mat-label>Apellido Persona</mat-label>
                                <input [disabled]=true formControlName="apellidoPersona"
                                    [class.is-invalid]="apellidoPersona" matInput placeholder="Ingrese un apellido"
                                    autocomplete="off">
                                <small *ngIf="apellidoPersona" class="text-danger">Ingrese el nombre</small>
                            </mat-form-field>
                        </div>

                        <div class="form-group">
                            <mat-form-field class="example-full-width" appearance="fill" [style.width.%]=100>
                                <mat-label>Nickname</mat-label>
                                <input formControlName="nickname" [class.is-invalid]="nickname" matInput
                                    placeholder="Ingrese un apellido" autocomplete="off">
                                <small *ngIf="nickname" class="text-danger">Ingrese el nickname</small>
                            </mat-form-field>
                        </div>

                        <div class="alert alert-info" role="alert">
                            <strong>Nota:</strong> Si cambia el email, corrobore que sea correcto ya que todas las
                            notificaciones del sistema se enviarán a esa cuenta.
                        </div>

                        <div class="form-group">
                            <mat-form-field class="example-full-width" appearance="fill" [style.width.%]=100>
                                <mat-label>Email - ATENCIÓN: debe ser una cuenta valida (usuario con el que se ingresa
                                    al sistema)</mat-label>
                                <input [disabled]=true formControlName="email" [class.is-invalid]="email" matInput
                                    placeholder="Ingrese un email" autocomplete="off">
                                <small *ngIf="email" class="text-danger">Ingrese el email</small>
                            </mat-form-field>
                        </div>

                        <div class="form-group">
                            <mat-form-field appearance="fill" [style.width.%]=100>
                                <mat-label>Perfil</mat-label>
                                <mat-select formControlName="perfil" [disabled]="desdeAfiliados"
                                    [class.is-invalid]="perfil">
                                    <mat-option *ngFor="let p of selectPerfiles" [value]="p._id">{{ p.descripcion
                                        }}</mat-option>
                                </mat-select>
                                <small *ngIf="perfil" class="text-danger">Seleccione un perfil</small>
                            </mat-form-field>
                        </div>

                        <div class="alert alert-danger" role="alert">
                            <strong>Nota:</strong> ¡ATENCIÓN! Los AFILIADOS NO pueden tener más de una SEDE. Nunca
                            asignar más de una sede a un afiliado. Solo usuarios administrativos pueden realizar esta
                            acción.
                        </div>

                        <div class="form-group">
                            <mat-form-field appearance="fill" [style.width.%]=100>
                                <mat-label>Sedes</mat-label>
                                <mat-select formControlName="signoSedes" [disabled]="desdeAfiliados || !sedes"
                                    [(ngModel)]="sedesSeleccionadas" multiple>
                                    <mat-option *ngFor="let sede of sedes" [value]="sede._id">{{ sede.nombre
                                        }}</mat-option>
                                </mat-select>
                                <small *ngIf="sede" class="text-danger">Seleccione al menos una sede</small>
                            </mat-form-field>
                        </div>

                        <div class="form-group">
                            <mat-slide-toggle [(ngModel)]="isChecked"
                                formControlName="estado">Suspendido</mat-slide-toggle>
                        </div>

                        <div class="form-group">
                            <mat-slide-toggle [(ngModel)]="emailChecked" formControlName="email_verified">Email
                                verificado</mat-slide-toggle>
                        </div>

                        <button [disabled]="!forma.valid || flagDisableButton" type="submit">Guardar</button>

                        <a *ngIf="!this.desdeAfiliados" routerLink="/usuarios-listado"><button
                                type="button">Cancelar</button></a>
                        <a *ngIf="this.desdeAfiliados" routerLink="/afiliados-listado"><button
                                type="button">Cancelar</button></a>
                        <br><br>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>