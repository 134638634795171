<!-- ACTIVAR PARA AADIDESS SI LO SOLICITA ESTA EN VARIAS PAG (PROFILE / GYM Y HOME)-->
<!-- <app-cookiebot></app-cookiebot> -->
<div
    class="gym-home-area"
    *ngFor="let Image of bgImage;"
    style="background-image: url({{Image.img}});"
>
    <div class="gym-banner-slides">
        <owl-carousel-o [options]="gymBannerSlides">
            <ng-template carouselSlide>
                <div class="gym-banner-item">
                    <div class="container-fluid">
                        <div class="row align-items-center">
                            <div class="col-lg-6 col-md-12">
                                <div class="gym-banner-content ">
                                    <img
                                        src="assets/img/logo-signomedico-2.png"
                                        alt="SignoMédico"
                                    >
                                    <h1>{{ idioma | translate: "home.BIENVENIDA" | async}} Signo Médico</h1>
                                    <p>{{ idioma | translate: "home.BIENVENIDA-SUBTITULO" | async}}</p>
                                    <div class="row">
                                        <div class="col-lg-3 col-md-12">
                                            <button
                                                routerLink="/profile-authentication"
                                                type="button"
                                                (click)="this.navigateRoot('/profile-authentication')"
                                                class="default-btn animate__animated animate__bounceInUp"
                                            ><i class="fas fa-user-circle"></i>{{ idioma | translate:
                                                "login.INICIAR-SESION" | async}}</button>
                                        </div>
                                    </div>
                                    <br>
                                    <!-- <div class="row">
                                        <div class="col-lg-4 col-md-12">
                                            <app-modal-legal [TC]="true" [PDP]="true"></app-modal-legal>
                                        </div>
                                    </div> -->
                                    <!-- TECNODRET LOGO -->
                                    <!--<app-tecnodret></app-tecnodret>-->
                                    <!-- FIN TECNODRET LOGO -->
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-12">
                                <div class="gym-banner-image">
                                    <img
                                        src="assets/img/sliders/slider-1.png"
                                        alt="image"
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="gym-banner-item">
                    <div class="container-fluid">
                        <div class="row align-items-center">
                            <div class="col-lg-6 col-md-12">
                                <div class="gym-banner-content">
                                    <img
                                        src="assets/img/logo-signomedico-2.png"
                                        alt="SignoMedico"
                                    >
                                    <h1>{{ idioma | translate: "home.BANNER2-TITULO" | async}}</h1>
                                    <p>{{ idioma | translate: "home.BANNER2-SUBTITULO" | async}}</p>
                                    <div class="row">
                                        <div class="col-lg-6 col-md-12">
                                            <button
                                                routerLink="/profile-authentication"
                                                type="button"
                                                w
                                                (click)="this.navigateRoot('/profile-authentication')"
                                                class="default-btn animate__animated animate__bounceInUp"
                                            ><i class="fas fa-user-circle"></i>{{ idioma | translate:
                                                "login.INICIAR-SESION" | async}}</button>
                                        </div>
                                    </div>
                                    <br>
                                    <!-- <div class="row">
                                        <div class="col-lg-4 col-md-12">
                                            <app-modal-legal [TC]="true" [PDP]="true"></app-modal-legal>
                                        </div>
                                    </div> -->
                                    <!-- TECNODRET LOGO -->
                                    <app-tecnodret></app-tecnodret>
                                    <!-- FIN TECNODRET LOGO -->
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-12">
                                <div class="gym-banner-image">
                                    <img
                                        src="assets/img/sliders/slider-2.png"
                                        alt="image"
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </owl-carousel-o>
    </div>

</div>