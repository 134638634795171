import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
 providedIn: 'root'
})
export class StepperActualizacionService {
 private _estadoSolicitud = new BehaviorSubject<number>(null);
 public estadoSolicitud$ = this._estadoSolicitud.asObservable();

 actualizarEstado(nuevoEstado: number) {
   this._estadoSolicitud.next(nuevoEstado);
 }
}
