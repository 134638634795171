import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Subscription } from 'rxjs';
import { Translators } from 'src/app/services/shared/translators.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import Swal from 'sweetalert2';
import { GuiToolsService } from 'src/app/services/shared/gui-tools.service';

@Component({
    selector: 'app-gym-coaching-banner',
    templateUrl: './gym-coaching-banner.component.html',
    styleUrls: ['./gym-coaching-banner.component.scss']
})
export class GymCoachingBannerComponent implements OnInit {

    idioma: Subscription;

    gymBannerSlides: OwlOptions = {
        items: 1,
        nav: true,
        loop: true,
        dots: false,
        autoplay: false,
        animateIn: 'fadeIn',
        animateOut: 'fadeOut',
        autoplayHoverPause: true,
        autoplayTimeout: 10000,
        navText: [
            '<i class=\'flaticon-chevron\'></i>',
            '<i class=\'flaticon-right-arrow\'></i>'
        ],
        responsive: {
            0: {
                autoHeight: true
            },
            576: {
                autoHeight: false
            },
            768: {
                autoHeight: false
            },
            992: {
                autoHeight: false
            }
        }
    };

    bgImage = [
        {
            img: 'assets/img/gym-banner-bg.jpg'
        }
    ];

    constructor(
        private translatorService: Translators,
        private router: Router,
        private authservice: AuthService,
        private guiToolsService: GuiToolsService,
    ) {
        this.idioma = this.translatorService.getIdiomaSeleccionado().subscribe(resp => {
            this.idioma = resp;
          });
    }

    ngOnInit(): void {
    }

    useLanguage(idioma: string){
        this.translatorService.setIdiomaSeleccionado(idioma);
    }

    navigateRoot(url: string){
        this.router.navigateByUrl(url);
    }

    getGuestToken() {
        Swal.showLoading()
        this.authservice.getGuestToken();
    }


}
