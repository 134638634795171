import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UsuarioService } from 'src/app/services/shared/usuario.service';
import { PerfilesService } from './../../../../services/perfiles/perfiles.service';

@Component({
  selector: 'app-usuarios-administracion',
  templateUrl: './usuarios-administracion.component.html',
  styleUrls: ['./usuarios-administracion.component.scss']
})
export class UsuariosAdministracionComponent implements OnInit {

  countUsuarios = 0;

  constructor(
      private router: Router ,
      private perfilesService: PerfilesService,
      private usuarioService: UsuarioService
  ) { }

  ngOnInit(): void {
    this.perfilesService.allowedByUserLogon(this.router.url);
    this.getCountUsuarios();
  }

  async getCountUsuarios(){
    await this.usuarioService.getCountUsers().then((users: any) => {
      this.countUsuarios = users;
    });
  }


}
