import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PerfilesService } from 'src/app/services/perfiles/perfiles.service';
import { GuiMsjService } from 'src/app/services/shared/gui-msj.service';
import { Catalogo } from '../../../../../interfaces/interface-bakend/shared/catalogo/catalogo.interface';
import { CatalogoService } from '../../../../../services/shared/catalogo.service';
import { EntidadesKeys } from 'src/app/enum/entidades-keys.enum';

@Component({
    selector: 'app-centro-propio-especialidad-alta',
    templateUrl: './centro-propio-especialidad-alta.component.html',
    styleUrls: ['./centro-propio-especialidad-alta.component.scss'],
})
export class CentroPropioEspecialidadAltaComponent implements OnInit {
    form: FormGroup;
    catalogo: Catalogo;


    constructor(
        private router: Router,
        private catalogoService: CatalogoService,
        public guiMsjService: GuiMsjService
    ) {
        this.createForm();
        this.getCatalogo();
    }

    ngOnInit(): void {}

    get especialidadNoValida() {
        return this.form.get('especialidad').invalid && this.form.get('especialidad').touched;
    }

    async getCatalogo() {
        this.catalogo = await this.catalogoService.getFindByKey(EntidadesKeys.CENTROS_PROPIOS_ESPECIALIDADES);
    }

    createForm() {
        this.form = new FormGroup({
            especialidad: new FormControl('', [
                Validators.required,
                Validators.min(3),
            ]),
            activa: new FormControl(false, [Validators.required]),
        });
    }

    async setDataForma(): Promise<Catalogo> {
        const data: Catalogo = {
            key: null,
            concepto: this.form.value.especialidad,
            logico_1: this.form.value.activa,
            parent: this.catalogo._id,
            properties: {},
        };

        return data;
    }

    async post() {
        await this.guiMsjService.msjFormSubmit('Guardando');
        this.catalogoService.post(await this.setDataForma());
        await this.guiMsjService.msjFormSubmit('DatosGuardados');
        this.router.navigateByUrl('/centros-propios-especialidades-listado');
    }
}
