<div class="page-title-area">
  <div class="container">
    <div class="page-title-content">
      <ul>
        <li><a routerLink="/">Inicio</a></li>
        <li><a routerLink="/configurar-administracion">Configuración
          Administración</a></li>
        <li><a routerLink="/doctores-listado">Listado de Doctores</a></li>
        <li>Nuevo Doctor</li>
      </ul>
      <h2>Nuevo Doctor</h2>
    </div>
  </div>
  <div class="shape9"><img src="assets/img/shape-signomedico.png" alt="image" width="10%"></div>
</div>

<div class="andorra-apply-content ptb-70  animate__animated animate__fadeIn">
  <div class="container">
    <div class="row mx-auto">
      <div class="col-lg-12 col-md-12">
        <div class="apply-form">
          <form [formGroup]="form" (ngSubmit)="createDoctor()">

            <div class="row">
              <div class="col-lg-6 col-sm-6 col-md-6 col-xs-12">
                <div class="form-group">
                  <mat-form-field class="example-full-width" appearance="fill" [style.width.%]=100>
                    <mat-label>Doctor</mat-label>
                    <input formControlName="doctor" matInput placeholder="Doctor"
                      [class.is-invalid]="doctorNoValido" autocomplete="off">
                    <mat-error *ngIf="doctorNoValido">Ingrese un
                      doctor</mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-6 col-sm-6 col-md-6 col-xs-12">
                <div class="form-group">
                  <mat-form-field appearance="fill" class="example-full-width" [style.width.%]=80>
                    <mat-label>Especialidad</mat-label>
                    <mat-select formControlName="especialidad">
                      <mat-option *ngFor="let especialidad of especialidades"
                        [value]="especialidad._id">{{especialidad.concepto}}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="especialidadNoSeleccionada">Seleccione una especialidad </mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-6 col-sm-6 col-md-6 col-xs-12">
                <div class="form-group">
                  <mat-form-field appearance="fill" class="example-full-width" [style.width.%]=100>
                    <mat-label>Centros Propios</mat-label>
                    <mat-select formControlName="centro" multiple>
                      <mat-option *ngFor="let centro of centros"
                        [value]="centro._id">{{centro.concepto}}</mat-option>
                    </mat-select>
                    <mat-hint>(Puede seleccionar más de uno o ninguno, no es requerido)</mat-hint>
                  </mat-form-field>
                </div>
              </div>
            </div>

            <div class="row my-4">
              <div class="col-lg-4 col-md-4 col-xs-12">
                <div class="form-group">
                  <mat-slide-toggle formControlName="activo">Activo</mat-slide-toggle>
                </div>
              </div>
            </div>

            <div class="row">

              <div class="col-lg-4 col-md-4">
                <button type="button" routerLink="/doctores-listado"
                  class="btn btn-warning">Cancelar</button>
              </div>

              <div class="col-lg-4 col-md-4">
                <button [disabled]="!form.valid" type="submit">Guardar</button>
              </div>

            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>