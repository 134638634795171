<div class="single-courses-box animate__animated animate__jackInTheBox contenedorMQuery" (click)="navCtrlDetalleOfertaLaboral(actividad)">
    <div class="courses-image">
        <a (click)="navCtrlDetalleOfertaLaboral(actividad)" routerLink='/solicitud-tramites' class="image">
            <img [src]=" 1 | mediaOfertaLaboral: actividad.imagen | safe" alt="image" class="imgMediaquery">
            <a class="link-btn"></a>
        </a>
    </div>
    <div class="courses-content">
        <div class="container">
            <h3>
                <a (click)="navCtrlDetalleOfertaLaboral(actividad)" routerLink='/solicitud-tramites' class="link-btn">{{actividad.nombre}}</a>
            </h3>
            <h6>{{ actividad.resumenAvatar}}</h6>
        </div>
    </div>
</div>
