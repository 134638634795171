

import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { OfertasSolicitudComponent } from '../ofertas-solicitud/ofertas-solicitud.component';
import { Solicitud } from '../../../../../interfaces/interface-bakend/ofertas-laborales/solicitudes.interface';
import { APP_CONFIG_OFERTAS, IAppOfertas } from '../../../../../app.config/app.config-ofertas';
import { SolicitudesService } from '../../../../../services/ofertas-laborales/solicitudes.service';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth/auth.service';
import { SignoSede } from 'src/app/interfaces/interface-bakend/entidades/signo-sede.interface';
import { SignoSedeService } from 'src/app/services/entidades/signo-sedes.service';
import { Router } from '@angular/router';
import { Actividad } from '@interfaces/interface-bakend/ofertas-laborales/actividad.interface';
import { ActividadesService } from '@services/ofertas-laborales/actividades.service';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ModalDetalleGecrosComponent } from '@components/usuarios/pages/modal-detalle-gecros/modal-detalle-gecros.component';
import { ModalDetalleComponent } from '@components/usuarios/pages/modal-detalle/modal-detalle.component';
import { UserEntreUser } from '@interfaces/interface-bakend/seguridad/users/user-entre-user.interface';

@Component({
    selector: 'app-ofertas-listado',
    templateUrl: './ofertas-listado.component.html',
    styleUrls: ['./ofertas-listado.component.scss'],
})
export class OfertasListadoComponent implements OnInit , OnDestroy{
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    displayedColumns: string[] = [
        'nro_solicitud', 'signoSedeNombre', 'solicitante', 'beneficiario', 'parentesco', 'cobertura', 'fechaEstado', 'trámite', 'identificacion', 'estado', 'progreso', 'id'
    ];

    dataSource = new MatTableDataSource<Solicitud>();
    solicitudes: Solicitud[] = [];
    solicitudesFiltradas: Solicitud[] = [];
    solicitudesNuevas: Solicitud[] = [];
    flagLoaded = true;
    selectEstado: number;
    selectSede: string[] = [];
    flagLoadedModal = true;
    activarButton = true;
    estado: number;
    sede: string;
    fecha: any;

    newState: Subscription;
    private newStateSubscription: Subscription;
    sedes: SignoSede[] = [];
    sedesByUsuario: SignoSede[] = [];
    selectedEstados: number[] = [];
    actividades: Actividad[] = [];
    idActividadSeleccionada: string;
    flagSolicitudesCargadas = false;
    solicitudCounts: { pendientes: { [key: string]: number }, leidas: { [key: string]: number } } = {
        pendientes: {},
        leidas: {}
    };
    fechaDesde: Date;
    fechaHasta: Date;

    cantidadSolicitudesPendientes = 0;
    cantidadSolicitudesLeidas = 0;
    cantidadSolicitudesTotal = 0;

    loading: boolean = false;

    constructor(
        @Inject(APP_CONFIG_OFERTAS) public iAppOfertas: IAppOfertas,
        private router: Router,
        private solicitudesService: SolicitudesService,
        public dialog: MatDialog,
        private authService: AuthService,
        private signoSedeService: SignoSedeService,
        private actividadesService: ActividadesService,
        private snackBar: MatSnackBar
    ) {

        this.newStateSubscription = this.solicitudesService.getsetNewStater().subscribe(resp => {

            // Encuentra la solicitud en filteredData por su _id
            const solicitudIndex = this.dataSource.filteredData.findIndex(solicitud => solicitud._id === resp?.state?._id);
            
            if (solicitudIndex !== -1) {
                // Actualiza el valor de lastState_numberState con lastState.numberState
                this.dataSource.filteredData[solicitudIndex].lastState_numberState = resp.state?.lastState?.numberState;

                // Si quieres reflejar los cambios en el dataSource, asigna una nueva instancia de MatTableDataSource
                this.dataSource = new MatTableDataSource(this.dataSource.filteredData);

                // Si estás usando paginación y ordenación, asignalos de nuevo
                this.dataSource.paginator = this.paginator;
                this.dataSource.sort = this.sort;
            }

            this.newState = resp;
        });


        const hoy = new Date();
        this.fechaDesde = hoy;
        this.fechaHasta = hoy;
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
        if (this.newStateSubscription) {
            this.newStateSubscription.unsubscribe();
        }
    }

    formatDate(date: Date): string {
        return date.toISOString().split('T')[0];
    }

    async buscarPorFechas() {
        this.cantidadSolicitudesLeidas = 0;
        this.cantidadSolicitudesPendientes = 0;
        this.cantidadSolicitudesTotal = 0;
        if (this.fechaDesde && this.fechaHasta) {
            this.flagLoaded = true;
            this.fecha = {
                fechaDesde: this.formatDate(this.fechaDesde),
                fechaHasta: this.formatDate(this.fechaHasta)
            };
            this.flagLoaded = false;
            this.flagSolicitudesCargadas = false;
            await this.loadData().then(async () => {
                const sedes: any = {
                    signoSedes: this.selectSede
                };
                await this.cargarInicialdeSolicitudes(sedes, this.fecha);
                // this.checkForNewSolicitudes(); // Verificar si hay nuevas solicitudes
                this.flagLoaded = true;
            });
        } else {
            this.flagLoaded = false;
            console.log('Por favor, seleccione ambas fechas.');
        }
    }

    async loadData() {
        this.flagSolicitudesCargadas = false;
        const sedes = await this.getSedesPorUsuario();
        await this.getActividades();
        await this.cargarSolicitudesPorSede(sedes);
    }

    async getActividades() {
        this.actividades = await this.actividadesService.getActividades();
    }

    async getSedesPorUsuario(): Promise<string[]> {
        this.sedes = await this.signoSedeService.getSedes();
        const usuario = await this.authService.getUserLogon();
        if (this.sedes !== undefined) {
            this.sedesByUsuario = this.sedes.filter((sede: any) => usuario.signoSedes.includes(sede?._id));
            this.checkSedes();
        }
        return this.selectSede;
    }

    checkSedes() {
        this.selectSede = this.sedesByUsuario.map(sede => sede._id);
    }

    // checkEstados() { // REINICIA LOS ESTADOS
    //     this.selectedEstados = this.iAppOfertas.selectEstados.map(estado => estado.number);
    // }

    checkEstados() {
        if (this.selectedEstados.length === 0) {
            this.selectedEstados = this.iAppOfertas.selectEstados.map(estado => estado.number);
        }
    }

    async cargarSolicitudesPorSede(sedes: string[]) {
        if (sedes && sedes.length > 0) {
            this.sede = sedes.join(', ');
            await this.applyFilters();
        }
    }

    async cargarSolicitudesPorEstado(selectedEstados: number[]) {
        if (selectedEstados.length > 0) {
            this.selectedEstados = selectedEstados;
            await this.applyFilters();
        } else {
            console.log('sin coincidencias');
        }
    }

    openDialogModaSolicitud(): void {
        const dialogRef = this.dialog.open(OfertasSolicitudComponent, {
            width: '900px',
            height: '1000px',
            disableClose: false,
        });

        dialogRef.afterClosed().subscribe(() => {
            this.flagLoadedModal = true;
        });
    }

    async loadDataTableBySede(sedes: string[], fecha: any) {
        try {
            const psedes: any = {
                signoSedes: sedes
            };
            this.controlCargaSolicitudes(this.solicitudes, psedes, fecha);
        } catch (error) {
            console.error('Error al cargar solicitudes por sede:', error);
        }
    }

    async applyFilters(): Promise<Solicitud[]> {
        let filteredSolicitudes = this.solicitudes;

        if (this.selectedEstados.length > 0) {
            filteredSolicitudes = filteredSolicitudes.filter(solicitud => this.selectedEstados.includes(solicitud.lastState_numberState));
        }

        if (this.selectSede.length > 0) {
            filteredSolicitudes = filteredSolicitudes.filter(solicitud => this.selectSede.includes(solicitud.signoSede._id));
        }

        // if (this.fechaDesde && this.fechaHasta) {
        //     const fechaDesde = new Date(this.fechaDesde).getTime();
        //     const fechaHasta = new Date(this.fechaHasta).getTime();
        //     filteredSolicitudes = filteredSolicitudes.filter(solicitud => {
        //         const fechaSolicitud = new Date(solicitud.createdAt).getTime();
        //         return fechaSolicitud >= fechaDesde && fechaSolicitud <= fechaHasta;
        //     });
        // }

        return await this.formatDataTable(filteredSolicitudes, this.idActividadSeleccionada).then((data: Solicitud[]) => {
            this.dataSource = new MatTableDataSource<Solicitud>(data);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
            this.flagLoaded = true;
            return data;
        });
    }

    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();

        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }

    reloadCurrentRoute() {
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.router.navigate(['autorizaciones-listado']);
        });
    }

    async formatDataTable(solicitudes: Solicitud[], idActividadSeleccionada: string): Promise<Solicitud[]> {
        console.log('formatDataTable', solicitudes, idActividadSeleccionada);
        const formattedData = solicitudes.filter(s => s.actividadId === idActividadSeleccionada);
        return formattedData;
    }

    // calculateSolicitudCounts(solicitudes: Solicitud[]) {

    //     // Reinicializar los contadores
    //     this.solicitudCounts = {
    //         pendientes: {},
    //         leidas: {}
    //     };
    //     this.cantidadSolicitudesPendientes = 0;
    //     this.cantidadSolicitudesLeidas = 0;

    //     solicitudes.forEach(solicitud => {
    //         const actividadNombre = solicitud.actividad_nombre ? solicitud.actividad_nombre : 'Desconocida';

    //         // Inicializar los contadores si no existen
    //         if (!this.solicitudCounts.pendientes[actividadNombre]) {
    //             this.solicitudCounts.pendientes[actividadNombre] = 0;
    //         }
    //         if (!this.solicitudCounts.leidas[actividadNombre]) {
    //             this.solicitudCounts.leidas[actividadNombre] = 0;
    //         }

    //         // Contar según el estado de "leido"
    //         if (solicitud.leido === undefined || solicitud.leido === false) {
    //             this.solicitudCounts.pendientes[actividadNombre]++;
    //             this.cantidadSolicitudesPendientes++;
    //         } else {
    //             this.solicitudCounts.leidas[actividadNombre]++;
    //             this.cantidadSolicitudesLeidas++;
    //         }
    //     });
    // }

    calculateSolicitudCounts(solicitudes: Solicitud[]) {
        console.log('calculateSolicitudCounts - this.solicitudes', this.solicitudes);
        // Reinicializar los contadores
        this.solicitudCounts = {
            pendientes: {},
            leidas: {}
        };

        solicitudes.forEach(solicitud => {
            const actividadNombre = solicitud.actividad_nombre ? solicitud.actividad_nombre : 'Desconocida';
            // Inicializar los contadores si no existen
            if (!this.solicitudCounts.pendientes[actividadNombre]) {
                this.solicitudCounts.pendientes[actividadNombre] = 0;
            }
            if (!this.solicitudCounts.leidas[actividadNombre]) {
                this.solicitudCounts.leidas[actividadNombre] = 0;
            }

            // Contar según el estado de "leido" y si la sede está en la lista del usuario
            const isSedeInUsuario = this.sedesByUsuario.some(sede => sede._id === solicitud.signoSede._id);
            if (!solicitud.leido && isSedeInUsuario) {
                this.solicitudCounts.pendientes[actividadNombre]++;
                this.cantidadSolicitudesPendientes++;
                this.cantidadSolicitudesTotal++;
            } else if (solicitud.leido) {
                this.solicitudCounts.leidas[actividadNombre]++;
                this.cantidadSolicitudesLeidas++;
                this.cantidadSolicitudesTotal++;
            }
        });

        console.log('this.solicitudCounts', this.solicitudCounts);
    }

    getKeys(obj: { [key: string]: number }): string[] {
        return Object.keys(obj);
    }

    // async handleTabSelection(event: MatTabChangeEvent) {
    //     if (this.flagSolicitudesCargadas) {
    //         this.flagLoaded = false;
    //         this.resetTabContent();
    //         this.checkSedes();
    //         this.checkEstados();
    //         const actividadSeleccionada = this.actividades[event.index];
    //         this.idActividadSeleccionada = actividadSeleccionada._id;
    //         await this.applyFilters();
    //         this.flagLoaded = true;
    //     }
    // }

    async handleTabSelection(event: MatTabChangeEvent) {
    if (this.flagSolicitudesCargadas) {
        this.flagLoaded = false;
        this.resetTabContent();
        // this.checkSedes();
        // No reinicializar selectedEstados aquí
        const actividadSeleccionada = this.actividades[event.index];
        this.idActividadSeleccionada = actividadSeleccionada._id;
        await this.applyFilters();
        this.flagLoaded = true;
    }
}

    resetTabContent() {
        // this.selectSede = [];
        // this.selectedEstados = []; // REINICIA LOS ESTADOS
        this.dataSource = new MatTableDataSource<Solicitud>();
    }

    getStateName(stateNumber: number): string {
        const estado = this.iAppOfertas.selectEstados[stateNumber];
        return estado ? estado.name : 'Desconocido';
    }

    getColor(stateNumber: number): string {
        switch (stateNumber) {
            case 4: return '#28a745'; // Verde
            case 3: return 'rgb(173, 216, 230)'; // Cyan pastel
            case 2: return '#ffc107'; // Amarillo
            case 1: return '#6c757d'; // Gris
            case 5: return '#dc3545'; // Rojo
            default: return '';
        }
    }

    async verDetalleGecros(identificacion: string) {
        const dialogRef = this.dialog.open(ModalDetalleGecrosComponent, {
            width: '900px',
            height: '600px',
            disableClose: true,
            data: { identificacion }
        });

        dialogRef.afterClosed().subscribe(() => {
            // acciones tras cerrar el modal
        });
    }

    verDetalleSede(idSede: string, idUser: string): void {
        const dialogRef = this.dialog.open(ModalDetalleComponent, {
            width: '600px',
            height: '300px',
            disableClose: true,
            data: { idUser, idSede }
        });

        dialogRef.afterClosed().subscribe(() => {
            // acciones tras cerrar el modal
        });
    }

    controlCargaSolicitudes(solds: Solicitud[], sede: string[], fecha: any) {
        if (this.flagSolicitudesCargadas) {
            this.cargaSolicitudesPreCargadas(solds, sede);
        } else {
            this.cargarInicialdeSolicitudes(sede, fecha);
        }
    }

    async prepararSolicitudesPorTab(solds: Solicitud[]): Promise<Solicitud[]> {
        return await this.formatDataTable(solds, this.idActividadSeleccionada).then((data: Solicitud[]) => {
            this.dataSource = new MatTableDataSource<Solicitud>(data);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
            this.flagLoaded = true;
            return data;
        });
    }

    async cargarInicialdeSolicitudes(sede: string[], fecha: any): Promise<Solicitud[]> {
        try {
            this.selectedEstados = this.iAppOfertas.selectEstados.map(estado => estado.number);
            this.solicitudes = await this.solicitudesService.getSolicitudesBySede(sede, fecha);
            this.flagSolicitudesCargadas = true;
            this.calculateSolicitudCounts(this.solicitudes); // Calcular la cantidad de solicitudes por actividad
            this.idActividadSeleccionada = this.actividades[0]._id;
            return await this.prepararSolicitudesPorTab(this.solicitudes);
        } catch (error) {
            console.error('Error al cargar solicitudes:', error);
            throw error;
        }
    }

    async cargaSolicitudesPreCargadas(solds: Solicitud[], sede: string[]): Promise<Solicitud[]> {
        return await this.prepararSolicitudesPorTab(solds);
    }

    // Show the counts on the tab labels
    getTabLabel(actividad: Actividad): string {
        const countLeidas = this.solicitudCounts.leidas[actividad.nombre] || 0;
        const countPendientes = this.solicitudCounts.pendientes[actividad.nombre] || 0;
        return `${actividad.nombre} (Leídas: ${countLeidas}, Pendientes: ${countPendientes})`;
    }


    async verSolicitud(solicitud: Solicitud) {
        this.loading = true; // Mostrar el indicador de carga
        try {
            const sol = await this.solicitudesService.getSolicitudById(solicitud._id);
            this.solicitudesService.setSolicitudSeleccionada(sol);
            await this.updateSolicitudLeido(sol);
            solicitud.leido = true; // Actualizar el estado localmente para reflejar el cambio en el ícono
            this.openDialogModaSolicitud();
        } catch (error) {
            console.error('Error al ver la solicitud:', error);
        } finally {
            this.loading = false; // Ocultar el indicador de carga
        }
    }


    async updateSolicitudLeido(sol: Solicitud) {
        const solicitud: any = {
            _id: sol._id,
            leido: true,
            titular: sol.titular,
            usuario: sol.usuario,
            pais: sol.pais._id,
            actividad: sol.actividad._id
        };

        await this.solicitudesService.updateSolicitudLeido(sol._id, solicitud);
    }

}
