<h1 mat-dialog-title><strong>Información de usuario</strong></h1>
<div mat-dialog-content *ngIf="!sede && !flag">Cargando sede...</div>
<br>
<mat-progress-bar mode="indeterminate" *ngIf="!sede && !flag"></mat-progress-bar>
<div mat-dialog-content *ngIf="!sede && flag" style="background-color: #f42d2d; margin-left: 1px; margin-right: 1px; border-radius: 5px;">Sin sede asignada</div>
<div mat-dialog-content *ngIf="sede">Sede asignada: <strong>{{sede.nombre}}</strong></div>
<hr>
<form [formGroup]="form" (ngSubmit)="guardarSede()">
    <mat-form-field>
        <mat-label>Seleccionar Sede</mat-label>
        <mat-select formControlName="signoSedes" [(ngModel)]="sedeAsignada">
            <mat-option *ngFor="let sede of sedes" [value]="sede._id">{{sede.nombre}}</mat-option>
        </mat-select>
    </mat-form-field>
    <button mat-raised-button color="primary" type="submit" [disabled]="form.invalid || btnActualizar" style="margin-left: 10px;">Actualizar</button>
    <mat-progress-bar mode="indeterminate" *ngIf="guardandoSede"></mat-progress-bar>
</form>

<div mat-dialog-actions style="display: flex;justify-content: flex-end;"> 
    <button mat-button mat-dialog-close>Cerrar</button>
</div>