import { Component, OnInit, Inject, NgZone } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AuthService } from '@services/auth/auth.service';
import { GuiToolsService } from '@services/shared/gui-tools.service';
import { Translators } from 'src/app/services/shared/translators.service';
import { APP_CONFIG, IAppConfig } from 'src/app/app.config/app.config';
import { GuiMsjService } from 'src/app/services/shared/gui-msj.service';
import { MatDialog } from '@angular/material/dialog';
import { TermsConditions } from 'src/app/auth/profile-authentication/terms-conditions/terms-conditions.component';
import { Register } from 'src/app/interfaces/interface-frontend/register';
import { OpenPageService } from '@services/shared/open-page.service';
import { GecrosAfiliadosService } from 'src/app/services/entidades/gecros-afiliados.service';
import { SignoGecrosAfiliado } from 'src/app/interfaces/interface-bakend/entidades/signo-greco-afiliado.interface';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-persona-alta',
    templateUrl: './persona-alta.component.html',
    styleUrls: ['./persona-alta.component.scss'],
})
export class PersonaAltaComponent implements OnInit {

    registerForm: FormGroup;
    loading: Subscription;
    idioma: Subscription;

    loadingForm: boolean = false;
    flagFormaValido: boolean = false;
    flagDataGecros: boolean = false;
    viewSpinner: boolean = false;

    showPasswordRegister: boolean = false;
    showPasswordConfirm: boolean = false;
    check: boolean = false;

    idPasaporte: string;
    identificacion: string = "";

    dataGecros: SignoGecrosAfiliado = undefined;
    signoPlan: string = "";
    signoSedeCodigo: string;

    showTimeoutMessage: boolean = false;
    searchTimeout: any;

    constructor(
        private authservice: AuthService,
        private guiToolsService: GuiToolsService,
        private translatorService: Translators,
        @Inject(APP_CONFIG) private iAppConfig: IAppConfig,
        public guiMsjService: GuiMsjService,
        public dialog: MatDialog,
        private openPageService: OpenPageService,
        private gecrosAfiliadosService: GecrosAfiliadosService,
        private zone: NgZone
    ) {
        this.loading = this.guiToolsService.getLoading().subscribe(resp => {
            this.loadingForm = resp;
        });

        this.idioma = this.translatorService.getIdiomaSeleccionado().subscribe(resp => {
            this.idioma = resp;
        });

        this.createFormRegister();

    }

    async ngOnInit() {
        this.setIdPasaporte(this.iAppConfig.dniid);
        this.authservice.getCheck$().subscribe((acepta: boolean) => {
            this.check = acepta;
        });
        this.createListenersStateForm();
    }

    async ngOnDestroy() {
        this.loading.unsubscribe();
        this.idioma.unsubscribe();
    }

    setIdPasaporte(id: string) {
        this.idPasaporte = id;
    }

    useLanguage(idioma: string) {
        this.translatorService.setIdiomaSeleccionado(idioma);
    }

    get emailNoValido() {
        const emailControl = this.registerForm.get('email');
        const emailValue = emailControl.value;
        const emailPattern = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/;
        return !emailControl.valid && emailControl.touched && emailPattern.test(emailValue);
    }

    get passwordNoValido() {
        return this.registerForm.get('password').invalid && this.registerForm.get('password').touched;
    }

    get confirmPasswordNoValido() {
        const pass1 = this.registerForm.get('password').value;
        const pass2 = this.registerForm.get('confirmPassword').value;
        return (pass1 === pass2) ? false : true;
    }

    get confirmEmailNoValido() {
        const email1 = this.registerForm.get('email').value;
        const email2 = this.registerForm.get('confirmEmail').value;
        return (email1 === email2) ? false : true;
    }

    get getNombreNoValido() {
        return this.registerForm.get('nombrePersona').invalid && this.registerForm.get('nombrePersona').touched;
    }

    get getApellidoNoValido() {
        return this.registerForm.get('apellidoPersona').invalid && this.registerForm.get('apellidoPersona').touched;
    }

    get getIdentificacionNoValida() {
        return this.registerForm.get('identificacion').invalid && this.registerForm.get('identificacion').touched;
    }

    createListenersStateForm() {
        return this.registerForm.statusChanges.subscribe(async status => {
            this.flagFormaValido = status === 'VALID';
        });
    }

    async registerUser() {
        if (this.registerForm.invalid) {
            Object.values(this.registerForm.controls).forEach(control => { control.markAsTouched(); });
            return;
        }
        await this.guiMsjService.msjFormSubmit('Espere');
        Swal.showLoading();
        await this.authservice.registerAfiliado(await this.setDataForma());
    }

    async searchAfiliado() {
        if (this.identificacion && this.identificacion.trim() !== '') {
            this.viewSpinner = true;
            this.showTimeoutMessage = false;

            clearTimeout(this.searchTimeout);

            this.searchTimeout = new Promise<void>((_, reject) => {
                setTimeout(() => {
                    this.zone.run(() => {
                        this.showTimeoutMessage = true;
                    });
                    reject(new Error('Timeout'));
                }, 3000);
            });


            try {
                const resp = await Promise.race([this.gecrosAfiliadosService.getAfiliadoByIdentificacion(this.identificacion),
                this.searchTimeout
                ]);

                if (resp.data[0] !== undefined) {

                    const nombresAfiliado: string[] = resp.data[0]?.nombreAfiliado.trim().split(/\s+/);
                    const apellido = nombresAfiliado.pop().trim();
                    const nombresSinApellido = nombresAfiliado.join(' ');

                    this.flagDataGecros = true;
                    this.dataGecros = resp.data[0];
                    this.registerForm.controls['email'].setValue(resp.data[0]?.email?.trim() || null);
                    this.registerForm.controls['confirmEmail'].setValue(resp.data[0]?.email?.trim() || null);
                    this.registerForm.controls['nombrePersona'].setValue(nombresSinApellido);
                    this.registerForm.controls['apellidoPersona'].setValue(apellido);
                    this.signoSedeCodigo = resp.data[0]?.numeroAfiliado.substring(0, 3);
                    this.signoPlan = resp.data[0]?.plan;
                } else {
                    this.flagDataGecros = false;
                    this.dataGecros = undefined;
                    this.registerForm.controls['email'].setValue(null);
                    this.registerForm.controls['confirmEmail'].setValue(null);
                    this.registerForm.controls['nombrePersona'].setValue(null);
                    this.registerForm.controls['apellidoPersona'].setValue(null);
                    this.signoSedeCodigo = null;
                    this.signoPlan = null;
                }
            } catch (error) {
                console.error(error);
                this.showTimeoutMessage = true;
            } finally {
                clearTimeout(this.searchTimeout);
                this.viewSpinner = false;
            }
        } else {
            this.viewSpinner = false;
        }
    }

    async setDataForma(): Promise<Register> {
        const data: Register = {
            email: this.registerForm.value.email,
            emailConfirm: this.registerForm.value.confirmEmail,
            password: this.registerForm.value.password,
            passwordConfirm: this.registerForm.value.confirmPassword,
            nombrePersona: this.registerForm.value.nombrePersona,
            apellidoPersona: this.registerForm.value.apellidoPersona,
            tipoIdentificacion: this.idPasaporte,
            identificacion: this.registerForm.value.identificacion,
            aceptaTerminos: this.registerForm.value.aceptaTerminos,
            proteccionDeDatos: this.registerForm.value.proteccionDeDatos,
            clausula: this.registerForm.value.clausula,
            fromWeb: true,
            fromApp: false,
            signoGecrosAfiliado: this.dataGecros,
            signoPlan: this.signoPlan != undefined ? this.signoPlan : null,
            codigoGecros: this.signoSedeCodigo != undefined ? this.signoSedeCodigo : null,
            email_verified: this.registerForm.value.email_verified
        };

        return data;
    }

    // CREACION DE FORMULARIO

    createFormRegister() {
        this.registerForm = new FormGroup({
            email: new FormControl('', [Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$'),]),
            confirmEmail: new FormControl('', [Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$'),]),
            password: new FormControl('', [Validators.required, Validators.minLength(8), Validators.pattern(/((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/)]),
            confirmPassword: new FormControl('', [Validators.required, Validators.minLength(8)]),
            nombrePersona: new FormControl('', [Validators.required, Validators.minLength(3)]),
            apellidoPersona: new FormControl('', [Validators.required, Validators.minLength(3)]),
            identificacion: new FormControl('', [Validators.required, Validators.minLength(8), Validators.maxLength(15)]),
            aceptaTerminos: new FormControl('', Validators.requiredTrue),
            proteccionDeDatos: new FormControl('', Validators.requiredTrue,),
            clausula: new FormControl(false, Validators.requiredTrue),
            email_verified: new FormControl(''),
        });
    }

    verTerminos() {
        this.openDialog();
    }

    openDialog() {
        const dialogRef = this.dialog.open(TermsConditions, {

            width: '900px',
            height: '800px',
            disableClose: true,

        });
        dialogRef.afterClosed().subscribe();
    }

    openBlank(type: string) {
        this.openPageService.openBlank(type);
    }

}
