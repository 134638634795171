import { Response } from 'src/app/interfaces/interface-bakend/response';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import { StorageService } from '../shared/storage.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { GuiMsjService } from '../shared/gui-msj.service';
import { SignoCentroMedico } from 'src/app/interfaces/interface-bakend/entidades/signo-centro-medico.interface';
import Swal from 'sweetalert2';

const URL_BACKEND = environment.urlBackend;

@Injectable({ providedIn: 'root' })
export class SignoCentroMedicoService {

signoCentroMedicoSeleccionado : SignoCentroMedico;

    constructor(
        private httpClient: HttpClient,
        private router: Router,
        private guiMsjService: GuiMsjService,
        private authService: AuthService,
        private storageService: StorageService
    ) {}

    async create(centroMedico: SignoCentroMedico): Promise<SignoCentroMedico> {
        const headers = new HttpHeaders({
            'x-token': await this.authService.loadJwToken(),
        });

        return new Promise(async (resolve, reject) => {
            await this.httpClient
                .post<Response>(`${URL_BACKEND}/signo-centros`, centroMedico, {
                    headers,
                })
                .subscribe(
                    (resp: Response) => {
                        Swal.close();

                        Swal.fire({
                            icon: 'success',
                            title: 'Centro Médico guardado satisfactoriamente',
                            showConfirmButton: false,
                            timer: 1000,
                        });

                        this.router.navigate(['/centros-medicos-listado']);
                        resolve(resp.response);
                    },
                    (error) => {
                        console.error(error);
                        this.guiMsjService.msjFormSubmit('crearCentroMedicoError');
                        this.router
                            .navigateByUrl('/', { skipLocationChange: true })
                            .then(async () => {
                                await this.router.navigateByUrl('/');
                            });
                        reject(error);
                    }
                );
        });
    }

    getCentroMedicoSeleccionado(): SignoCentroMedico {
        return this.signoCentroMedicoSeleccionado;
    }

    async getCentroMedicos(): Promise<SignoCentroMedico[]> {
        return new Promise(async (resolve) => {
            const headers = new HttpHeaders({
                'x-token': await this.authService.loadJwToken(),
            });

            this.httpClient
                .get<Response>(`${URL_BACKEND}/signo-centros/findAll`, {
                    headers,
                })
                .subscribe((resp: Response) => {
                    resolve(resp.response);
                });
        });
    }

    async getCentroMedicoById(id: string): Promise<SignoCentroMedico> {
        return new Promise(async (resolve) => {
            const headers = new HttpHeaders({
                'x-token': await this.authService.loadJwToken(),
            });

            this.httpClient
                .get<Response>(`${URL_BACKEND}/signo-centros/findOne/${id}`, {
                    headers,
                })
                .subscribe((resp: Response) => {
                    resolve(resp.response);
                });
        });
    }
    
    async getAllCentrosMedicosByEspecialidad(EspecialidadId: string): Promise<SignoCentroMedico[]> {
        return new Promise(async (resolve, reject) => {
            const headers = new HttpHeaders({
                'x-token': await this.authService.loadJwToken(),
            });

            this.httpClient
                .get<Response>(`${URL_BACKEND}/signo-centros/findAllByEspecialidad/${EspecialidadId}`, {
                    headers,
                })
                .subscribe((resp: Response) => {
                    resolve(resp.response);
                },
                (error: Error) => {
                    console.error(error);
                    reject(error.message);
                })
        });
    }

   async updateCentroMedico(_id: string, centroMedico: SignoCentroMedico): Promise<SignoCentroMedico> {
        return new Promise(async (resolve) => {
            const headers = new HttpHeaders({
                'x-token': await this.authService.loadJwToken(),
            });
            this.httpClient
                .put<Response>(
                    `${URL_BACKEND}/signo-centros/updateOne/${_id}`,
                    centroMedico,
                    { headers }
                )
                .subscribe(
                    (resp: Response) => {
                        resolve(resp.response);
                    },
                    (error) => {
                        console.error(error);
                        this.guiMsjService.msjFormSubmit('centroMedicoDuplicado');
                    }
                );
        });
    }

    setCentroMedicoSeleccionado(centroMedico: SignoCentroMedico) {
        this.signoCentroMedicoSeleccionado = centroMedico;
        this.storageService.setCentroMedico( this.signoCentroMedicoSeleccionado);
    }
}
