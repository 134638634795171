<div class="row">
    <div class="col-lg-4 col-md-4">
        <div class="products-details-desc">
            <div class="products-meta">
                <span>Trámite: <span class="in-stock">{{ solicitud.actividad.nombre }}</span></span>
                <span>Nro Solicitud : <a >{{ solicitud._nro }}</a></span>
                <span>Fecha Solicitud: <span class="sku">{{ solicitud.createdAt | date:"dd/MM/yy" }}</span></span>
                <span>Pais: <a >{{ nombrePais }}</a></span>
            </div>
        </div>
    </div>
    <div class="col-lg-4 col-md-4">
        <div class="products-details-desc">
            <div class="products-meta">
                <span>Documentación: <a>{{ estadoDocumentacion }}</a></span>
                <span>Titular: <a>{{  solicitud.usuario.fullName }}</a></span>
                <span>
                    Benef.: <a>{{ beneficiario?.adicional?.fullName ? beneficiario.adicional.fullName : solicitud.usuario.fullName }}</a>
                </span>                
                <span>
                    Parentesco: <a>{{ beneficiario?.parentesco ? beneficiario.parentesco : 'TITULAR' }}</a>
                </span>
                
                <span>Progreso: <span class="in-stock">{{ solicitud.lastState.numberState | estadosPipe }}</span></span>
            </div>
        </div>        
    </div>
    <div class="col-lg-4 col-md-4">
        <div class="image animate__fadeIn img-user">
            <ng-container *ngIf="this.usuario.avatar == 'av-1.png'; else elseTemplate">
                <img [src]="imageUrl" width="200px" height="180px" class="img-thumbnail" />
            </ng-container>
            <ng-template #elseTemplate>
                <img [src]=" this.usuario._id | mediaUsuariosPipe: this.usuario.avatar  | safe" [alt]="this.usuario.avatar" width="200px" height="180px" class="img-thumbnail">
            </ng-template>

        </div>
    </div>

</div>

<div class="row mt-5">
    <div class="col-lg-12 col-md-12">
        <mat-accordion>
            <mat-expansion-panel>
                <mat-expansion-panel-header class="contacto">
                    <mat-panel-title>
                        Datos de Contacto
                    </mat-panel-title>
                    <mat-panel-description>
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <div class="col-lg-4 col-md-4">
                    <div class="products-details-desc">
                        <div class="products-meta">
                            <span>Email: <a >{{ this.usuario.email }}</a></span>
                            <span>Teléfono: <a >{{ this.usuario.paisCodeArea }}{{ this.usuario.telefono }}</a></span>
                        </div>
                    </div>
                </div>
            </mat-expansion-panel>

        </mat-accordion>
    </div>
</div>