import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from '../../../../interfaces/interface-bakend/seguridad/users/user.interface';
import { UsuarioService } from '../../../../services/shared/usuario.service';
import { Perfil } from '../../../../interfaces/interface-bakend/seguridad/acl/perfil.interface';
import { GuiMsjService } from 'src/app/services/shared/gui-msj.service';
import { CursosService } from 'src/app/services/cursos/cursos.service';
import { AlumnoTitulo, Disciplina, Titulo } from 'src/app/interfaces/interface-bakend/cursos/titulo.interface';
import { Alumno } from 'src/app/interfaces/interface-bakend/cursos/alumno.interface';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import Swal from 'sweetalert2'
import { SignoSede } from 'src/app/interfaces/interface-bakend/entidades/signo-sede.interface';
import { SignoSedeService } from 'src/app/services/entidades/signo-sedes.service';

@Component({
  selector: 'app-usuario-modificacion',
  templateUrl: './usuario-modificacion.component.html',
  styleUrls: ['./usuario-modificacion.component.scss']
})
export class UsuarioModificacionComponent implements OnInit, AfterViewInit {

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] = ['nombre', 'disciplina', 'otorgamiento', 'vencimiento', 'certifica', 'observaciones', '_id'];
  dataSource: any;

  forma: FormGroup;
  formTitulos: FormGroup;
  usuario: User;
  selectPerfiles: Perfil[];
  flagLoaded = false;
  flagDisableButton = false;
  flagValidForma = false;
  estado = true;
  isChecked = true;
  emailChecked: boolean = false;
  titulos: Titulo[] = [];
  disciplinas: Disciplina[] = [];
  titulosDelAlumno: AlumnoTitulo[] = [];
  subscriptions: Subscription;

  data$: Observable<AlumnoTitulo[]>;
  setTitulo: any;
  editaTitulo = false;
  tituloSeleccionado: Titulo;
  sedes: SignoSede[];
  sedesSeleccionadas: any;
  isMultipleSelectionEnabled = true;
  desdeAfiliados: boolean;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private usuarioService: UsuarioService,
    private guiMsjService: GuiMsjService,
    private cursosService: CursosService,
    public dialog: MatDialog,
    private signoSedeService: SignoSedeService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.desdeAfiliados = params['desdeAfiliados'] || false;
      this.createForm();
    });
  }

  createListenersStateForm() {  
    return this.forma.statusChanges.subscribe(status => {
      // console.log(this.forma.value);
    });
  }

  ngAfterViewInit(): void {
    this.getEstadoVariables();
  }

  reset() {
    this.formTitulos.reset();
    this.editaTitulo = false;
  }

  async createForm() {
    this.forma = new FormGroup({
      nombrePersona: new FormControl({ value: this.usuario?.nombrePersona || 'SIN REGISTRAR SU NOMBRE', disabled: true }),
      apellidoPersona: new FormControl({ value: this.usuario?.apellidoPersona || 'SIN REGISTRAR SU APELLIDO', disabled: true }),
      nickname: new FormControl({ value: this.usuario?.nickname || 'SIN REGISTRAR SU NICKNAME', disabled: true }),
      email: new FormControl({ value: this.usuario?.email || '', disabled: false }),
      perfil: new FormControl({ value: this.usuario?.perfil?._id, disabled: this.desdeAfiliados }, [Validators.required]),
      estado: new FormControl({ value: this.usuario?.suspendido }, Validators.required),
      email_verified: new FormControl({ value: this.usuario?.email_verified }, Validators.required),
      signoSedes: new FormControl({ value: '', disabled: this.desdeAfiliados }, Validators.required) // Marcar las sedes que tiene el usuario
    });

    this.formTitulos = new FormGroup({
      titulo: new FormControl(Validators.required),
      fechaOtorgamiento: new FormControl(new Date(), Validators.required),
      fechaVencimiento: new FormControl(new Date(), Validators.required),
      certifica: new FormControl(true),
      disciplina: new FormControl(Validators.required),
      nivel: new FormControl('', Validators.required),
      observaciones: new FormControl('')
    });

    await this.getPerfiles();
    await this.getSedes();
    this.flagLoaded = true;
  }

  async loadDataTable() {
    await this.cursosService.getTitulosAlumnosFindAllByAlumno((await this.cursosService.findAlumnoByUsuario(this.usuario._id))._id).then((titulos: AlumnoTitulo[]) => {
      this.dataSource = new MatTableDataSource(titulos);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  async loadDataPage() {
    await this.loadDataTable();
    this.flagLoaded = true;
    return;
  }

  async getUsuario() {
    this.isChecked = this.usuarioService.getUsuarioSeleccionado().suspendido;
    this.emailChecked = this.usuarioService.getUsuarioSeleccionado().email_verified;

    const signoSedes = this.usuarioService.getUsuarioSeleccionado().signoSedes;

    if (signoSedes && signoSedes.length > 0) {
      this.sedesSeleccionadas = signoSedes;
    }

    return this.usuario = this.usuarioService.getUsuarioSeleccionado();
  }

  async getEstadoVariables() {
    if (this.usuarioService.getUsuarioSeleccionado() === undefined) {
      this.router.navigateByUrl('/usuarios-listado');
      return;
    } else {
      await this.getUsuario();
      await this.createForm();
      this.createListenersStateForm();
      this.flagLoaded = true;
    }
  }

  async setDataForma(): Promise<User> {
    this.usuario.nickname = this.forma.value.nickname;
    this.usuario.perfil = this.forma.value.perfil;
    this.usuario.suspendido = this.forma.value.estado;
    this.usuario.email_verified = this.forma.value.email_verified;
    this.usuario.email = this.forma.value.email;

    if (typeof this.usuario.delegacion === 'string') {
      this.usuario.delegacion = {
        _id: this.usuario.delegacion
      };
    }

    if (typeof this.usuario.disciplina === 'string') {
      this.usuario.disciplina = {
        _id: this.usuario.disciplina
      };
    }
    this.usuario.email_verified = this.forma.value.email_verified;
    this.usuario.signoSedes = this.forma.value.signoSedes;

    return this.usuario;
  }

  async getPerfiles() {
    this.selectPerfiles = await this.usuarioService.getPerfiles();
  }

  async getSedes() {
    this.sedes = await this.signoSedeService.getSedes();
  }

  async ngSubmit() {
    this.flagDisableButton = true;
    await this.guiMsjService.msjFormSubmit('Guardando');
    const flag = await this.usuarioService.updateOne(await this.setDataForma());
    if (flag) {
      Swal.close();
      if (this.desdeAfiliados) {
        this.router.navigateByUrl('/afiliados-listado');
      } else {
        this.router.navigateByUrl('/usuarios-listado'); 
      }
      this.guiMsjService.msjFormSubmit('UsuarioModificadoOK');
    } else {
      Swal.close();
      this.guiMsjService.msjFormSubmit('UsuarioModificadoError');
    }
  }

  async getDisciplinas() {
    await this.cursosService.getDisciplinas().then((disciplinas: Disciplina[]) => {
      this.disciplinas = disciplinas;
    });
  }

  async getTitulosAlumnos() {
    await this.cursosService.getTitulosAlumnosFindAllByAlumno(await (await this.cursosService.findAlumnoByUsuario(this.usuario._id))._id).then((titulos: AlumnoTitulo[]) => {
      this.titulosDelAlumno = titulos;
    });
  }

  get fechaOtorgamientoNoValida() {
    return this.formTitulos.get('fechaOtorgamiento').invalid && this.formTitulos.get('fechaOtorgamiento').touched;
  }

  get fechaVencimientoNoValida() {
    return this.formTitulos.get('fechaVencimiento').invalid && this.formTitulos.get('fechaVencimiento').touched;
  }

  get nombrePersona() {
    return this.forma.get('nombrePersona').invalid && this.forma.get('nombrePersona').touched;
  }

  get apellidoPersona() {
    return this.forma.get('apellidoPersona').invalid && this.forma.get('apellidoPersona').touched;
  }

  get nickname() {
    return this.forma.get('nickname').invalid && this.forma.get('nickname').touched;
  }

  get email() {
    return this.forma.get('email').invalid && this.forma.get('email').touched;
  }

  get perfil() {
    return this.forma.get('perfil').invalid && this.forma.get('perfil').touched;
  }

  get sede() {
    return this.forma.get('signoSedes').invalid && this.forma.get('signoSedes').touched;
  }

}

// import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
// import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
// import { ActivatedRoute, Router } from '@angular/router';
// import { User } from '../../../../interfaces/interface-bakend/seguridad/users/user.interface';
// import { UsuarioService } from '../../../../services/shared/usuario.service';
// import { Perfil } from '../../../../interfaces/interface-bakend/seguridad/acl/perfil.interface';
// import { GuiMsjService } from 'src/app/services/shared/gui-msj.service';
// import { CursosService } from 'src/app/services/cursos/cursos.service';
// import { AlumnoTitulo, Disciplina, Titulo } from 'src/app/interfaces/interface-bakend/cursos/titulo.interface';
// import { Alumno } from 'src/app/interfaces/interface-bakend/cursos/alumno.interface';
// import { MatSort } from '@angular/material/sort';
// import { MatPaginator } from '@angular/material/paginator';
// import { MatTableDataSource } from '@angular/material/table';
// import { Observable, Subscription } from 'rxjs';
// import { MatDialog } from '@angular/material/dialog';
// import Swal from 'sweetalert2'
// import { SignoSede } from 'src/app/interfaces/interface-bakend/entidades/signo-sede.interface';
// import { SignoSedeService } from 'src/app/services/entidades/signo-sedes.service';


// @Component({
//   selector: 'app-usuario-modificacion',
//   templateUrl: './usuario-modificacion.component.html',
//   styleUrls: ['./usuario-modificacion.component.scss']
// })
// export class UsuarioModificacionComponent implements OnInit, AfterViewInit {

//   /* Data Table*/
//   @ViewChild(MatSort) sort: MatSort;
//   @ViewChild(MatPaginator) paginator: MatPaginator;
//   displayedColumns: string[] =
//     [
//       'nombre', 'disciplina', 'otorgamiento', 'vencimiento', 'certifica', 'observaciones', '_id'
//     ];
//   dataSource: any;

//   forma: FormGroup;
//   formTitulos: FormGroup;
//   usuario: User;
//   selectPerfiles: Perfil[];
//   flagLoaded = false;
//   flagDisableButton = false;
//   flagValidForma = false;
//   estado = true;
//   isChecked = true;
//   emailChecked: boolean = false;
//   titulos: Titulo[] = [];
//   disciplinas: Disciplina[] = [];
//   titulosDelAlumno: AlumnoTitulo[] = [];
//   subscriptions: Subscription;

//   data$: Observable<AlumnoTitulo[]>;
//   setTitulo: any;
//   editaTitulo: boolean = false;
//   tituloSeleccionado: Titulo;
//   sedes: SignoSede[];
//   sedesSeleccionadas: any;
//   isMultipleSelectionEnabled: boolean = true;
//   desdeAfiliados: boolean;  // Variable para capturar el parámetro

//   constructor(
//     private router: Router,
//     private activatedRoute: ActivatedRoute,  // Inyecta ActivatedRoute
//     private usuarioService: UsuarioService,
//     private guiMsjService: GuiMsjService,
//     private cursosService: CursosService,
//     public dialog: MatDialog,
//     private signoSedeService: SignoSedeService
//   ) {

//   }

//   ngOnInit(): void {
//     // Captura el parámetro desdeAfiliados
//     this.activatedRoute.queryParams.subscribe(params => {
//       this.desdeAfiliados = params['desdeAfiliados'] || false;
//     });
//   }

//   createListenersStateForm() {  
//     return this.forma.statusChanges.subscribe( async status => {
//       // console.log(this.forma.value);
//     });
//   }

//   ngAfterViewInit(): void {
//     this.getEstadoVariables();
//   }

//   reset() {
//     this.formTitulos.reset();
//     this.editaTitulo = false;
//   }

//   async createForm() {

//     this.forma = new FormGroup({
//         nombrePersona: new FormControl({ value: this.usuario.nombrePersona || 'SIN REGISTRAR SU NOMBRE', disabled: true }),
//         apellidoPersona: new FormControl({ value: this.usuario.apellidoPersona || 'SIN REGISTRAR SU APELLIDO', disabled: true }),
//         nickname: new FormControl({ value: this.usuario.nickname || 'SIN REGISTRAR SU NICKNAME', disabled: true }),
//         email: new FormControl({ value: this.usuario.email || '', disabled: false }),
//         perfil: new FormControl((this.usuario.perfil ? this.usuario.perfil._id : null), [Validators.required]),
//         estado: new FormControl({ value: this.usuario.suspendido }, Validators.required),
//         email_verified: new FormControl({ value: this.usuario.email_verified }, Validators.required),
//         signoSedes: new FormControl('', Validators.required) // Marcar las sedes que tiene el usuario
//     });

//     this.formTitulos = new FormGroup({
//         titulo: new FormControl(Validators.required),
//         fechaOtorgamiento: new FormControl(new Date(), Validators.required),
//         fechaVencimiento: new FormControl(new Date(), Validators.required),
//         certifica: new FormControl(true),
//         disciplina: new FormControl(Validators.required),
//         nivel: new FormControl('', Validators.required),
//         observaciones: new FormControl('')
//     });

//  }



//   // async createForm() {
//   //   this.forma = new FormGroup({
//   //     nombrePersona: new FormControl({ value: this.usuario.nombrePersona || 'SIN REGISTRAR SU NOMBRE', disabled: true }),
//   //     apellidoPersona: new FormControl({ value: this.usuario.apellidoPersona || 'SIN REGISTRAR SU APELLIDO', disabled: true }),
//   //     nickname: new FormControl({ value: this.usuario.nickname || 'SIN REGISTRAR SU NICKNAME', disabled: true }),
//   //     email: new FormControl({ value: this.usuario.email || '', disabled: true }),
//   //     perfil: new FormControl((this.usuario.perfil ? this.usuario.perfil._id : null), [Validators.required]),
//   //     estado: new FormControl({ value: this.usuario.suspendido }, Validators.required),
//   //     email_verified: new FormControl({ value: this.usuario.email_verified }, Validators.required),
//   //     signoSedes: new FormControl('', Validators.required)
//   //   });

//   //   this.formTitulos = new FormGroup({
//   //     titulo: new FormControl(Validators.required),
//   //     fechaOtorgamiento: new FormControl(new Date(), Validators.required),
//   //     fechaVencimiento: new FormControl(new Date(), Validators.required),
//   //     certifica: new FormControl(true),
//   //     disciplina: new FormControl(Validators.required),
//   //     nivel: new FormControl('', Validators.required),
//   //     observaciones: new FormControl('')
//   //   })
//   // }

//   async loadDataTable() {
//     await this.cursosService.getTitulosAlumnosFindAllByAlumno((await this.cursosService.findAlumnoByUsuario(this.usuario._id))._id).then((titulos: AlumnoTitulo[]) => {
//       this.dataSource = new MatTableDataSource(titulos);
//       this.dataSource.sort = this.sort;
//       this.dataSource.paginator = this.paginator;
//     })
//   }

//   applyFilter(event: Event) {
//     const filterValue = (event.target as HTMLInputElement).value;
//     this.dataSource.filter = filterValue.trim().toLowerCase();
//   }

//   async loadDataPage() {
//     await this.loadDataTable();
//     this.flagLoaded = true;
//     return;
//   }

//   async getUsuario() {
//     this.isChecked = this.usuarioService.getUsuarioSeleccionado().suspendido;
//     this.emailChecked = this.usuarioService.getUsuarioSeleccionado().email_verified;

//     const signoSedes = this.usuarioService.getUsuarioSeleccionado().signoSedes;

//     if (signoSedes && signoSedes.length > 0) {
//       this.sedesSeleccionadas = signoSedes;
//     }

//     return this.usuario = this.usuarioService.getUsuarioSeleccionado();
//   }


//   async getEstadoVariables() {
//     if (this.usuarioService.getUsuarioSeleccionado() === undefined) {
//       this.router.navigateByUrl('/usuarios-listado');
//       return;
//     } else {
//       await this.getUsuario();
//       await this.getPerfiles();
//       await this.getSedes();
//       await this.createForm();
//       // await this.getDisciplinas();
//       // await this.getTitulosAlumnos();
//       // await this.loadDataPage();
//       this.createListenersStateForm()
//       this.flagLoaded = true;
//     }
//   }

//   async setDataForma(): Promise<User> {
//     this.usuario.nickname = this.forma.value.nickname;
//     this.usuario.perfil = this.forma.value.perfil;
//     this.usuario.suspendido = this.forma.value.estado;
//     this.usuario.email_verified = this.forma.value.email_verified;
//     this.usuario.email = this.forma.value.email;

//     if (typeof this.usuario.delegacion === 'string') {
//       this.usuario.delegacion = {
//         _id: this.usuario.delegacion
//       };
//     }

//     if (typeof this.usuario.disciplina === 'string') {
//       this.usuario.disciplina = {
//         _id: this.usuario.disciplina
//       };
//     }
//     this.usuario.email_verified = this.forma.value.email_verified;
//     // this.usuario.signoSedes = [this.forma.value.signoSedes];
//     this.usuario.signoSedes = this.forma.value.signoSedes;

//     return this.usuario;
//   }

//   async getPerfiles() {
//     this.selectPerfiles = await this.usuarioService.getPerfiles();
//   }

//   async getSedes() {
//     this.sedes = await this.signoSedeService.getSedes();
//   }

//   async ngSubmit() {
//     this.flagDisableButton = true;
//     await this.guiMsjService.msjFormSubmit('Guardando');
//     const flag = await this.usuarioService.updateOne(await this.setDataForma());
//     if (flag) {
//       Swal.close();
//       if (this.desdeAfiliados) {
//         this.router.navigateByUrl('/afiliados-listado');
//       } else {
//         this.router.navigateByUrl('/usuarios-listado'); 
//       }
//       this.guiMsjService.msjFormSubmit('UsuarioModificadoOK');
//     } else {
//       Swal.close();
//       this.guiMsjService.msjFormSubmit('UsuarioModificadoError');
//     }
//   }

//   async getDisciplinas() {
//     await this.cursosService.getDisciplinas().then((disciplinas: Disciplina[]) => {
//       this.disciplinas = disciplinas;
//     });
//   }

//   async getTitulosAlumnos() {
//     await this.cursosService.getTitulosAlumnosFindAllByAlumno(await (await this.cursosService.findAlumnoByUsuario(this.usuario._id))._id).then((titulos: AlumnoTitulo[]) => {
//       this.titulosDelAlumno = titulos;
//     });
//   }

//   /* validaciones form */

//   get fechaOtorgamientoNoValida() {
//     return this.formTitulos.get('fechaOtorgamiento').invalid && this.formTitulos.get('fechaOtorgamiento').touched;
//   }

//   get fechaVencimientoNoValida() {
//     return this.formTitulos.get('fechaVencimiento').invalid && this.formTitulos.get('fechaVencimiento').touched;
//   }

//   get nombrePersona() {
//     return this.forma.get('nombrePersona').invalid && this.forma.get('nombrePersona').touched;
//   }

//   get sede() {
//     return this.forma.get('signoSedes').invalid && this.forma.get('signoSedes').touched;
//   }

// }
