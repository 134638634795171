import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, Subject } from 'rxjs';
import { Solicitud } from '@interfaces/interface-bakend/ofertas-laborales/solicitudes.interface';
import { AgregarDocumentosExtraComponent } from '../../components/ofertas-laborales/pages/solicitud/agregar-documentos-extra/agregar-documentos-extra.component';
import { DocumentosEstadosSolicitar } from '../../interfaces/interface-bakend/ofertas-laborales/actividad.interface';
import { SolicitudesService } from '../ofertas-laborales/solicitudes.service';
import { MediaService } from 'src/app/services/shared/media.service';

@Injectable({
  providedIn: 'root'
})
export class DocumentosService {

  private documentosSolicitar = new Subject<any>(); 
  private documentosEnviadosView = new Subject<any>(); 
  private solicitud: Solicitud;

  constructor(
        public dialog: MatDialog,
        private solicitudesService: SolicitudesService,
  ) { }

  getDocumentosSolicitar(): Observable<any>   {
    return this.documentosSolicitar.asObservable();
  }

  setDocumentosSolicitar(idAdjunto: string) {    
    this.documentosSolicitar.next({ idAdjunto: idAdjunto});
  }

  _getDocumentosEnviadosView(): Observable<any>   {
    return this.documentosEnviadosView.asObservable();
  }

  _setDocumentosEnviadosView$(flag: boolean) {    
    this.documentosEnviadosView.next({ flag: true});
  }

  openDialogDocUpload(solicitud: Solicitud, idAdjunto: string, label: string, index: string): void {

      const dialogRef = this.dialog.open(AgregarDocumentosExtraComponent, {
        width: '800px',
        height: '650px',
       disableClose: true,
       data: {
                solicitud: solicitud,
                adjunto: idAdjunto,
                label,
                labelButton: 'ACEPTAR',
                accept : 'application/pdf, image/jpeg, image/jpg, image/png'
              }
      });

      
    dialogRef.afterClosed().subscribe(result => {
      if (result) {// este resulta esta relacionado al flarCargaCorrecta
        this.setDocumentosSolicitar(idAdjunto); // comunico que se cargo el documento   
      } else  {
            return;
      }

    });
  }



}

