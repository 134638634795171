<div class="page-title-area">
  <div class="container">
    <div class="page-title-content">
      <ul>
        <li *ngFor="let route of routes">
          <a routerLink="{{route.url}}">{{route.label}}</a>
        </li>
      </ul>
      <h2>Modificar Empresa</h2>
      <h4 *ngIf="this.signoEmpresa">{{signoEmpresa.nombre}}</h4>
    </div>
  </div>
  <div class="shape9"><img src="assets/img/shape-signomedico.png" alt="image" width="10%"></div>
</div>

<div class="andorra-apply-content ptb-70  animate__animated animate__fadeIn">
  <div class="container">
    <div class="row mx-auto">
      <div class="col-lg-12 col-md-12">
        <div class="apply-form">
          <form [formGroup]="form" (ngSubmit)="updateEmpresa()">

            <div class="row align-content-center align-items-center">
              <div class="col-lg-6 col-md-6 col-sm-12">

                <div class="form-group">
                  <mat-form-field class="example-full-width" appearance="fill" [style.width.%]=100>
                    <mat-label>Nombre</mat-label>
                    <input formControlName="nombre" matInput placeholder="Nombre"
                      [class.is-invalid]="nombreNoValido" autocomplete="off">
                    <mat-error *ngIf="nombreNoValido">Ingrese un
                      nombre</mat-error>
                  </mat-form-field>
                </div>

                <div class="form-group">
                  <mat-form-field appearance="fill" [style.width.%]=100>
                    <mat-label>Estado</mat-label>
                    <mat-select formControlName="estado">
                      <mat-option *ngFor="let estado of estados" [value]="estado">{{ estado }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>

              <div class="col-lg-4 col-md-4 col-sm-12">

                <div class="form-group">
                  <mat-form-field color="accent" appearance="fill" [style.width.%]=100>
                    <mat-label>Fecha</mat-label>
                    <input formControlName="fecha" matInput [matDatepicker]="picker2"
                      [class.is-invalid]="fechaNoValida" autocomplete="off">
                    <mat-error *ngIf="fechaNoValida">Ingrese una
                      fecha de inicio</mat-error>
                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                    <mat-datepicker #picker2 color="primary"></mat-datepicker>
                  </mat-form-field>
                </div>

                <div class="form-group">
                     <button (click)="verAfiliadosAsociados()"
                  type="button" class=" btn btn-primary button-list-afiliados"
                    title="'Lista de afiliados asociado a {{signoEmpresa.nombre}}">
                    <i class="fas fa-th-list" aria-hidden="false" aria-label="List icon" ></i>
                    Afiliados
                  </button>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-4 col-md-4">
                  <button type="button" routerLink="/empresas-listado"
                    class="btn btn-warning">Cancelar</button>
                </div>

                <div class="col-lg-4 col-md-4">
                  <button [disabled]="!form.valid" type="submit">Guardar</button>
                </div>

              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>