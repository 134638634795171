<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Inicio</a></li>
                <li>Configuración Usuarios</li>
            </ul>
            <h2>Configurar Usuarios</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape-signomedico.png" alt="image" width="10%"></div>
</div>

<div class="features-area pb-70 animate__animated animate__fadeIn">
    <div class="container">

        <div class="row m-5">
            <div class="col-lg-6 col-sm-6 col-md-6">
                <div class="single-features-box">
                    <div class="icon">
                        <i class="fas fa-users"></i>
                    </div>
                    <h3>Listado de Usuarios</h3>
                    <p>Acceda al listado de Usuarios del sistema</p>
                    <a routerLink="/usuarios-listado" class="btn btn-warning">Ver Usuarios</a>
                </div>
            </div>
            <div class="col-lg-6 col-sm-6 col-md-6">
                <div class="single-features-box">
                    <div class="icon">
                        <i class="far fa-plus-square"></i>
                    </div>
                    <h3>Nuevo Usuario</h3>
                    <p>Cree un nuevo Usuario en el sistema</p>
                    <a routerLink="/usuario-alta" class="btn btn-success">Dar de alta Usuario</a>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="funfacts-area-two">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-6">
                <div class="single-funfacts">
                    <h3><span [countUp]="this.countUsuarios"></span></h3>
                    <p>Usuarios</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-6">
                <div class="single-funfacts">
                    <h3><span [countUp]="this.countUsuarios">00</span>%</h3>
                    <p>Administradores</p>
                </div>
            </div>
        </div>
    </div>
</div>