import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Actividad } from '../../../../interfaces/interface-bakend/ofertas-laborales/actividad.interface';
import { PaisDisponible } from '../../../../interfaces/interface-bakend/shared/paises/pais-disponible/pais-disponible.interface';
import { Pais } from '../../../../interfaces/interface-bakend/shared/paises/pais.interfaces';
import { Catalogo } from '../../../../interfaces/interface-bakend/shared/catalogo/catalogo.interface';
import { APP_CONFIG_SHARED, IAppShared } from '../../../../app.config/app.config-shared';
import { UsuarioService } from '../../../../services/shared/usuario.service';
import { CatalogoService } from '../../../../services/shared/catalogo.service';
import { GuiToolsService } from '../../../../services/shared/gui-tools.service';
import { Translators } from '../../../../services/shared/translators.service';
import { Subscription } from 'rxjs';
import { PaisesService } from '../../../../services/shared/paises.service';
import { GuiMsjService } from '../../../../services/shared/gui-msj.service';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ModalConfirmarComponent } from '../../../ofertas-laborales/pages/solicitud-postular/modal-confirmar/modal-confirmar.component';
import { PreviousRouteService } from '@services/shared/previous-route.service';

import { UserEntreUserService } from '@services/shared/user-entre-user.service';
import { User } from '../../../../interfaces/interface-bakend/seguridad/users/user.interface';
import { PersonasService } from 'src/app/services/shared/personas.service';
import { SignoSede } from '@interfaces/interface-bakend/entidades/signo-sede.interface';
import { SignoSedeService } from '@services/entidades/signo-sedes.service';
import { SignoPlan } from '@interfaces/interface-bakend/entidades/signo-plan.interface';
import { SignoPlanService } from '@services/entidades/signo-planes.service';
import { SignoEmpresa } from '@interfaces/interface-bakend/entidades/signo-empresa.interface';
import { SignoEmpresaService } from '@services/entidades/signo-empresas.service';
import { SignoGestion } from '@interfaces/interface-bakend/entidades/signo-gestion-interface';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
    selector: 'app-persona-modificacion',
    templateUrl: './persona-modificacion.component.html',
    styleUrls: ['./persona-modificacion.component.scss']
})
export class PersonaModificacionComponent implements OnInit {

    usuario: User;
    formAfiliado: FormGroup;
    actividad: Actividad;
    pais: PaisDisponible;
    nombrePais: string;
    nombreActividad: string;

    paises: Pais[] = [];
    paisCodeAreas: Pais[] = [];
    estadosCiviles: Catalogo[] = [];
    tiposIdentificaciones: Catalogo[] = [];
    paisDondeAplicaFormas: Catalogo[] = [];
    sedes: SignoSede[] = [];
    planes: SignoPlan[] = [];
    empresas: SignoEmpresa[] = [];
    idiomas: Catalogo[] = [];

    selectedEstadoCivil: Catalogo;
    selectedTipoIdentificacion: Catalogo;
    selectedCondiMigra: Catalogo;
    selectedPais: Pais;
    selectedSede: SignoSede;
    selectedPlan: SignoPlan;
    selectedEmpresa: SignoEmpresa;

    flagLoaded: boolean = false;
    flagProcesando: boolean = false;
    flagFormAfiliadoValido: boolean = false;
    flagExisteUsuarioConIdentificacion: boolean = false;
    flagExisteIdentificacion: boolean = false;
    flagPreCarga: boolean = false;
    flagoConfirmData: boolean = false;
    flagGrupoFamiliar: boolean = false;

    idioma: Subscription;

    /*Gestion*/

    isPMI: boolean = false;
    isDisability: boolean = false;
    isChronicities: boolean = false;
    estados: string[] = ['ACTIVA', 'SUSPENDIDA', 'BAJA', 'DESCONOCIDO'];

    /* Data Table Grupo Familiar*/
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    displayedColumns: string[] =
        [
            'nombre', 'identificacion', 'domicilio', 'email', 'sede', 'plan', 'empresa'
        ];
    dataSource: MatTableDataSource<User>;

    routes: { url: string, label: string }[] = [];

    mostrarCard = false ;

    constructor(
        @Inject(APP_CONFIG_SHARED) private iAppShared: IAppShared,
        private paisesService: PaisesService,
        private catalogoService: CatalogoService,
        private guiMsjService: GuiMsjService,
        private guiToolsService: GuiToolsService,
        private router: Router,
        private previousRouteService: PreviousRouteService,
        private usuarioService: UsuarioService,
        public dialog: MatDialog,
        private translatorService: Translators,
        private personasService: PersonasService,
        private signoSedeService: SignoSedeService,
        private signoPlanService: SignoPlanService,
        private signoEmpresaService: SignoEmpresaService,
        private userEntreUserService: UserEntreUserService
    ) {

        this.idioma = this.translatorService.getIdiomaSeleccionado().subscribe(resp => {
            this.idioma = resp;
        });

        this.createFormAfiliado();
        // this.createListenersStateForm();
    }

    ngOnInit(): void {
        this.previousRouteService.getRoutes().subscribe(routes => {
            this.routes = routes;
        }).unsubscribe();
        this.getData();
        this.loadUserAndForm();
    }

    ngAfterViewInit(): void {
        this.loadGrupoFamiliar();
    }

    getEstadoAfiliado(estado: string): string {
        console.log('estado', estado);
        switch (estado) {
            case 'Con Cobertura':
                return 'ACTIVA';
            case 'Sin Cobertura por Baja':
                return 'BAJA';
            case 'Momentáneamente sin cobertura':
                return 'SUSPENDIDA';
            default:
                return 'DESCONOCIDO';
        }
    }

    async getData() {
        await this.getPaises();
        await this.getEstadosCiviles();
        await this.getTipoIdentificaciones();
        await this.getPaisDondeAplicaFormas();
        await this.getSedes();
        await this.getPlanes();
        await this.getEmpresas();

        this.flagLoaded = true;
    }

    /* Validaciones */

    get nombre() {
        return this.formAfiliado.get('nombre').invalid && this.formAfiliado.get('nombre').touched;
    }

    get apellido() {
        return this.formAfiliado.get('apellido').invalid && this.formAfiliado.get('apellido').touched;
    }

    get tipoIdentificacion() {
        return this.formAfiliado.get('tipoIdentificacion').invalid && this.formAfiliado.get('tipoIdentificacion').touched;
    }

    get identificacion() {
        return this.formAfiliado.get('identificacion').invalid && this.formAfiliado.get('identificacion').touched;
    }

    get birth() {
        return this.formAfiliado.get('birth').invalid && this.formAfiliado.get('birth').touched;
    }

    get paisNacimiento() {
        return this.formAfiliado.get('paisNacimiento').invalid && this.formAfiliado.get('paisNacimiento').touched;
    }

    get paisCodeArea() {
        return this.formAfiliado.get('paisCodeArea').invalid && this.formAfiliado.get('paisCodeArea').touched;
    }

    get telefono() {
        return this.formAfiliado.get('telefono').invalid && this.formAfiliado.get('telefono').touched;
    }

    get estado() {
        return this.formAfiliado.get('estado').invalid && this.formAfiliado.get('estado').touched;
    }

    get fechaVigenciaNoValida() {
        return this.formAfiliado.get('fechaVigencia').invalid && this.formAfiliado.get('fechaVigencia').touched;
    }

    /* Fin Validaciones */

    /* Métodos form */

    async onSubmit() {
        /* 0- confirmo datos
        /* 1- update user */
        /* 2- update solicitud estado */
        /* 3- refreshing state solicitud */

        const usuarioUpdated: User = await this.setDataForma();

        this.flagoConfirmData = await this.openDialogConfirmData(usuarioUpdated);

        if (this.flagoConfirmData) {
            this.flagFormAfiliadoValido = false;
            this.flagProcesando = true;
            this.usuarioUpdate(usuarioUpdated);
        } else {
            return;
        }
    }

    createFormAfiliado() {

        this.formAfiliado = new FormGroup({
            nombre: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(50)]),
            apellido: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(50)]),
            tipoIdentificacion: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(50)]),
            identificacion: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(50)]),
            paisNacimiento: new FormControl('',),
            estadoCivil: new FormControl('', ),
            paisCodeArea: new FormControl('', ),
            telefono: new FormControl('', ),
            birth: new FormControl(
                this.guiToolsService.datePipeTransform
                    (
                        new Date().setFullYear(new Date().getFullYear() - 21
                        ), 'yyyy-MM-dd'),
                [Validators.required]),
            signoSedes: new FormControl('', [Validators.required]),
            signoPlan: new FormControl('', Validators.required),
            signoEmpresas: new FormControl('',),
            estado: new FormControl('', ),
            fechaVigencia: new FormControl('', ),
            fechaCobertura: new FormControl(''),
            fechaBaja: new FormControl(''),
            pmi: new FormControl(false),
            fechaVtoPMI: new FormControl(''),
            discapacidad: new FormControl(false),
            fechaVtoDiscapacidad: new FormControl(''),
            otrasCronicidades: new FormControl(false),
            fechaVtoOtrasCronicidades: new FormControl(''),
        });
    }

    async loadSelectedData(afiliado: User) {
        try {
            const signoSedes = afiliado?.signoSedes ?? null;
            this.selectedSede = signoSedes?.[0] || null;

            const signoEmpresas = afiliado?.signoEmpresas ?? null;
            this.selectedEmpresa = signoEmpresas?.[0] || null;

            const signoPlan = afiliado?.signoPlan ?? null;
            if (signoPlan) {
                this.selectedPlan = (await this.signoPlanService.getPlanById(signoPlan))._id as SignoPlan;
            }
        } catch (error) {
            console.error('Error al cargar los datos:', error);
            this.selectedSede = null;
            this.selectedEmpresa = null;
            this.selectedPlan = null;
        }
    }

    async cargarFormAfiliado(usuario: User) {
        console.log('cargarFormAfiliado', usuario);
        try {
            this.formAfiliado.setValue({
                nombre: usuario?.nombrePersona || '',
                apellido: usuario?.apellidoPersona || '',
                tipoIdentificacion: usuario?.tipoIdentificacion?.concepto || '',
                identificacion: usuario?.identificacion || '',
                birth: usuario?.birth ? this.guiToolsService.datePipeTransform(usuario?.birth, 'yyyy-MM-dd') : this.guiToolsService.datePipeTransform(new Date(), 'yyyy-MM-dd'),
                paisNacimiento: usuario?.paisNacimiento ? usuario.paisNacimiento.name_es : null,
                estadoCivil: usuario?.estadoCivil ? usuario?.estadoCivil.concepto : null,
                paisCodeArea: usuario?.paisCodeArea || '',
                telefono: usuario?.telefono || '',
                signoSedes: "",
                signoPlan: "",
                signoEmpresas: "",
                estado: this.getEstadoAfiliado(this.usuario?.signoGecrosAfiliado?.estado),
                
                fechaVigencia: usuario?.signoGestion?.fechaVigencia ? usuario?.signoGestion?.fechaVigencia : this.guiToolsService.datePipeTransform(new Date(), 'yyyy-MM-dd'),
                fechaCobertura: usuario?.signoGestion?.fechaCobertura ? usuario?.signoGestion?.fechaCobertura : this.guiToolsService.datePipeTransform(new Date(), 'yyyy-MM-dd'),
                fechaBaja: usuario?.signoGestion?.fechaBaja ? usuario?.signoGestion?.fechaBaja : this.guiToolsService.datePipeTransform(new Date(), 'yyyy-MM-dd'),
                pmi: usuario?.signoGestion?.pmi || false,
                fechaVtoPMI: usuario?.signoGestion?.fechaVtoPmi ? usuario?.signoGestion?.fechaVtoPmi : this.guiToolsService.datePipeTransform(new Date(), 'yyyy-MM-dd'),
                discapacidad: usuario?.signoGestion?.discapacidad || false,
                fechaVtoDiscapacidad: usuario?.signoGestion?.fechaVtoDiscapacidad ? usuario?.signoGestion?.fechaVtoDiscapacidad : this.guiToolsService.datePipeTransform(new Date(), 'yyyy-MM-dd'),
                otrasCronicidades: usuario?.signoGestion?.otrasCronicidades || false,
                fechaVtoOtrasCronicidades: usuario?.signoGestion?.fechaVtoOtrasCronicidades ? usuario?.signoGestion?.fechaVtoOtrasCronicidades : this.guiToolsService.datePipeTransform(new Date(), 'yyyy-MM-dd'),
            });

            // cargo los selectores
            this.selectedTipoIdentificacion = usuario?.tipoIdentificacion;
            this.selectedEstadoCivil = usuario?.estadoCivil;
            this.selectedPais = usuario?.paisNacimiento;
            this.updateValuesPMI(usuario?.signoGestion?.pmi || false);
            this.updateValuesDisability(usuario?.signoGestion?.discapacidad || false);
            this.updateValuesChronicities(usuario?.signoGestion?.otrasCronicidades || false);
        } catch (error) {
            console.error('Error al cargar los datos asociado al afiliado:', error);
        }
    }

    // async cargarFormAfiliado(usuario: User) {

    //     this.formAfiliado.setValue({
    //         nombre: usuario.nombrePersona,
    //         apellido: usuario.apellidoPersona,
    //         tipoIdentificacion: usuario?.tipoIdentificacion?.concepto,
    //         identificacion: usuario.identificacion,
    //         birth: usuario?.birth ? this.guiToolsService.datePipeTransform(usuario?.birth, 'yyyy-MM-dd') : this.guiToolsService.datePipeTransform(new Date(), 'yyyy-MM-dd'),
    //         paisNacimiento: usuario?.paisNacimiento ? usuario.paisNacimiento.name_es : null,
    //         estadoCivil: usuario?.estadoCivil ? usuario?.estadoCivil.concepto : null,
    //         paisCodeArea: usuario?.paisCodeArea ? usuario.paisCodeArea : null,
    //         telefono: usuario?.telefono ? usuario.telefono : null,
    //         signoSedes: "",
    //         signoPlan: "",
    //         signoEmpresas: "",
    //         estado: usuario?.signoGestion ? usuario?.signoGestion?.estado : null,
    //         fechaVigencia: usuario?.signoGestion?.fechaVigencia ? usuario?.signoGestion?.fechaVigencia : this.guiToolsService.datePipeTransform(new Date(), 'yyyy-MM-dd'),
    //         fechaCobertura: usuario?.signoGestion?.fechaCobertura ? usuario?.signoGestion?.fechaCobertura : this.guiToolsService.datePipeTransform(new Date(), 'yyyy-MM-dd'),
    //         fechaBaja: usuario?.signoGestion?.fechaBaja ? usuario?.signoGestion?.fechaBaja : this.guiToolsService.datePipeTransform(new Date(), 'yyyy-MM-dd'),
    //         pmi: usuario?.signoGestion ? usuario?.signoGestion?.pmi : false,
    //         fechaVtoPMI: usuario?.signoGestion?.fechaVtoPmi ? usuario?.signoGestion?.fechaVtoPmi : this.guiToolsService.datePipeTransform(new Date(), 'yyyy-MM-dd'),
    //         discapacidad: usuario?.signoGestion ? usuario?.signoGestion?.discapacidad : false,
    //         fechaVtoDiscapacidad: usuario?.signoGestion?.fechaVtoDiscapacidad ? usuario?.signoGestion?.fechaVtoDiscapacidad : this.guiToolsService.datePipeTransform(new Date(), 'yyyy-MM-dd'),
    //         otrasCronicidades: usuario?.signoGestion ? usuario?.signoGestion?.otrasCronicidades : false,
    //         fechaVtoOtrasCronicidades: usuario?.signoGestion?.fechaVtoOtrasCronicidades ? usuario?.signoGestion?.fechaVtoOtrasCronicidades : this.guiToolsService.datePipeTransform(new Date(), 'yyyy-MM-dd'),
    //     });

    //     // cargo los selectores
    //     this.selectedTipoIdentificacion = usuario?.tipoIdentificacion;
    //     this.selectedEstadoCivil = usuario?.estadoCivil;
    //     this.selectedPais = usuario?.paisNacimiento;
    //     this.updateValuesPMI(usuario?.signoGestion ? usuario?.signoGestion?.pmi : false);
    //     this.updateValuesDisability(usuario?.signoGestion ? usuario?.signoGestion?.discapacidad : false);
    //     this.updateValuesChronicities(usuario?.signoGestion ? usuario?.signoGestion?.otrasCronicidades : false);
    // }

    async loadUserAndForm() {
        try {
            const afiliadoSeleccionado = this.personasService.getPersonaSeleccionada();

            if (!afiliadoSeleccionado) {
                throw new Error('No hay afiliado seleccionado.');
            }

            this.usuario = afiliadoSeleccionado;
            // Cargo datos en paralelo 
            await Promise.all([
                this.cargarFormAfiliado(this.usuario),
                this.loadSelectedData(this.usuario)
            ]);

        } catch (error) {
            console.error('Error al cargar los datos asociado al afiliado:', error);
            await this.router.navigateByUrl('/afiliados-listado');
        }
    }

    async setDataForma(): Promise<User> {

        const dataFormaGestion: SignoGestion = {
            estado: this.formAfiliado.value.estado,
            fechaVigencia: this.formAfiliado.value.fechaVigencia,
            fechaBaja: this.formAfiliado.value.fechaBaja,
            fechaCobertura: this.formAfiliado.value.fechaCobertura,
            pmi: this.formAfiliado.value.pmi,
            fechaVtoPmi: this.formAfiliado.value.fechaVtoPMI,
            discapacidad: this.formAfiliado.value.discapacidad,
            fechaVtoDiscapacidad: this.formAfiliado.value.fechaVtoDiscapacidad,
            otrasCronicidades: this.formAfiliado.value.otrasCronicidades,
            fechaVtoOtrasCronicidades: this.formAfiliado.value.fechaVtoOtrasCronicidades
        }

        const dataForma: User = {
            nombrePersona: this.formAfiliado.value.nombre.toUpperCase(),
            apellidoPersona: this.formAfiliado.value.apellido.toUpperCase(),
            tipoIdentificacion: this.selectedTipoIdentificacion,
            identificacion: this.formAfiliado.value.identificacion,
            birth: new Date(this.formAfiliado.value.birth).toISOString(),
            paisNacimiento: this.selectedPais,
            estadoCivil: this.selectedEstadoCivil,
            paisCodeArea: this.formAfiliado.value.paisCodeArea,
            telefono: this.formAfiliado.value.telefono,
            fromApp: false,
            fromWeb: true,
            esPersona: true,
            signoSedes: [this.formAfiliado.value.signoSedes],
            signoPlan: this.formAfiliado.value.signoPlan,
            signoEmpresas: [this.formAfiliado.value.signoEmpresas],
            signoGestion: dataFormaGestion || null,
        };

        return dataForma;
    }

    createListenersStateForm() {
        return this.formAfiliado.statusChanges.subscribe(async status => {
            // Valido que la identificación modificada no exista
            this.validarIdentificacionModificada(status);
        });
    }


    /* FIN Métodos form */

    reloadCurrentRoute() {
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.router.navigate(['afiliados-listado']);
            return;
        });
    }

    usuarioUpdate(usuarioUpdated: User) {
        this.usuarioService.updateUser(
            this.personasService.getPersonaSeleccionada()._id,
            usuarioUpdated
        ).then((usuario: User) => {
            if (usuario) {
                this.guiMsjService.msjFormSubmit('afiliadoModificadoOK');
                this.router.navigateByUrl('/afiliados-listado');
                this.reloadCurrentRoute();
                
            } else {
                this.guiMsjService.msjFormSubmit('afiliadoModificadoError');
                this.router.navigateByUrl('/afiliado-modificacion');
            }
        });
    }

    async validarIdentificacionModificada(status: string) {
        if (this.formAfiliado.value.identificacion) {
            if (
                this.personasService.getPersonaSeleccionada().identificacion
                !==
                this.formAfiliado.value.identificacion
            ) {
                this.flagExisteIdentificacion =
                    await this.usuarioService.
                        getExistsTipoAndIdentificacion(this.selectedTipoIdentificacion._id, this.formAfiliado.value.identificacion);
            } else {
                this.flagExisteIdentificacion = false;
            }

            if (status === 'VALID' && !this.flagExisteIdentificacion) {
                this.flagFormAfiliadoValido = true;
            } else {
                this.flagFormAfiliadoValido = false;
            }
        } else {
            return;
        }
    }

    /* Selectores */

    onChangePais(event: string): void {
        this.selectedPais = this.paises.filter(x => x.name_es === event)[0];
    }

    onChangeTipoIdentificacion(event: string): void {
        this.selectedTipoIdentificacion = this.tiposIdentificaciones.filter(x => x.concepto === event)[0];
    }

    onChangeCondiMigra(event: string): void {
        this.selectedCondiMigra = this.paisDondeAplicaFormas.filter(x => x.concepto === event)[0];
    }

    onChangeEstadoCivil(event: string): void {
        this.selectedEstadoCivil = this.estadosCiviles.filter(x => x.concepto === event)[0];
    }

    updateValuesPMI(isChecked: boolean) {
        this.isPMI = isChecked;
        this.formAfiliado.controls['pmi'].setValue(isChecked);
    }

    updateValuesDisability(isChecked: boolean) {
        this.isDisability = isChecked;
        this.formAfiliado.controls['discapacidad'].setValue(isChecked);
    }

    updateValuesChronicities(isChecked: boolean) {
        this.isChronicities = isChecked;
        this.formAfiliado.controls['otrasCronicidades'].setValue(isChecked);
    }

    async getPaises() {
        await this.paisesService.getPaises().then((resp: Pais[]) => {
            this.paises = resp;
        });
    }

    async getSedes() {
        try {
            this.sedes = await this.signoSedeService.getSedes();
        } catch (error) {
            console.error('Error al obtener sedes:', error);
        }
    }

    async getPlanes() {
        try {
            this.planes = await this.signoPlanService.getPlanes();
        }
        catch (error) {
            console.error('Error al obtener planes:', error)
        }
    }

    async getEmpresas() {
        try {
            this.empresas = await this.signoEmpresaService.getEmpresas();
        }
        catch (error) {
            console.error('Error al obtener empresas:', error)
        }
    }

    async getEstadosCiviles() {
        await this.catalogoService.findDescendantsByKeyByLenguage(this.iAppShared.estadoCivil,
            this.translatorService.getIdioma()).
            then((resp: Catalogo[]) => {
                return this.estadosCiviles = resp;
            });
    }

    async getTipoIdentificaciones() {
        await this.catalogoService.findDescendantsByKeyByLenguage(this.iAppShared.tiposIdentificaciones,
            this.translatorService.getIdioma()).
            then((resp: Catalogo[]) => {
                return this.tiposIdentificaciones = resp;
            });
    }

    async getPaisDondeAplicaFormas() {
        await this.catalogoService.findDescendantsByKeyByLenguage(this.iAppShared.paisDondeAplica,
            this.translatorService.getIdioma()).then((resp: Catalogo[]) => {
                return this.paisDondeAplicaFormas = resp;
            });
    }

    /* Fin Selectores */


    formatDomicilio(calle: string, numero: string): string {
        return (calle.replace(/\s+/g, '') + ' ' + numero);
    }

    async getDataAfiliado(sedeId: string, empresaId?: string, planId?: string): Promise<{ sede: string, plan: string, empresa: string }> {
        try {
            const sede = sedeId ? (await this.signoSedeService.getSedeById(sedeId))?.nombre : "Sin sede asociada";
            const plan = planId ? (await this.signoPlanService.getPlanById(planId))?.denominacion : "Sin plan asociado";
            const empresa = empresaId ? (await this.signoEmpresaService.getEmpresaById(empresaId))?.nombre : "Sin empresa asociada";
            return { sede, plan, empresa };
        } catch (error) {
            console.error(error, "Error al obtener los datos del familiar");
            return { sede: undefined, plan: undefined, empresa: undefined };
        }
    }

    async loadDataTable() {
        try {
            const grupoFamiliar = [];

            //Se obtiene al afiliado 
            const afiliadoSeleccionado = this.personasService.getPersonaSeleccionada();
            if (!afiliadoSeleccionado) {
                throw new Error('No hay afiliado seleccionado.');
            }

            /**
             ** Adicionales
             */

            const beneficiaries = await this.userEntreUserService.findGrupoFamiliar(afiliadoSeleccionado._id);

            if (beneficiaries.length > 0) {
                for (const beneficiary of beneficiaries) {
                    const { adicional } = beneficiary;

                    //Adicionales del afiliado seleccionado
                    if (adicional) {
                        const sedeIdAdicional = adicional?.signoSedes[0]?.toString() || '';
                        const empresaIdAdicional = adicional?.signoEmpresas[0]?.toString() || '';
                        const planIdAdicional = adicional?.signoPlan?.toString() || '';
                        const dataAdicional = await this.getDataAfiliado(sedeIdAdicional, empresaIdAdicional, planIdAdicional);
                        if (dataAdicional) {
                            const { sede: sedeIdAdicional, empresa: empresaIdAdicional, plan: planIdAdicional } = dataAdicional;
                            adicional.signoSedes[0] = { nombre: sedeIdAdicional };
                            adicional.signoEmpresas[0] = { nombre: empresaIdAdicional };
                            adicional.signoPlan = planIdAdicional;
                        }
                        grupoFamiliar.push(adicional);
                    }
                }
            }

            /**
            ** Fin Adicionales
            */

            /**
             ** Titulares
             */

            const headlines = await this.userEntreUserService.findTitular(afiliadoSeleccionado._id);

            if (headlines.length > 0) {
                for (const headline of headlines) {
                    const { titular } = headline;

                    if (titular) {
                        const sedeIdTitular = titular?.signoSedes[0]?.toString() || '';
                        const empresaIdTitular = titular?.signoEmpresas[0]?.toString() || '';
                        const planIdTitular = titular?.signoPlan?.toString() || '';
                        const dataTitular = await this.getDataAfiliado(sedeIdTitular, empresaIdTitular, planIdTitular);
                        if (dataTitular) {
                            const { sede: sedeTitular, plan: planTitular, empresa: empresaTitular } = dataTitular;
                            titular.signoSedes[0] = { nombre: sedeTitular };
                            titular.signoEmpresas[0] = { nombre: empresaTitular };
                            titular.signoPlan = planTitular;
                        }
                        grupoFamiliar.push(titular);
                    }
                }
            }

            /**
            ** Fin Titulares
            */

            if (grupoFamiliar.length !== 0) {
                this.dataSource = new MatTableDataSource(grupoFamiliar);
                this.dataSource.sort = this.sort;
                this.dataSource.paginator = this.paginator;
                this.flagGrupoFamiliar = true;
            }
        } catch (error) {
            console.error("Se ha producido un error al cargar la lista de familiares vinculados al afiliado.", error);
        }
    }

    async loadGrupoFamiliar() {
        await this.loadDataTable();
        return;
    }

    openDialogConfirmData(data: User): Promise<boolean> {

        return new Promise<boolean>(resolve => {
            const dialogRef = this.dialog.open(ModalConfirmarComponent, {
                width: 'auto',
                height: '825px',
                disableClose: true,
                data
            });

            dialogRef.afterClosed().subscribe((result: boolean) => {
                resolve(result);
            });
        });
    }

    mostrarDatosGecros() {
        this.mostrarCard = !this.mostrarCard;
    }

    // async getFindOneByEmail(email: string): Promise<boolean> {

    //     return new Promise(resolve => {
    //         this.usuarioService.getFindOneByEmail(email).then((resp: User) => {
    //             if (resp !== null && resp.identificacion !== undefined) {
    //                 this.usuario = resp;
    //                 this.flagExisteUsuarioConIdentificacion = true;
    //                 resolve(true);
    //             } else {
    //                 this.flagExisteUsuarioConIdentificacion = false;
    //                 resolve(false);
    //             }
    //         });
    //     });

    // }



}
