<div class="page-title-area">
  <div class="container">
    <div class="page-title-content">
      <ul>
        <li><a routerLink="/">Inicio</a></li>
        <li><a routerLink="/configurar-administracion">Configuración
            Administración</a></li>
        <li><a routerLink="/centros-medicos-listado">Listado de Centros Médicos</a></li>
        <li>Modificar Centro Médico</li>
      </ul>
      <h2>Modificar Centro Médico</h2>
      <h4 *ngIf="this.signoCentroMedico">{{signoCentroMedico.nombre}}</h4>
    </div>
  </div>
  <div class="shape9"><img src="assets/img/shape-signomedico.png" alt="image" width="10%"></div>
</div>

<div class="andorra-apply-content ptb-70  animate__animated animate__fadeIn">
  <div class="container">
    <div class="row mx-auto">
      <div class="col-lg-12 col-md-12">
        <div class="apply-form">
          <form [formGroup]="form" (ngSubmit)="updateCentroMedico()">

            <div class="row">

              <div class="col-lg-8 col-md-8 col-xs-12">
                <div class="form-group">
                  <mat-form-field class="example-full-width" appearance="fill" [style.width.%]=100>
                    <mat-label>Centro Médico</mat-label>
                    <input formControlName="nombre" matInput placeholder="Centro Médico"
                      [class.is-invalid]="centroMedicoNoValido" autocomplete="off">
                    <mat-error *ngIf="centroMedicoNoValido">Ingrese un
                      centro médico</mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-xs-12">
                <div class="form-group">
                  <mat-slide-toggle formControlName="activo">Activo</mat-slide-toggle>
                </div>
              </div>

            </div>
            <div class="row">

              <div class="col-lg-6 col-md-6 col-xs-12">
                <div class="form-group">
                  <mat-form-field class="example-full-width" appearance="fill" [style.width.%]=100>
                    <mat-label>Calle</mat-label>
                    <input formControlName="calle" matInput placeholder="Calle"
                      [class.is-invalid]="calleNoValida" autocomplete="off">
                    <mat-error *ngIf="calleNoValida">Ingrese una
                      calle</mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-xs-12">
                <div class="form-group">
                  <mat-form-field class="example-full-width" appearance="fill" [style.width.%]=100>
                    <mat-label>Altura</mat-label>
                    <input formControlName="altura" matInput placeholder="Altura"
                      [class.is-invalid]="alturaNoValida" autocomplete="off">
                    <mat-error *ngIf="alturaNoValida">Ingrese una
                      altura</mat-error>
                  </mat-form-field>
                </div>
              </div>
              
            </div>

            <div class="row">
              <div class="col-lg-6 col-md-6 col-xs-10">
                <div class="form-group">
                  <mat-form-field class="example-full-width" appearance="fill" [style.width.%]=100>
                    <mat-label>Piso/Depto/Casa</mat-label>
                    <input formControlName="pisoDpto" matInput placeholder="Piso o Depto o Casa"
                      autocomplete="off">
                  </mat-form-field>
                </div>
              </div>

              <div class="col-lg-6 col-md-6 col-xs-12">
                <div class="form-group">
                  <mat-form-field class="example-full-width" appearance="fill" [style.width.%]=100>
                    <mat-label>Horarios</mat-label>
                    <input formControlName="horarios" type="text" matInput
                      placeholder="Lunes a Viernes de 10hrs a 18hrs" autocomplete="off">
                    <mat-icon matSuffix>access_time</mat-icon>
                  </mat-form-field>
                </div>
              </div>
            </div>

            <div class="row">

              <div class="col-lg-6 col-md-6 col-xs-12">
                <div class="form-group">
                  <mat-form-field appearance="fill" class="example-full-width" [style.width.%]=100>
                    <mat-label>Zona</mat-label>
                    <mat-select formControlName="zona" [(ngModel)]="zonaSeleccionada">
                      <mat-option *ngFor="let zona of zonas" [value]="zona._id"
                        [class.is-invalid]="zonaNoValida">{{zona.concepto}}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="zonaNoValida">Seleccione una zona</mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-xs-12">
                <div class="form-group">
                  <mat-form-field appearance="fill" class="example-full-width" [style.width.%]=100>
                    <mat-label>Localidad</mat-label>
                    <mat-select formControlName="localidad" [(ngModel)]="localidadSeleccionada">
                      <mat-option *ngFor="let localidad of localidades" [value]="localidad._id"
                        [class.is-invalid]="localidadNoValida">{{localidad.nombre}}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="localidadNoValida">Seleccione una localidad</mat-error>
                  </mat-form-field>
                </div>
              </div>

            </div>

            <div class="row">

              <div class="col-lg-6 col-md-6 col-xs-12">
                <div class="form-group">
                  <mat-form-field class="example-full-width" appearance="fill" [style.width.%]=100>
                    <mat-label>Teléfonos</mat-label>
                    <input formControlName="telefonos" type="text" matInput placeholder="11 4256 7865"
                      [class.is-invalid]="telefonosNoValidos" autocomplete="off">
                    <mat-icon matSuffix>phone</mat-icon>
                    <mat-error *ngIf="telefonosNoValidos">
                      Ingrese un teléfono
                    </mat-error>
                    <mat-hint>Telefono separados por coma</mat-hint>
                  </mat-form-field>
                </div>
              </div>

              <div class="col-lg-6 col-md-6 col-xs-12">
                <div class="form-group">
                  <mat-form-field class="example-full-width" appearance="fill" [style.width.%]=100>
                    <mat-label>Celular (Whatsapp)</mat-label>
                    <input formControlName="celular" type="text" matInput placeholder="11 4256 7865"
                      [class.is-invalid]="celularNoValido" autocomplete="off">
                    <mat-icon matSuffix>phone</mat-icon>
                    <mat-error *ngIf="celularNoValido">
                      Ingrese un celular
                    </mat-error>
                    <mat-hint>Telefono celular para whatsapp</mat-hint>
                  </mat-form-field>
                </div>
              </div>

            </div>

            <div class="row">

              <div class="col-lg-4 col-md-4 col-xs-12">
                <div class="form-group">
                  <mat-form-field appearance="fill" [style.width.%]=100>
                    <mat-label>Especialidades</mat-label>
                    <mat-select formControlName="especialidades" [(ngModel)]="especialidadesSeleccionadas"
                      multiple>
                      <mat-option *ngFor="let especialidad of especialidades"
                        [value]="especialidad._id">{{especialidad.concepto}}</mat-option>
                    </mat-select>
                    <mat-hint>(Puede seleccionar más de una o ninguna, no es requerido)</mat-hint>
                  </mat-form-field>
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-xs-12">
                <div class="form-group">
                  <mat-form-field appearance="fill" [style.width.%]=100>
                    <mat-label>Rubros</mat-label>
                    <mat-select formControlName="rubros" [(ngModel)]="rubrosSeleccionados" multiple>
                      <mat-option *ngFor="let rubro of rubros"
                        [value]="rubro._id">{{rubro.concepto}}</mat-option>
                    </mat-select>
                    <mat-hint>(Puede seleccionar más de uno o ninguno, no es requerido)</mat-hint>
                  </mat-form-field>
                </div>
              </div>

              <div class="col-lg-4 col-md-4 col-xs-12">
                <div class="form-group">
                  <mat-form-field appearance="fill" [style.width.%]=100>
                    <mat-label>Planes</mat-label>
                    <mat-select formControlName="planes" [(ngModel)]="planesSeleccionados" multiple>
                      <mat-option *ngFor="let plan of planes"
                        [value]="plan._id">{{plan.denominacion}}</mat-option>
                    </mat-select>
                    <mat-hint>(Puede seleccionar más de uno o ninguno, no es requerido)</mat-hint>
                  </mat-form-field>
                </div>
              </div>

            </div>

            <div class="row">

              <div class="col-lg-6 col-md-6 col-xs-12">
                <div class="form-group">
                  <mat-form-field class="example-full-width" appearance="fill" [style.width.%]=100>
                    <mat-label>Latitud</mat-label>
                    <input formControlName="latitud" matInput placeholder="Latitud de la ubicación"
                      autocomplete="off" (change)="updatesCoordinates()" (input)="updateMarkerLocation()">
                    <mat-error *ngIf="form.get('latitud').hasError('pattern')">Ingrese
                      una latitud válida.</mat-error>
                  </mat-form-field>
                </div>
              </div>

              <div class="col-lg-6 col-md-6 col-xs-12">
                <div class="form-group">
                  <mat-form-field class="example-full-width" appearance="fill" [style.width.%]=100>
                    <mat-label>Longitud</mat-label>
                    <input formControlName="longitud" matInput placeholder="Longitud de la ubicación"
                      autocomplete="off" (change)="updatesCoordinates()" (input)="updateMarkerLocation()">
                    <mat-error *ngIf="form.get('longitud').hasError('pattern')">Ingrese
                      una longitud válida.</mat-error>
                  </mat-form-field>
                </div>
              </div>

            </div>

            <div class="row">
              <div class="col-lg-12 col-md-12 col-sm-12">
                <app-map></app-map>
              </div>
            </div>

            <div class="row">

              <div class="col-lg-4 col-md-4">
                <button type="button" routerLink="/centros-medicos-listado"
                  class="btn btn-warning">Cancelar</button>
              </div>

              <div class="col-lg-4 col-md-4">
                <button [disabled]="!form.valid" type="submit">Guardar</button>
              </div>

            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>