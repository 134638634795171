import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { User } from '../../../../../interfaces/interface-bakend/seguridad/users/user.interface';
import { Translators } from '../../../../../services/shared/translators.service';
import { GuiToolsService } from '../../../../../services/shared/gui-tools.service';
import { SignoSedeService } from '@services/entidades/signo-sedes.service';
import { SignoPlanService } from '@services/entidades/signo-planes.service';
import { SignoEmpresaService } from '@services/entidades/signo-empresas.service';
@Component({
  selector: 'app-modal-confirmar',
  templateUrl: './modal-confirmar.component.html',
  styleUrls: ['./modal-confirmar.component.scss']
})
export class ModalConfirmarComponent implements OnInit {

  idioma: Subscription;
  sedeSelecionada: string = '';
  planSelecionado: string = '';
  empresaSelecionada: string = '';

  birth: string;
  pasaporteValido: boolean;


  constructor(
    /* Modal Ref. */
    public dialogRef: MatDialogRef<ModalConfirmarComponent>,
    @Inject(MAT_DIALOG_DATA) public data: User,
    /* Fin Modal Ref. */
    private guiToolsService: GuiToolsService,
    private translatorService: Translators,
    private signoSedeService: SignoSedeService,
    private signoPlanesService: SignoPlanService,
    private signoEmpresasService: SignoEmpresaService
  ) {
    this.idioma = this.translatorService.getIdiomaSeleccionado().subscribe(resp => {
      this.idioma = resp;
    });
  }

  async ngOnInit(): Promise<void> {
    this.birth = this.guiToolsService.dateTransform(this.data.birth);
    await this.getSedeSeleccionada();
    await this.getPlanSeleccionado();
    await this.getEmpresaSeleccionada();
  }

  dateTransform(date: string) {
    return this.guiToolsService.dateTransform(date);
  }

  async getSedeSeleccionada() {
    const signoSedes = this.data?.signoSedes != undefined ? this.data?.signoSedes : null;
    if (signoSedes && signoSedes.length > 0) {
      const sede = signoSedes[0] as string;
      this.sedeSelecionada = (await this.signoSedeService.getSedeById(sede)).nombre;
    }
  }

  async getPlanSeleccionado() {
    const signoPlan = this.data?.signoPlan != undefined ? this.data?.signoPlan : null;
    if (signoPlan) {
      this.planSelecionado = (await this.signoPlanesService.getPlanById(signoPlan)).denominacion;
    }
  }

  async getEmpresaSeleccionada() {
    const signoEmpresa = this.data?.signoEmpresas != undefined ? this.data?.signoEmpresas : null;
    if (signoEmpresa && signoEmpresa.length > 0) {
      const empresa = signoEmpresa[0] as string;
      this.empresaSelecionada = (await this.signoEmpresasService.getEmpresaById(empresa)).nombre;
    }
  }

  aceptar() {
    this.dialogRef.close(true);
  }

  cancelar() {
    this.dialogRef.close(false);
  }

}
