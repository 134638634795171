<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li *ngFor="let route of routes">
                    <a routerLink="{{route.url}}">{{route.label}}</a>
                </li>
            </ul>
            <h2>Configurar Administración</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape-signomedico.png"
             alt="image"
             width="10%"></div>
    </div>   

<div class="features-area pb-70 animate__animated animate__fadeInDown">
    <div class="container">

       <app-menu></app-menu>

        <div class="row m-5">

            <div class="col-lg-4 col-sm-6 col-md-6 col-xs-12 mb-5">
                <div class="single-features-box">
                    <div class="icon">
                        <i class="fas fa-clinic-medical"
                           style="color: #0d6efd;"></i>
                    </div>
                    <h3>Listado de Centros Médicos</h3>
                    <p>Acceda al listado de <strong>Centros Médicos
                        </strong> del
                        sistema</p>
                    <a routerLink="/centros-medicos-listado"
                       class="btn btn-primary">Ver Centros Médicos</a>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-6 col-xs-12 mb-5">
                <div class="single-features-box">
                    <div class="icon">
                        <i class="fas fa-hospital"
                           style="color: #6c757d;"></i>
                    </div>
                    <h3>Listado de Rubros</h3>
                    <p>Acceda al listado de <strong>Rubros</strong> del sistema
                    </p>
                    <a routerLink="/rubros-listado"
                       class="btn btn-secondary">Ver
                        Rubros</a>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-6 col-xs-12 mb-5">
                <div class="single-features-box">
                    <div class="icon">
                        <i class="fas fa-hospital-user"
                           style="color: #198754"></i>
                    </div>
                    <h3>Listado de Especialidades</h3>
                    <p>Acceda al listado de <strong>Especialidades</strong> del
                        sistema</p>
                    <a routerLink="/especialidades-listado"
                       class="btn btn-success">Ver Especialidades</a>
                </div>
            </div>
        </div>

        <div class="row m-5">

            <div class="col-lg-4 col-sm-6 col-md-6 col-xs-12 mb-5">
                <div class="single-features-box">
                    <div class="icon">
                        <i class="fas fa-map-pin"
                           style="color: #dc3545;"></i>
                    </div>
                    <h3>Listado de Zonas</h3>
                    <p>Acceda al listado de <strong>Zonas</strong> del sistema
                    </p>
                    <a routerLink="/zonas-listado"
                       class="btn btn-danger"
                       role="button">Ver
                        Zonas</a>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-6 col-xs-12 mb-5">
                <div class="single-features-box">
                    <div class="icon">
                        <i class="fas fa-map-marker-alt"
                           style="color: #ffc107;"></i>
                    </div>
                    <h3>Listado de Localidades</h3>
                    <p>Acceda al listado de <strong>Localidades</strong> del sistema
                    </p>
                    <a routerLink="/localidades-listado"
                       class="btn btn-warning">Ver
                        Localidades</a>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-6 col-xs-12 mb-5">
                <div class="single-features-box">
                    <div class="icon">
                        <i class="fas fa-hospital-alt"
                           style="color: #0dcaf0"></i>
                    </div>
                    <h3>Listado de Centros Propios</h3>
                    <p>Acceda al listado de <strong>Centros
                            Propios</strong> del sistema</p>
                    <a routerLink="/centros-propios-listado"
                       class="btn btn-info">Ver Centros</a>
                </div>
            </div>

        </div>

        <div class="row m-5">

            <div class="col-lg-4 col-sm-6 col-md-6 col-xs-12 mb-5">
                <div class="single-features-box">
                    <div class="icon">
                        <i class="fas fa-user-md"
                           style="color: #d35400;"></i>
                    </div>
                    <h3>Listado de Centros Propios Doctores</h3>
                    <p>Acceda al listado de <strong>Centros Propios Doctores</strong> del
                        sistema</p>
                    <a routerLink="/doctores-listado"
                       class=" btn btn-light custom-color-pumpkin">Ver
                        Centros Propios Doctores</a>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-6 col-xs-12 mb-5">
                <div class="single-features-box">
                    <div class="icon">
                        <i class="fas fa-briefcase-medical"
                           style="color: #212529;"></i>
                    </div>
                    <h3>Listado de Centros Propios Especialidades</h3>
                    <p>Acceda al listado de <strong>Centros Propios
                            Especialidades</strong> del
                        sistema</p>
                    <a routerLink="/centros-propios-especialidades-listado"
                       class="btn btn-dark">Ver
                        Centros Propios
                        Especialidades</a>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-6 col-xs-12 mb-5">
                <div class="single-features-box">
                    <div class="icon">
                        <i class="fas fa-users"
                           style="color: #34495e;"></i>
                    </div>
                    <h3>Listado de Afiliados</h3>
                    <p>Acceda al listado de <strong>Afiliados</strong> del sistema
                    </p>
                    <a routerLink="/afiliados-listado"
                       class="btn btn-light custom-color-wet-asphalt">Ver
                        Afiliados</a>
                </div>
            </div>

        </div>

        <div class="row m-5">

            <div class="col-lg-4 col-sm-6 col-md-6 col-xs-12 mb-5">
                <div class="single-features-box">
                    <div class="icon">
                        <i class="fas fa-handshake"
                           style="color: #8e44ad;"></i>
                    </div>
                    <h3>Listado de Sedes</h3>
                    <p>Acceda al listado de <strong>Sedes</strong> del sistema
                    </p>
                    <a routerLink="/sedes-listado"
                       class="btn btn-light custom-color-wisteria">Ver
                        Sedes</a>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-6 col-xs-12 mb-5">
                <div class="single-features-box">
                    <div class="icon">
                        <i class="fas fa-calendar-check"
                           style="color: #D980FA;"></i>
                    </div>
                    <h3>Listado de Autorizaciones</h3>
                    <p>Acceda al listado de <strong>
                            Autorizaciones
                        </strong> del sistema
                    </p>
                    <a routerLink="/autorizaciones-listado"
                       class="btn btn-light custom-color-lavender-tea">Ver
                        Autorizaciones</a>
                </div>
            </div>

            
            <div class="col-lg-4 col-sm-6 col-md-6 col-xs-12 mb-5">
                <div class="single-features-box">
                    <div class="icon">
                        <i class="fas fa-building"
                           style="color: #1abc9c;"></i>
                    </div>
                    <h3>Listado de Empresas</h3>
                    <p>Acceda al listado de <strong>Empresas</strong> del
                        sistema</p>
                    <a routerLink="/empresas-listado"
                       class="btn btn-light custom-color-turquoise">Ver
                        Empresas</a>
                </div>
            </div>

            <!-- <div class="col-lg-4 col-sm-6 col-md-6 col-xs-12 mb-5">
                <div class="single-features-box">
                    <div class="icon">
                        <i class="fas fa-spell-check"
                           style="color: #1B1464;"></i>
                    </div>
                    <h3>Autorizaciones por filtro</h3>
                    <p>
                        Acceda a las autorizaciones del sistema filtrando por sede o estado.
                    </p>
                    <a routerLink="/autorizaciones-filtros"
                       class="btn btn-light custom-color-under-the-sea">Ver
                        Autorizaciones por filtro</a>
                </div>
            </div> -->

        </div>

        <div class="row m-5">

            <div class="col-lg-4 col-sm-6 col-md-6 col-xs-12 my-5">
                <div class="single-features-box">
                    <div class="icon">
                        <i class="far fa-credit-card"
                           style="color: #c0392b;"></i>
                    </div>
                    <h3>Listado de Planes</h3>
                    <p>Acceda al listado de <strong>Planes</strong> del
                        sistema</p>
                    <a routerLink="/planes-listado"
                       class="btn btn-light custom-color-pomegranate">Ver
                        Planes</a>
                </div>
            </div>

        </div>
    </div>