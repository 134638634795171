<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">{{ idioma | translate: "navbar.UL-INICIO" | async}}</a></li>
                <li>{{ idioma | translate: "form.MI-CUENTA" | async}}</li>
            </ul>
            <h2>{{ idioma | translate: "form.MI-CUENTA" | async}}</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<app-loading *ngIf="!flagLoaded" class="m-5"></app-loading><br>

<div class="profile-area pb-70 mt-5">
    <div class="container">
        <div class="profile-box animate__animated animate__fadeIn" *ngIf="flagLoaded">
            <div class="row align-items-right">
                <div class="col-lg-4 col-md-4 col-xs-12">
                    <div class="image">
                        <div matRipple [matRippleCentered]="centered" [matRippleDisabled]="disabled"
                            [matRippleUnbounded]="unbounded" [matRippleRadius]="radius" [matRippleColor]="color">

                            <ng-container *ngIf="this.usuario.avatar == 'av-1.png' else elseTemplate">
                                <img [src]="imageUrl" class="rounded float-start" width="300px" />
                            </ng-container>
                            <ng-template #elseTemplate>
                                <img [src]=" this.usuario._id | mediaUsuariosPipe: this.usuario.avatar | safe"
                                    [alt]="this.usuario.avatar" class="rounded float-start" width="300px">
                            </ng-template>

                        </div>
                    </div>
                    <br>
                    <div>
                        <form [formGroup]="formImg" (ngSubmit)="guardarImg()">
                            <div class="form-group">
                                <input [disabled]="condition" class="file-input" type="file" formControlName="imagen"
                                    #fileInput accept="image/jpeg, image/jpg, image/png"
                                    (change)="onChangeFile(fileInput.files[0], $event)" />
                            </div>
                            <!--<div class="material-ripple-container mat-elevation-z4 mt-5" matRipple [matRippleCentered]="centered" [matRippleDisabled]="disabled" [matRippleUnbounded]="unbounded" [matRippleRadius]="radius" [matRippleColor]="color">
                                <img [src]="imageUrl" />
                            </div>-->
                            <div class="form-group">
                                <button [disabled]="!formImg.valid || this.cargandoArchivo" type="submit"
                                    class="button button-xs" disabled>{{ idioma | translate: "form.GUARDAR-FOTO" |
                                    async}}</button>
                            </div>

                        </form>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 col-xs-12">
                    <div class="content animate__animated animate__fadeIn" *ngIf="flagLoaded">
                        <h4>{{ idioma | translate: "form.DATOS-PERSONALES" | async}}</h4>

                        <h3 *ngIf="this.usuario.nombrePersona">{{ this.usuario.nombrePersona }} {{
                            this.usuario.apellidoPersona }}</h3>
                        <p *ngIf="this.usuario.presentacion" style="text-align: justify;">{{ this.usuario.presentacion
                            }}</p>
                        <ul class="info">
                            <li *ngIf="this.usuario.telefono"><span>{{ idioma | translate: "form.NUMERO-TELEFONO" |
                                    async}}: </span>{{ this.usuario.paisCodeArea }} - {{ this.usuario.telefono }}</li>
                            <li *ngIf="this.usuario.email"><span>{{ idioma | translate: "form.EMAIL" | async}}: </span>
                                {{ this.usuario.email }}</li>
                            <li *ngIf="this.usuario.birth">
                                <span>{{ idioma | translate: "form.FECHA-NACIMIENTO" | async}}:</span>
                                {{ this.usuario.birth | date: 'dd-MM-yyyy':'UTC'}}
                            </li>
                        </ul>

                        <!-- Button trigger modal -->
                        <div class="mt-5">
                            <button type="button" style="margin-right: 2%;" class="button btn-xs"
                                (click)="this.openDialogModificarClave()">
                                {{ idioma | translate: "form.MODIFICAR-CLAVE" | async}}
                            </button>
                            <!-- <button type="button" class="button" data-bs-toggle="modal" data-bs-target="#modalPresentacion">
                                Modificar presentación
                            </button> -->
                        </div>

                    </div>


                </div>
                <div class="col-lg-4 col-md-6 col-xs-12">
                    <div class="content animate__animated animate__fadeIn" *ngIf="flagLoadedGecros && gecrosAfiliado">
                        <h4>Datos Afiliado Gecros</h4>
                        <h3 *ngIf="this.gecrosAfiliado.nombreAfiliado">{{ this.gecrosAfiliado.nombreAfiliado }}</h3>
                        <ul class="info">
                            <li *ngIf="this.gecrosAfiliado.email">Email: <strong>{{this.gecrosAfiliado.email}}</strong>
                            </li>
                            <li *ngIf="this.gecrosAfiliado.numeroAfiliado">Nro. Afiliado:
                                <strong>{{this.gecrosAfiliado.numeroAfiliado}}</strong></li>
                            <li *ngIf="this.sedeAfiliado">Sede: <strong>{{sedeAfiliado}}</strong></li>
                            <li *ngIf="this.gecrosAfiliado.plan">Plan: <strong>{{this.gecrosAfiliado.plan}}</strong>
                            </li>
                            <li *ngIf="this.gecrosAfiliado.estado">Estado:
                                <strong>{{this.gecrosAfiliado.estado}}</strong>
                            </li>
                            <li *ngIf="this.gecrosAfiliado.convenio">Convenio:
                                <strong>{{this.gecrosAfiliado.convenio}}</strong>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Modal -->
<div class="modal fade" id="modalPresentacion" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Modificar presentación</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <form [formGroup]="formPresentacion" (ngSubmit)="guardarPresentacion()">

                    <div class="form-group">
                        <mat-form-field class="example-full-width" appearance="fill" [style.width.%]=100>
                            <mat-label>Presentación</mat-label>
                            <textarea formControlName="presentacion" rows="5" matInput
                                placeholder="Ingrese una presentación"></textarea>
                        </mat-form-field>
                    </div>

                    <div class="modal-footer">
                        <button type="submit" class="button">Guardar</button>
                    </div>
                </form>
            </div>

        </div>
    </div>
</div>