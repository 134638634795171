import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { UserList } from '../../../../interfaces/interface-bakend/seguridad/users/personas.interface';
import { PersonasService } from '../../../../services/shared/personas.service';
import { User } from '../../../../interfaces/interface-bakend/seguridad/users/user.interface';
import { MatDialog } from '@angular/material/dialog';
import { ModalDetalleGecrosComponent } from '../modal-detalle-gecros/modal-detalle-gecros.component';
import { ModalDetalleComponent } from '../modal-detalle/modal-detalle.component';
import { GuiMsjService } from '@services/shared/gui-msj.service';
import { SignoSede } from '@interfaces/interface-bakend/entidades/signo-sede.interface';
import { SignoSedeService } from '@services/entidades/signo-sedes.service';
import { PreviousRouteService } from '@services/shared/previous-route.service';
import Swal from 'sweetalert2';
import { UsuarioService } from '@services/shared/usuario.service';
import { CursosService } from 'src/app/services/cursos/cursos.service';
import { AlumnoTitulo } from '@interfaces/interface-bakend/cursos/titulo.interface';
import { ModalModificarClaveUsersComponent } from '../modal-modificar-clave/modal-modificar-clave.component';

@Component({
    selector: 'app-personas-listado',
    templateUrl: './personas-listado.component.html',
    styleUrls: ['./personas-listado.component.scss']
})
export class PersonasListadoComponent implements OnInit, AfterViewInit {

    /* Data Table*/

    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    displayedColumns: string[] =
        [
            'nombre', 'apellido', 'nacionalidad', 'identificacion', 'fechaNacimiento', 'email', 'telefono', '_id'
        ];
    dataSource: any;
    personas: UserList[];

    /* Fin Data Table*/

    routes: { url: string, label: string }[] = [];

    usuarioLoad = false;
    flagLoaded = false;
    sedes: SignoSede[];

    constructor(
        private personasService: PersonasService,
        private router: Router,
        private previousRouteService: PreviousRouteService,
        public dialog: MatDialog,
        private guiMsjService: GuiMsjService,
        private signoSedeService: SignoSedeService,
        private usuariosService: UsuarioService,
        private cursosService: CursosService,
    ) { }

    ngOnInit(): void {
        this.previousRouteService.getRoutes().subscribe(routes => {
            this.routes = routes;
        });
    }

    async ngAfterViewInit() {
        await this.loadDataPage();
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    }

    verDetalle(idSede: string, idUser: string): void {
        const dialogRef = this.dialog.open(ModalDetalleComponent, {
            width: '600px',
            height: '300px',
            disableClose: true,
            data: { idUser, idSede }
        });

        dialogRef.afterClosed().subscribe(resp => {
        });
    }

    async verDetalleGecros(user: User) {
        const dialogRef = this.dialog.open(ModalDetalleGecrosComponent, {
            width: '900px',
            height: '600px',
            disableClose: true,
            data: user
        });

        dialogRef.afterClosed().subscribe(resp => {
        });
    }

    async getSedes() {
        this.sedes = await this.signoSedeService.getSedes();
    }

    async verPersona(id: string) {
        await this.guiMsjService.msjFormSubmit('Espere');
        Swal.showLoading();
        this.personasService.setPersonaSeleccionada(await this.personasService.getById(id));
        console.log(await this.personasService.getPersonaSeleccionada());
        Swal.close();
        this.router.navigateByUrl('/afiliado-modificacion');
    }

    async loadDataTable() {
        await this.personasService.getAllPersonas().then((personas: User[]) => {
            this.dataSource = new MatTableDataSource(personas);
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
        });
    }

    async loadDataPage() {
        await this.loadDataTable();
        this.flagLoaded = true;
        return;
    }

    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }

    async verUsuario(_id: string) {
        await this.usuariosService.getFindOne(_id);
        this.router.navigate(['/usuario-modificacion'], { queryParams: { desdeAfiliados: true } });
    }

    /* Modal Modificar Clave */
    openDialogModificarClave(idUsuario: string): void {

        const dialogRef = this.dialog.open(ModalModificarClaveUsersComponent, {
            width: '800px',
            height: '820px',
            disableClose: true,
            data: idUsuario,
        });

        dialogRef.afterClosed().subscribe(resp => {
        });
    }
}
