import { Response } from 'src/app/interfaces/interface-bakend/response';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import { StorageService } from '../shared/storage.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { GuiMsjService } from '../shared/gui-msj.service';
import { SignoLocalidad } from 'src/app/interfaces/interface-bakend/entidades/signo-localidad.interface';
import Swal from 'sweetalert2';

const URL_BACKEND = environment.urlBackend;

@Injectable({ providedIn: 'root' })
export class SignoLocalidadService {

signoLocalidadSeleccionada : SignoLocalidad;

    constructor(
        private httpClient: HttpClient,
        private router: Router,
        private guiMsjService: GuiMsjService,
        private authService: AuthService,
        private storageService: StorageService
    ) {}
    async create(localidad: SignoLocalidad): Promise<SignoLocalidad> {
        const headers = new HttpHeaders({
            'x-token': await this.authService.loadJwToken(),
        });

        return new Promise(async (resolve, reject) => {
            await this.httpClient
                .post<Response>(`${URL_BACKEND}/signo-localidades`, localidad, {
                    headers,
                })
                .subscribe(
                    (resp) => {
                        Swal.close();

                        Swal.fire({
                            icon: 'success',
                            title: 'Localidad guardada satisfactoriamente',
                            showConfirmButton: false,
                            timer: 1100,
                        });

                        this.router.navigate(['/localidades-listado']);
                        resolve(resp.response);
                    },
                    (error) => {
                        console.error(error);
                        this.guiMsjService.msjFormSubmit('crearLocalidadError');
                        this.router
                            .navigateByUrl('/', { skipLocationChange: true })
                            .then(async () => {
                                await this.router.navigateByUrl('/');
                            });
                        reject(error);
                    }
                );
        });
    }

    getLocalidadSeleccionada(): SignoLocalidad {
        return this.signoLocalidadSeleccionada;
    }

    async getLocalidades(): Promise<SignoLocalidad[]> {
        return new Promise(async (resolve) => {
            const headers = new HttpHeaders({
                'x-token': await this.authService.loadJwToken(),
            });

            this.httpClient
                .get<Response>(`${URL_BACKEND}/signo-localidades/findAll`, {
                    headers,
                })
                .subscribe((resp: Response) => {
                    resolve(resp.response);
                });
        });
    }

    async getLocalidadById(id: string): Promise<SignoLocalidad> {
        return new Promise(async (resolve) => {
            const headers = new HttpHeaders({
                'x-token': await this.authService.loadJwToken(),
            });

            this.httpClient
                .get<Response>(`${URL_BACKEND}/signo-localidades/findOne/${id}`, {
                    headers,
                })
                .subscribe((resp: Response) => {
                    Swal.close();
                    resolve(resp.response);
                });
        });
    }

    async updateLocalidad(_id: string, localidad: SignoLocalidad): Promise<SignoLocalidad> {
        return new Promise(async (resolve) => {
            const headers = new HttpHeaders({
                'x-token': await this.authService.loadJwToken(),
            });
            this.httpClient
                .put<Response>(
                    `${URL_BACKEND}/signo-localidades/updateOne/${_id}`,
                    localidad,
                    { headers }
                )
                .subscribe(
                    (resp: Response) => {
                        resolve(resp.response);
                    },
                    (error) => {
                        console.error(error);
                        this.guiMsjService.msjFormSubmit('localidadDuplicada');
                    }
                );
        });
    }

    setLocalidadSeleccionada(localidad: SignoLocalidad) {
        this.signoLocalidadSeleccionada = localidad;
        this.storageService.setLocalidad( this.signoLocalidadSeleccionada);
    }
}
