export enum IdiomaKeys {
  AR = 'ar',
  BR = 'br',
  CA = 'ca',
  DE = 'de',
  EN = 'en',
  ES = 'es',
  FR = 'fr',
  IT = 'it',
}

export enum DiccionarioKeys {
  I18NFILE = 'general-service-signomedico',
}