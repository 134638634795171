import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SignoSedeService } from 'src/app/services/entidades/signo-sedes.service';
import { SignoSede } from 'src/app/interfaces/interface-bakend/entidades/signo-sede.interface';
import { Router } from '@angular/router';
import { GuiMsjService } from 'src/app/services/shared/gui-msj.service';

@Component({
    selector: 'app-sede-alta',
    templateUrl: './sede-alta.component.html',
    styleUrls: ['./sede-alta.component.scss'],
})
export class SedeAltaComponent implements OnInit {
    form: FormGroup;

    constructor(
        private signoSedeService: SignoSedeService,
        private route: Router,
        private guiMsjService: GuiMsjService
    ) {
        this.createForm();
    }

    ngOnInit(): void { }

    get codigoNoValido() {
        return this.form.get('codigo').invalid;
    }

    get nombreNoValido() {
        return this.form.get('nombre').invalid;
    }

    get emailNoValido() {
        return this.form.get('email').invalid;
    }

    get telefonoNoValido() {
        return this.form.get('telefono').invalid;
    }

    get whatsappNoValido() {
        return this.form.get('whatsapp').invalid;
    }

    get detalleNoValido() {
        return this.form.get('detalle').invalid;
    }

    createForm() {
        this.form = new FormGroup({
            codigo: new FormControl('', [
                Validators.required
            ]),
            nombre: new FormControl('', [
                Validators.required,
                Validators.minLength(3),
                Validators.maxLength(50),
            ]),
            email: new FormControl('', [
                Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,3}$'),
            ]),
            telefono: new FormControl('', [  
                Validators.minLength(3),
                Validators.maxLength(50),
                Validators.pattern('[0-9+-]+$')
            ]),
            whatsapp: new FormControl('', [
                Validators.minLength(3),
                Validators.maxLength(50),
                Validators.pattern('[0-9+-]+$')
            ]),
            consultorioInterno: new FormControl(true),
            detalle: new FormControl('', [
                Validators.minLength(3),
                Validators.maxLength(256),
            ]),
        });
    }

    async createSede() {
        await this.guiMsjService.msjFormSubmit('Guardando');
        this.signoSedeService.create(await this.setDataForma())
        await this.guiMsjService.msjFormSubmit('DatosGuardados');
        this.route.navigateByUrl('/sedes-listado');
    }

    async setDataForma(): Promise<SignoSede> {
        const sede: SignoSede = {
            codigoGecro: this.form.value.codigo || "",
            nombre: this.form.value.nombre || "",
            email: this.form.value.email || "",
            telefono: this.form.value.telefono || "",
            whatsapp: this.form.value.whatsapp || "",
            consultorioInterno: this.form.value.consultorioInterno || false,
            detalle: this.form.value.detalle || "",
        };

        return sede;
    }
}
